import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'br-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() type!: string;
  @Input() code!: string;
  @Input() message!: string;

  constructor(
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void { }

  routeToEditBooking() {
    this.modalService.dismissAll();
    this.router.navigate(['my-account', 'edit-booking', this.code]);
  }

  close() {
    this.modalService.dismissAll();
  }

}
