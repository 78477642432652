<div class="row align-items-center item-wrapper-inner">
  <!-- item image -->
  <div class="col-lg-3 item-img">
    <img class="rounded-2 img-fluid w-100" [src]="itemImage" alt="" />
  </div>
  <!-- item content -->
  <div class="col-lg-9 mt-small-20 item-content">
    <!-- title, location, Rate-->
    <div class="d-flex align-items-start justify-content-between mb-2">
      <div class="d-flex flex-column align-items-start justify-content-between">
        <h2 class="text-dark">{{ getTranslatedData(item?.name!) }}</h2>
        <span
          class="location text-primary d-flex align-items-start justify-content-start"
        >
          <i class="bi bi-geo-alt-fill me-1"></i>
          <span>{{ item?.location }}</span>
        </span>
      </div>
      <!-- <span class="text-lg text-dark fs-3 fw-bold">€ 22,55<span class="fs-5 fw">/hr</span></span> -->
    </div>

    <!-- Amenities -->
    <div class="amenities">
      <ul class="list flex-wrap">
        <!-- <li class="list-item"> <i class="br-icon-person fs-5 me-1"></i> An item</li> -->
        <li class="list-item" *ngIf="item?.persons_capacity">
          <i class="br-icon-electric-propultion fs-5 me-1"></i>
          {{ item?.persons_capacity }}
          {{ "CategoryItem.Person" | translate }}
        </li>
        <li class="list-item" *ngIf="item?.seating_capacity">
          <i class="br-icon-electric-propultion fs-5 me-1"></i>
          {{ item?.seating_capacity }} {{ "CategoryItem.Person" | translate }}
        </li>
        <li class="list-item" *ngIf="item?.battery_capacity">
          <i class="br-icon-battery fs-5 me-1"></i>
          {{ "CategoryItem.Battery" | translate }} {{ item?.battery_capacity }}%
        </li>
      </ul>
    </div>

    <!-- Prices and reservation -->
    <div class="reservations mt-3">
      <h4 class="fs-6 fw-bold">
        {{ "CategoryItem.PricesAndReservation" | translate }}
      </h4>
      <ul class="list">
        <li
          class="list-item col-6 col-lg-4"
          *ngFor="let avalability of availabilities"
          (click)="checkAvailability(item?.id!, avalability)"
          [ngClass]="avalability?.selected ? 'active' : ''"
        >
          <div
            class="item-inner"
            [ngStyle]="{
              'justify-content': avalability?.price ? 'space-between' : 'center'
            }"
          >
            <span class="time">{{ getAvailabilityTitle(avalability) }}</span>
            <span class="price fw-bold" *ngIf="avalability?.price">{{
              avalability?.price | currency: "EUR":"symbol":"1.2-2":locale
            }}</span>
          </div>
        </li>
      </ul>
    </div>

    <!-- Seats for Cruise Category -->
    <ng-container *ngIf="(isCruise || isGame) && hasSelection">
      <form class="prices-form">
        <div class="row align-items-center justify-content-start holder">
          <div
            class="col-xl-5 col-12 d-flex align-items-center justify-content-start"
          >
            <div class="col-auto">
              <label class="col-form-label" *ngIf="isCruise">
                {{ "CategoryItem.Seats" | translate }}
              </label>
              <label class="col-form-label" *ngIf="isGame">
                {{ "CategoryItem.NoOfPersons" | translate }}
              </label>
            </div>
            <div class="col">
              <select
                class="form-select"
                [(ngModel)]="selectedSeats"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="updateCapacities()"
              >
                <option *ngFor="let item of seatOptions" [ngValue]="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <!--          <div class="col-md-auto ms-auto">-->
          <!--            <button-->
          <!--              class="btn btn-primary text-white px-5 mt-small-20"-->
          <!--              (click)="changeReservation($event)"-->
          <!--            >-->
          <!--              {{ "Buttons.Booking" | translate }}-->
          <!--            </button>-->
          <!--          </div>-->
        </div>
      </form>
    </ng-container>

    <!--    <ng-template #otherItems>-->
    <!-- Prices Selection -->
    <form class="prices-form mb-3" *ngIf="hasCapacity">
      <!-- adults and children selection -->
      <div
        class="row holder align-items-end"
        *ngIf="item?.seating_capacity! == 1"
      >
        <div class="col-sm-6 col-md-4">
          <div class="col-auto">
            <input
              class="me-2"
              type="radio"
              name="adults_or_children"
              [(ngModel)]="adultOrChildren"
              value="adult"
            />
            <label class="col-form-label">
              {{ "CategoryItem.Adults" | translate }}
            </label>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="col-auto">
            <input
              class="me-2"
              type="radio"
              name="adults_or_children"
              [(ngModel)]="adultOrChildren"
              value="children"
            />
            <label class="col-form-label">
              {{ "CategoryItem.Children" | translate }} 3-11
            </label>
          </div>
        </div>
      </div>

      <div
        class="row holder align-items-end"
        *ngIf="item?.seating_capacity! > 1 || item?.persons_capacity! > 1"
      >
        <div class="col-sm-6 col-md-4">
          <label class="col-form-label fw-bold">
            {{ "CategoryItem.Adults" | translate }}
          </label>
          <select
            class="form-select"
            [(ngModel)]="selectedNoOfAdult"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateCapacities()"
          >
            <option [ngValue]="0" [selected]="true">
              {{ "Common.ChooseHere" | translate }}
            </option>
            <option *ngFor="let item of adultOptions" [ngValue]="item">
              <ng-container *ngIf="isFixedPrice; else variablePrice">
                {{ item }}
              </ng-container>
              <ng-template #variablePrice>
                {{ item }} x
                {{ adultPrice | currency: "EUR":"symbol":"1.2-2":locale }} =
                {{
                  item * adultPrice | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </ng-template>
            </option>
          </select>
        </div>

        <!-- prices for adults and child -->
        <div class="col-sm-6 col-md-4">
          <label class="col-form-label fw-bold">
            {{ "CategoryItem.Children" | translate }} 3-11
          </label>
          <select
            class="form-select"
            [(ngModel)]="selectedNoOfChild"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateCapacities()"
          >
            <option [ngValue]="0" [selected]="true">
              {{ "Common.ChooseHere" | translate }}
            </option>
            <option *ngFor="let item of childOptions" [ngValue]="item">
              <ng-container *ngIf="isFixedPrice; else variablePrice">
                {{ item }}
              </ng-container>
              <ng-template #variablePrice>
                {{ item }} x
                {{ childPrice | currency: "EUR":"symbol":"1.2-2":locale }} =
                {{
                  item * childPrice | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </ng-template>
            </option>
          </select>
        </div>
      </div>

      <!-- Complementary Items -->
      <div class="row holder align-items-end">
        <div class="col-12">
          <hr />
        </div>
        <div class="col-sm-8 col-md-8">
          <div class="row complentery-row">
            <div class="col-12">
              <!--              <div *ngIf="item?.category?.type !== 'game'">-->
              <!--                <a-->
              <!--                  class="d-inline-block underline text-info"-->
              <!--                  (click)="showExtraItemModal()"-->
              <!--                >-->
              <!--                  {{ "Cart.ExtraItems" | translate }}-->
              <!--                </a>-->
              <!--              </div>-->
              <div>
                <a
                  class="d-inline-block underline text-info"
                  (click)="showComplementaryItemsModal()"
                >
                  {{ "Cart.Complementaries" | translate }}
                </a>
              </div>
            </div>
            <ng-container *ngIf="hasExtraItems">
              <div class="col-lg-8 col-6">
                <div>
                  <strong>{{
                    "Cart.ComplimentaryExtraItems" | translate
                  }}</strong>
                </div>
              </div>
              <div class="col-lg-2 col-3">
                <div>
                  <strong>{{ "Cart.Price" | translate }}</strong>
                </div>
              </div>
              <div class="col-lg-2 col-3">
                <div>
                  <strong>{{ "Cart.Total" | translate }}</strong>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- Complementary Items -->
          <div
            class="row complentery-row"
            *ngFor="let ci of complementaryItems"
          >
            <div class="col-lg-8 col-6">
              <div>
                {{ ci.quantity }} x {{ ci.title }}
                <a class="d-inline-block underline ps-2 text-info" (click)="showAllergicItemDialog(ci)">
                  {{ "Complementaries.ViewEdit" | translate }}
                </a>
              </div>
            </div>
            <div class="col-lg-2 col-3">
              <div>
                {{ ci?.price | currency: "EUR":"symbol":"1.2-2":locale }}
              </div>
            </div>
            <div class="col-lg-2 col-3">
              <div>
                <strong>{{
                  ci?.price! * ci?.quantity!
                    | currency: "EUR":"symbol":"1.2-2":locale
                }}</strong>
              </div>
            </div>
          </div>

          <!-- Extra Items -->
          <div class="row complentery-row" *ngFor="let ei of extraItems">
            <div class="col-lg-8 col-6">
              <div>{{ ei.pivot_quantity }} x {{ ei.name }}</div>
            </div>
            <div class="col-lg-2 col-3">
              <div>
                {{ ei?.price | currency: "EUR":"symbol":"1.2-2":locale }}
              </div>
            </div>
            <div class="col-lg-2 col-3">
              <div>
                <strong>{{
                  ei?.price! * ei?.pivot_quantity!
                    | currency: "EUR":"symbol":"1.2-2":locale
                }}</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-auto ms-auto">
          <button
            class="btn btn-primary text-white px-5 mt-small-20"
            (click)="changeReservation($event)"
          >
            {{ "Buttons.Booking" | translate }}
          </button>
        </div>
      </div>
    </form>
    <!--    </ng-template>-->
  </div>
</div>
