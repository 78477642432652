<!-- BreadCrumbs -->
<div class="container">
    <div class="row">
        <div class="col breadCrumbs-wrapper">
            <i class="bi bi-house-door pe-auto" routerLink="/home"></i>
            <i class="bi bi-chevron-right"></i>
            <app-ng-dynamic-breadcrumb class="breadCrumb-link" [lastLinkColor]="'#000'" [symbol]="'>'">
            </app-ng-dynamic-breadcrumb>
        </div>
    </div>
</div>

<!-- /BreadCrumbs -->
<div class="container static-wrapper">
    <div class="row m-0">
        <div class="col col-12 p-0">
            <div class="feature-img">
                <h1>{{ page?.name }}</h1>
                <img class="img-fluid rounded"
                    [src]="page?.images ? page?.images?.url! : '../../../assets/images/staticPages/faq.png'" alt="">
            </div>
        </div>
        <div class="col col-12 static-content" [innerHtml]="page?.content"></div>
    </div>
</div>