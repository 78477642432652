<div class="modal-body p-5">
  <div class="row align-items-center mb-5">
    <div class="col-3">
      <div class="img">
        <img
          src="{{
            (item?.images)![0]?.url ||
              '../../../assets/images/categories/item2.png'
          }}"
          class="img-fluid rounded"
          alt=""
        />
      </div>
    </div>
    <div class="col-9">
      <h3 class="text-secondary fs-3 fw-bolder m-0">{{ item?.title }}</h3>
    </div>
  </div>

  <p class="text-left mb-3 fw-bold text-gray-800">
    {{ "AllergicItems.SubTitle" | translate }}
  </p>

  <div class="col-6 mb-5">
    <select
      id="exampleInputEmail1"
      class="form-select form-select-sm rounded"
      aria-label="Default select example"
      [(ngModel)]="totalAllergicPersons"
    >
      <option *ngFor="let number of numberOfPersons" [ngValue]="number">
        {{ number }}
      </option>
    </select>
  </div>

  <span class="text-left mb-3 fw-bold text-gray-800 text-18">
    {{ "AllergicItems.Items" | translate }}
  </span>

  <div class="d-flex align-items-start justify-content-between mb-5">
    <ul class="p-3 ps-0 pb-0 m-0">
      <ng-container *ngFor="let item of allergicItems; let i = index">
        <li class="form-check mb-3" *ngIf="i % 2 === 0">
          <input
            class="form-check-input"
            type="checkbox"
            [value]="item.id"
            (change)="itemChanged($event)"
            [checked]="item.checked"
          />
          <label class="form-check-label">{{ item.name }}</label>
        </li>
      </ng-container>
    </ul>

    <ul class="p-3 ps-0 pb-0 m-0">
      <ng-container *ngFor="let item of allergicItems; let i = index">
        <li class="form-check mb-3" *ngIf="i % 2 !== 0">
          <input
            class="form-check-input"
            [checked]="item.checked"
            type="checkbox"
            [value]="item.id"
            (change)="itemChanged($event)"
          />
          <label class="form-check-label">
            {{ item.name }}
          </label>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="d-flex align-items-center justify-content-center gap-2">
    <button
      type="button"
      class="px-5 btn btn-success text-light text-14"
      (click)="updateComplementaryItems()"
    >
      {{ "Buttons.Update" | translate }}
    </button>
  </div>
</div>
