import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'br-booking-success-modal',
  templateUrl: './booking-success-modal.component.html',
  styleUrls: ['./booking-success-modal.component.scss']
})
export class BookingSuccessModalComponent implements OnInit {

  @Input() code!: string;

  constructor(
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.modalService.dismissAll();
      this.router.navigate(['my-account', 'booking-detail', this.code]);
    }, (2000));
  }

}
