import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function ApplicationInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  storage: LocalStorage
) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    const defaultLang = 'en';
    translate.addLangs(['en', 'nl', 'de', 'es']);
    translate.setDefaultLang(defaultLang);

    const locale = localStorage.getItem('lang') || defaultLang;

    // storage
    //   .getItem<string>('lang', { type: 'string' })
    //   .pipe(
    //     catchError(() => of(null)),
    //     map((val) => val || 'en'),
    //     debounceTime(100),
    //     distinctUntilChanged()
    //   )
    //   .subscribe(async (locale: string | null) => {
    //     locale ??= 'en';

    try {
      await translate.use(locale).toPromise();
    } catch (err) {
      console.log(err);
    }
    console.log(`Successfully initialized ${locale} language.`);
    // });
  };
}
