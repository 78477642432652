import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { AppRoutingModule } from './app-routing.module';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { CategoryListingComponent } from './pages/category-listing/category-listing.component';
import { ItemDetailsComponent } from './pages/category-listing/item-details/item-details.component';
import { SwiperModule } from 'swiper/angular';
import { BookingComponent } from './pages/booking/booking.component';
import { PersonalDetailsComponent } from './pages/booking/personal-details/personal-details.component';
import { PaymentComponent } from './pages/booking/payment/payment.component';
import { ReviewTermsComponent } from './pages/booking/review-terms/review-terms.component';
import { PaymentCompleteComponent } from './pages/booking/payment-complete/payment-complete.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { MyProfileComponent } from './pages/my-account/my-profile/my-profile.component';
import { MyBookingsComponent } from './pages/my-account/my-bookings/my-bookings.component';
import { BookingDetailComponent } from './pages/my-account/booking-detail/booking-detail.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { ForgetPassConfirmationComponent } from './pages/auth/forget-pass-confirmation/forget-pass-confirmation.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { RegisterDealerComponent } from './pages/auth/register-dealer/register-dealer.component';
import { MyPaymentsComponent } from './pages/my-account/my-payments/my-payments.component';
import { ServicesModule } from './services/services.module';
import { SharedModule } from './shared/shared.module';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { VerifyComponent } from './pages/auth/verify/verify.component';
import { CategoryItemComponent } from './components/category-item/category-item.component';
import { NoRecordFoundComponent } from './components/no-record-found/no-record-found.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { AllergicItemModalComponent } from './components/allergic-item-modal/allergic-item-modal.component';
import { OrderRightSidebarComponent } from './components/order-right-sidebar/order-right-sidebar.component';
import { ValidationMessagesComponent } from './components/validation-messages/validation-messages.component';
import { CancelBookingModalComponent } from './components/cancel-booking-modal/cancel-booking-modal.component';
import { ChangeBookingModalComponent } from './components/change-booking-modal/change-booking-modal.component';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { BookingSuccessModalComponent } from './components/booking-success-modal/booking-success-modal.component';
import { EditBookingDetailsComponent } from './pages/my-account/edit-booking-details/edit-booking-details.component';
import { ComplementaryItemsModalComponent } from './components/complementary-items-modal/complementary-items-modal.component';
import { BookingCategoryItemComponent } from './components/booking-category-item/booking-category-item.component';
import { LoaderComponent } from './components/loader/loader.component';
import { StaticPageComponent } from './pages/static-page/static-page.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { TaxDetailModalComponent } from './components/tax-detail-modal/tax-detail-modal.component';
import { ExtraItemsModalComponent } from './components/extra-items-modal/extra-items-modal.component';
import { PaymentStatusComponent } from './pages/payment-status/payment-status.component';
import { CartComponent } from './pages/cart/cart.component';
import { MyOrdersComponent } from './pages/my-account/my-orders/my-orders.component';
import { CartAlertComponent } from './components/cart-alert/cart-alert.component';
import { ResetCartConfirmationComponent } from './components/reset-cart-confirmation/reset-cart-confirmation.component';
import { MessageAlertModalComponent } from './components/message-alert-modal/message-alert-modal.component';
import {
  ApplicationInitializerFactory,
  HttpLoaderFactory,
} from './utils/translation.config';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NgSelectModule } from '@ng-select/ng-select';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 3000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CategoriesComponent,
    CategoryListingComponent,
    ItemDetailsComponent,
    BookingComponent,
    PersonalDetailsComponent,
    PaymentComponent,
    ReviewTermsComponent,
    PaymentCompleteComponent,
    MyAccountComponent,
    MyProfileComponent,
    MyBookingsComponent,
    BookingDetailComponent,
    LoginComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    ForgetPassConfirmationComponent,
    ResetPasswordComponent,
    ContactUsComponent,
    RegisterDealerComponent,
    MyPaymentsComponent,
    ValidationMessagesComponent,
    VerifyComponent,
    CategoryItemComponent,
    AllergicItemModalComponent,
    ChangePasswordModalComponent,
    OrderRightSidebarComponent,
    CancelBookingModalComponent,
    ChangeBookingModalComponent,
    EditBookingDetailsComponent,
    ConfirmationModalComponent,
    BookingSuccessModalComponent,
    ComplementaryItemsModalComponent,
    NoRecordFoundComponent,
    SafeHtmlPipe,
    BookingCategoryItemComponent,
    LoaderComponent,
    StaticPageComponent,
    TaxDetailModalComponent,
    ExtraItemsModalComponent,
    PaymentStatusComponent,
    CartComponent,
    MyOrdersComponent,
    CartAlertComponent,
    ResetCartConfirmationComponent,
    MessageAlertModalComponent,
  ],
  imports: [
    GooglePlaceModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    NgDynamicBreadcrumbModule,
    SwiperModule,
    ServicesModule,
    SharedModule,
    NgSelectModule,
    NotifierModule.withConfig(customNotifierOptions),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector, LocalStorage],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
