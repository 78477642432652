import * as moment from 'moment';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { PageModel } from 'src/app/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { BookingModel } from 'src/app/models/booking.model';
import { dealer, ORDER_ID, user } from '../../../utils/general-constants';
import { CartService } from '../../../services/cart/cart.service';
import { UserService } from '../../../services/user/user.service';
import { AuthService } from '../../../services/auth/auth.service';
import { DEFAULT_DATE_FORMAT } from '../../../utils/general-constants';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';
import { MessageAlertModalComponent } from '../../../components/message-alert-modal/message-alert-modal.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'br-review-terms',
  templateUrl: './review-terms.component.html',
  styleUrls: ['./review-terms.component.scss'],
})
export class ReviewTermsComponent extends BaseComponent implements OnInit {
  form!: FormGroup;
  orderId!: number;
  total: number = 0;
  locale: string = 'en';
  securityFee: number = 0;
  reviewPages: Array<PageModel> = [];
  data: Array<Array<BookingModel>> = [];

  commissionAmount: number = 0;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private cartService: CartService,
    private storage: LocalStorage,
    private errorService: ErrorHandlerService,
    private modalService: NgbModal
  ) {
    super(router, authService, userService, translate);
    storage.getItem(ORDER_ID).subscribe((id: any) => {
      this.orderId = id;
      this.list(id);
    });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      checkboxes: new FormArray([]),
      security: new FormControl(null, [Validators.requiredTrue]),
    });
    this.addCheckboxes();

    this.authService.loggedIn?.subscribe((res) => {
      if (!res) return;

      this.user$.subscribe((u) => {
        if (u) {
          if (u?.data?.role === dealer) {
            this.commissionAmount = u?.data?.profile?.commission;
          }
        }
      });
    });

    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  list(id: number) {
    this.showLoader();
    this.cartService
      .list(id)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.total = res?.data?.price;
          res.data.reservations.forEach((item) => {
            this.securityFee +=
              item.availability.availabilityable.category.security_fee;
          });
          this.data = this.groupBy(res.data.reservations);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  addCheckboxes() {
    this.reviewPages?.forEach(() => {
      this.checkboxesFormArray.push(
        new FormControl(null, [Validators.requiredTrue])
      );
    });
  }

  get checkboxesFormArray() {
    return this.form.controls.checkboxes as FormArray;
  }

  submit() {
    this.showLoader();
    this.storage.getItem('user').subscribe((user: any) => {
      const payload = {
        user_name: user?.user_name,
        user_email: user?.user_email,
        user_phone: user?.user_phone,
        user_country: user?.user_country,
        zip_code: user?.zip,
        note: user?.note,
        source: 'online',
        date: moment(new Date()).format(DEFAULT_DATE_FORMAT),
      };

      this.cartService
        .placeOrder(payload, this.orderId)
        .pipe(
          finalize(() => {
            this.hideLoader();
          })
        )
        .subscribe(
          (res) => {
            this.storage.removeItem(ORDER_ID).subscribe(() => {
              this.cartService.resetCart(false);
              this.router
                .navigate(['thank-you', res?.data?.code])
                .catch((reason) => {
                  console.warn(reason);
                });

              const modalRef = this.modalService.open(
                MessageAlertModalComponent,
                {
                  centered: true,
                }
              );
              modalRef.componentInstance.message = this.translate.instant(
                marker('ReviewTerms.UserMessage')
              );
            });
          },
          (error) => {
            if (error?.error?.expired_reservations?.length > 0) {
              this.errorService.showToast(
                'Some reservations are not available right now, kindly check the cart',
                'info'
              );
              this.router.navigate(['/cart'], {
                state: {
                  ids: error?.error?.expired_reservations,
                },
              });
            } else {
              this.errorService.handle(error);
            }
          }
        );
    });
  }
}
