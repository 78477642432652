import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const desc = 'desc';
export const brAccessToken = 'br_access_token';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const BookingStatuses = [
  {
    name: marker('BookingStatus.ALL'),
    value: 'ALL',
  },
  {
    name: marker('BookingStatus.HOLD'),
    value: 'HOLD',
  },
  {
    name: marker('BookingStatus.CONFIRMED'),
    value: 'CONFIRMED',
  },
  {
    name: marker('BookingStatus.CANCELLED'),
    value: 'CANCELLED',
  },
  {
    name: marker('BookingStatus.EXPIRED'),
    value: 'EXPIRED',
  },
];

export const user = 'user';
export const dealer = 'dealer';

export const BOOKING_STATUS_HOLD = 'HOLD';
export const BOOKING_STATUS_CONFIRMED = 'CONFIRMED';
export const BOOKING_STATUS_CANCELLED = 'CANCELLED';
export const BOOKING_STATUS_EXPIRED = 'EXPIRED';

export const PAYMENT_STATUSES = [
  {
    name: marker('PaymentStatus.ALL'),
    value: 'ALL',
  },
  {
    name: marker('PaymentStatus.OPEN'),
    value: 'OPEN',
  },
  {
    name: marker('PaymentStatus.PAID'),
    value: 'PAID',
  },
  {
    name: marker('PaymentStatus.CANCELLED'),
    value: 'CANCELED',
  },
  {
    name: marker('PaymentStatus.FAILED'),
    value: 'FAILED',
  },
  {
    name: marker('PaymentStatus.EXPIRED'),
    value: 'EXPIRED',
  },
];

export const PAYMENT_STATUS_OPEN = 'open';
export const PAYMENT_STATUS_PAID = 'paid';
export const PAYMENT_STATUS_CANCELLED = 'canceled';
export const PAYMENT_STATUS_FAILED = 'failed';
export const PAYMENT_STATUS_EXPIRED = 'expired';

export const boat = 'boat';
export const cruise = 'cruise';
export const bicycle = 'bicycle';
export const service = 'service';
export const game = 'game';
export const packages = 'package';

export const PERCENTAGE = 'PERCENTAGE';
export const FIXED_AMOUNT = 'FIXED AMOUNT';

export const TAX_VAT = 'VAT';
export const TAX_VMR = 'VMR';

export const APP_VERSION = 'BR_APP_VERSION';
export const CART_COUNT = 'BR_CART_COUNT';
export const ORDER_ID = 'BR_ORDER_ID';
export const TIME_COUNTER = 'BR_TIME_COUNTER';

export enum ASSET_AVAILABILITY {
  CUSTOM = 'Available during custom timings',
  SUNRISE_SUNSET = 'Available from sunrise till sunset',
  PARK_TIMINGS = 'Available during opening hours',
}
