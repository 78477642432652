<div class="row align-items-center item-wrapper-inner">
  <!-- item image -->
  <div
    class="col-12 item-img"
    [ngClass]="{ 'col-12 mb-3': gridActive, 'col-4': listActive }"
  >
    <img class="rounded-2 img-fluid w-100" [src]="itemImage" alt="" />
  </div>
  <!-- item content -->
  <div
    class="col-12 item-content"
    [ngClass]="{ 'col-12': gridActive, 'col-8': listActive }"
  >
    <!-- title, location, Rate-->
    <div class="d-flex align-items-start justify-content-between mb-2">
      <div class="d-flex flex-column align-items-start justify-content-between">
        <h2 class="text-dark" (click)="routeToItemDetail(item?.id!)">
          {{ getTranslatedData(item?.name!) }}
        </h2>
        <span
          class="location text-primary d-flex align-items-start justify-content-start"
        >
          <i class="bi bi-geo-alt-fill me-1"></i>
          <span>{{ item?.location }}</span>
        </span>
      </div>
      <span *ngIf="isPackage" class="text-lg text-dark item-price fw-bold">
        {{ item?.price | currency: "EUR":"symbol":"1.2-2":locale }}
      </span>
      <span
        *ngIf="item?.price_per_hour"
        class="text-lg text-dark item-price fw-bold"
      >
        {{ item?.price_per_hour | currency: "EUR":"symbol":"1.2-2":locale }}
        <span>/hr</span>
      </span>
    </div>

    <!-- item description -->
    <p
      class="m-0 mb-3 item-desc"
      [innerHtml]="getTranslatedData(item?.description!)"
    ></p>

    <!-- Amenities -->
    <div class="amenities mb-3" *ngIf="hasAmenities">
      <ul class="list">
        <li class="list-item" *ngIf="item?.persons_capacity!">
          <i class="br-icon-person fs-5 me-1"></i>
          {{ item?.persons_capacity }} {{ "CategoryItem.Persons" | translate }}
        </li>
        <li class="list-item" *ngIf="item?.seating_capacity">
          <i class="br-icon-person fs-5 me-1"></i>
          {{ item?.seating_capacity }} {{ "CategoryItem.Persons" | translate }}
        </li>
        <li class="list-item" *ngIf="item?.is_electric">
          <i class="br-icon-battery fs-5 me-1"></i>
          {{ item?.battery_capacity }} %
        </li>
      </ul>
    </div>

    <!-- Prices and reservation -->
    <div class="reservations">
      <h4 class="fs-6 fw-bold">
        {{ "CategoryItem.PricesAndReservation" | translate }}
      </h4>
      <ul
        class="list"
        [ngClass]="{ 'list-column': gridActive, 'list-row': listActive }"
      >
        <li
          class="list-item col-6 col-lg-4"
          *ngFor="let availability of availabilities | slice: 0:3"
          (click)="checkAvailability(item?.id!, availability)"
          [ngClass]="availability?.selected ? 'active' : ''"
        >
          <div
            class="item-inner"
            [ngStyle]="{
              'justify-content':
                availability?.fix_price == 1 ? 'space-between' : 'center'
            }"
          >
            <span class="time">{{ getAvailabilityTitle(availability) }}</span>
            <span class="price fw-bold" *ngIf="availability?.fix_price == 1">
              {{
                availability?.price | currency: "EUR":"symbol":"1.2-2":locale
              }}
            </span>
          </div>
        </li>
        <!-- Custom Availability for Boat -->
        <li
          class="list-item col-6 col-lg-4"
          *ngIf="hasCustomSlot"
          (click)="toggleSlotType($event, 'custom')"
          [ngClass]="isCustomSlot ? 'active' : ''"
        >
          <div class="item-inner" [ngStyle]="{ 'justify-content': 'center' }">
            <span class="time">{{ "CategoryItem.Future" | translate }}</span>
          </div>
        </li>
      </ul>
    </div>

    <!-- for custom availability -->
    <form class="prices-form" *ngIf="isCustomSlot">
      <div
        class="row align-items-center justify-content-start holder"
        [ngClass]="{
          'column align-items-start': gridActive,
          'align-items-center': listActive
        }"
      >
        <div
          class="col-xl-5 col-12 d-flex align-items-center justify-content-start"
          [ngClass]="{ 'col-12': gridActive, 'col-xl-5': listActive }"
        >
          <div class="col-auto">
            <label class="col-form-label">
              {{ "CategoryItem.From" | translate }}
            </label>
          </div>
          <div class="col">
            <input
              type="time"
              class="form-control"
              [(ngModel)]="from"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="disableToAndFrom"
            />
          </div>
        </div>

        <div
          class="col-xl-6 col-12 d-flex align-items-center justify-content-start"
          [ngClass]="{ 'col-12': gridActive, 'col-xl-6': listActive }"
        >
          <div class="col-auto">
            <label class="col-form-label">
              {{ "CategoryItem.Duration" | translate }}
            </label>
          </div>
          <div class="col">
            <select
              class="form-select"
              aria-label="Default select example"
              [(ngModel)]="duration"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="disableToAndFrom"
            >
              <option *ngFor="let item of durationList" [ngValue]="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <!-- seats for cruise category -->
    <form class="prices-form" *ngIf="(isCruise || isGame) && hasSelection">
      <div
        class="row align-items-center justify-content-start holder"
        [ngClass]="{
          'column align-items-start': gridActive,
          'align-items-center': listActive
        }"
      >
        <div
          class="col-xl-5 col-12 d-flex align-items-center justify-content-start"
          [ngClass]="{ 'col-12': gridActive, 'col-xl-5': listActive }"
        >
          <div class="col-auto">
            <label class="col-form-label">
              {{
                isCruise
                  ? ("CategoryItem.Seats" | translate)
                  : ("CategoryItem.NoOfPersons" | translate)
              }}
            </label>
          </div>
          <div class="col">
            <select
              class="form-select"
              [(ngModel)]="selectedSeats"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="updateCapacities()"
            >
              <option [ngValue]="0" [selected]="true">
                {{ "CategoryItem.ChooseHere" | translate }}
              </option>
              <option *ngFor="let item of seatOptions" [ngValue]="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <!-- prices for adults and child -->
    <form class="prices-form" *ngIf="hasCapacity">
      <div
        class="row align-items-center justify-content-start holder"
        [ngClass]="{
          'column align-items-start': gridActive,
          'align-items-center': listActive
        }"
        *ngIf="item?.seating_capacity! == 1 || item?.persons_capacity! == 1"
      >
        <div
          class="col-xl-5 col-12 d-flex align-items-center justify-content-start"
          [ngClass]="{ 'col-12': gridActive, 'col-xl-5': listActive }"
        >
          <div class="col-auto">
            <input
              class="me-2"
              type="radio"
              name="adults_or_children"
              [(ngModel)]="adultOrChildren"
              value="adult"
            />
            <label class="col-form-label">
              {{ "CategoryItem.Adults" | translate }}
            </label>
          </div>
        </div>

        <div
          class="col-xl-6 col-12 d-flex align-items-center justify-content-start"
          [ngClass]="{ 'col-12': gridActive, 'col-xl-6': listActive }"
        >
          <div class="col-auto">
            <input
              class="me-2"
              type="radio"
              name="adults_or_children"
              [(ngModel)]="adultOrChildren"
              value="children"
            />
            <label class="col-form-label">
              {{ "CategoryItem.Children" | translate }} 3-11
            </label>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-start holder"
        [ngClass]="{
          'column align-items-start': gridActive,
          'align-items-center': listActive
        }"
        *ngIf="item?.seating_capacity! > 1 || item?.persons_capacity! > 1"
      >
        <div
          class="col-xl-5 col-12 d-flex align-items-center justify-content-start"
          [ngClass]="{ 'col-12': gridActive, 'col-xl-5': listActive }"
        >
          <div class="col-auto">
            <label class="col-form-label">
              {{ "CategoryItem.Adults" | translate }}
            </label>
          </div>
          <div class="col">
            <select
              class="form-select"
              [(ngModel)]="selectedNoOfAdult"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="updateCapacities()"
            >
              <option [ngValue]="0" [selected]="true">
                {{ "CategoryItem.ChooseHere" | translate }}
              </option>
              <option *ngFor="let item of adultOptions" [ngValue]="item">
                <ng-container
                  *ngIf="
                    isFixedPrice || isCustomSlot || isPackage;
                    else variablePrice
                  "
                >
                  {{ item }}
                </ng-container>
                <ng-template #variablePrice>
                  {{ item }} x
                  {{ adultPrice | currency: "EUR":"symbol":"1.2-2":locale }} =
                  {{
                    item * adultPrice | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </ng-template>
              </option>
            </select>
          </div>
        </div>

        <div
          class="col-xl-6 col-12 d-flex align-items-center justify-content-start"
          [ngClass]="{ 'col-12': gridActive, 'col-xl-6': listActive }"
        >
          <div class="col-auto">
            <label class="col-form-label">
              {{ "CategoryItem.Children" | translate }} 3-11
            </label>
          </div>
          <div class="col">
            <select
              class="form-select"
              [(ngModel)]="selectedNoOfChild"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="updateCapacities()"
            >
              <option [ngValue]="0" [selected]="true">
                {{ "CategoryItem.ChooseHere" | translate }}
              </option>
              <option *ngFor="let item of childOptions" [ngValue]="item">
                <ng-container
                  *ngIf="
                    isFixedPrice || isCustomSlot || isPackage;
                    else variablePrice
                  "
                >
                  {{ item }}
                </ng-container>
                <ng-template #variablePrice>
                  {{ item }} x
                  {{ childPrice | currency: "EUR":"symbol":"1.2-2":locale }} =
                  {{
                    item * childPrice | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </ng-template>
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <!-- booking buttons -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="col pe-1">
        <button
          class="btn btn-outline-secondary"
          (click)="routeToItemDetail(item?.id!)"
        >
          {{ "Buttons.ViewMore" | translate }}
        </button>
      </div>
      <div class="col-auto ps-2">
        <button class="btn btn-primary text-white" (click)="addToCart($event)">
          {{ "Buttons.AddToCart" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
