import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderModel } from 'src/app/models/cart.model';
import { TranslateService } from '@ngx-translate/core';
import {
  bicycle,
  boat,
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_EXPIRED,
  cruise,
  DEFAULT_DATE_FORMAT,
  game,
  ORDER_ID,
  packages,
  TIME_COUNTER,
} from '../../utils/general-constants';
import { CartService } from '../../services/cart/cart.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { BookingService } from '../../services/booking/booking.service';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';
import {
  BookingModel,
  BookingReservationModel,
  ComplementaryAllergicItem,
  ComplementaryItemModel,
  ExtraItemModel,
  PromoParamsModel,
  TimeslotResevationModel,
} from 'src/app/models/booking.model';
import * as moment from 'moment';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaxDetailModalComponent } from '../../components/tax-detail-modal/tax-detail-modal.component';
import { ExtraItemsModalComponent } from '../../components/extra-items-modal/extra-items-modal.component';
import { AllergicItemModalComponent } from '../../components/allergic-item-modal/allergic-item-modal.component';
import { ResetCartConfirmationComponent } from '../../components/reset-cart-confirmation/reset-cart-confirmation.component';
import { ComplementaryItemsModalComponent } from '../../components/complementary-items-modal/complementary-items-modal.component';
import { MessageAlertModalComponent } from '../../components/message-alert-modal/message-alert-modal.component';

@Component({
  selector: 'br-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent extends BaseComponent implements OnInit {
  form: FormGroup = new FormGroup({
    promo_code: new FormControl(null, [Validators.required]),
  });

  timeOut!: boolean;
  order!: OrderModel;
  timeCounter!: string;
  locale: string = 'en';
  data: Array<Array<BookingModel>> = [];
  allergicItems: Array<ComplementaryAllergicItem> = [];

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private cartService: CartService,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService,
    private modalService: NgbModal,
    private storage: LocalStorage
  ) {
    super(router, authService, userService, translate);
    // get ids if the user is redirected from the review terms page
    const ids = this.router.getCurrentNavigation()?.extras?.state!?.ids;

    storage.getItem(ORDER_ID).subscribe((id: any) => {
      this.list(id, ids);
    });
    this.bookingService.timeCounter.subscribe((res) => {
      if (res) {
        this.timeOut = false;
        this.timeCounter = res;
      } else {
        this.timeOut = true;
      }
    });
  }

  ngOnInit(): void {
    this.storage.getItem(TIME_COUNTER).subscribe((res: any) => {
      if (res?.mint && res?.sec) {
        const seconds = parseInt(res.mint) * 60 + parseInt(res.sec);
        this.bookingService.startTimer(seconds);
      }
    });

    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  list(id: number, ids?: Array<number>) {
    this.showLoader();
    this.cartService
      .list(id)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.order = res.data;
          if (ids?.length) {
            res.data.reservations.forEach((r) => {
              if (ids?.includes(r.id)) {
                r.is_expired = true;
              }
            });
          }
          this.data = this.groupBy(res.data.reservations);
        },
        (error) => {
          if (error.status === 404) {
            this.cartService.resetCart(false);
            this.errorService.showErrorMessage(marker('NotFound'));
          } else {
            this.errorService.handle(error);
          }
        }
      );
  }

  showComplementaryDialog(item: BookingModel) {
    const modalRef = this.modalService.open(ComplementaryItemsModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.id = item?.availability?.availabilityable?.id;
    modalRef.componentInstance.type =
      item?.availability?.availabilityable?.category?.type;

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: ComplementaryItemModel) => {
        if (receivedEntry) {
          // to check in case of item which need full day to prepare
          if (receivedEntry?.food_item && receivedEntry?.full_day_required) {
            const end = moment(item.date!, DEFAULT_DATE_FORMAT);
            const start = moment(new Date()).format(DEFAULT_DATE_FORMAT);
            const duration = moment.duration(end.diff(start));
            const hours = duration.asHours();
            if (hours < 24) {
              this.errorService.showErrorMessage(
                marker('Complementaries.FullDayRequired')
              );
              return;
            }
          }

          // to check if item is available in timeslot time
          if (!item?.availability?.is_fullday) {
            if (receivedEntry?.items_availability_associations?.length >= 1) {
              receivedEntry?.items_availability_associations?.forEach((ia) => {
                let timeSegments = [];
                timeSegments.push([
                  item?.availability?.from,
                  item?.availability?.till,
                ]);
                timeSegments.push([ia?.from, ia?.to]);
                ia.overlap = this.checkAvailabilityOverlap(timeSegments);
              });

              let checker =
                receivedEntry?.items_availability_associations?.every(
                  (v) => v.overlap === false
                );
              if (checker) {
                this.errorService.showErrorMessage(
                  marker('Complementaries.NotAvailable')
                );
                return;
              }
            }
          }

          const index = item.complementaryItems.findIndex(
            (ci) => ci.id === receivedEntry.id
          );

          if (index !== -1) {
            item.complementaryItems[index].quantity = receivedEntry?.quantity;
          } else {
            item.complementaryItems.push(receivedEntry);
          }
          this.updateReservationItem(item);
        }
      }
    );
  }

  showExtraItemDialog(item: BookingModel) {
    const modalRef = this.modalService.open(ExtraItemsModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.id = item?.availability?.availabilityable?.id;
    modalRef.componentInstance.type =
      item?.availability?.availabilityable?.category?.type;

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: ExtraItemModel) => {
        if (receivedEntry) {
          // to check if item is available in timeslot time
          if (!item?.availability?.is_fullday) {
            if (receivedEntry?.items_availability_associations?.length >= 1) {
              receivedEntry?.items_availability_associations?.forEach((ia) => {
                let timeSegments = [];
                timeSegments.push([
                  item?.availability?.from,
                  item?.availability?.till,
                ]);
                timeSegments.push([ia?.from, ia?.to]);
                ia.overlap = this.checkAvailabilityOverlap(timeSegments);
              });

              let checker =
                receivedEntry?.items_availability_associations?.every(
                  (v) => v.overlap === false
                );
              if (checker) {
                this.errorService.showErrorMessage(
                  marker('ExtraItems.NotAvailable')
                );
                return;
              }
            }
          }

          const index = item.extraItems.findIndex(
            (ci) => ci.id == receivedEntry.id
          );
          if (index !== -1) {
            item.extraItems[index].pivot_quantity =
              receivedEntry.pivot_quantity;
          } else {
            item.extraItems.push(receivedEntry);
          }
          this.updateReservationItem(item);
        }
      }
    );
  }

  showAllergicItemDialog(ci: ComplementaryItemModel, item: BookingModel) {
    const modalRef = this.modalService.open(AllergicItemModalComponent, {
      centered: true,
    });

    const seatingCapacity =
      item?.seats || item?.availability?.availabilityable?.seating_capacity;

    modalRef.componentInstance.item = ci;
    modalRef.componentInstance.totalPerson = seatingCapacity;
    modalRef.componentInstance.totalAllergicPersons =
      item.number_of_allergic_person;

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      const index = item.complementaryItems.findIndex(
        (ii) => ii.id === receivedEntry.id
      );
      if (index !== -1) {
        item.complementaryItems[index].allergic_items = item.complementaryItems[
          index
        ].allergic_items.filter((item) =>
          receivedEntry.allergicItems?.includes(item.id)
        );
      }
      this.allergicItems.push(receivedEntry);
      this.updateReservationItem(item);
    });
  }

  create(group: Array<BookingModel>) {
    if (!group.length) return;

    this.showLoader();
    const item = group[group.length - 1];

    const type = item.availability.availabilityable.category.type;
    const seats = item?.number_of_adults + item?.number_of_children;

    const timeslotPayload: TimeslotResevationModel = {
      date: moment(item?.date).format(DEFAULT_DATE_FORMAT),
      item_id: item?.availability?.availabilityable?.id,
      item_type: type,
    };

    if (item?.availability?.is_custom_slot) {
      timeslotPayload.from_time = item?.availability?.from
        ? moment(item?.availability?.from, 'HH:mm:ss').format('HH:mm:ss')
        : null;
      timeslotPayload.till_time = item?.availability?.till
        ? moment(item?.availability?.till, 'HH:mm:ss').format('HH:mm:ss')
        : null;
    } else {
      timeslotPayload.availability_id = item?.availability?.id;
    }

    if ([game, cruise].includes(type)) {
      timeslotPayload.seats = seats;
    }

    switch (type) {
      case packages:
        this.managePackage(item);
        return;
      case game:
        this.manageGame(item);
        return;
      default:
        break;
    }

    this.bookingService.timeslotReservation(timeslotPayload).subscribe(
      (res) => {
        const payload: BookingReservationModel = {
          availability_id: res?.data?.id,
          date: moment(item?.date).format(DEFAULT_DATE_FORMAT),
          item_id: res?.data?.availabilityable?.id,
          number_of_adults: item?.number_of_adults,
          number_of_children: item?.number_of_children,
          complementary_items: {},
          extra_items: {},
          number_of_allergic_person: item.number_of_allergic_person,
          source: 'online',
          seats: seats,
          order_id: this.order.id!,
        };

        this.bookingService
          .bookingReservation(payload)
          .pipe(
            finalize(() => {
              this.hideLoader();
            })
          )
          .subscribe(
            (res) => {
              this.cartService.addToCart();
              this.list(this.order.id);
            },
            (error) => {
              this.errorService.handle(error);
            }
          );
      },
      (error) => {
        this.hideLoader();
        this.errorService.handle(error);
      }
    );
  }

  managePackage(item: BookingModel) {
    const payload = {
      type: item?.availability?.availabilityable?.category?.type,
      availability_id: item?.availability?.id,
      date: moment(item?.date).format(DEFAULT_DATE_FORMAT),
      from: item?.from
        ? moment(item?.from, 'HH:mm:ss').format('HH:mm:ss')
        : null,
      till: item?.till
        ? moment(item?.till, 'HH:mm:ss').format('HH:mm:ss')
        : null,
      source: 'online',
      price: item?.price,
      order_id: this.order.id,
    };

    this.bookingService
      .packageBooking(
        this.clean(payload),
        item.availability?.availabilityable?.id
      )
      .subscribe(
        (res) => {
          this.cartService.addToCart();
          this.list(this.order.id);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  manageGame(item: BookingModel) {
    const bookedSeats = item.availability?.reservations.reduce(
      (totalSeats, reservation) => {
        if (
          moment(reservation.date).isSame(
            moment(item.date).format(DEFAULT_DATE_FORMAT)
          ) &&
          reservation.status !== BOOKING_STATUS_EXPIRED &&
          reservation.status !== BOOKING_STATUS_CANCELLED
        ) {
          return totalSeats + reservation.seats;
        } else {
          return totalSeats;
        }
      },
      0
    );
    console.log(bookedSeats);
    const totalCapacity =
      item.availability.availabilityable.seating_capacity ||
      item.availability.availabilityable.persons_capacity;
    const availableSeats = totalCapacity - bookedSeats;
    if (availableSeats < item.seats) {
      this.hideLoader();
      return;
    }

    this.showLoader();

    const payload = {
      type: game,
      availability_id: item.availability!.id,
      number_of_adults: item.number_of_adults,
      number_of_children: item.number_of_children,
      date: moment(item?.date).format(DEFAULT_DATE_FORMAT),
      price: item.price,
      number_of_allergic_person: 0,
      complementary_items: {},
      source: 'online',
      seats: item.number_of_adults + item.number_of_children,
      order_id: item.order_id,
    };

    this.bookingService
      .gamesBooking(
        payload,
        item?.availability?.availabilityable?.id,
        item?.availability!.id
      )
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.cartService.addToCart();
          this.list(this.order.id);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  deleteReservation(reservation: BookingModel) {
    this.processDelete([reservation.id]);
  }

  remove(group: Array<BookingModel>) {
    if (!group.length) return;
    const item = group[group.length - 1];
    this.processDelete([item.id]);
  }

  reset() {
    let ids: Array<number> = [];
    this.data.forEach((group) => {
      group.map((item) => ids.push(item.id));
    });
    const modalRef = this.modalService.open(ResetCartConfirmationComponent, {
      centered: true,
    });

    modalRef.componentInstance.passEntry.subscribe((value: boolean) => {
      if (value === true) {
        this.processDelete(ids, true);
      }
    });
  }

  processDelete(ids: Array<number>, clear?: boolean) {
    this.cartService.deleteReservation({ reservation_ids: ids }).subscribe(
      (res) => {
        if (clear) {
          this.clearStorage();
        } else {
          this.cartService.removeFromCart();
          if (this.order.reservations.length === 1) {
            this.clearStorage();
          } else {
            this.list(this.order.id);
          }
        }
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }

  private clearStorage() {
    this.storage.removeItem(ORDER_ID).subscribe((res) => {
      this.cartService.resetCart();
      this.router.navigate(['home']);
    });
  }

  increment(
    item: BookingModel,
    key: 'number_of_adults' | 'number_of_children'
  ) {
    if (
      item.availability?.availabilityable?.category?.sub_type == 'cruise' ||
      item.availability?.availabilityable?.category?.type === game
    ) {
      const bookedSeats = item.availability?.reservations.reduce(
        (totalSeats, reservation) => {
          if (
            moment(reservation.date).isSame(
              moment(item.date).format(DEFAULT_DATE_FORMAT)
            ) &&
            reservation.status !== BOOKING_STATUS_EXPIRED &&
            reservation.status !== BOOKING_STATUS_CANCELLED
          ) {
            return totalSeats + reservation.seats;
          } else {
            return totalSeats;
          }
        },
        0
      );
      const totalCapacity =
        item.availability.availabilityable.seating_capacity ||
        item.availability.availabilityable.persons_capacity;
      const availableSeats = totalCapacity - bookedSeats;
      if (availableSeats <= 0) return;
    }

    const seats = item.number_of_adults + item.number_of_children;

    if (
      seats >= item.availability?.availabilityable?.seating_capacity ||
      seats >= item.availability?.availabilityable?.persons_capacity
    )
      return;
    item[key]++;
    this.updateReservationItem(item);
  }

  decrement(
    item: BookingModel,
    key: 'number_of_adults' | 'number_of_children'
  ) {
    if (item?.number_of_adults + item?.number_of_children == 1) return;

    if (
      key == 'number_of_adults' &&
      item?.number_of_children >= 1 &&
      item?.number_of_adults <= 0
    )
      return;
    if (
      key == 'number_of_children' &&
      item?.number_of_adults >= 1 &&
      item?.number_of_children <= 0
    )
      return;

    item[key]--;
    this.updateReservationItem(item);
  }

  manageSeats(item: BookingModel, action: 'increment' | 'decrement') {
    if (item.availability?.availabilityable?.category?.type == game) {
      if (action === 'increment') {
        const bookedSeats = item.availability?.reservations.reduce(
          (totalSeats, reservation) => {
            return totalSeats + reservation.seats;
          },
          0
        );
        const availableSeats =
          item.availability.availabilityable.persons_capacity - bookedSeats;
        if (availableSeats <= 0) return;

        item.seats++;
      } else if (action === 'decrement') {
        if (item.seats <= 1) return;
        item.seats--;
      }
      this.updateReservationItem(item);
    }
  }

  applyVoucher(reservation: BookingModel) {
    if (reservation?.promotion?.active) {
      this.form.reset();
      this.errorService.showErrorMessage(marker('Payment.PromoAlreadyApplied'));
      return;
    }

    const params: PromoParamsModel = {
      code: this.form.value.promo_code,
      category_id: reservation?.availability?.availabilityable?.category_id,
      boat_id:
        reservation?.availability?.availabilityable?.category?.type === boat
          ? reservation?.availability?.availabilityable?.id
          : null,
      bicycle_id:
        reservation?.availability?.availabilityable?.category?.type === bicycle
          ? reservation?.availability?.availabilityable?.id
          : null,
      reservation_id: reservation.id,
    };

    this.bookingService.applyPromoCode(params).subscribe(
      (promo) => {
        this.form.reset();

        if (promo?.data?.active == false) return;

        if (promo?.data?.amount > reservation?.price) {
          this.errorService.showErrorMessage(
            marker('Payment.PromoNotApplicable')
          );
          return;
        }
        reservation.voucher_code = promo?.data?.promo_code;
        this.updateReservationItem(reservation);
      },
      (error) => {
        this.form.reset();
        this.errorService.handle(error);
      }
    );
  }

  proceedToCheckout() {
    this.cartService.proceedToCheckout(this.order.id).subscribe(
      (res) => {
        this.router
          .navigate(['booking', 'personal-details'])
          .catch((reason) => {
            console.warn(reason);
          });
      },
      (error) => {
        if (error?.error?.expired_reservations?.length > 0) {
          this.errorService.showToast(
            this.translate.instant(marker('Cart.ReservationUnavailable')),
            'info'
          );
          this.order.reservations.forEach((r) => {
            if (error?.error?.expired_reservations.includes(r.id)) {
              r.is_expired = true;
            }
          });
        } else {
          this.errorService.handle(error);
        }
      }
    );
  }

  private updateReservationItem(item: BookingModel) {
    this.showLoader();

    let finalResult: any = {};
    for (let i = 0; i < item?.complementaryItems?.length!; i++) {
      const complementaryItems = {
        [item?.complementaryItems![i]?.id]: {
          quantity: item.complementaryItems![i]?.quantity,
          allergic_items: item.complementaryItems[i].allergic_items?.length
            ? item.complementaryItems[i].allergic_items.map((ai) => ai.id)
            : this.allergicItems?.length
            ? this.allergicItems.find(
                (ai) => ai.id == item.complementaryItems[i].id
              )!?.allergic_items
            : [],
        },
      };
      finalResult = Object.assign(complementaryItems, finalResult);
    }

    let number_of_allergic_person = 0;
    for (const finalResultKey in finalResult) {
      number_of_allergic_person += finalResult[finalResultKey].quantity;
    }

    const type = item.availability.availabilityable.category.type;
    switch (type) {
      case game:
        this.updateGame(item, finalResult, number_of_allergic_person);
        return;
      default:
        break;
    }

    // set payload for extra items
    let extraItems = {};
    for (let i = 0; i < item.extraItems?.length!; i++) {
      const extraItem = {
        [item?.extraItems![i]?.id]: {
          quantity: item?.extraItems![i]?.pivot_quantity,
        },
      };
      extraItems = Object.assign(extraItem, extraItems);
    }

    const tickets =
      item.availability.availabilityable_type == game
        ? item.seats
        : item.number_of_children + item.number_of_adults;

    const payload = {
      availability_id: item.availability_id,
      date: item.date,
      item_id: item.availability.availabilityable_id,
      number_of_adults: item.number_of_adults,
      number_of_children: item.number_of_children,
      complementary_items: finalResult,
      extra_items: extraItems,
      number_of_allergic_person: number_of_allergic_person,
      source: 'online',
      voucher_code: item.voucher_code
        ? item?.voucher_code
        : item?.promotion?.promo_code
        ? item?.promotion?.promo_code!
        : null,
      code: item.code,
      seats: tickets,
    };

    console.log();

    this.bookingService
      .updateBooking(this.clean(payload))
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.allergicItems = [];
          this.list(this.order.id);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  updateGame(
    item: BookingModel,
    complementary_items: Array<ComplementaryItemModel>,
    number_of_allergic_person: number
  ) {
    const payload = {
      type: game,
      availability_id: item.availability!.id,
      date: item.date,
      number_of_allergic_person: number_of_allergic_person,
      number_of_adults: item.number_of_adults,
      number_of_children: item.number_of_children,
      complementary_items: complementary_items,
      source: 'online',
      seats: item.number_of_adults + item.number_of_children,
      order_id: item.order_id,
      voucher_code: item?.promotion?.promo_code
        ? item?.promotion?.promo_code
        : null,
    };

    this.bookingService
      .updateGameBooking(item?.code, this.clean(payload))
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.list(this.order.id);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  showTaxDetail(item: BookingModel) {
    const modalRef = this.modalService.open(TaxDetailModalComponent, {
      size: 'xl',
      centered: true,
    });
    modalRef.componentInstance.reservationId = item.id;
  }

  setItemMedia(item: BookingModel) {
    return item?.availability?.availabilityable?.media
      ? item?.availability?.availabilityable?.media[0]?.original_url!
      : '../../../assets/images/categories/img2.jpg';
  }

  showServiceFee(item: BookingModel) {
    const modalRef = this.modalService.open(MessageAlertModalComponent, {
      size: 'sm',
      centered: true,
    });
    modalRef.componentInstance.message = this.translate.instant(
      marker('Cart.ServiceFeeDetail'),
      {
        name: this.getTranslatedData(
          item?.availability?.availabilityable?.name!
        ),
        serviceFee: item.availability?.availabilityable?.category?.service_fee,
      }
    );
  }

  removeComplementaryItem(booking: BookingModel, item: ComplementaryItemModel) {
    booking.complementaryItems = booking.complementaryItems.filter(
      (ci) => ci.id !== item.id
    );
    this.updateReservationItem(booking);
  }
}
