import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComplementaryItemModel } from 'src/app/models/booking.model';
import { BookingService } from '../../services/booking/booking.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../pages/base.component';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { ErrorHandlerService } from '../../services/error/error-handler.service';

@Component({
  selector: 'br-complementary-items-modal',
  templateUrl: './complementary-items-modal.component.html',
  styleUrls: ['./complementary-items-modal.component.scss'],
})
export class ComplementaryItemsModalComponent
  extends BaseComponent
  implements OnInit
{
  @Input() id!: number;
  @Input() type!: string;
  loading: boolean = false;
  complementaryItems: Array<ComplementaryItemModel> = [];
  @Output() passEntry: EventEmitter<ComplementaryItemModel> =
    new EventEmitter();

  locale: string = 'en';

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private bookingService: BookingService,
    private modalService: NgbActiveModal,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.getComplementaryItems();

    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  getComplementaryItems() {
    this.loading = true;
    this.bookingService
      .getComplementaryItems(this.id, { type: this.type })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (res) => {
          this.complementaryItems = res?.data.filter(ci => ci?.available_for === "web" || ci?.available_for === "kiosk_and_web").map((ci) => {
            if (!ci.quantity) {
              return { ...ci, quantity: 0 };
            }
            return ci;
          });
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  close() {
    this.modalService.close();
  }

  addComplementaryItem(item: ComplementaryItemModel) {
    this.passEntry.emit(item);
    this.modalService.close();
  }

  setImage(item: ComplementaryItemModel): string {
    return (
      item?.images[0]?.url || '../../../assets/images/categories/item2.png'
    );
  }
}
