import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryInfoModal, UserModel } from 'src/app/models/user.model';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ErrorHandlerService } from '../../../services/error/error-handler.service';
import { ChangePasswordModalComponent } from '../../../components/change-password-modal/change-password-modal.component';
import { COUNTRIES } from 'src/app/utils/countries.constants';
import { PhoneNumberValidator } from 'src/app/utils/phoneNumberValidator';

@Component({
  selector: 'br-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent extends BaseComponent implements OnInit {
  form!: FormGroup;
  userDetail!: UserModel;
  formChanged: boolean = false;
  cityLoading: boolean = false;
  fileSizeError: boolean = false;
  imageUrl!: string | ArrayBuffer | null;
  @ViewChild('fileInput') el!: ElementRef;
  countries: Array<CountryInfoModal> = COUNTRIES;
  selectedCountry: CountryInfoModal = this.countries[0];

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private modalService: NgbModal,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.email, Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      zip_code: new FormControl(null, [Validators.required]),
      avatar: new FormControl(null),
      address: new FormControl(null, [Validators.required]),
      commission: new FormControl(null),
    });
    this.patchProfileForm();
  }

  patchProfileForm(): void {
    this.showLoader();
    this.userService.getUpdatedUser().subscribe(
      (res) => {
        this.hideLoader();
        if (res) {
          this.userDetail = res;
          this.imageUrl =
            res.data?.avatar?.url ||
            '../../../../assets/images/profile/avatar.png';
          this.form.patchValue({
            name: res?.data?.name,
            email: res?.data?.email,
            zip_code: res?.data?.profile?.zip_code,
            address: res?.data?.profile?.address,
            commission: res?.data?.profile?.commission,
          });
          if (res?.data?.profile?.phone?.includes('-')) {
            this.form.patchValue({
              phone: res?.data?.profile?.phone?.split('-')[1],
            });
            this.selectedCountry =
              this.countries.find(
                (c) => c?.phoneCode === res?.data?.profile?.phone?.split('-')[0]
              )! || this.countries[0];
          } else {
            this.form.patchValue({
              phone: res?.data?.profile?.phone,
            });
            this.selectedCountry =
              this.countries.find(
                (c) =>
                  c?.phoneCode === '+' + res?.data?.profile?.country?.phone_code
              )! || this.countries[0];
          }
          if (this.selectedCountry) {
            this.form.controls.phone.setValidators([
              PhoneNumberValidator(this.selectedCountry.countryCode),
              Validators.required,
              Validators.pattern('^[0-9]*$'),
            ]);
          }
        } else {
          this.imageUrl = '../../../../assets/images/profile/avatar.png';
        }
      },
      (error) => {
        this.hideLoader();
        this.errorService.handle(error);
      }
    );
  }

  phoneChanged(value: string) {
    this.selectedCountry = this.countries.find((c) => c.phoneCode === value)!;
    this.form.get('phone')?.setValue(null);
    this.form.controls.phone.setValidators([
      PhoneNumberValidator(this.selectedCountry.countryCode),
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]);
    this.form.get('phone')?.updateValueAndValidity();
  }

  submit(): void {
    if (!this.form.valid) {
      this.errorService.formValidationError();
      return;
    }

    this.form.value.phone =
      this.selectedCountry.phoneCode + '-' + this.form.value.phone;

    const payload = this.clean(this.form.value);

    this.showLoader();
    this.userService
      .updateUserProfile(payload)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.userService.userSubject.next(res);
          this.errorService.showErrorMessage(
            marker('MyProfile.UpdateSucess'),
            'success'
          );
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  openModal() {
    this.modalService.open(ChangePasswordModalComponent, {
      centered: true,
    });
  }

  uploadFile(event: any) {
    let reader = new FileReader();
    let file = event.target.files[0];

    if (event.target.files && event.target.files[0]) {
      if (file.size < 2000000) {
        this.fileSizeError = false;
        reader.readAsDataURL(file);
        // When file uploads set it to file formcontrol
        reader.onload = () => {
          this.imageUrl = reader.result;
          this.form.patchValue({
            avatar: reader.result,
          });
          this.form.markAsDirty();
        };
      } else {
        this.fileSizeError = true;
      }
    }
  }

  removeUploadedFile(): void {
    this.fileSizeError = false;
    this.imageUrl = '../../../../assets/images/profile/avatar.png';
    this.form.patchValue({
      avatar: null,
    });
    this.form.markAsDirty();
  }

  /**
   * It will handle the Location related fields
   *
   * @param address formatted address returned from google
   */
  handleAddressChange(address: Address) {
    this.form.patchValue({
      address: address?.formatted_address,
    });
  }
}
