import * as moment from 'moment';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { GameModel } from '../../models/booking.model';
import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../pages/base.component';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import {
  BikeModel,
  BoatModel,
  CategoryDetailModel,
  CategoryListModel,
  CategoryParamsModel,
  ServiceModel,
} from 'src/app/models/booking.model';
import { DEFAULT_DATE_FORMAT, packages } from '../../utils/general-constants';

@Component({
  selector: 'br-change-booking-modal',
  templateUrl: './change-booking-modal.component.html',
  styleUrls: ['./change-booking-modal.component.scss'],
})
export class ChangeBookingModalComponent
  extends BaseComponent
  implements OnInit
{
  type!: string;
  form!: FormGroup;
  @Input() code!: string;
  @Input() orderId!: string;
  loading: boolean = false;
  params!: CategoryParamsModel;
  categoryDetail!: CategoryDetailModel;
  categories: Array<CategoryListModel> = [];
  minDate = moment(new Date()).format(DEFAULT_DATE_FORMAT);
  allData: Array<BoatModel | BikeModel | ServiceModel | GameModel> = [];

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    public bookingService: BookingService,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    const date = moment(new Date()).format(DEFAULT_DATE_FORMAT);
    this.form = new FormGroup({
      type: new FormControl(null, Validators.compose([Validators.required])),
      date: new FormControl(date),
    });
    this.getCategoriesListing();
  }

  getCategoriesListing(): void {
    this.bookingService.categoryListing().subscribe(
      (res: CategoryListModel[]) => {
        this.bookingService.categories = res;

        // to show the selected value (first item from the categories list)
        this.form.patchValue({
          type: res[0].id,
        });
        // get categories data
        this.setCategoryListingParams();
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }

  // to update the params - on init, date or type change
  setCategoryListingParams(): void {
    const isBefore = moment(this.form.value.date, 'YYYY/MM/DD').isBefore(
      moment(new Date(), DEFAULT_DATE_FORMAT),
      'day'
    );
    if (isBefore) return;

    this.resetData();
    this.params = {
      id: [this.form.value.type],
      date: this.form.value.date,
    };
    this.getListing();
  }

  getListing() {
    this.loading = true;
    this.bookingService
      .categoryDetail(this.params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (res) => {
          this.categoryDetail = res;
          this.type = res?.data[0]?.type;
          this.categoryDetail?.data?.forEach((cd) => {
            cd?.boats?.forEach((b) => {
              this.allData.push(b);
            });

            cd?.bicycles?.forEach((item) => {
              this.allData.push(item);
            });

            cd?.services?.forEach((s) => {
              this.allData.push(s);
            });

            cd?.games?.forEach((s) => {
              this.allData.push(s);
            });
          });
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  resetData(): void {
    this.allData = [];
  }
}
