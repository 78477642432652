import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Config } from '../../config';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BookingService } from '../booking/booking.service';
import { ErrorHandlerService } from '../error/error-handler.service';
import { OrderModel, PlaceOrderModel } from 'src/app/models/cart.model';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {CART_COUNT} from "../../utils/general-constants";

@Injectable({
  providedIn: 'root',
})
export class CartService extends HttpService {
  cartCount: number = 0;
  cartUpdated$ = new Subject<number>();

  constructor(
    http: HttpClient,
    private storage: LocalStorage,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService,
    private router: Router,
    private translate: TranslateService
  ) {
    super(http);
    storage.getItem(CART_COUNT).subscribe((res: any) => {
      if (!res) return;
      this.cartCount = res;
      this.cartUpdated$.next(res);
    });
  }

  addToCart() {
    this.cartCount++;
    this.storage.setItem(CART_COUNT, this.cartCount).subscribe((res) => {
      this.cartUpdated$.next(this.cartCount);
      this.errorService.showToast(
        this.translate.instant(marker('Cart.Success')),
        'success'
      );
    });
  }

  removeFromCart() {
    this.cartCount--;
    this.storage.setItem(CART_COUNT, this.cartCount).subscribe((res) => {
      this.cartUpdated$.next(this.cartCount);
    });
  }

  resetCart(redirect: boolean = true) {
    this.cartCount = 0;
    this.storage.setItem(CART_COUNT, this.cartCount).subscribe((res) => {
      this.cartUpdated$.next(this.cartCount);
      this.bookingService.timeExpired.next(true);
      if (redirect) {
        this.router.navigate(['home']);
      }
    });
  }

  list(code: number | string, type: string = 'by_id') {
    return this.http.get<{ data: OrderModel }>(
      `${Config.apiUrl}/order/${code}/reservations/list/${type}`
    );
  }

  deleteReservation(params: any) {
    return this.http.delete(`${Config.apiUrl}/order/reservation/delete`, {
      params: this.serialize(params),
    });
  }

  placeOrder(body: PlaceOrderModel, orderId: number) {
    return this.http.post<{ data: OrderModel }>(
      `${Config.apiUrl}/order/${orderId}/book`,
      body
    );
  }

  proceedToCheckout(orderId: number) {
    return this.http.get<{
      expired_reservations: Array<number>;
      message: string;
    }>(`${Config.apiUrl}/order/${orderId}/reservation/timeslot-reservation`);
  }
}
