import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BookingModel } from 'src/app/models/booking.model';
import { ORDER_ID } from '../../../utils/general-constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { CartService } from '../../../services/cart/cart.service';
import { ErrorHandlerService } from '../../../services/error/error-handler.service';
import { TaxDetailModalComponent } from '../../../components/tax-detail-modal/tax-detail-modal.component';

@Component({
  selector: 'br-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent extends BaseComponent implements OnInit {
  locale = 'en';
  orderId!: number;
  total: number = 0;
  serviceFee: number = 0;
  data: Array<Array<BookingModel>> = [];
  reservations: Array<BookingModel> = [];

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private storage: LocalStorage,
    private errorService: ErrorHandlerService,
    private cartService: CartService,
    private modalService: NgbModal
  ) {
    super(router, authService, userService, translate);
    storage.getItem(ORDER_ID).subscribe((res: any) => {
      this.orderId = res;
      this.list();
    });
  }

  ngOnInit(): void {
    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        startWith(this.translate.currentLang || this.translate.defaultLang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  list() {
    this.showLoader();
    this.cartService
      .list(this.orderId)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.reservations = res.data.reservations;
          this.total = res?.data?.price;
          res.data.reservations.forEach((item) => {
            this.serviceFee =
              this.serviceFee +
              item.availability.availabilityable.category.service_fee;
          });
          this.data = this.groupBy(res.data.reservations);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  viewTaxDetail(id: number) {
    const modalRef = this.modalService.open(TaxDetailModalComponent, {
      centered: true,
      size: 'xl',
    });

    modalRef.componentInstance.reservationId = id;
  }
}
