import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ComplementaryAllergicItem} from "../../models/booking.model";

@Component({
  selector: 'br-message-alert-modal',
  templateUrl: './message-alert-modal.component.html',
  styleUrls: ['./message-alert-modal.component.scss']
})
export class MessageAlertModalComponent implements OnInit {
  @Input() message!: string;
  @Input() showButtons: boolean = false;
  @Output() passEntry: EventEmitter<{confirm: boolean}> = new EventEmitter();
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  close(): void {
    this.modalService.dismissAll();
  }

  confirm() {
    this.passEntry.emit({confirm: true});
    this.activeModal.dismiss();
  }

}
