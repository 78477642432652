<div class="container auth-wrapper">
    <div class="row m-0">
        <div class="col-md-6 ps-0 pe-md-3 pe-0">
            <div class="feature-img">
                <!-- <img class="img-fluid" src="../../../../assets/images/auth/login.png" alt=""> -->
                <swiper [pagination]="{clickable: true}" class="loginSlider">
                    <ng-template swiperSlide>
                        <img class="img-fluid" src="../../../../assets/images/auth/register.png" alt="">
                    </ng-template>
                    <ng-template swiperSlide>
                        <img class="img-fluid" src="../../../../assets/images/auth/register.png" alt="">
                    </ng-template>
                    <ng-template swiperSlide>
                        <img class="img-fluid" src="../../../../assets/images/auth/register.png" alt="">
                    </ng-template>
                </swiper>
            </div>
        </div>

        <div class="col-md-6">
            <div class="auth-content">
                <form autocomplete="off" [formGroup]="form" (ngSubmit)="register()">
                    <div class="row">
                        <div class="col col-12">
                            <h4 class="fs-4 fw-bold mb-4 text-center">{{'Signup.Title' | translate}}</h4>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="fname"
                                    placeholder="{{ 'Register.Placeholder.Name' | translate }}" formControlName="name">
                                <label for="fname">{{'Signup.Form.FullName' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'name')"
                                [label]="'Signup.Form.FullName' | translate"></br-validation-messages>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email"
                                    placeholder="{{ 'Register.Placeholder.Email' | translate }}"
                                    formControlName="email">
                                <label for="email">{{'Signup.Form.Email' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'email')"
                                [label]="'Signup.Form.Email' | translate"></br-validation-messages>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="username"
                                    placeholder="{{ 'Register.Placeholder.Username' | translate }}"
                                    formControlName="username">
                                <label for="username">{{'Signup.Form.Username' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'username')"
                                [label]="'Signup.Form.Username' | translate"></br-validation-messages>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="password" class="form-control" id="password"
                                    placeholder="{{'Register.Placeholder.Password' | translate}}"
                                    formControlName="password">
                                <label for="password">{{'Signup.Form.Password' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'password')"
                                [label]="'Signup.Form.Password' | translate"></br-validation-messages>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="password" class="form-control" id="pconfirmation"
                                    placeholder="{{ 'Register.Placeholder.PasswordConfirmation' | translate }}"
                                    formControlName="password_confirmation">
                                <label for="pconfirmation">
                                    {{'Signup.Form.PasswordConfirmation' | translate}}
                                </label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'password_confirmation')"
                                [label]="'Signup.Form.PasswordConfirmation' | translate"
                                [relatedControls]="'Signup.Form.Password' | translate"></br-validation-messages>
                        </div>

                        <div class="col text-center mt-3">
                            <button type="submit" [disabled]="!form.valid"
                                class="btn btn-primary d-inline-block text-light px-5 text-18 w-100 text-uppercase">
                                {{'Buttons.CreateAccount' | translate}}
                            </button>
                        </div>

                        <div class="col col-12">
                            <p class="text-center py-3 text-gray-600 text-14">
                                {{ 'Signup.AccountMessage' | translate }}
                                <button
                                    class="btn btn-link text-info text-decoration-underline text-14 p-0 d-inline-block"
                                    routerLink="/page/terms-and-conditions">
                                    {{ 'Buttons.TermsConditions' | translate }}
                                </button> {{ 'Signup.And' | translate }}
                                <button
                                    class="btn btn-link text-info text-decoration-underline text-14 p-0 d-inline-block"
                                    routerLink="/page/privacy-policy">
                                    {{ 'Buttons.PrivacyPolicy' | translate }}.
                                </button>
                            </p>
                        </div>

                        <div class="col col-12 d-flex align-items-center justify-content-between pt-lg-5 mt-lg-5">
                            <button class="btn btn-link text-black-50 text-decoration-none text-14 fw-bold p-0"
                                routerLink="/login">
                                {{ 'Buttons.AlreadyHaveAccount' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
