import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../../services/booking/booking.service';
import {
  ComplementaryAllergicItem,
  ComplementaryItemModel,
} from '../../models/booking.model';
import { AllergicItemModel } from 'src/app/models/booking.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'br-allergic-item-modal',
  templateUrl: './allergic-item-modal.component.html',
  styleUrls: ['./allergic-item-modal.component.scss'],
})
export class AllergicItemModalComponent implements OnInit {
  @Input() totalAllergicPersons: number = 1;
  numberOfPersons: Array<number> = [];
  allergicItems: Array<AllergicItemModel> = [];

  @Input() totalPerson!: number;
  @Input() item!: ComplementaryItemModel;

  @Output() passEntry: EventEmitter<ComplementaryAllergicItem> =
    new EventEmitter();

  constructor(
    private bookingService: BookingService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.numberOfPersons = new Array(this.totalPerson)
      .fill(0)
      .map((_, idx) => idx + 1);
    this.getAllergicItems();
  }

  getAllergicItems() {
    const ids = this.item?.allergic_items.map((ai) => ai.id);
    this.bookingService.allergicItemsList().subscribe((res) => {
      this.allergicItems = res.data.map((item) => {
        return { ...item, checked: !!ids.includes(item.id) };
      });
    });
  }

  itemChanged(ev: any) {
    // mark the selected item as checked
    const index = this.allergicItems.findIndex(
      (item) => item.id == ev.target.value
    );
    if (index === -1) return;
    this.allergicItems[index].checked = !this.allergicItems[index].checked;
  }

  updateComplementaryItems() {
    const payload: ComplementaryAllergicItem = {
      id: this.item.id,
      allergic_items: this.allergicItems
        .filter((item) => item.checked)
        .map((item) => item.id),
      number_of_allergic_person: this.totalAllergicPersons,
    };
    this.passEntry.emit(payload);
    this.modalService.dismissAll();
  }
}
