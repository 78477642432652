<div class="container booking-wrapper">
  <div class="row">
    <!-- title + Steps-->
    <div
      class="title-and-steps flex-row align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center justify-content-start">
        <ul class="active-step">
          <li
            class="bg-success border-0"
            routerLink="/booking/personal-details"
          >
            <span class="lh-1"><i class="br-icon-check"></i></span>
          </li>
          <li class="bg-success border-0" routerLink="/booking/payment">
            <span class="lh-1"><i class="br-icon-check"></i></span>
          </li>
          <li>
            <span>3</span>
          </li>
        </ul>
        <h4>{{ "ReviewTerms.Title" | translate }}</h4>
      </div>
      <ul class="inactive-steps"></ul>
    </div>
    <!-- /Title + Steps-->
  </div>

  <div class="row">
    <!-- Left -->
    <div class="col-xl-9 col-lg-8 col-12 p-0 pe-lg-2">
      <div class="blue-header-section">
        <!-- Section Header -->
        <div
          class="section-header py-4 px-4 d-flex flex-row align-items-center justify-content-between"
        >
          <h4 class="mb-0">{{ "ReviewTerms.Title" | translate }}</h4>
        </div>

        <!-- Section Body -->
        <div class="section-body">
          <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="row justify-content-center">
              <div class="col-md-7 pe-md-4">
                <ul class="ps-5 ms-md-5">
                  <li
                    formArrayName="checkboxes"
                    *ngFor="
                      let page of checkboxesFormArray.controls;
                      let i = index
                    "
                  >
                    <div class="d-flex">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        [formControlName]="i"
                      />
                      <label class="text-left ps-3" for="flexCheckDefault">
                        {{ "ReviewTerms.AgreeTo" | translate }}
                        <a
                          class="text-info"
                          [routerLink]="['/page', reviewPages[i]?.slug!]"
                        >
                          {{ getTranslatedData(reviewPages[i]?.name!) }}
                        </a>
                      </label>
                    </div>
                  </li>

                  <li>
                    <div class="d-flex">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        formControlName="security"
                      />
                      <label class="text-left ps-3">
                        {{ "ReviewTerms.AgreeToSubmit" | translate }}
                        <b>{{
                          securityFee | currency: "EUR":"symbol":"1.2-2":locale
                        }}</b>
                        {{ "ReviewTerms.CheckInSecurityDeposit" | translate }}
                      </label>
                    </div>
                  </li>
                </ul>

                <!-- Confirm Booking -->
                <ul class="d-flex justify-content-center table-row pt-5 px-5">
                  <li class="col d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-info py-3 px-5 text-white fs-6 btn-lg w-100"
                      [disabled]="!form.valid"
                    >
                      {{ "Buttons.ConfirmBooking" | translate }}
                    </button>
                  </li>
                </ul>
                <small *ngIf="user">
                  {{ "ReviewTerms.RecurringDiscountMessage" | translate }}
                </small>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Right -->
    <div class="col-xl-3 col-lg-4 col-12 p-0 ps-lg-2">
      <div class="blue-header-section h-100 border order-details">
        <br-order-right-sidebar
          *ngIf="data?.length"
          [reservations]="data"
          [total]="total"
        >
        </br-order-right-sidebar>
      </div>
    </div>
  </div>
</div>
