<div class="container booking-wrapper">
  <div class="row">
    <!-- Thank You -->
    <div class="col col-12">
      <div class="blue-header-section">
        <!-- Section Body -->
        <div class="section-body">
          <div
            class="row justify-content-center flex-column align-items-center"
          >
            <div
              class="col-lg-4 col-md-6 col-12 d-flex justify-content-center flex-column align-items-center"
            >
              <h2 class="text-success fs-2 m-0 mb-1 fw-bold">
                {{ "PaymentComplete.Title" | translate }}
              </h2>

              <p class="text-gray fs-6">
                {{ "PaymentComplete.SubTitle" | translate }}
              </p>

              <div [innerHTML]="order?.qr_code | safeHtml"></div>

              <ul class="p-5 pb-0 w-100">
                <li>
                  <span
                    >{{ "PaymentComplete.BookingNumber" | translate }}:</span
                  >
                  <span class="fw-bolder">{{ order?.code }}</span>
                </li>

                <li>
                  <span>{{ "PaymentComplete.BookingDate" | translate }}:</span>
                  <span class="fw-bolder">{{ order?.created_at | date }}</span>
                </li>

                <li>
                  <span>
                    {{ "PaymentComplete.BookingMethod" | translate }}:
                  </span>
                  <span class="fw-bolder">{{ order?.source }}</span>
                </li>

                <li *ngIf="userDetail?.data?.role === 'dealer'">
                  <span>{{ "PaymentComplete.Commission" | translate }}:</span>
                  <span class="fw-bolder">
                    {{ userDetail?.data?.profile?.commission! }}%
                  </span>
                </li>
                <li *ngIf="userDetail?.data?.role === 'dealer'">
                  <span>{{ "PaymentComplete.BeforeCommission" | translate }}:</span>
                  <span class="fw-bolder">
                    {{ reservationSum | currency: "EUR":"symbol":"1.2-2":locale }}
                  </span>
                </li>
                <li>
                  <span>
                    {{ "PaymentComplete.TotalBookingAmount" | translate }}:
                  </span>
                  <span class="fw-bolder">
                    {{ order?.price | currency: "EUR":"symbol":"1.2-2":locale }}
                  </span>
                </li>
              </ul>
            </div>

            <!-- Confirm Booking -->
            <ul
              class="col-lg-3 col-md-6 col-12 d-flex flex-column justify-content-center table-row pt-5 px-5"
            >
              <li class="col d-flex justify-content-center">
                <button
                  class="btn btn-outline-info py-3 px-5 fs-6 btn-lg w-100"
                  (click)="downloadQRCode()"
                >
                  {{ "Buttons.DownloadQRCode" | translate }}
                </button>
              </li>
              <li
                class="col d-flex justify-content-center"
                *ngIf="userDetail?.data?.role !== 'dealer'"
              >
                <button
                  (click)="redirectToMollie()"
                  class="btn btn-info py-3 px-5 text-white fs-6 btn-lg w-100"
                >
                  {{ "Buttons.MakePayment" | translate }}
                </button>
              </li>
              <li
                class="col d-flex justify-content-center"
                *ngIf="userDetail?.data?.role === 'dealer'"
              >
                <button
                  (click)="generateReceipt()"
                  class="btn btn-info py-3 px-5 text-white fs-6 btn-lg w-100"
                >
                  {{ "Buttons.GenerateReceipt" | translate }}
                </button>
              </li>
              <li
                class="col d-flex justify-content-center"
                *ngIf="userDetail?.data?.role === 'dealer'"
              >
                <button
                  routerLink="/home"
                  class="btn btn-info py-3 px-5 text-white fs-6 btn-lg w-100"
                >
                  {{ "Buttons.BackToHome" | translate }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
