import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { BookingModel } from '../../models/booking.model';
import { BaseComponent } from '../../pages/base.component';
import { AuthService } from '../../services/auth/auth.service';
import { CartService } from '../../services/cart/cart.service';
import { UserService } from 'src/app/services/user/user.service';
import {
  ORDER_ID,
  packages,
  TAX_VAT,
  TAX_VMR,
} from '../../utils/general-constants';
import { ErrorHandlerService } from '../../services/error/error-handler.service';

@Component({
  selector: 'br-tax-detail-modal',
  templateUrl: './tax-detail-modal.component.html',
  styleUrls: ['./tax-detail-modal.component.scss'],
})
export class TaxDetailModalComponent extends BaseComponent implements OnInit {
  TAX_VAT = TAX_VAT;
  TYPE_PACKAGE = packages;
  @Input() reservations: Array<BookingModel> = [];

  @Input() reservationId!: number;

  constructor(
    protected router: Router,
    protected userService: UserService,
    protected authService: AuthService,
    protected translate: TranslateService,
    private storage: LocalStorage,
    private cartService: CartService,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
    storage.getItem(ORDER_ID).subscribe((res: any) => {
      if (!res) return;
      this.list(res);
    });
  }

  ngOnInit(): void {}

  list(id: number) {
    this.cartService.list(id).subscribe(
      (res) => {
        this.reservations = res.data.reservations;
        if (!this.reservationId) return;
        this.reservations = this.reservations.filter(
          (r) => r.id === this.reservationId
        );
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }

  amountWithoutTax(price: number, percentage_value: number) {
    const amount = price * (100 / (percentage_value + 100));
    return Math.round(amount * 100) / 100;
  }

  taxAmount(price: number, percentage_value: number) {
    const amount = price * (percentage_value / (100 + percentage_value));
    return Math.round(amount * 100) / 100;
  }

  priceWithVMR(item: BookingModel, type: string, id: number) {
    let availabilityPrice = this.calculateAvailabilityPrice(item);
    let quantity = this.getQuantityOfPersons(item);

    if (type === TAX_VMR) {
      availabilityPrice += this.categoryAndItemTax(item?.availability, quantity, id)
        .total_vmr!;
    }

    return availabilityPrice;
  }
}
