<!-- Section Header -->
<div
  class="section-header py-4 px-4 d-flex flex-row align-items-center justify-content-between"
>
  <h4 class="mb-0">{{ "Sidebar.Title" | translate }}</h4>
</div>

<!-- Section Body -->
<div class="section-body p-0 order-details">
  <ul class="p-3 pb-0 border-bottom">
    <li class="mb-3 align-items-center">
      <h3 class="text-black-100 fs-4 fw-bold pe-3">
        {{ "Sidebar.TimeRemaining" | translate }}
        <span class="fs-6 d-block">{{
          "Sidebar.CompleteYourOrder" | translate
        }}</span>
      </h3>
      <span class="fw-bolder fs-5 text-info fst-italic">{{ timeCounter }}</span>
    </li>
  </ul>

  <!-- Order detail - group of reservations -->
  <div class="px-3 border-bottom order-item" *ngFor="let group of reservations">
    <ul class="pt-3 border-bottom" *ngFor="let item of group">
      <li>
        <span class="col-7 fw-bolder">
          {{ getTranslatedData(item.availability.availabilityable.name) }}
        </span>
        <span class="fw-bold"> </span>
      </li>

      <!-- packages -->
      <ng-container
        *ngIf="
          item?.availability?.availabilityable?.category?.type === 'package';
          else otherCategories
        "
      >
        <li>
          <span class="col-7">{{
            "BookingDetail.ReservationPrice" | translate
          }}</span>
          <span>
            {{
              item?.availability?.availabilityable?.price
                | currency: "EUR":"symbol":"1.2-2":locale
            }}
          </span>
        </li>
      </ng-container>

      <ng-template #otherCategories>
        <!-- reservation price -->
        <ng-container
          *ngIf="item?.availability?.fix_price === true; else fixPrice"
        >
          <li>
            <span class="col-7">{{
              "BookingDetail.ReservationPrice" | translate
            }}</span>
            <span>
              {{
                (item?.availability?.availabilityable?.category?.type == "game"
                  ? item?.availability?.price! * item?.seats!
                  : item?.availability?.price
                ) | currency: "EUR":"symbol":"1.2-2":locale
              }}
            </span>
          </li>
        </ng-container>

        <ng-template #fixPrice>
          <!-- adults -->
          <li *ngIf="item.availability.fix_price === false">
            <span class="col-7"
              >{{ item.number_of_adults }} x
              {{ "Sidebar.Adults" | translate }}</span
            >
            <span>
              {{
                item?.number_of_adults! *
                  (item?.availability?.variable_price!
                    ? item.availability.adult_price!
                    : item?.availability?.price_per_person!)
                  | currency: "EUR":"symbol":"1.2-2":locale
              }}</span
            >
          </li>

          <!-- children -->
          <li *ngIf="item.availability.fix_price === false">
            <span class="col-7"
              >{{ item.number_of_children }} x
              {{ "Sidebar.Children" | translate }}</span
            >
            <span>
              {{
                item?.number_of_children! *
                  (item?.availability?.variable_price!
                    ? item.availability.child_price!
                    : item?.availability?.price_per_person!)
                  | currency: "EUR":"symbol":"1.2-2":locale
              }}</span
            >
          </li>
        </ng-template>
      </ng-template>

      <!-- Complementary Items -->
      <li *ngFor="let ci of item?.complementaryItems">
        <span class="col-7">{{ ci.quantity }} x {{ ci.title }}</span>
        <span>
          {{
            ci.quantity * ci.price | currency: "EUR":"symbol":"1.2-2":locale
          }}</span
        >
      </li>

      <!-- Extra Items -->
      <li *ngFor="let ei of item?.extraItems">
        <span class="col-7">{{ ei.pivot_quantity }} x {{ ei.name }}</span>
        <span>{{
          ei.pivot_quantity * ei.price | currency: "EUR":"symbol":"1.2-2":locale
        }}</span>
      </li>

      <!-- Sub total -->
      <li>
        <span class="col-7 fw-bold"> {{ "Sidebar.SubTotal" | translate }}</span>
        <span class="fw-bold">
          {{
            reservationSubTotal(item!, false)
              | currency: "EUR":"symbol":"1.2-2":locale
          }}
        </span>
      </li>

      <ng-container>
        <!-- Tax on reservation -->
        <li>
          <span class="col-7">{{ "Sidebar.TaxDetail" | translate }}</span>
          <span class="text-info" (click)="viewTaxDetail(item?.id!)">
            {{ "Sidebar.View" | translate }}
          </span>
        </li>

        <!-- Voucher -->
        <li *ngIf="item?.promotion?.active">
          <span class="col-7">{{ "Sidebar.VoucherDiscount" | translate }}</span>
          <span>
            {{
              item?.promotion?.type === "FIXED AMOUNT"
                ? (-1 * item?.promotion?.amount!
                  | currency: "EUR":"symbol":"1.2-2":locale)
                : item?.promotion?.amount + "%"
            }}
          </span>
        </li>
      </ng-container>

      <!-- Service Fee -->
      <li *ngIf="item?.availability?.availabilityable?.category?.service_fee!">
        <span class="col-7">{{ "Sidebar.ServiceFee" | translate }}</span>
        <span>
          {{
            item?.availability?.availabilityable?.category?.service_fee
              | currency: "EUR":"symbol":"1.2-2":locale
          }}
        </span>
      </li>

      <!-- Total -->
      <li>
        <span class="col-7 fw-bold"> {{ "Sidebar.Total" | translate }}</span>
        <span class="fw-bold">
          {{ item?.price | currency: "EUR":"symbol":"1.2-2":locale }}
        </span>
      </li>
    </ul>
  </div>

  <ul class="p-3 pb-1 bg-light">
    <li>
      <span class="fw-bolder">{{ "Sidebar.TotalAmount" | translate }}</span>
      <span class="fw-bolder">
        {{ total | currency: "EUR":"symbol":"1.2-2":locale }}
      </span>
    </li>

    <li *ngIf="commissionAmount > 0">
      <span>{{ "Sidebar.CommissionAmount" | translate }}</span>
      <span>{{ commissionAmount }}%</span>
    </li>

    <li *ngIf="commissionAmount > 0">
      <span class="fw-bolder">{{ "Sidebar.AfterCommission" | translate }}</span>
      <span class="fw-bolder">
        {{
          total - (total * commissionAmount) / 100
            | currency: "EUR":"symbol":"1.2-2":locale
        }}
      </span>
    </li>

    <li *ngIf="recurringDiscount > 0">
      <span>{{ "Sidebar.RecurringBookingDiscount" | translate }}</span>
      <span>{{ recurringDiscount }}%</span>
    </li>
    
    <li *ngIf="recurringDiscount > 0">
      <span class="fw-bolder">{{ "Sidebar.AfterDiscount" | translate }}</span>
      <span class="fw-bolder">
        {{
          total - (total * recurringDiscount) / 100
            | currency: "EUR":"symbol":"1.2-2":locale
        }}
      </span>
    </li>
  </ul>
</div>
