import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from './../../base.component';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'br-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {

  form!: FormGroup;
  username!: string;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private errorService: ErrorHandlerService,
  ) {
    super(router, authService, userService, translate)
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.username = navigation.extras.state.username
    }
  }

  ngOnInit(): void {
    const password = new FormControl(null,
      Validators.compose([Validators.required, Validators.minLength(8)])
    );

    const passwordConfirmation = new FormControl(null, (control: AbstractControl) => {
      if (password.valid && control.value !== password.value) {
        return { confirmed: true };
      }
      return null;
    });

    this.form = new FormGroup({
      username: new FormControl(this.username || null, Validators.compose([
        Validators.required
      ])),
      reset_password_token: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(8),
        Validators.maxLength(8),
      ])),
      password,
      password_confirmation: passwordConfirmation
    })
  }

  submit(): void {
    Object.keys(this.form.controls).forEach(key =>
      this.form.get(key)?.markAsTouched()
    );

    if (this.form.invalid) {
      this.errorService.formValidationError();
      return;
    }

    this.showLoader();
    this.authService.reset(this.form.value).pipe(
      finalize(() => this.hideLoader())
    ).subscribe(res => {
      this.form.reset();
      this.router.navigate(['home']);
    }, error => {
      this.errorService.handle(error);
    });
  }

}
