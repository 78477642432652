import * as moment from 'moment';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { UserModel } from '../models/user.model';
import { of, ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { game, packages, TAX_VAT, TAX_VMR } from '../utils/general-constants';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services/user/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AvailabilityModel,
  BookingModel,
  TranslationModel,
} from '../models/booking.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  template: '',
})
export class BaseComponent implements OnInit, OnDestroy {
  user!: UserModel | null;
  loggedIn: boolean = true;
  protected readonly destroy = new Subject<boolean>();
  protected readonly user$ = new ReplaySubject<UserModel | null>(1);

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService
  ) {
    authService.onLoginChange
      ?.pipe(
        switchMap((loggedIn: boolean) => {
          this.loggedIn = loggedIn;

          const url = window.location.pathname;
          if (
            loggedIn &&
            url !== '/register' &&
            url !== '/login' &&
            url !== '/forgot-password' &&
            url !== '/verify-email' &&
            url !== '/reset-password'
          ) {
            return userService.get();
          }

          return of<UserModel | null>(null);
        }),
        takeUntil(this.destroy)
      )
      .subscribe((user: UserModel) => {
        this.user = user;
        this.user$.next(user);
        this.userService.userSubject.next(user);
      });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  getFormControl(form: FormGroup, controlName: string): FormGroup {
    return form.get(controlName) as FormGroup;
  }

  getTranslatedData(value: TranslationModel | string | undefined): string {
    let lang: string = this.translate.currentLang;
    if (value && typeof value === 'object') {
      // return the locale name else return the name in english
      return value[lang as keyof typeof value] ?? value['en'];
    } else if (value && typeof value === 'string') {
      return value;
    } else {
      return 'NA';
    }
  }

  calculateHours(booking: BookingModel) {
    if (booking?.from || booking?.till) {
      // start time and end time
      const startTime = moment(booking?.from, 'HH:mm:ss');
      const endTime = moment(booking?.till, 'HH:mm:ss');
      return this.secondsToHms(endTime.diff(startTime, 'seconds'));
    } else if (booking?.availability?.is_fullday) {
      return this.translate.instant(marker('FullDay'));
    } else {
      return 'NA';
    }
  }

  getAvailabilityTitle(availability: AvailabilityModel): string {
    if (availability?.from && availability?.till) {
      return (
        availability?.from.split(':')[0] +
        ':' +
        availability?.from.split(':')[1] +
        ' - ' +
        availability?.till.split(':')[0] +
        ':' +
        availability?.till.split(':')[1]
      );
    } else if (availability?.is_fullday) {
      return this.translate.instant(marker('FullDay'));
    } else {
      return this.getTranslatedData(availability.title);
    }
  }

  getAdultAndChildPrice(availability: AvailabilityModel): {
    ap: number;
    cp: number;
  } {
    if (availability?.fix_price == true) {
      let ap = availability?.price;
      let cp = availability?.price;
      return { ap, cp };
    } else if (availability?.variable_price == true) {
      let ap = availability?.adult_price;
      let cp = availability?.child_price;
      return { ap, cp };
    } else {
      let ap = availability?.price_per_person;
      let cp = availability?.price_per_person;
      return { ap, cp };
    }
  }

  secondsToHms(d: number) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    // let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : '';
    let mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : '';
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay;
  }

  getFromUntil(time: string) {
    if (!time) return 'NA';
    return time.split(':')[0] + ':' + time.split(':')[1];
  }

  /**
   * Takes object and return object without null values
   *
   * @param obj any, object from which null keys will be removed
   * @returns any, return object without null keys
   */
  clean(obj: any): any {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  isInt(n: number) {
    return Number(n) === n && n % 1 === 0;
  }

  showLoader() {
    this.userService.showLoader.next(true);
  }

  hideLoader() {
    this.userService.showLoader.next(false);
  }

  counter(i: number) {
    return new Array(i);
  }

  groupBy(data: Array<BookingModel>) {
    let results = data.reduce(
      (results: { [key: number]: Array<BookingModel> }, b: BookingModel) => {
        (results[b.availability.availabilityable.category_id] =
          results[b.availability.availabilityable.category_id] || []).push(b);
        return results;
      },
      {}
    );

    const pairs = [];
    for (let key in results) {
      if (results.hasOwnProperty(key)) {
        pairs.push(results[key]);
      }
    }
    return pairs;
  }

  calculateAvailabilityPrice(reservation: BookingModel): number {
    if (reservation.availability.availabilityable.category.type == packages)
      return reservation.availability?.availabilityable?.price;

    let total = 0;
    if (reservation?.availability?.fix_price === true) {
      if(reservation?.availability?.availabilityable?.category?.type === game) {
        total = reservation?.availability?.price * reservation?.seats;
      } else {
        total = reservation?.availability?.price;
      }
    } else if (reservation?.availability.variable_price === true) {
      total =
        reservation?.availability?.adult_price! *
          reservation?.number_of_adults! +
        reservation?.availability?.child_price! *
          reservation?.number_of_children!;
    } else {
      total =
        (reservation?.number_of_adults! + reservation?.number_of_children!) *
        reservation?.availability?.price_per_person!;
    }
    return total;
  }

  reservationSubTotal(item: BookingModel, includeServiceFee: boolean): number {
    let subtotal = this.calculateAvailabilityPrice(item);

    subtotal = subtotal - item.voucher_discount;

    item.complementaryItems.forEach((ci) => {
      subtotal += ci.price * ci.quantity;
    });

    item.extraItems.forEach((ei) => {
      subtotal += ei.price * ei.pivot_quantity;
    });

    if (includeServiceFee) {
      subtotal =
        subtotal + item?.availability?.availabilityable?.category?.service_fee!;
    }

    return subtotal;
  }

  checkAvailabilityOverlap(timeSegments: any) {
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1: any, timeSegment2: any) =>
      timeSegment1[0]?.localeCompare(timeSegment2[0])
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];

      if (currentEndTime > nextStartTime) {
        return true;
      }
    }
    return false;
  }

  getQuantityOfPersons(booking: BookingModel) {
    // quantity
    let quantity = 0;
    if (booking?.availability?.availabilityable?.category?.type === packages) {
      quantity = booking?.availability?.availabilityable?.persons_capacity;
    } else if (
      booking?.availability?.availabilityable?.category?.type === game
    ) {
      quantity = booking?.seats;
    } else {
      quantity = booking?.number_of_adults + booking?.number_of_children;
    }

    return quantity;
  }

  // taxCalculation(booking: BookingModel) {
  //   let quantity = this.getQuantityOfPersons(booking);
  //   const { total_vmr, taxPercentage } = this.categoryAndItemTax(
  //     booking?.availability,
  //     quantity
  //   );

  //   let ct = 0;
  //   // tax calculation on the complementary items
  //   booking?.complementaryItems?.map((ci) => {
  //     ci?.tax_associations?.map((cta) => {
  //       if (cta.active) {
  //         ct += ci?.price * ci.quantity * (cta?.percentage_value / 100);
  //       }
  //     });
  //   });

  //   // tax calculation on the extra items
  //   booking?.extraItems?.map((ei) => {
  //     ei?.tax_associations?.map((cta) => {
  //       if (cta.active) {
  //         ct += ei?.price * ei?.pivot_quantity * (cta?.percentage_value / 100);
  //       }
  //     });
  //   });

  //   const price =
  //     this.calculateAvailabilityPrice(booking) - booking.voucher_discount;

  //   const totalTax = price * (taxPercentage / 100) + ct + total_vmr;

  //   return totalTax;
  // }

  categoryAndItemTax(
    availability: AvailabilityModel,
    quantity: number,
    id: number
  ) {
    let vmr = 0;
    let taxPercentage = 0;

    // tax calculation on the category
    const ct = availability?.availabilityable?.category?.tax_associations.find(
      (a) => a.id === id
    )!;
    if (ct.type === TAX_VAT && ct.active) {
      taxPercentage = taxPercentage + ct.percentage_value;
    } else if (ct.type === TAX_VMR) {
      vmr = ct.amount;
    }

    const total_vmr = vmr * (quantity || 0);

    // tax association on the category item i.e., boat || bicycle
    availability?.availabilityable?.tax_associations?.map((ta) => {
      if (ta.type === TAX_VAT && ta.active) {
        taxPercentage = taxPercentage + ta.percentage_value;
      }
    });

    return { total_vmr, taxPercentage };
  }
}
