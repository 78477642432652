<div class="container auth-wrapper">
    <div class="row m-0">
        <div class="col-md-6 ps-0 pe-md-3 pe-0">
            <div class="feature-img">
                <img class="img-fluid" src="../../../../assets/images/auth/forget.png" alt="">
            </div>
        </div>

        <div class="col-md-6">
            <div class="auth-content">

                <form autocomplete="off" [formGroup]="form" (ngSubmit)="verify()">
                    <div class="row">
                        <div class="col">
                            <h4 class="fs-4 fw-bold mb-4 text-center">{{'Verify.Title' | translate}}</h4>
                        </div>
                        <div class="col col-12">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email"
                                    placeholder="{{ 'Verify.Placeholder.Email' | translate }}" formControlName="email">
                                <label for="email">{{'Verify.Form.Email' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'email')"
                                [label]="'Verify.Form.Email' | translate"></br-validation-messages>
                        </div>

                        <div class="col col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="token"
                                    placeholder="{{ 'Verify.Placeholder.VerificationCode' | translate }}"
                                    formControlName="token">
                                <label for="token">{{'Verify.Form.VerificationCode' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'token')"
                                [label]="'Verify.Form.VerificationCode' | translate"></br-validation-messages>
                        </div>

                        <div class="col text-center mt-3">
                            <button type="submit" [disabled]="!form.valid"
                                class="btn btn-primary d-inline-block text-light px-5 text-18 w-100 text-uppercase">
                                {{'Buttons.Verify' | translate}}
                            </button>
                        </div>

                        <div
                            class="col col-12 d-flex align-items-center justify-content-between signup-ForgetHolder pt-5 mt-3">
                            <button class="btn btn-link text-black-50 text-decoration-none text-14 fw-bold"
                                type="button" (click)="resetVerificationCode()">
                                {{ 'Buttons.ResendVerificationCode' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>