import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from './../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { user } from './../../../utils/general-constants';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from './../../../services/auth/auth.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'br-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends BaseComponent implements OnInit {

  form!: FormGroup;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    const password = new FormControl(
      null,
      Validators.compose([Validators.required, Validators.minLength(8)])
    );

    const passwordConfirmation = new FormControl(null, [
      Validators.required,
      (control: AbstractControl) => {
        if (password.valid && control.value !== password.value) {
          return { confirmed: true };
        }
        return null;
      },
    ]);

    this.form = new FormGroup({
      name: new FormControl(null, Validators.compose([Validators.required])),
      username: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      email: new FormControl(
        null,
        Validators.compose([Validators.email, Validators.required])
      ),
      password,
      password_confirmation: passwordConfirmation,
      role: new FormControl(user),
    });
  }

  register(): void {
    Object.keys(this.form.controls).forEach((key) =>
      this.form.get(key)?.markAsTouched()
    );

    if (this.form.invalid) {
      this.errorService.formValidationError();
      return;
    }

    this.showLoader();

    this.authService.register(this.form.value).pipe(
      finalize(() => this.hideLoader())
    ).subscribe((res) => {
      this.errorService.showToast(res?.message, 'success');
      this.router.navigate(['verify-email'], {
        state: {
          email: this.form.value.email
        }
      });
      this.form.reset();
    }, (error) => {
      this.errorService.handle(error);
    });
  }
}
