<div class="container-fluid p-0 home-wrapper">
  <div class="row m-0 main-banner">
    <img
      src="../../../assets/images/home/Layer 20.jpg"
      alt=""
      class="img-fluid p-0 banner-img"
    />
    <div class="inner-content">
      <div class="container">
        <div class="row">
          <div class="reservation-block col-lg-4 col-md-7 col-sm-8 col-12">
            <h3>{{ "Home.MakeReservations" | translate }}</h3>
            <form autocomplete="off" [formGroup]="form" (ngSubmit)="submit()">
              <div class="mb-4">
                <label for="selectActivity" class="form-label">
                  {{ "Home.SelectActivity" | translate }}
                </label>
                <select
                  id="selectActivity"
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="type"
                >
                  <option value="null" disabled="true" [selected]="true">
                    {{ "Home.SelectActivity" | translate }}
                  </option>
                  <option
                    *ngFor="let item of bookingService.categories"
                    [value]="item.id"
                  >
                    {{ getTranslatedData(item.name) }}
                  </option>
                </select>
                <br-validation-messages
                  [control]="getFormControl(form, 'type')"
                  [label]="'Activity' | translate"
                ></br-validation-messages>
              </div>
              <div class="mb-5">
                <label for="date" class="form-label">{{
                  "Home.SelectDate" | translate
                }}</label>
                <div class="inputTypeDate">
                  <input
                    type="date"
                    class="form-control"
                    id="date"
                    formControlName="date"
                    [min]="minDate"
                    max="2030-12-31"
                  />
                </div>
              </div>
              <div>
                <button
                  class="btn btn-primary btn-lg text-white"
                  type="submit"
                  [disabled]="!form.valid"
                >
                  {{ "Buttons.Search" | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="welcomeSection">
  <div class="container">
    <div class="row flex-md-row flex-column-reverse">
      <div class="col-md-8 col-12">
        <h2 class="section-title">
          Welcome to <span>ARENDSHORST BUITENACTIVITEITEN</span>
        </h2>
        <p class="section-desc">
          Get to know the diversity of the water village of Giethoorn together
          with your family or colleagues! In a boat, kayak, on foot, on a
          two-wheeler or with various activities. Experience Giethoorn at
          Arendshorst Outdoor Activities!
        </p>

        <h3 class="section-subtitle">Facilillites and Services</h3>
        <div class="row">
          <ul>
            <li><i class="bi bi-arrow-right"></i><span>Rent a Boat</span></li>
            <li>
              <i class="bi bi-arrow-right"></i
              ><span>On Land Outdoor Activities</span>
            </li>
            <li>
              <i class="bi bi-arrow-right"></i><span>day Out family</span>
            </li>

            <li>
              <i class="bi bi-arrow-right"></i><span>Food and Drinks</span>
            </li>
            <li><i class="bi bi-arrow-right"></i><span>Rent a Bike</span></li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 col-12 mb-5 mb-md-0">
        <img
          class="img-fluid"
          src="../../../assets/images/home/welcome-image.png"
          alt=""
        />
      </div>
    </div>
  </div>
</section>

<section class="why-chooseUs">
  <div class="container">
    <h2 class="section-title text-center">
      Why <span>{{ "Home.ChooseUs.Title" | translate }}</span>
    </h2>
    <div class="row">
      <!-- Item -->
      <div class="col-12 col-lg-3 col-md-6">
        <div class="img-holder">
          <span class="br-icon br-icon-Competitive-Pricing"></span>
        </div>
        <h3 class="section-subtitle">
          {{ "Home.ChooseUs.Section1.Title" | translate }}
        </h3>
        <p class="section-desc">
          {{ "Home.ChooseUs.Section1.Description" | translate }}
        </p>
      </div>

      <!-- Item -->
      <div class="col-12 col-lg-3 col-md-6">
        <div class="img-holder">
          <span class="br-icon br-icon-Worldwide-Coverage"></span>
        </div>
        <h3 class="section-subtitle">
          {{ "Home.ChooseUs.Section2.Title" | translate }}
        </h3>
        <p class="section-desc">
          {{ "Home.ChooseUs.Section2.Description" | translate }}
        </p>
      </div>

      <!-- Item -->
      <div class="col-12 col-lg-3 col-md-6">
        <div class="img-holder">
          <span class="br-icon br-icon-fast-booking"></span>
        </div>
        <h3 class="section-subtitle">
          {{ "Home.ChooseUs.Section3.Title" | translate }}
        </h3>
        <p class="section-desc">
          {{ "Home.ChooseUs.Section3.Description" | translate }}
        </p>
      </div>

      <!-- Item -->
      <div class="col-12 col-lg-3 col-md-6">
        <div class="img-holder">
          <span class="br-icon br-icon-Guided-Tours"></span>
        </div>
        <h3 class="section-subtitle">
          {{ "Home.ChooseUs.Section4.Title" | translate }}
        </h3>
        <p class="section-desc">
          {{ "Home.ChooseUs.Section4.Description" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<div class="Call-us-now">
  <div class="container">
    <div
      class="row mx-lg-5 mx-0 inner justify-content-start align-items-center"
    >
      <div class="col-lg-1 col-md-2 col-3 d-flex justify-content-center pe-0">
        <i class="bi bi-telephone-plus fs-1"></i>
      </div>
      <div
        class="col-lg-4 col-md-10 col-9 ps-0 mb-3 mb-lg-0 d-flex flex-column justify-content-center align-items-start"
      >
        <span class="fs-5 text-light mb-1 fw-bold">{{
          "Home.CallUsNow" | translate
        }}</span>
        <a
          class="text-light fs-2 fw-bold text-decoration-none"
          href="tel: +31521362436"
          >+31521362436</a
        >
      </div>
      <div class="col-lg-7 col-12">
        <p class="fs-5 text-light fw-bold">
          {{ "Home.Newsletter.Title" | translate }}
        </p>
        <form [formGroup]="newsletterForm" (ngSubmit)="subscribe()">
          <div class="mb-3 subcribe-input">
            <input
              type="email"
              class="form-control"
              [placeholder]="'Home.EmailAddress' | translate"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              formControlName="email"
            />
            <button
              class="btn btn-secondary"
              [disabled]="!newsletterForm.valid"
              type="submit"
            >
              {{ "Buttons.Send" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
