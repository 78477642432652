import { Config } from 'src/app/config';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { merge, Observable, of, Subject, timer } from 'rxjs';
import { ChangePasswordModel } from 'src/app/models/auth.model';
import { TokenStoreService } from '../token/token-store.service';
import { PageModel, UserModel } from 'src/app/models/user.model';
import {
  distinctUntilChanged,
  mergeMap,
  publishReplay,
  refCount,
  retryWhen,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

@Injectable()
export class UserService extends HttpService {
  public user!: UserModel | null;
  showLoader = new Subject<boolean>();
  userSubject = new Subject<UserModel | null>();
  private readonly user$!: Observable<UserModel | null>;
  private readonly refreshUser = new Subject<boolean>();

  constructor(
    http: HttpClient,
    authService: AuthService,
    private tokenStoreService: TokenStoreService
  ) {
    super(http);
    authService.onLoginChange?.subscribe(() => (this.user = null));
    this.user$ = merge(
      authService.onLoginChange,
      this.refreshUser.pipe(switchMap(async () => authService.loggedIn))
    ).pipe(
      startWith(false),
      distinctUntilChanged(),
      switchMap((loggedIn) =>
        loggedIn
          ? this.http.get<UserModel>(this.buildUrl('me'))
          : of<UserModel | null>(null)
      ),
      retryWhen((errors) =>
        errors.pipe(mergeMap((_, attempt) => timer((attempt + 1) * 500)))
      ),
      publishReplay(1),
      refCount()
    );
  }

  broadCastUser(user: UserModel) {
    this.userSubject.next(user);
  }

  get(): Observable<UserModel> {
    if (this.user) {
      return of(this.user);
    }

    return this.http
      .get<UserModel>(this.buildUrl('auth', 'me'))
      .pipe(tap((user) => (this.user = user)));
  }

  getUpdatedUser(userId?: number): Observable<UserModel> {
    return this.http
      .get<UserModel>(this.buildUrl('auth', 'me', userId ? userId : ''))
      .pipe(
        tap((user) => {
          if (!userId) {
            this.user = user;
            this.broadCastUser(user);
          }
        })
      );
  }

  updateUserProfile(request: any) {
    return this.http.put<UserModel>(`${Config.apiUrl}/auth/me`, request);
  }

  changePassword(request: ChangePasswordModel) {
    return this.http.post(`${Config.apiUrl}/auth/change-password`, request);
  }

  getLoggedInUser(user$: any): any {
    const promise = new Promise((resolve, reject) => {
      user$.subscribe((data: any) => {
        resolve(data);
      });
    });
    return promise;
  }

  removeAccount() {
    return this.http
      .delete(this.buildUrl('me'))
      .pipe(switchMap(() => this.tokenStoreService.updateToken(null)));
  }

  getPagesList(params: { type: string }) {
    return this.http.get<{ data: Array<PageModel> }>(
      `${Config.apiUrl}/page-list`,
      {
        params: this.serialize(params),
      }
    );
  }

  getPageDetail(params: { slug: string }) {
    return this.http.get<{ data: Array<PageModel> }>(`${Config.apiUrl}/page`, {
      params: this.serialize(params),
    });
  }

  subscribeNewsletter(data: { email: string }) {
    return this.http.post<{ message: string }>(
      `${Config.apiUrl}/subscribe-newsletter`,
      data
    );
  }
}
