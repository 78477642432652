import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStart, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ScriptService } from './services/script/ScriptService';
import { Config } from './config';
import { LocaleSpecification } from 'moment';
import * as moment from 'moment';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { SelectedLanguageModel } from './models/user.model';
import { APP_VERSION } from './utils/general-constants';

// const SCRIPT_PATH = `https://maps.googleapis.com/maps/api/js?key=${Config.googleMapKey}&libraries=places&language=en`;

async function tryLoadAngularLocale(
  locale: string
): Promise<{ angularLocale?: any; angularLocaleExtra?: any }> {
  try {
    const angularLocale = await import(
      /* webpackExclude: /\.d\.ts$/ */
      `@angular/common/locales/${locale}`
    ).then((m: { default: any } | any) => ('default' in m ? m.default : m));
    const angularLocaleExtra = await import(
      /* webpackExclude: /\.d\.ts$/ */
      `@angular/common/locales/extra/${locale}`
    )
      .then((m: { default: any } | any) => ('default' in m ? m.default : m))
      .catch(() => undefined);

    return { angularLocale, angularLocaleExtra };
  } catch (e) {}

  return {};
}

async function tryLoadMomentLocale(
  locale: string
): Promise<LocaleSpecification | null> {
  try {
    return await import(`moment/dist/locale/${locale}`).then(
      (m: { default: LocaleSpecification } | LocaleSpecification) =>
        'default' in m ? m.default : m
    );
  } catch (e) {}

  return null;
}

export const LANGUAGES: Array<SelectedLanguageModel> = [
  {
    title: 'NL (Dutch)',
    icon: '../../../assets/images/home/Layer 21.png',
    value: 'nl',
  },
  {
    title: 'EN (English)',
    icon: '../../../assets/images/home/Layer 23.png',
    value: 'en',
  },
  {
    title: 'DE (German)',
    icon: '../../../assets/images/home/Layer 22.png',
    value: 'de',
  },
  {
    title: 'ES (Spanish)',
    icon: '../../../assets/images/home/Layer 1420.png',
    value: 'es',
  },
];

@Component({
  selector: 'br-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  isHome!: boolean;
  loading: boolean = false;
  title = 'boat-reservation';

  readonly languages = LANGUAGES;
  selectedLanguage = LANGUAGES[0];
  private readonly registeredAngularLocales = ['en'];

  constructor(
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private errorService: ErrorHandlerService,
    private storage: LocalStorage,
    private changeDetector: ChangeDetectorRef,
    private renderer: Renderer2,
    private scriptService: ScriptService
  ) {
    // manage the loader
    this.userService.showLoader.subscribe((res) => {
      this.loading = res;
    });
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.isHome = event.url.includes('/home') || event.url === '/';
      }
    });
  }

  ngOnInit(): void {
    // const scriptElement = this.scriptService.loadJsScript(
    //   this.renderer,
    //   SCRIPT_PATH
    // );
    // scriptElement.onload = () => {
    //   console.log('Google API Script loaded');
    // };
    // scriptElement.onerror = () => {
    //   console.log('Could not load the Google API Script!');
    // };
    this.setup();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  private setup() {
    this.manageStorage();
    this.setupLanguage();
  }

  private setupLanguage() {
    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        startWith(this.translate.currentLang || this.translate.defaultLang),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((locale) => {
        this.selectedLanguage =
          this.languages.find((lang) => lang.value === locale) ||
          this.languages[0];
      });

    this.storage
      .getItem<string>('lang', { type: 'string' })
      .pipe(
        catchError(() => of(null)),
        map((val) => val || 'en'),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((locale: string | null) => {
        locale ??= 'en';

        this.onLanguageChange(
          this.languages.find((lang) => lang.value === locale) ||
            this.languages[0]
        ).catch((reason) => console.warn(reason));
      });
  }

  async onLanguageChange(lang: SelectedLanguageModel): Promise<void> {
    this.selectedLanguage = lang;

    await Promise.all([
      this.loadAngularLocale(lang.value),
      this.loadMomentLocale(lang.value),
    ]);

    if (this.selectedLanguage.value !== lang.value) {
      return;
    }

    this.translate.use(lang.value);

    this.storage.setItem('lang', lang.value).subscribe({
      error: (reason) => {
        this.errorService.handle(reason);
      },
    });
    localStorage.setItem('lang', lang.value);
  }

  private async loadAngularLocale(locale: string): Promise<void> {
    if (this.registeredAngularLocales.includes(locale)) {
      return;
    }

    let currentLocale = locale.replace('_', '-');

    while (this.selectedLanguage.value === locale && currentLocale) {
      const { angularLocale, angularLocaleExtra } = await tryLoadAngularLocale(
        currentLocale
      );
      console.log(this.selectedLanguage.value, locale, currentLocale);
      if (angularLocale) {
        this.registerAngularLocale(locale, angularLocale, angularLocaleExtra);

        return;
      }

      currentLocale = currentLocale.split('-').slice(0, -1).join('-');
    }

    if (this.selectedLanguage.value !== locale) {
      return;
    }

    const { angularLocale, angularLocaleExtra } = await tryLoadAngularLocale(
      'en'
    );

    this.registerAngularLocale(locale, angularLocale, angularLocaleExtra);
  }

  private async loadMomentLocale(locale: string): Promise<void> {
    if (moment.locales().includes(locale)) {
      moment.locale(locale);

      return;
    }

    let currentLocale = locale.replace('_', '-');

    while (this.selectedLanguage.value === locale && currentLocale) {
      const momentLocale = await tryLoadMomentLocale(currentLocale);

      if (momentLocale) {
        this.registerMomentLocale(locale, momentLocale);

        return;
      }

      currentLocale = currentLocale.split('-').slice(0, -1).join('-');
    }

    if (this.selectedLanguage.value !== locale) {
      return;
    }

    this.registerMomentLocale(locale, (moment.localeData('en') as any)._config);
  }

  private registerAngularLocale(
    locale: string,
    angularLocale: any,
    angularLocaleExtra?: any
  ) {
    if (!this.registeredAngularLocales.includes(locale)) {
      registerLocaleData(angularLocale, locale, angularLocaleExtra);
      this.registeredAngularLocales.push(locale);
    }
  }

  private registerMomentLocale(locale: string, spec: LocaleSpecification) {
    if (!moment.locales().includes(locale)) {
      moment.defineLocale(locale, spec);
    }

    if (this.selectedLanguage.value === locale) {
      moment.locale(locale);
    }
  }

  private manageStorage() {
    this.storage.getItem(APP_VERSION).subscribe((res) => {
      if (!res) {
        // storage does not exist
        this.clearAndSetAppVersion();
      } else {
        // storage exist
        if (res !== Config.appVersion) {
          this.clearAndSetAppVersion();
        }
      }
    });
  }

  private clearAndSetAppVersion() {
    this.storage.clear().subscribe(() => {
      console.warn('storage clear');
    });
    this.storage.setItem(APP_VERSION, Config.appVersion).subscribe(() => {
      console.warn('app version set');
    });
  }
}
