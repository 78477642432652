<div class="container booking-wrapper">
  <div class="row">
    <!-- title + Steps-->
    <div
      class="title-and-steps flex-row align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center justify-content-start">
        <ul class="active-step">
          <li>
            <span>1</span>
          </li>
        </ul>
        <h4>{{ "PersonalDetail.Title" | translate }}</h4>
      </div>
      <ul class="inactive-steps">
        <li>
          <span>2</span>
        </li>
        <li>
          <span>3</span>
        </li>
      </ul>
    </div>
    <!-- /Title + Steps-->
  </div>

  <div class="row">
    <!-- left -->
    <div class="col-xl-9 col-lg-8 col-12 p-0 pe-md-2">
      <div class="blue-header-section">
        <!-- Section Header -->
        <div
          class="section-header py-4 px-4 d-flex flex-row align-items-center justify-content-between"
        >
          <h4 class="mb-0">{{ "PersonalDetail.Subtitle" | translate }}</h4>
        </div>

        <form [formGroup]="form" (ngSubmit)="submit()">
          <!-- Section Body -->
          <div class="section-body">
            <div class="row mx-0">
              <div class="col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="{{
                      'PersonalDetail.Placeholder.Name' | translate
                    }}"
                    formControlName="user_name"
                  />
                  <label for="name">{{
                    "PersonalDetail.Form.Name" | translate
                  }}</label>
                </div>
                <br-validation-messages
                  [control]="getFormControl(form, 'user_name')"
                  [label]="'PersonalDetail.Form.Name' | translate"
                >
                </br-validation-messages>
              </div>

              <div class="col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="{{
                      'PersonalDetail.Placeholder.EmailAddress' | translate
                    }}"
                    formControlName="user_email"
                  />
                  <label for="email">{{
                    "PersonalDetail.Form.Email" | translate
                  }}</label>
                </div>
                <br-validation-messages
                  [control]="getFormControl(form, 'user_email')"
                  [label]="'PersonalDetail.Form.Email' | translate"
                ></br-validation-messages>
              </div>

              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-floating mb-3">
                      <!--                      <select-->
                      <!--                        [ngModel]="selectedCountry.phoneCode"-->
                      <!--                        [ngModelOptions]="{ standalone: true }"-->
                      <!--                        (change)="phoneChanged($event)"-->
                      <!--                        class="form-select"-->
                      <!--                      >-->
                      <!--                        <option-->
                      <!--                          *ngFor="let item of countries"-->
                      <!--                          [value]="item?.phoneCode"-->
                      <!--                        >-->
                      <!--                          {{ item?.flag }} {{ item?.phoneCode }}-->
                      <!--                        </option>-->
                      <!--                      </select>-->
                      <ng-select
                        [ngModel]="selectedCountry?.phoneCode"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="phoneChanged($event)"
                        [clearable]="false"
                        class="form-select custom-select"
                      >
                        <ng-option
                          *ngFor="let item of countries"
                          [value]="item?.phoneCode"
                          >{{ item?.flag }} {{ item?.phoneCode }} -
                          {{ item?.country }}</ng-option
                        >
                      </ng-select>
                      <label for="tel">{{
                        "PersonalDetail.Form.Code" | translate
                      }}</label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-floating mb-3">
                      <input
                        type="tel"
                        class="form-control"
                        id="tel"
                        placeholder="{{
                          'PersonalDetail.Placeholder.PhoneNumber' | translate
                        }}"
                        formControlName="user_phone"
                      />
                      <label for="tel">{{
                        "PersonalDetail.Form.PhoneNumber" | translate
                      }}</label>
                    </div>
                  </div>
                </div>
                <br-validation-messages
                  [control]="getFormControl(form, 'user_phone')"
                  [label]="'PersonalDetail.Form.PhoneNumber' | translate"
                >
                </br-validation-messages>
              </div>

              <div class="col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    ngx-google-places-autocomplete
                    #refLoc
                    #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)"
                    id="address"
                    class="google-places form-control"
                    formControlName="user_country"
                  />
                  <label for="address">{{
                    "PersonalDetail.Form.Address" | translate
                  }}</label>
                </div>
                <br-validation-messages
                  [control]="getFormControl(form, 'user_country')"
                  [label]="'PersonalDetail.Form.Address' | translate"
                ></br-validation-messages>
              </div>

              <div class="col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="zip"
                    placeholder="{{
                      'PersonalDetail.Placeholder.Code' | translate
                    }}"
                    formControlName="zip"
                  />
                  <label for="zip">{{
                    "PersonalDetail.Form.ZipCode" | translate
                  }}</label>
                </div>
                <br-validation-messages
                  [control]="getFormControl(form, 'zip')"
                  [label]="'PersonalDetail.Form.ZipCode' | translate"
                ></br-validation-messages>
              </div>

              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="{{
                      'PersonalDetail.Placeholder.Note' | translate
                    }}"
                    id="floatingTextarea2"
                    style="height: 100px"
                    formControlName="note"
                  ></textarea>
                </div>
              </div>
            </div>

            <ul class="row justify-content-between table-row pt-5 px-2 px-lg-0">
              <li class="col d-flex justify-content-end">
                <button
                  type="submit"
                  class="btn btn-info py-3 px-5 text-white fs-6 btn-lg"
                  [disabled]="!form.valid"
                >
                  {{ "Buttons.Continue" | translate }}
                </button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>

    <!-- Right -->
    <div class="col-xl-3 col-lg-4 col-12 p-0 ps-md-2">
      <div class="blue-header-section border order-details">
        <br-order-right-sidebar
          *ngIf="data?.length"
          [reservations]="data"
          [total]="order?.price!"
        >
        </br-order-right-sidebar>
      </div>
    </div>
  </div>
</div>
