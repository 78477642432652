import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExtraItemModel } from '../../models/booking.model';

@Component({
  selector: 'br-reset-cart-confirmation',
  templateUrl: './reset-cart-confirmation.component.html',
  styleUrls: ['./reset-cart-confirmation.component.scss'],
})
export class ResetCartConfirmationComponent implements OnInit {
  @Output() passEntry: EventEmitter<boolean> = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  confirm(value: boolean) {
    this.passEntry.emit(value);
    this.modalService.dismissAll();
  }

  close() {
    this.modalService.dismissAll();
  }
}
