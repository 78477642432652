import { Config } from 'src/app/config';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import {
  BookingReservationModel,
  CategoryDetailModel,
  ItemParamsModel,
  PromoParamsModel,
  TimeslotResevationModel,
  SunInfoModel,
  ComplementaryItemModel,
  ExtraItemModel,
  CategoryListModel,
  OrderDetail,
  ParkTimingModel,
} from 'src/app/models/booking.model';
import {
  AllergicItemModel,
  AvailabilityModel,
  BookingModel,
  BookingPromoModel,
  PaymentModel,
} from '../../models/booking.model';
import { OrderModel } from '../../models/cart.model';
import { TIME_COUNTER } from '../../utils/general-constants';
import { map } from 'rxjs/operators';

@Injectable()
export class BookingService extends HttpService {
  intervalTimer!: any;
  timeCounter = new Subject<string>();
  timeExpired = new Subject<boolean>();
  categories: Array<CategoryListModel> = [];

  constructor(
    http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
    private errorService: ErrorHandlerService,
    private localStorage: LocalStorage
  ) {
    super(http);

    this.timeExpired.subscribe((res) => {
      if (res == true) {
        this.clearTimer(false);
        clearInterval(this.intervalTimer);
      }
    });
  }

  startTimer(duration: number) {
    let timer = duration,
      minutes,
      seconds;

    // clear the interval if any before moving to next component
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.intervalTimer = setInterval(() => {
      minutes = Math.floor(timer / 60);
      seconds = Math.floor(timer % 60);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      const time = {
        mint: minutes,
        sec: seconds,
      };

      // store time to start the timer from same time when moved to next component
      this.localStorage.setItem(TIME_COUNTER, time).subscribe((res) => {
        console.info(res);
      });

      const t = minutes + ':' + seconds;
      this.timeCounter.next(t);

      if (--timer < 0) {
        clearInterval(this.intervalTimer);
        this.clearTimer(true);
      }
    }, 1000);
  }

  clearTimer(expired: boolean) {
    // remove the timer and booking detail when time end or user redirects
    this.localStorage.removeItem(TIME_COUNTER).subscribe((res) => {
      console.info(res);
    });

    if (expired) {
      this.errorService.showToast(
        this.translateService.instant(marker('Booking.TimeExpired'))
      );
    }
  }

  categoryListing(params?: any): Observable<any> {
    return this.http
      .get(`${Config.apiUrl}/booking/category-listing`, {
        params: this.serialize(params),
      })
      .pipe(
        map(({ data }: any) => {
          const categories: CategoryListModel[] = [];
          for (const key in data) {
            const item = {
              id: data[key].id,
              name: data[key].name,
              images: data[key]?.images,
            };
            categories.push(item);
          }
          return categories;
        })
      );
  }

  categoryDetail(params?: any) {
    return this.http.get<CategoryDetailModel>(
      `${Config.apiUrl}/booking/category-listing-detail`,
      {
        params: this.serialize(params),
      }
    );
  }

  itemDetail(params?: ItemParamsModel): Observable<any> {
    return this.http.get(`${Config.apiUrl}/booking/item-details`, {
      params: this.serialize(params),
    });
  }

  timeslotReservation(body: TimeslotResevationModel) {
    return this.http.post<{ data: AvailabilityModel }>(
      `${Config.apiUrl}/booking/timeslot-reservation`,
      body
    );
  }

  bookingReservation(body: BookingReservationModel) {
    return this.http.post<{ data: BookingModel }>(
      `${Config.apiUrl}/booking/reservation-booking`,
      body
    );
  }

  gamesBooking(body: any, itemId: number, availability_id: number) {
    return this.http.post<{ data: BookingModel }>(
      `${Config.apiUrl}/game/reservation/${itemId}/availability/${availability_id}`,
      body
    );
  }

  packageBooking(body: any, itemId: number) {
    return this.http.post<{ data: BookingModel }>(
      `${Config.apiUrl}/package/reservation/${itemId}`,
      body
    );
  }

  updateBooking(body: BookingReservationModel) {
    return this.http.post<{ data: BookingModel }>(
      `${Config.apiUrl}/booking/update-reservation-booking`,
      body
    );
  }

  updateGameBooking(code: string, body: any) {
    return this.http.put(
      `${Config.apiUrl}/game/reservation/${code}/update`,
      body
    );
  }

  cancelDealerReservation(params: { code: string }) {
    return this.http.get(`${Config.apiUrl}/booking/cancel-dealer-reservation`, {
      params: this.serialize(params),
    });
  }

  cancelUserReservation(params: { code: string }) {
    return this.http.get(`${Config.apiUrl}/booking/reservation-cancel`, {
      params: this.serialize(params),
    });
  }

  cancelReservationCharges(params: { code: string }) {
    return this.http.get<{ message: string }>(
      `${Config.apiUrl}/booking/reservation-cancel-charges`,
      {
        params: this.serialize(params),
      }
    );
  }

  ordersList(params?: any) {
    return this.http.get<{ data: Array<OrderModel> }>(
      `${Config.apiUrl}/user/my-orders`,
      {
        params: this.serialize(params),
      }
    );
  }

  bookingListing(orderId: number, params?: any) {
    return this.http.get<{
      data: { reservations: Array<BookingModel>; orderDetails: OrderDetail };
    }>(`${Config.apiUrl}/user/my-order/${orderId}/reservations`, {
      params: this.serialize(params),
    });
  }

  getComplementaryItems(id: number, params: any) {
    return this.http.get<{ data: Array<ComplementaryItemModel> }>(
      `${Config.apiUrl}/booking/get-complementary-items/${id}`,
      {
        params: this.serialize(params),
      }
    );
  }

  bookingExtraItems(id: number, params: any) {
    return this.http.get<{ data: Array<ExtraItemModel> }>(
      `${Config.apiUrl}/booking/get-extra-items/${id}`,
      {
        params: this.serialize(params),
      }
    );
  }

  reservationDetail(params?: { code: string }) {
    return this.http.get<{ data: BookingModel }>(
      `${Config.apiUrl}/user/my-reservation/detail`,
      {
        params: this.serialize(params),
      }
    );
  }

  allergicItemsList(params?: any) {
    return this.http.get<{ data: Array<AllergicItemModel> }>(
      `${Config.apiUrl}/booking/allergic-items-listing`,
      {
        params: this.serialize(params),
      }
    );
  }

  applyPromoCode(params: PromoParamsModel) {
    return this.http.get<{ data: BookingPromoModel }>(
      `${Config.apiUrl}/booking/promotion`,
      {
        params: this.serialize(params),
      }
    );
  }

  dealerPaymentListing(params?: any) {
    return this.http.get<{ data: Array<PaymentModel> }>(
      `${Config.apiUrl}/user/my-payments`,
      {
        params: this.serialize(params),
      }
    );
  }

  getRecurringDiscount(params: { date: string }) {
    return this.http.get<{ discount_percentage: number }>(
      `${Config.apiUrl}/user/reservation/discount`,
      {
        params: this.serialize(params),
      }
    );
  }

  getSunriseDetail() {
    return this.http.get<SunInfoModel>(`${Config.apiUrl}/sun-info`);
  }

  parkTiming(params: { date: string }) {
    return this.http.get<ParkTimingModel>(`${Config.apiUrl}/settings/park-timing`, {
      params: this.serialize(params),
    });
  }
}
