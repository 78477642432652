import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { PageModel } from '../../models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';

@Component({
  selector: 'br-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contact!: PageModel;

  constructor(
    private userService: UserService,
    private errorService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.userService.showLoader.next(true);
    this.userService.getPageDetail({ slug: 'contact us' }).pipe(
      finalize(() => this.userService.showLoader.next(false))
    ).subscribe(res => {
      this.contact = res?.data[0];
    }, error => {
      this.errorService.handle(error);
    })
  }

}
