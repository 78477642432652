import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from '../../../services/auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorHandlerService } from '../../../services/error/error-handler.service';

// import Swiper core and required modules
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';

SwiperCore.use([Pagination]);

@Component({
  selector: 'br-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  form!: FormGroup;
  showPassword: boolean = false;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      password: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
  }

  login(): void {
    Object.keys(this.form.controls).forEach((key) =>
      this.form.get(key)?.markAsTouched()
    );

    if (this.form.invalid) {
      this.errorService.formValidationError();
      return;
    }

    this.showLoader();

    this.authService
      .login(this.form.value)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.form.reset();
          this.router.navigate(['home']).catch((reason) => {
            console.warn(reason);
          });
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
