<p *ngIf="visible" [ngSwitch]="error" class="error">
    <em *ngSwitchCase="'required'">
        {{ 'ValidationMessage.Required' | translate:{label: label} }}
    </em>
    <em *ngSwitchCase="'email'">
        {{ 'ValidationMessage.Email' | translate:{label: label} }}
    </em>
    <em *ngSwitchCase="'WrongNumber'">
        {{ "ValidationMessage.WrongNumber" | translate: { label: label } }}
    </em>
    <em *ngSwitchCase="'minlength'">
        {{ 'ValidationMessage.MinLength' | translate: { label: label, value: getValue(error, 'requiredLength') } }}
    </em>
    <em *ngSwitchCase="'maxlength'">
        {{ 'ValidationMessage.MaxLength' | translate:{ label: label, value: getValue(error, 'requiredLength') } }}
    </em>
    <em *ngSwitchCase="'min'">
        {{ 'ValidationMessage.Min' | translate:{ label: label, value: getValue(error, 'min') } }}
    </em>
    <em *ngSwitchCase="'max'">
        {{ 'ValidationMessage.Max' | translate:{ label: label, value: getValue(error, 'max') } }}
    </em>
    <em *ngSwitchCase="'confirmed'">
        {{ 'ValidationMessage.Confirmed' | translate:{ label: label, related: getLabel(error, 'target') ||
        relatedControls } }}
    </em>
    <em *ngSwitchCase="'pattern'">
        {{ 'ValidationMessage.InvalidPattern' | translate:{ label: label, pattern: pattern } }}
    </em>
    <em *ngSwitchCase="'smaller'">
        {{ 'ValidationMessage.Smaller' | translate:{ label: label, secondLabel: secondLabel } }}
    </em>
    <em *ngSwitchCase="'greater'">
        {{ 'ValidationMessage.Greater' | translate:{ label: label, secondLabel: secondLabel } }}
    </em>
</p>