import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { PaymentModel } from 'src/app/models/booking.model';
import { UserService } from '../../../services/user/user.service';
import { AuthService } from '../../../services/auth/auth.service';
import { PAYMENT_STATUSES } from '../../../utils/general-constants';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import {
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_EXPIRED,
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_OPEN,
  PAYMENT_STATUS_PAID,
} from 'src/app/utils/general-constants';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'br-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.scss'],
})
export class MyPaymentsComponent extends BaseComponent implements OnInit {
  locale: string = 'en';
  loading: boolean = true;
  statuses = PAYMENT_STATUSES;
  payments!: Array<PaymentModel>;
  params: { status: string | null } = {
    status: null,
  };

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.getPaymentListing();
    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  getPaymentListing() {
    this.showLoader();
    this.loading = true;
    this.params = this.clean(this.params);
    this.bookingService
      .dealerPaymentListing(this.params)
      .pipe(
        finalize(() => {
          this.hideLoader();
          this.loading = false;
        })
      )
      .subscribe(
        (res) => {
          this.payments = res.data;
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  setStatus(status: string): string {
    switch (status.toLowerCase()) {
      case PAYMENT_STATUS_PAID.toLowerCase():
        return 'text-success';
      case PAYMENT_STATUS_OPEN.toLowerCase():
        return 'text-warning';
      case PAYMENT_STATUS_CANCELLED.toLowerCase():
      case PAYMENT_STATUS_FAILED.toLowerCase():
      case PAYMENT_STATUS_EXPIRED.toLowerCase():
        return 'text-danger';
      default:
        return '';
    }
  }

  statusChanged(ev: any) {
    this.params = {
      status:
        ev.target.value.toLowerCase() === 'all'
          ? null
          : ev.target.value.toLowerCase(),
    };
    this.getPaymentListing();
  }
}
