import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExtraItemModel } from '../../models/booking.model';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../pages/base.component';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'br-extra-items-modal',
  templateUrl: './extra-items-modal.component.html',
  styleUrls: ['./extra-items-modal.component.scss'],
})
export class ExtraItemsModalComponent extends BaseComponent implements OnInit {
  @Input() id!: number;
  @Input() type!: string;

  locale: string = 'en';
  loading: boolean = false;
  extraItems: Array<ExtraItemModel> = [];
  @Output() passEntry: EventEmitter<ExtraItemModel> = new EventEmitter();

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private bookingService: BookingService,
    private modalService: NgbActiveModal
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.getExtraItems();

    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  getExtraItems() {
    this.loading = true;
    this.bookingService
      .bookingExtraItems(this.id, { type: this.type })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res) => {
        this.extraItems = res?.data;
      });
  }

  addExtraItem(item: ExtraItemModel) {
    this.passEntry.emit(item);
    this.modalService.close();
  }

  setImage(item: ExtraItemModel) {
    return (
      item?.images[0]?.url || '../../../assets/images/categories/item2.png'
    );
  }

  counter(i: number) {
    return new Array(i);
  }

  close() {
    this.modalService.dismiss();
  }
}
