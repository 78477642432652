import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from './../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from './../../../services/auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';

@Component({
  selector: 'br-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent extends BaseComponent implements OnInit {

  form!: FormGroup;

  constructor(
    router: Router,
    authService: AuthService,
    userService: UserService,
    translate: TranslateService,
    private errorService: ErrorHandlerService,
  ) {
    super(router, authService, userService, translate)
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl(null, Validators.compose([
        Validators.required
      ]))
    });
  }

  forget(): void {
    Object.keys(this.form.controls).forEach(key =>
      this.form.get(key)?.markAsTouched()
    );

    if (this.form.invalid) {
      this.errorService.formValidationError();
      return;
    }

    this.authService.forgot(this.form.value).subscribe(res => {
      this.form.reset();
      this.router.navigate(['confirm-email'], { state: { username: this.form.value.username } });
    }, error => {
      this.errorService.handle(error);
    });

  }

}
