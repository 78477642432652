import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';

@Component({
  selector: 'br-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent extends BaseComponent implements OnInit {

  email!: string;
  form!: FormGroup;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private errorService: ErrorHandlerService,
  ) {
    super(router, authService, userService, translate)
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.email = navigation.extras.state.email
    }
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl(null, Validators.compose([
        Validators.email,
        Validators.required
      ])),
      token: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(4),
        Validators.maxLength(4),
      ]))
    });
    this.form.patchValue({
      email: this.email
    })
  }

  verify(): void {
    Object.keys(this.form.controls).forEach(key =>
      this.form.get(key)?.markAsTouched()
    );

    if (this.form.invalid) {
      this.errorService.formValidationError();
      return;
    }

    this.showLoader();
    this.authService.emailVerification(this.form.value).pipe(
      finalize(() => this.hideLoader())
    ).subscribe(res => {
      this.errorService.showToast(res?.message, 'success');
      this.router.navigate(['login']).catch(reason => {
        console.warn(reason)
      });
    }, error => {
      this.errorService.handle(error);
    });

  }

  resetVerificationCode() {
    const email = this.form.value.email;

    if (!email) {
      this.form.get('email')?.markAsTouched()
      return;
    }

    this.showLoader();
    this.authService.resendVerificationCode({ email }).pipe(
      finalize(() => this.hideLoader())
    ).subscribe(res => {
    }, error => {
      this.errorService.handle(error);
    });
  }

}
