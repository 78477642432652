<div class="modal-body p-0 category-listing-content">
  <form [formGroup]="form">
    <div class="row px-lg-5 py-5 border-bottom m-0 mb-5">
      <div class="col-6">
        <label for="date" class="form-label">
          {{ "ChangeBooking.Form.SelectDate" | translate }}
        </label>
        <div class="inputTypeDate">
          <input
            type="date"
            class="form-control"
            id="date"
            formControlName="date"
            (change)="setCategoryListingParams()"
            [min]="minDate"
            max="2030-12-31"
          />
        </div>
      </div>
      <div class="col-6">
        <label for="type" class="form-label">
          {{ "ChangeBooking.Form.SelectActivity" | translate }}
        </label>
        <select
          id="type"
          class="form-select"
          aria-label="Default select example"
          formControlName="type"
          (change)="setCategoryListingParams()"
        >
          <option
            *ngFor="let item of bookingService.categories"
            [value]="item.id"
          >
            {{ getTranslatedData(item.name) }}
          </option>
        </select>
      </div>
    </div>
  </form>

  <div class="row px-lg-5 m-0">
    <!-- item -->
    <div
      class="item-wrapper col-12 p-0 rounded mb-4"
      *ngFor="let item of allData"
    >
      <br-booking-category-item
        [item]="item"
        [code]="code"
        [type]="type"
        [confirmationDate]="form.value.date"
        [orderId]="orderId"
      >
      </br-booking-category-item>
    </div>

    <br-no-record-found
      *ngIf="!allData?.length && !loading"
    ></br-no-record-found>
    <!-- /item -->
  </div>
</div>
