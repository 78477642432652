<!-- title + Grid View -->
<div class="d-flex flex-row align-items-center justify-content-between mb-4">
  <button
    class="btn btn-link text-dark text-decoration-none gap-3 d-flex align-items-center justify-content-start"
    (click)="back()"
  >
    <span class="br-icon-back"></span>
    {{ "Buttons.BackToListing" | translate }}
  </button>
</div>

<!-- Booking tier -->
<div class="booking-with-wrapper rounded" *ngIf="booking">
  <div
    class="d-flex row booking-card br-booking-header align-items-start justify-content-between border-bottom"
  >
    <div class="col-3 p-0 d-flex align-items-center justify-content-start">
      <img
        [src]="
          booking.availability.availabilityable.media[0].original_url ||
          '../../../../assets/images/profile/default.svg'
        "
        class="rounded img-fluid tier-img"
        alt=""
      />

      <div
        class="p-0 d-flex flex-column align-items-start justify-content-center"
      >
        <h3 class="fw-bolder">
          {{
            getTranslatedData(booking?.availability?.availabilityable?.name!)
          }}
        </h3>
        <div class="d-flex align-items-end justify-content-start">
          <span class="badge px-3" [ngClass]="setStatus(booking?.status!)">{{
            booking?.status
          }}</span>
        </div>
      </div>
    </div>

    <div class="col-7 p-0">
      <div class="row gy-1">
        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-black-50 title-gray">{{
              "BookingDetail.BookingNumber" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.code
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-black-50 title-gray">
              {{ "MyBooking.CreatedDate" | translate }}
            </span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.created_at | date
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-black-50 title-gray">
              {{ "PaymentComplete.BookingDate" | translate }}
            </span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.date | date
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-black-50 title-gray">{{
              "BookingDetail.From" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.from || "NA"
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-black-50 title-gray">{{
              "BookingDetail.Until" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.till || "NA"
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-black-50 title-gray">{{
              "BookingDetail.Duration" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              calculateHours(booking)
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-2 col-auto pe-0 d-flex align-items-start justify-content-end"
    >
      <div class="d-flex flex-column align-items-end justify-content-center">
        <span class="text-black-50 title-gray">{{
          "BookingDetail.TotalAmount" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">{{
          booking?.price | currency: "EUR":"symbol":"1.2-2":locale
        }}</span>
      </div>

      <!--      <div class="pt-2">-->
      <!--        <button class="btn btn-link p-0 text-decoration-none">-->
      <!--          <span class="br-icon-info text-black-50 fs-4"></span>-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
  <div class="row">
    <div class="col col-12">
      <!-- Section Body -->
      <div class="section-body">
        <div class="row justify-content-between">
          <div class="col-md-6 pe-4">
            <ul class="ps-5">
              <li>
                <span class="fw-bolder">
                  {{ "BookingDetail.OrderOverview" | translate }}
                </span>
                <span class="fw-bolder">
                  {{ "BookingDetail.Total" | translate }}
                </span>
              </li>

              <li>
                <span class="fw-bold">
                  {{
                    getTranslatedData(
                      booking?.availability?.availabilityable?.name!
                    )
                  }}
                </span>
                <span class="fw-bold"> </span>
              </li>

              <li *ngIf="isFixedPrice">
                <span>{{ "BookingDetail.ReservationPrice" | translate }}</span>
                <span>
                  {{
                    booking?.availability?.price
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </li>

              <li *ngIf="isFixedPrice && isGame">
                <span>{{ "BookingDetail.Seats" | translate }}</span>
                <span>
                  {{ booking?.seats }}
                </span>
              </li>

              <ng-container *ngIf="!isFixedPrice">
                <li>
                  <span>
                    <span class="pe-4">
                      {{ booking?.number_of_adults }}
                      {{ "Ticket.Adults" | translate }} x
                      {{
                        adultPrice | currency: "EUR":"symbol":"1.2-2":locale
                      }}</span
                    >
                  </span>
                  <span>
                    {{
                      booking?.number_of_adults! * adultPrice
                        | currency: "EUR":"symbol":"1.2-2":locale
                    }}
                  </span>
                </li>

                <li>
                  <span>
                    <span class="pe-4">
                      {{ booking?.number_of_children }}
                      {{ "Ticket.Children" | translate }} x
                      {{
                        childPrice | currency: "EUR":"symbol":"1.2-2":locale
                      }}</span
                    >
                  </span>
                  <span>{{
                    booking?.number_of_children! * childPrice!
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}</span>
                </li>
              </ng-container>

              <li *ngIf="isPackage">
                <span>{{ "Ticket.Tickets" | translate }}</span>
                <span>
                  {{ booking?.seats! }} x
                  {{
                    booking?.availability?.price!
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                  =
                  <span class="fw-bolder">
                    {{
                      booking?.seats! * booking?.availability?.price!
                        | currency: "EUR":"symbol":"1.2-2":locale
                    }}
                  </span>
                </span>
              </li>

              <!-- complementary items -->
              <li *ngFor="let item of booking?.complementaryItems">
                <span>
                  <span class="pe-4"> {{ item?.quantity }}x</span>
                  {{ item?.title! }}
                </span>
                <span>
                  {{
                    item?.quantity! * item?.price!
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </li>

              <!-- extra items -->
              <li *ngFor="let item of booking?.extraItems">
                <span>
                  <span class="pe-4"> {{ item?.pivot_quantity }}x</span>
                  {{ getTranslatedData(item?.name!) }}
                </span>
                <span>
                  {{
                    item?.pivot_quantity! * item?.price!
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </li>

              <li>
                <span class="fw-bold">
                  {{ "BookingDetail.SubTotal" | translate }}
                </span>
                <span class="fw-bold">
                  {{
                    reservationSubTotal(booking!, false)
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </li>

              <li>
                <span>{{ "BookingDetail.TaxDetail" | translate }}</span>
                <span class="text-info" (click)="showTaxDetail(booking)">
                  {{ "BookingDetail.View" | translate }}
                </span>
              </li>

              <!-- voucher discount -->
              <li *ngIf="booking?.promotion?.active">
                <span>{{ "BookingDetail.Voucher.Discount" | translate }}:</span>
                <span>
                  {{
                    booking?.promotion?.type! === "FIXED AMOUNT"
                      ? (-1 * booking?.promotion?.amount!
                        | currency: "EUR":"symbol":"1.2-2":locale)
                      : booking?.promotion?.amount + "%"
                  }}
                </span>
              </li>
            </ul>

            <ul class="bg-light ps-5 pt-3 pb-2 mb-2">
              <!-- service fee -->
              <li
                *ngIf="
                  booking?.availability?.availabilityable?.category?.service_fee
                "
              >
                <span>{{ "BookingDetail.ServiceFee" | translate }}:</span>
                <span>
                  {{
                    booking?.availability?.availabilityable?.category
                      ?.service_fee | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </li>

              <li *ngIf="booking?.recurring_discount">
                <span>
                  {{ "BookingDetail.RecurringBookingDiscount" | translate }}
                </span>
                <span>{{
                  -1 * booking?.recurring_discount!
                    | currency: "EUR":"symbol":"1.2-2":locale
                }}</span>
              </li>

              <li *ngIf="booking?.payment">
                <span>{{ "BookingDetail.PaymentMethod" | translate }}:</span>
                <span>{{ booking?.payment?.method }}</span>
              </li>
            </ul>

            <ul class="ps-5">
              <li>
                <span class="fw-bolder">
                  {{ "BookingDetail.TotalDue" | translate }}
                </span>
                <span class="fw-bolder">
                  {{ booking?.price | currency: "EUR":"symbol":"1.2-2":locale }}
                </span>
              </li>
            </ul>
          </div>

          <div class="col-md-6 ps-5 pe-4">
            <ul>
              <li>
                <span class="fw-bolder">
                  {{ "BookingDetail.AllergicItems" | translate }}
                </span>
              </li>
              <li *ngFor="let item of allergicItemsArray">
                <span>{{ item?.name }}</span>
              </li>
            </ul>

            <h6 class="fw-bold fst-italic">
              {{ "BookingDetail.SpecialInstructions" | translate }}
            </h6>

            <p>{{ booking?.note }}</p>

            <div
              class="d-flex gap-2"
              *ngIf="
                !booking?.availability?.availabilityable?.is_booking_monster &&
                !booking?.check_in &&
                booking.status! !== BOOKING_STATUS_EXPIRED &&
                booking.status !== BOOKING_STATUS_CANCELLED
              "
            >
              <button
                type="button"
                class="btn btn-info text-white btn-lg w-100"
                (click)="changeBooking()"
              >
                {{ "Buttons.ChangeBooking" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-danger btn-lg w-100"
                (click)="cancelBooking()"
                *ngIf="isBookingConfirmed"
              >
                {{ "Buttons.CancelBooking" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
