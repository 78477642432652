<!-- Right -->
<div class="Booking-wrapper ps-2">
  <!-- title + Grid View -->
  <div class="d-flex flex-row align-items-center justify-content-between mb-4">
    <h3 class="card-title">{{ "MyOrder.Title" | translate }}</h3>
    <!-- Pagination -->
    <div
      class="col-md-3 col-6 page-selection d-inline-flex flex-row align-items-center justify-content-start"
    >
      <label class="form-label me-2 mb-0 text-gray-600">
        {{ "MyBooking.Filter" | translate }}:
      </label>
      <select
        class="form-select form-select-sm"
        aria-label="Default select example"
        (change)="statusChanged($event)"
      >
        <option *ngFor="let item of statuses" [value]="item.value">
          {{ item.name | translate }}
        </option>
      </select>
    </div>
  </div>

  <!-- Booking tier-->
  <div
    class="row booking-card br-booking-header align-items-center justify-content-between px-3 rounded"
    *ngFor="let order of orders"
    [routerLink]="['/my-account', 'my-booking', order.id]"
  >
    <div class="col-4 p-0 d-flex align-items-center justify-content-star">
      <!--      <img-->
      <!--        [src]="-->
      <!--          userService.user?.data?.avatar?.url ||-->
      <!--          '../../../../assets/images/profile/dp.png'-->
      <!--        "-->
      <!--        class="rounded img-fluid tier-img"-->
      <!--        alt=""-->
      <!--      />-->
      <div
        class="d-flex flex-column align-items-start justify-content-center me-5"
      >
        <h3 class="fw-bolder">
          {{ order.code }}
        </h3>
        <div class="d-flex align-items-end justify-content-start">
          <span class="badge px-3" [ngClass]="setStatus(order?.status!)">
            {{ order?.status | uppercase }}
          </span>
        </div>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-gray-600 title-gray">{{
          "MyBooking.CreatedDate" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">{{
          order?.date | date
        }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-gray-600 title-gray">{{
          "MyBooking.BookingNumber" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">{{ order?.code }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-gray-600 title-gray">{{
          "MyBooking.Source" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">
          {{ order?.source }}
        </span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-end justify-content-center">
        <span class="text-gray-600 title-gray">
          {{ "MyBooking.TotalAmount" | translate }}</span
        >
        <span class="text-black fw-bold detail-gray">{{
          order?.price | currency: "EUR":"symbol":"1.2-2":locale
        }}</span>
      </div>
    </div>

    <div
      class="col col-auto pe-0"
      *ngIf="
        order?.customer_type !== 'dealer' &&
        !order?.payment &&
        order?.status !== 'expired' &&
        order?.status !== 'confirmed' &&
        !order?.paid_by_kiosk
      "
    >
      <div class="d-flex flex-column align-items-end justify-content-center">
        <span class="text-gray-600 title-gray">
          {{ "MyBooking.Payment" | translate }}
        </span>
        <span
          class="text-info fw-bold detail-gray"
          (click)="redirectToMollie(order?.code!, $event)"
        >
          {{ "MyBooking.MakePayment" | translate }}
        </span>
      </div>
    </div>
  </div>

  <br-no-record-found *ngIf="!orders?.length && !loading"></br-no-record-found>
</div>
<!-- /Right -->
