import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'br-forget-pass-confirmation',
  templateUrl: './forget-pass-confirmation.component.html',
  styleUrls: ['./forget-pass-confirmation.component.scss']
})
export class ForgetPassConfirmationComponent implements OnInit {

  username!: string;

  constructor(
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.username = navigation.extras.state.username
    }
  }

  ngOnInit(): void { }

  routeToReset(): void {
    this.router.navigate(['reset-password'], { state: { username: this.username } })
  }

}
