<div class="container auth-wrapper">
  <div class="row m-0">
    <div class="col-md-6 ps-0 pe-md-3 pe-0">
      <div class="feature-img">
        <!-- <img class="img-fluid" src="../../../../assets/images/auth/register.png" alt=""> -->
        <swiper [pagination]="{ clickable: true }" class="loginSlider">
          <ng-template swiperSlide>
            <img
              class="img-fluid"
              src="../../../../assets/images/auth/register.png"
              alt=""
            />
          </ng-template>
          <ng-template swiperSlide>
            <img
              class="img-fluid"
              src="../../../../assets/images/auth/register.png"
              alt=""
            />
          </ng-template>
          <ng-template swiperSlide>
            <img
              class="img-fluid"
              src="../../../../assets/images/auth/register.png"
              alt=""
            />
          </ng-template>
        </swiper>
      </div>
    </div>

    <div class="col-md-6">
      <div class="auth-content">
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="login()">
          <div class="row">
            <div class="col">
              <h4 class="fs-4 fw-bold mb-4 text-center">
                {{ "Login.Title" | translate }}
              </h4>
            </div>
            <div class="col col-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  placeholder="{{ 'Login.Placeholder.Username' | translate }}"
                  formControlName="username"
                />
                <label for="username">{{
                  "Login.Form.Username" | translate
                }}</label>
              </div>
              <br-validation-messages
                [control]="getFormControl(form, 'username')"
                [label]="'Login.Form.Username' | translate"
              ></br-validation-messages>
            </div>

            <div class="col col-12">
              <div class="form-floating mb-3">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  class="form-control"
                  id="password"
                  placeholder="{{ 'Login.Placeholder.Password' | translate }}"
                  formControlName="password"
                />
                <i
                  (click)="togglePassword()"
                  class="bi password-icon"
                  [ngClass]="showPassword ? 'bi-eye-slash-fill' : 'bi-eye-fill'"
                ></i>
                <label for="password">{{
                  "Login.Form.Password" | translate
                }}</label>
              </div>
              <br-validation-messages
                [control]="getFormControl(form, 'password')"
                [label]="'Login.Form.Password' | translate"
              ></br-validation-messages>
            </div>

            <div class="col text-center mt-3">
              <button
                type="submit"
                [disabled]="!form.valid"
                class="btn btn-primary d-inline-block text-light px-5 text-18 w-100 text-uppercase"
              >
                {{ "Buttons.Login" | translate }}
              </button>
            </div>

            <div
              class="col col-12 d-flex align-items-center justify-content-between signup-ForgetHolder"
            >
              <button
                class="btn btn-link text-black-50 text-decoration-none text-14 fw-bold"
                routerLink="/forget-password"
              >
                {{ "Buttons.ForgetPassword" | translate }}
              </button>

              <button
                class="btn btn-link text-black-50 text-decoration-none text-14 fw-bold"
                routerLink="/verify-email"
              >
                {{ "Buttons.VerifyAccount" | translate }}
              </button>

              <button
                class="btn btn-link text-black-50 text-decoration-none text-14 fw-bold"
                routerLink="/register"
              >
                {{ "Buttons.NewUser" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
