<div class="modal-body p-0">
  <div class="row">
    <!-- left -->
    <div class="col-xl-12 col-lg-12 col-12">
      <div class="blue-header-section">
        <!-- Section Header -->
        <div
          class="section-header py-4 px-4 d-flex flex-row align-items-center justify-content-between"
        >
          <h4 class="mb-0">{{ "Tax.Title" | translate }}</h4>
        </div>

        <!-- Section Body -->
        <div class="section-body p-0">
          <!-- header -->
          <ul class="row table-row table-header">
            <li class="col">
              {{ "Tax.Item" | translate }}
            </li>
            <li class="col">
              {{ "Tax.Type" | translate }}
            </li>
            <li class="col">
              {{ "Tax.AmountIncludingTax" | translate }}
            </li>
            <li class="col">
              {{ "Tax.TaxAmount" | translate }}
            </li>
            <li class="col justify-content-end">
              {{ "Tax.AmountExcludingTax" | translate }}
            </li>
          </ul>

          <ng-container *ngFor="let item of reservations">
            <!-- row - Tax on Category -->
            <ul
              class="row table-row"
              *ngFor="
                let ta of item.availability?.availabilityable?.category
                  ?.tax_associations
              "
            >
              <li class="col">
                {{
                  getTranslatedData(
                    item?.availability?.availabilityable?.category?.name!
                  )
                }}
              </li>

              <li class="col">
                {{ ta?.type }}
              </li>

              <li class="col">
                {{ priceWithVMR(item, ta.type, ta?.id!) }}
              </li>

              <li class="col">
                <ng-container *ngIf="ta.type === TAX_VAT; else vmr">
                  {{ ta?.percentage_value }} % =
                  {{
                    taxAmount(
                      calculateAvailabilityPrice(item),
                      ta?.percentage_value!
                    )
                  }}
                </ng-container>
                <ng-template #vmr>
                  {{
                    ta?.amount! *
                      (item?.availability?.availabilityable?.category?.type ===
                      TYPE_PACKAGE
                        ? item?.availability?.availabilityable
                            ?.persons_capacity!
                        : item?.seats!)
                  }}
                </ng-template>
              </li>

              <li class="col justify-content-end">
                <ng-container *ngIf="ta.type === TAX_VAT; else vmrAmount">
                  {{
                    amountWithoutTax(
                      calculateAvailabilityPrice(item),
                      ta?.percentage_value!
                    )
                  }}
                </ng-container>
                <ng-template #vmrAmount>
                  {{ calculateAvailabilityPrice(item) }}
                </ng-template>
              </li>
            </ul>

            <!-- row - Tax on Category Item -->
            <ul
              class="row table-row"
              *ngFor="
                let ta of item?.availability?.availabilityable?.tax_associations
              "
            >
              <li class="col" aria-label="action">
                {{
                  getTranslatedData(item?.availability?.availabilityable?.name!)
                }}
              </li>

              <li class="col" aria-label="Service">
                {{ ta?.type }}
              </li>

              <li class="col">
                {{ calculateAvailabilityPrice(item) }}
              </li>

              <li class="col">
                <ng-container *ngIf="ta.type === TAX_VAT; else vmr">
                  {{ ta?.percentage_value }} % =
                  {{
                    taxAmount(
                      calculateAvailabilityPrice(item),
                      ta?.percentage_value!
                    )
                  }}
                </ng-container>
                <ng-template #vmr>
                  {{ ta?.amount! * item?.seats! }}
                </ng-template>
              </li>

              <li class="col justify-content-end">
                {{
                  amountWithoutTax(
                    calculateAvailabilityPrice(item),
                    ta?.percentage_value!
                  )
                }}
              </li>
            </ul>

            <!-- tax on complementary items -->
            <ng-container *ngFor="let ci of item?.complementaryItems">
              <ul class="row table-row" *ngFor="let ct of ci?.tax_associations">
                <li class="col">
                  {{ getTranslatedData(ci?.title!) }}
                </li>

                <li class="col">
                  {{ ct?.type }}
                </li>

                <li class="col">
                  {{ ci?.price }}
                </li>

                <li class="col">
                  {{ ct?.percentage_value }} % =
                  {{ taxAmount(ci?.price!, ct?.percentage_value!) }}
                </li>

                <li class="col justify-content-end">
                  {{ amountWithoutTax(ci?.price!, ct?.percentage_value!) }}
                </li>
              </ul>
            </ng-container>

            <!-- tax on other items -->
            <ng-container *ngFor="let ei of item?.extraItems">
              <ul class="row table-row" *ngFor="let et of ei?.tax_associations">
                <li class="col">
                  {{ getTranslatedData(ei?.name!) }}
                </li>

                <li class="col">
                  {{ et?.type }}
                </li>

                <li class="col">
                  {{ ei?.price }}
                </li>

                <li class="col">
                  {{ et?.percentage_value }} % =
                  {{ taxAmount(ei?.price!, et?.percentage_value!) }}
                </li>

                <li class="col justify-content-end">
                  {{ amountWithoutTax(ei?.price!, et?.percentage_value!) }}
                </li>
              </ul>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
