<div class="container auth-wrapper">
    <div class="row m-0">
        <div class="col-md-6 ps-0 pe-md-3 pe-0">
            <div class="feature-img">
                <!-- <img class="img-fluid" src="../../../../assets/images/auth/login.png" alt=""> -->
                <swiper [pagination]="{clickable: true}" class="loginSlider">
                    <ng-template swiperSlide>
                        <img class="img-fluid" src="../../../../assets/images/auth/register.png" alt="">
                    </ng-template>
                    <ng-template swiperSlide>
                        <img class="img-fluid" src="../../../../assets/images/auth/register.png" alt="">
                    </ng-template>
                    <ng-template swiperSlide>
                        <img class="img-fluid" src="../../../../assets/images/auth/register.png" alt="">
                    </ng-template>
                </swiper>
            </div>
        </div>

        <div class="col-md-6">
            <div class="auth-content">
                <form [formGroup]="form" (ngSubmit)="register()">
                    <div class="row">
                        <div class="col col-12">
                            <h4 class="fs-4 fw-bold mb-4 text-center">{{ 'DealerSignup.Subtitle' | translate }}</h4>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="fname"
                                    placeholder="{{ 'DealerSignup.Placeholder.Name' | translate }}"
                                    formControlName="full_name">
                                <label for="fname">{{ 'DealerSignup.Form.Name' | translate }}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'full_name')"
                                [label]="'DealerSignup.Form.Name' | translate"></br-validation-messages>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="email"
                                    placeholder="{{ 'DealerSignup.Placeholder.Email' | translate }}"
                                    formControlName="email">
                                <label for="email">{{ 'DealerSignup.Form.Email' | translate }}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'email')"
                                [label]="'DealerSignup.Form.Email' | translate"></br-validation-messages>
                        </div>

                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="phone"
                                    placeholder="{{ 'DealerSignup.Placeholder.Phone' | translate }}"
                                    formControlName="phone">
                                <label for="phone">{{ 'DealerSignup.Form.Phone' | translate }}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'phone')"
                                [label]="'DealerSignup.Form.Phone' | translate">
                            </br-validation-messages>
                        </div>

                        <div class="col-12">
                            <div class="form-floating">
                                <textarea class="form-control"
                                    placeholder="{{ 'DealerSignup.Placeholder.Description' | translate }}" id="desc"
                                    style="height: 100px" formControlName="description"></textarea>
                                <label for="desc">{{ 'DealerSignup.Form.Description' | translate }}</label>
                            </div>
                        </div>

                        <div class="col text-center mt-3">
                            <button class="btn btn-primary d-inline-block text-light px-5 text-18 w-100 text-uppercase">
                                {{ 'Buttons.Submit' | translate }}
                            </button>
                        </div>

                        <div class="col col-12">
                            <p class="text-center py-3 px-4 text-gray-600 text-14">
                                {{ 'DealerSignup.AccountMessage' | translate }}
                                <button
                                    class="btn btn-link text-info text-decoration-underline text-14 p-0 d-inline-block"
                                    routerLink="/forget-password">
                                    {{ 'Buttons.TermsConditions' | translate }}
                                </button> {{ 'DealerSignup.And' | translate }}
                                <button
                                    class="btn btn-link text-info text-decoration-underline text-14 p-0 d-inline-block"
                                    routerLink="/register">
                                    {{ 'Buttons.PrivacyPolicy' | translate }}.
                                </button>
                            </p>
                        </div>

                        <div class="col col-12 d-flex align-items-center justify-content-between pt-5 ">
                            <button class="btn btn-link text-black-50 text-decoration-none text-14 fw-bold p-0"
                                routerLink="/login">
                                {{ 'Buttons.AlreadyHaveAccount' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>