import { NgModule } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { UserService } from './user/user.service';
import { BookingService } from './booking/booking.service';
import { TokenStoreService } from './token/token-store.service';
import { ErrorHandlerService } from './error/error-handler.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RequestInterceptorService } from './request-interceptor/request-interceptor.service';
import { ScriptService } from './script/ScriptService';


@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        AuthService,
        TokenStoreService,
        ErrorHandlerService,
        BookingService,
        UserService,
        ScriptService,
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true }
    ],
    exports: [
        HttpClientModule
    ]
})
export class ServicesModule {
}
