import { Config } from 'src/app/config';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Injectable, Injector } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable, of, throwError, timer } from 'rxjs';
import { brAccessToken } from './../../utils/general-constants';
import { TokenStoreService } from '../token/token-store.service';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

  private locale: string | undefined;
  private translate!: TranslateService;

  constructor(
    private authService: AuthService,
    private injector: Injector,
    private storage: LocalStorage,
    private router: Router,
    private tokenStoreService: TokenStoreService
  ) {
    setTimeout(() => {
      this.translate = injector.get(TranslateService);
      this.locale = this.translate.currentLang;
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.locale = event.lang;
      });
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf(Config.baseUrl) !== 0) {
      return next.handle(req);
    }

    if (this.locale && !req.headers.has('X-Locale')) {
      req = req.clone({
        headers: req.headers.set('X-Locale', this.locale)
      });
    }

    let observable!: Observable<string | null> | undefined;
    if (req.url.indexOf(`${Config.baseUrl}/auth`) !== 0 || req.url.includes('auth/me') || req.url.includes('auth/logout') || req.url.includes('auth/change-password')) {
      observable = this.tokenStoreService.token?.pipe(
        map(token => token && token.access_token)
      );
    } else {
      observable = of(null);
    }

    return observable!.pipe(
      switchMap((token: string | null) => this.sendRequest(req, next, token))
    );
  }

  private sendRequest(req: HttpRequest<any>, next: HttpHandler, token: string | null = null, count: number = 0): Observable<HttpEvent<any>> {
    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status || 0) {
            // Connection error, delay by tries * 500ms and try again 5 times
            case 0:
            case -1:
              if (count < 5) {
                return timer(count * 500).pipe(
                  first(),
                  switchMap(() => this.sendRequest(req, next, token, count + 1))
                );
              }
              break;
            /* Authentication error, if this is the first try,
            there is a token and this is not an auth request, refresh and try again*/
            case 401:
              this.logut401();
              break;
          }
        }
        // Forward all remaining errors
        return throwError(error);
      })
    );
  }

  private logut401() {
    this.storage.getItem(brAccessToken).subscribe((token) => {
      this.authService.logout();
      this.tokenStoreService.updateToken(null);
      this.router.navigate(['login'], { replaceUrl: true });
    });
  }

}
