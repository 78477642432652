import { dealer, user } from '../../utils/general-constants';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';

@Component({
  selector: 'br-cancel-booking-modal',
  templateUrl: './cancel-booking-modal.component.html',
  styleUrls: ['./cancel-booking-modal.component.scss'],
})
export class CancelBookingModalComponent implements OnInit {
  @Input() code!: string;
  message!: string;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private bookingService: BookingService,
    private userService: UserService,
    private errorService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.bookingService
      .cancelReservationCharges({ code: this.code })
      .subscribe({
        next: (res) => {
          this.message = res.message;
        },
        error: (error: unknown) => {
          this.errorService.handle(error);
        },
      });
  }

  close() {
    this.modalService.dismissAll();
  }

  cancelBooking() {
    this.userService.getUpdatedUser().subscribe((res) => {
      if (res?.data?.role === user || res.data.role === 'staff_user') {
        this.cancelUserBooking();
      } else if (res?.data?.role === dealer) {
        this.cancelDealerBooking();
      }
    });
  }

  cancelUserBooking() {
    this.bookingService.cancelUserReservation({ code: this.code }).subscribe(
      (res) => {
        this.modalService.dismissAll();
        this.router.navigate(['my-account', 'my-booking']);
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }

  cancelDealerBooking() {
    this.bookingService.cancelDealerReservation({ code: this.code }).subscribe(
      (res) => {
        this.modalService.dismissAll();
        this.router.navigate(['my-account', 'my-booking']);
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }
}
