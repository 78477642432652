import { Config } from 'src/app/config';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends HttpService {
  constructor(http: HttpClient) {
    super(http);
  }

  molliePayment(body: { code: string }) {
    return this.http.post<{ data: string }>(
      `${Config.apiUrl}/mollie/get-payment`,
      body
    );
  }

  molliePaymentStatus(paymentId: number) {
    return this.http.get<{ message: string, order_code: string, status: string }>(
      `${Config.apiUrl}/mollie/get-payment-status/${paymentId}`
    );
  }

  generateReceipt(code: string) {
    return this.http.get<{ Url: string }>(
      `${Config.apiUrl}/vizma/generate_invoice/${code}`
    );
  }
}
