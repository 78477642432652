import {
  debounceTime,
  finalize,
  takeUntil,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BookingModel } from '../../../models/booking.model';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ErrorHandlerService } from '../../../services/error/error-handler.service';
import { ConfirmationModalComponent } from '../../../components/confirmation-modal/confirmation-modal.component';
import { CancelBookingModalComponent } from '../../../components/cancel-booking-modal/cancel-booking-modal.component';
import {
  BOOKING_STATUS_HOLD,
  BOOKING_STATUS_CONFIRMED,
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_EXPIRED,
  game,
  packages,
} from 'src/app/utils/general-constants';
import { TaxDetailModalComponent } from '../../../components/tax-detail-modal/tax-detail-modal.component';

@Component({
  selector: 'br-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss'],
})
export class BookingDetailComponent extends BaseComponent implements OnInit {
  type!: string;
  childPrice!: number;
  adultPrice!: number;
  bookingCode!: string;
  locale: string = 'en';
  booking!: BookingModel;
  allergicItemsArray: Array<any> = [];
  readonly BOOKING_STATUS_EXPIRED = BOOKING_STATUS_EXPIRED;
  readonly BOOKING_STATUS_CANCELLED = BOOKING_STATUS_CANCELLED;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    private location: Location,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private bookingService: BookingService,
    protected translate: TranslateService,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.bookingCode = this.route.snapshot.params.id;
    this.getReservationDetail();

    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  get isBookingConfirmed() {
    return this.booking?.status === 'CONFIRMED';
  }

  get isGame(): boolean {
    return this.type === game;
  }

  get isPackage(): boolean {
    return this.type === packages;
  }

  get isFixedPrice(): boolean {
    return this.booking.availability.fix_price == true;
  }

  back(): void {
    this.location.back();
  }

  getReservationDetail() {
    this.showLoader();
    this.bookingService
      .reservationDetail({ code: this.bookingCode })
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.booking = res?.data;
          this.type = this.booking.availability.availabilityable.category.type!;

          // check for fix || adult - child prices
          ({ ap: this.adultPrice, cp: this.childPrice } =
            this.getAdultAndChildPrice(this.booking?.availability));

          this.booking.complementaryItems.forEach((ci) => {
            // map the allergic items to local array
            ci.allergic_items.map((ai) => this.allergicItemsArray.push(ai));
          });
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  cancelBooking() {
    if (this.booking.availability?.availabilityable?.is_booking_monster) {
      this.errorService.showErrorMessage(
        'Booking Monster item can not be cancelled'
      );
      return;
    }
    const modalRef = this.modalService.open(CancelBookingModalComponent, {
      centered: true,
    });
    // pass data to allergic item modal
    modalRef.componentInstance.code = this.bookingCode;
  }

  changeBooking() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
    });
    // pass data to allergic item modal
    modalRef.componentInstance.type = 'edit-booking';
    modalRef.componentInstance.code = this.bookingCode;
    modalRef.componentInstance.message = this.translate.instant(
      marker('BookingDetail.ChangeConfirmation')
    );
  }

  setStatus(status: string): string {
    switch (status) {
      case BOOKING_STATUS_HOLD:
        return 'bg-warning';
      case BOOKING_STATUS_CONFIRMED:
        return 'bg-success';
      case BOOKING_STATUS_CANCELLED:
      case BOOKING_STATUS_EXPIRED:
        return 'bg-danger';
      default:
        return '';
    }
  }

  showTaxDetail(booking: BookingModel) {
    const modalRef = this.modalService.open(TaxDetailModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.reservations = [booking];
  }
}
