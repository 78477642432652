import { Config } from '../config';
import { HttpClient, HttpParams } from '@angular/common/http';

export abstract class HttpService {
    protected constructor(protected readonly http: HttpClient) {
    }

    protected buildUrl(...parts: Array<string | number>): string {
        return `${Config.baseUrl}/${parts.map(item => `${item}`).join('/')}`;
    }

    protected serialize(params: any, response?: HttpParams | undefined, key?: string | undefined): HttpParams {
        response = response || new HttpParams();

        if (typeof params === 'undefined' || (!key && params === null)) {
            return response;
        }

        if ((Array.isArray(params) || typeof params !== 'object') && !key) {
            throw new Error('Params must be an object');
        }

        if (params === null) {
            return response.append(key as string, '');
        }

        if (typeof params === 'boolean') {
            return response.append(key as string, params ? '1' : '0');
        }

        if (params instanceof Date) {
            return response.append(key as string, params.toISOString());
        }

        if (Array.isArray(params)) {
            return params.reduce((result, item) => this.serialize(item, result, `${key}[]`), response);
        }

        if (typeof params === 'object') {
            return Object.keys(params).reduce((result, child) => {
                if (key) {
                    return this.serialize(params[child], result, `${key}[${child}]`);
                }

                return this.serialize(params[child], result, child);
            }, response);
        }

        return response.append(key as string, `${params}`);
    }
}
