<!-- Right -->
<div class="Profile-wrapper ps-2">
  <!-- title + Grid View -->
  <div class="d-flex flex-row align-items-center justify-content-between mb-4">
    <h3 class="card-title">{{ "MyProfile.Title" | translate }}</h3>
    <button
      class="btn btn-lg btn-success py-2 px-5 text-light text-14"
      (click)="submit()"
      [disabled]="!form.valid || !form.dirty"
    >
      {{ "Buttons.Update" | translate }}
    </button>
  </div>

  <div class="row">
    <div class="col-md-4 mb-5 mb-md-0">
      <div class="avatar-holder">
        <div class="avatar-edit">
          <input
            type="file"
            id="imageUpload"
            accept=".png, .jpg, .jpeg"
            #fileInput
            (change)="uploadFile($event)"
          />
        </div>
        <div class="avatar-preview" *ngIf="imageUrl">
          <img [src]="imageUrl" class="img-fluid rounded" alt="" />
        </div>
        <p *ngIf="fileSizeError">
          {{ "MyProfile.ImageSizeError" | translate }}
        </p>
      </div>
      <div
        class="d-flex flex-row align-items-center justify-content-center py-2"
      >
        <button
          class="btn btn-link text-info text-decoration-none p-0"
          (click)="fileInput.click()"
        >
          {{ "Buttons.Change" | translate }}
        </button>
        <span class="text-black-50 px-2">|</span>
        <button
          class="btn btn-link text-info text-decoration-none p-0"
          (click)="removeUploadedFile()"
        >
          {{ "Buttons.Remove" | translate }}
        </button>
      </div>
    </div>

    <div class="col-md-8">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="fname"
                placeholder="{{ 'MyProfile.Placeholder.Name' | translate }}"
                formControlName="name"
              />
              <label for="fname">{{ "MyProfile.Form.Name" | translate }}</label>
            </div>
            <br-validation-messages
              [control]="getFormControl(form, 'name')"
              [label]="'MyProfile.Form.Name' | translate"
            ></br-validation-messages>
          </div>

          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="{{
                  'MyProfile.Placeholder.EmailAddress' | translate
                }}"
                formControlName="email"
                readonly
              />
              <label for="email">{{
                "MyProfile.Form.Email" | translate
              }}</label>
            </div>
            <br-validation-messages
              [control]="getFormControl(form, 'email')"
              [label]="'MyProfile.Form.Email' | translate"
            ></br-validation-messages>
          </div>

          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <!--              <select [ngModel]="selectedCountry?.phoneCode" [ngModelOptions]="{ standalone: true }"-->
              <!--                (change)="phoneChanged($event)" class="form-select">-->
              <!--                <option *ngFor="let item of countries" [value]="item?.phoneCode">-->
              <!--                  {{ item?.flag }} {{ item?.phoneCode }}-->
              <!--                </option>-->
              <!--              </select>-->
              <ng-select
                [ngModel]="selectedCountry?.phoneCode"
                [ngModelOptions]="{ standalone: true }"
                (change)="phoneChanged($event)"
                [clearable]="false"
                class="form-select custom-select"
              >
                <ng-option
                  *ngFor="let item of countries"
                  [value]="item?.phoneCode"
                  >{{ item?.flag }} {{ item?.phoneCode }} -
                  {{ item?.country }}</ng-option
                >
              </ng-select>
              <label for="tel">{{ "MyProfile.Form.Code" | translate }}</label>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <input
                type="tel"
                class="form-control"
                id="tel"
                placeholder="{{
                  'MyProfile.Placeholder.PhoneNumber' | translate
                }}"
                formControlName="phone"
              />
              <label for="tel">{{ "MyProfile.PhoneNumber" | translate }}</label>
            </div>
            <br-validation-messages
              [control]="getFormControl(form, 'phone')"
              [label]="'MyProfile.PhoneNumber' | translate"
            >
            </br-validation-messages>
          </div>

          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <input
                ngx-google-places-autocomplete
                #refLoc
                #placesRef="ngx-places"
                (onAddressChange)="handleAddressChange($event)"
                id="address"
                class="google-places form-control"
                formControlName="address"
              />
              <label for="address">
                {{ "MyProfile.Form.Address" | translate }}
              </label>
            </div>
            <br-validation-messages
              [control]="getFormControl(form, 'address')"
              [label]="'MyProfile.Form.Address' | translate"
            ></br-validation-messages>
          </div>

          <div class="col-sm-6">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="pcode"
                placeholder="{{
                  'MyProfile.Placeholder.EnterCode' | translate
                }}"
                formControlName="zip_code"
              />
              <label for="pcode">{{ "MyProfile.ZipCode" | translate }}</label>
            </div>
            <br-validation-messages
              [control]="getFormControl(form, 'zip_code')"
              [label]="'MyProfile.ZipCode' | translate"
            ></br-validation-messages>
          </div>

          <div class="col-sm-6" *ngIf="userDetail?.data?.role === 'dealer'">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="commission"
                placeholder="{{
                  'MyProfile.Placeholder.Commission' | translate
                }}"
                formControlName="commission"
                [readonly]="true"
              />
              <label for="pcode">{{
                "MyProfile.Commission" | translate
              }}</label>
            </div>
          </div>

          <div class="col-sm-6">
            <button
              type="button"
              class="btn btn-link text-info text-decoration-underline px-0"
              (click)="openModal()"
            >
              {{ "Buttons.ChangePassword" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- /Right -->
