import * as moment from 'moment';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthService } from '../../../services/auth/auth.service';
import { UserService } from '../../../services/user/user.service';
import {
  boat,
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_EXPIRED,
  cruise,
  DEFAULT_DATE_FORMAT,
  game,
  packages,
} from '../../../utils/general-constants';
import { BookingService } from '../../../services/booking/booking.service';
import { ErrorHandlerService } from '../../../services/error/error-handler.service';
import {
  BikeModel,
  BoatModel,
  ComplementaryAllergicItem,
  ExtraItemModel,
  GameModel,
  PackageModel,
} from '../../../models/booking.model';
import { ExtraItemsModalComponent } from 'src/app/components/extra-items-modal/extra-items-modal.component';
import { AllergicItemModalComponent } from 'src/app/components/allergic-item-modal/allergic-item-modal.component';
import { ChangeBookingModalComponent } from '../../../components/change-booking-modal/change-booking-modal.component';
import { BookingSuccessModalComponent } from 'src/app/components/booking-success-modal/booking-success-modal.component';
import { ComplementaryItemsModalComponent } from 'src/app/components/complementary-items-modal/complementary-items-modal.component';
import {
  BookingModel,
  BookingReservationModel,
  ComplementaryItemModel,
} from 'src/app/models/booking.model';

@Component({
  selector: 'br-edit-booking-details',
  templateUrl: './edit-booking-details.component.html',
  styleUrls: ['./edit-booking-details.component.scss'],
})
export class EditBookingDetailsComponent
  extends BaseComponent
  implements OnInit
{
  code!: string;
  type!: string;
  locale: string = 'en';
  booking!: BookingModel;
  adultOrChildren: string = 'adult';
  childPrice!: number;
  adultPrice!: number;

  note: string | undefined;
  selectedSeats: number = 1;
  selectedNoOfChild: number = 0;
  selectedNoOfAdult: number = 0;
  seatOptions: Array<number> = [];
  adultOptions: Array<number> = [];
  childOptions: Array<number> = [];
  allergicItems: Array<ComplementaryAllergicItem> = [];

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private location: Location,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.code = this.route.snapshot.params.code;
    this.getReservationDetail();

    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        startWith(this.translate.currentLang || this.translate.defaultLang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  get canDealerEdit(): boolean {
    return this.user?.data?.role === 'dealer' && !this.booking?.check_in!;
  }

  get isBookingConfirmed() {
    return this.booking?.status === 'CONFIRMED';
  }

  get itemImage(): string {
    return this.booking?.user?.avatar?.url || 'assets/images/profile/dp.png';
  }

  get seatingCapacity(): number {
    return (
      this.booking?.availability?.availabilityable?.seating_capacity ||
      this.booking?.availability?.availabilityable?.persons_capacity
    );
  }

  get isGame(): boolean {
    return this.type == game;
  }

  get isPackage(): boolean {
    return this.type === packages;
  }

  get isBoat(): boolean {
    return this.type === boat;
  }

  get isCruise(): boolean {
    return (
      this.isBoat &&
      this.booking?.availability?.availabilityable?.category?.sub_type?.includes(
        cruise
      )
    );
  }

  get isFixedPrice(): boolean {
    return !!this.booking.availability?.fix_price;
  }

  getReservationDetail() {
    this.showLoader();
    this.bookingService
      .reservationDetail({ code: this.code })
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.booking = res?.data;
          this.note = res?.data?.note;
          this.selectedNoOfAdult = res?.data?.number_of_adults;
          this.selectedNoOfChild = res?.data?.number_of_children;
          this.selectedSeats = res?.data?.seats
            ? res?.data?.seats
            : this.selectedNoOfChild + this.selectedNoOfAdult;
          this.type = res?.data?.availability?.availabilityable?.category?.type;

          ({ ap: this.adultPrice, cp: this.childPrice } =
            this.getAdultAndChildPrice(res?.data?.availability));

          this.updateCapacities();

          this.adultOrChildren =
            this.seatingCapacity == 1 && this.booking?.number_of_adults == 1
              ? 'adult'
              : 'children';
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  addComplementaryItem(item: ComplementaryItemModel): void {
    // to check in case of item which need full day to prepare
    if (item?.food_item && item?.full_day_required) {
      const end = moment(this.booking.date!, DEFAULT_DATE_FORMAT);
      const start = moment(new Date()).format(DEFAULT_DATE_FORMAT);
      const duration = moment.duration(end.diff(start));
      const hours = duration.asHours();
      if (hours < 24) {
        this.errorService.showErrorMessage(
          marker('Complementaries.FullDayRequired')
        );
        return;
      }
    }

    // to check if item is available in timeslot time
    if (item?.items_availability_associations?.length >= 1) {
      item?.items_availability_associations?.forEach((ia) => {
        let timeSegments = [];
        timeSegments.push([
          this.booking?.availability?.from,
          this.booking?.availability?.till,
        ]);
        timeSegments.push([ia?.from, ia?.to]);
        ia.overlap = this.checkAvailabilityOverlap(timeSegments);
      });

      let checker = item?.items_availability_associations?.every(
        (v) => v.overlap === false
      );
      if (checker) {
        this.errorService.showErrorMessage(
          marker('Complementaries.NotAvailable')
        );
        return;
      }
    }

    const index = this.booking.complementaryItems.findIndex(
      (ci) => ci.id === item.id
    );

    if (index !== -1) {
      this.booking.complementaryItems[index].quantity = item?.quantity;
    } else {
      this.booking.complementaryItems.push(item);
    }
  }

  removeComplementaryItem(item: ComplementaryItemModel) {
    this.booking.complementaryItems = this.booking.complementaryItems.filter(
      (ci) => ci.id !== item.id
    );
  }

  back(): void {
    this.location.back();
  }

  showComplementaryItemModal() {
    const modalRef = this.modalService.open(ComplementaryItemsModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.id =
      this.booking.availability.availabilityable.id;
    modalRef.componentInstance.type = this.type;

    // add the new item to the previous complementary items array
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: ComplementaryItemModel) => {
        if (receivedEntry) {
          this.addComplementaryItem(receivedEntry);
        }
      }
    );
  }

  // open extra items modal
  addExtraItems() {
    const modalRef = this.modalService.open(ExtraItemsModalComponent, {
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.id =
      this.booking.availability.availabilityable.id;
    modalRef.componentInstance.type = this.type;

    // add the new item to the previous complementary items array
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: ExtraItemModel) => {
        if (receivedEntry) {
          const index = this.booking.extraItems.findIndex(
            (ei) => ei.id === receivedEntry.id
          );
          if (index !== -1) {
            this.booking.extraItems[index].pivot_quantity =
              receivedEntry.pivot_quantity;
          } else {
            this.booking.extraItems.push(receivedEntry);
          }
        }
      }
    );
  }

  removeExtraItem(ei: ExtraItemModel) {
    this.booking.extraItems = this.booking.extraItems.filter(
      (item) => item.id !== ei.id
    );
  }

  showChangeBookingModal() {
    const modalRef = this.modalService.open(ChangeBookingModalComponent, {
      size: 'xl',
      centered: true,
    });
    modalRef.componentInstance.code = this.code;
    modalRef.componentInstance.orderId = this.booking?.order_id;
  }

  showAllergicItemDialog(ci: ComplementaryItemModel, item: BookingModel) {
    const modalRef = this.modalService.open(AllergicItemModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.item = ci;
    modalRef.componentInstance.complementaryAllergicItems =
      ci.allergic_items || [];
    modalRef.componentInstance.totalPerson =
      item?.availability?.availabilityable?.seating_capacity;

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.allergicItems.push(receivedEntry);
    });
  }

  updateBooking() {
    if (
      this.selectedNoOfChild + this.selectedNoOfAdult >
      this.seatingCapacity
    ) {
      this.errorService.showErrorMessage(
        marker('CategoryItem.SeatsUnavailable')
      );
      return;
    }

    if (this.seatingCapacity == 1) {
      this.selectedNoOfAdult = this.adultOrChildren == 'adult' ? 1 : 0;
      this.selectedNoOfChild = this.adultOrChildren == 'children' ? 1 : 0;
    }

    let finalResult: any = {};
    for (let i = 0; i < this.booking?.complementaryItems?.length!; i++) {
      const complementaryItems = {
        [this.booking?.complementaryItems![i]?.id]: {
          quantity: this.booking.complementaryItems![i]?.quantity,
          allergic_items: this.booking.complementaryItems[i].allergic_items
            ?.length
            ? this.booking.complementaryItems[i].allergic_items.map(
                (ai) => ai.id
              )
            : this.allergicItems?.length
            ? this.allergicItems.find(
                (ai) => ai.id == this.booking.complementaryItems[i].id
              )!?.allergic_items
            : [],
        },
      };
      finalResult = Object.assign(complementaryItems, finalResult);
    }

    // calculate the number of allergic persons
    let number_of_allergic_person = 0;
    for (const finalResultKey in finalResult) {
      number_of_allergic_person =
        number_of_allergic_person + finalResult[finalResultKey].quantity;
    }

    if (this.isGame) {
      this.processGameBooking(finalResult, number_of_allergic_person);
      return;
    } else {
      console.log('seats check');
      if (this.selectedNoOfAdult == 0 && this.selectedNoOfChild == 0) {
        this.errorService.showErrorMessage(marker('CategoryItem.SelectTicket'));
        return;
      }

      const totalPersons = this.selectedNoOfAdult + this.selectedNoOfChild;
      if (this.isCruise) {
        if (this.selectedSeats !== totalPersons) {
          this.errorService.showErrorMessage(
            marker('CategoryItem.IncorrectSeatCount')
          );
          return;
        }
      } else if (
        this.booking?.availability?.availabilityable?.seating_capacity <
        totalPersons
      ) {
        this.errorService.showErrorMessage(
          marker('CategoryItem.SeatsUnavailable')
        );
        return;
      }
    }

    // set payload for extra items
    let extraItems = {};
    for (let i = 0; i < this.booking.extraItems?.length!; i++) {
      const extraItem = {
        [this.booking?.extraItems![i]?.id]: {
          quantity: this.booking?.extraItems![i]?.pivot_quantity,
        },
      };
      extraItems = Object.assign(extraItem, extraItems);
    }

    this.booking.number_of_adults = this.selectedNoOfAdult;
    this.booking.number_of_children = this.selectedNoOfChild;

    const tickets =
      this.isCruise || this.isGame
        ? this.selectedSeats
        : this.selectedNoOfAdult + this.selectedNoOfChild;

    this.showLoader();

    // prepare the update reservation data
    const data: BookingReservationModel = {
      availability_id: this.booking.availability_id,
      date: moment(new Date(this.booking?.date!)).format(DEFAULT_DATE_FORMAT),
      item_id: this.booking?.availability?.availabilityable?.id,
      number_of_adults: this.selectedNoOfAdult,
      number_of_children: this.selectedNoOfChild,
      number_of_allergic_person: number_of_allergic_person,
      note: this.note,
      complementary_items: finalResult,
      extra_items: extraItems,
      code: this.booking.code,
      source: 'online',
      seats: tickets,
      voucher_code: this.booking?.promotion?.promo_code!,
    };

    this.bookingService
      .updateBooking(this.clean(data))
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          // redirect to reservation detail
          const modalRef = this.modalService.open(
            BookingSuccessModalComponent,
            {
              centered: true,
            }
          );
          modalRef.componentInstance.code = this.code;
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  processGameBooking(
    finalResult: Array<ComplementaryItemModel>,
    number_of_allergic_person: number
  ) {
    if (this.selectedNoOfAdult == 0 && this.selectedNoOfChild == 0) {
      this.errorService.showErrorMessage(marker('CategoryItem.SelectTicket'));
      return;
    }

    if (this.selectedNoOfAdult + this.selectedNoOfChild < this.selectedSeats) {
      this.errorService.showErrorMessage(marker('CategoryItem.PAXError'));
      return;
    }

    if (this.selectedSeats < this.selectedNoOfAdult + this.selectedNoOfChild) {
      this.errorService.showErrorMessage(
        marker('CategoryItem.SeatsUnavailable')
      );
      return;
    }

    this.showLoader();

    const data = {
      type: game,
      availability_id: this.booking.availability_id,
      date: moment(new Date(this.booking?.date!)).format(DEFAULT_DATE_FORMAT),
      number_of_allergic_person: number_of_allergic_person,
      note: this.note,
      complementary_items: finalResult,
      source: 'online',
      seats: this.selectedSeats,
      voucher_code: this.booking?.promotion?.promo_code!,
      number_of_adults: this.selectedNoOfAdult,
      number_of_children: this.selectedNoOfChild,
    };

    this.bookingService
      .updateGameBooking(this.booking.code, data)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          const modalRef = this.modalService.open(
            BookingSuccessModalComponent,
            { centered: true }
          );
          modalRef.componentInstance.code = this.code;
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  updateCapacities() {
    console.log(this.booking);
    let maxSeats =
      this.isGame || this.isPackage
        ? (this.booking?.availability?.availabilityable).persons_capacity
        : (
            this.booking?.availability?.availabilityable as
              | BoatModel
              | BikeModel
          ).seating_capacity;
    
    if (
      (this.isCruise || this.isGame) &&
      this.booking?.availability?.reservations?.length
    ) {
      maxSeats -= this.booking?.availability?.reservations.reduce(
        (acc, curr) => {
          if (
            moment(curr.date).isSame(
              moment(this.booking.date).format(DEFAULT_DATE_FORMAT)
            ) &&
            curr.id !== this.booking.id &&
            curr.status !== BOOKING_STATUS_EXPIRED &&
            curr.status !== BOOKING_STATUS_CANCELLED
          ) {
            return acc + curr.seats;
          } else {
            return acc;
          }
        },
        0
      );
    }
    console.log(maxSeats);

    this.seatOptions = new Array(maxSeats).fill(0).map((_, idx) => idx + 1);

    if (this.selectedSeats > maxSeats) {
      this.selectedSeats = maxSeats;
    }

    const overflow =
      this.selectedNoOfAdult +
      this.selectedNoOfChild -
      (this.isCruise || this.isGame ? this.selectedSeats : maxSeats);
    if (overflow > 0) {
      if (this.selectedNoOfAdult > 0) {
        if (this.selectedNoOfChild > 0) {
          this.selectedNoOfAdult = Math.max(
            0,
            this.selectedNoOfAdult - Math.floor(overflow / 2)
          );
          this.selectedNoOfChild = Math.max(
            0,
            this.selectedNoOfChild - Math.ceil(overflow / 2)
          );
        } else {
          this.selectedNoOfAdult = Math.max(
            0,
            this.selectedNoOfAdult - overflow
          );
        }
      } else if (this.selectedNoOfChild > 0) {
        this.selectedNoOfChild = Math.max(0, this.selectedNoOfChild - overflow);
      }
    }

    const maxAdult =
      (this.isCruise || this.isGame ? this.selectedSeats : maxSeats) -
      this.selectedNoOfChild;
    const maxChild =
      (this.isCruise || this.isGame ? this.selectedSeats : maxSeats) -
      this.selectedNoOfAdult;

    this.adultOptions = new Array(maxAdult).fill(0).map((_, idx) => idx + 1);
    this.childOptions = new Array(maxChild).fill(0).map((_, idx) => idx + 1);
  }
}
