import { forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { PageModel } from 'src/app/models/user.model';

@Component({
  selector: 'br-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  pages: Array<PageModel> = [];
  usefullLinks: Array<PageModel> = [];

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    forkJoin([
      this.userService.getPagesList({ type: 'pages' }),
      this.userService.getPagesList({ type: 'usefull_links' }),
    ]).subscribe((results) => {
      this.pages = results[0]?.data;
      this.usefullLinks = results[1]?.data;
    });
  }
}
