<div class="container auth-wrapper">
    <div class="row m-0">
        <div class="col-md-6 ps-0 pe-md-3 pe-0">
            <div class="feature-img">
                <img class="img-fluid" src="../../../../assets/images/auth/forget.png" alt="">
            </div>
        </div>

        <div class="col-md-6">
            <div class="auth-content">
                <form autocomplete="off" [formGroup]="form" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col">
                            <h4 class="fs-4 fw-bold mb-5 text-center">{{'Reset.Title' | translate}}</h4>
                        </div>

                        <div class="col col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="username"
                                    placeholder="{{ 'Reset.Placeholder.Username' | translate }}"
                                    formControlName="username">
                                <label for="username">{{'Reset.Form.Username' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'username')"
                                [label]="'Reset.Form.Username' | translate"></br-validation-messages>
                        </div>

                        <div class="col col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="token"
                                    placeholder="{{ 'Reset.Placeholder.Token' | translate }}"
                                    formControlName="reset_password_token">
                                <label for="token">{{'Reset.Form.Token' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'token')"
                                [label]="'Reset.Form.Token' | translate"></br-validation-messages>
                        </div>

                        <div class="col col-12">
                            <div class="form-floating mb-3">
                                <input type="password" class="form-control" id="password"
                                    placeholder="{{ 'Reset.Placeholder.Password' | translate }}"
                                    formControlName="password">
                                <label for="password">{{'Reset.Form.NewPassword' | translate}}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'password')"
                                [label]="'Reset.Form.NewPassword' | translate"></br-validation-messages>
                        </div>

                        <div class="col col-12">
                            <div class="form-floating mb-3">
                                <input type="password" class="form-control" id="pconfirmation"
                                    placeholder="{{ 'Reset.Placeholder.ConfirmPassword' | translate }}"
                                    formControlName="password_confirmation">
                                <label for="pconfirmation">{{'Reset.Form.ConfirmPassword' | translate}}</label>
                                <br-validation-messages [control]="getFormControl(form, 'password_confirmation')"
                                    [label]="'Reset.Form.ConfirmPassword' | translate"
                                    [relatedControls]="'Reset.Form.NewPassword' | translate"></br-validation-messages>
                            </div>
                        </div>

                        <div class="col text-center mt-3">
                            <button class="btn btn-primary d-inline-block text-light px-5 text-18 w-100 text-uppercase">
                                {{'Buttons.Save' | translate}}
                            </button>
                        </div>

                        <div class="col col-12  pt-5 mt-3">
                            <p class="text-gray-600 px-5 text-center mb-4">
                                {{ 'Reset.PasswordLengthMessage' | translate }}
                            </p>
                            <p class="text-gray-600 px-5 text-center">
                                {{ 'Reset.NewPasswordMessage' | translate }}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>