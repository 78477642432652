import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingModel, OrderDetail } from 'src/app/models/booking.model';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from '../../../services/auth/auth.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import {
  BOOKING_STATUS_HOLD,
  BOOKING_STATUS_CONFIRMED,
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_EXPIRED,
  BookingStatuses,
  packages,
} from 'src/app/utils/general-constants';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'br-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss'],
})
export class MyBookingsComponent extends BaseComponent implements OnInit {
  params: { status: string | null } = {
    status: null,
  };
  orderId!: number;
  locale: string = 'en';
  loading: boolean = true;
  statuses = BookingStatuses;
  bookings!: Array<BookingModel>;
  orderDetail!: OrderDetail;

  constructor(
    protected router: Router,
    protected userService: UserService,
    protected authService: AuthService,
    protected translate: TranslateService,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService,
    private route: ActivatedRoute
  ) {
    super(router, authService, userService, translate);
    this.orderId = route.snapshot.params.id;
    this.getListing();
  }

  ngOnInit(): void {
    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  getListing() {
    this.loading = true;
    this.showLoader();
    this.params = this.clean(this.params);
    this.bookingService
      .bookingListing(this.orderId, this.params)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.hideLoader();
        })
      )
      .subscribe(
        ({ data: { reservations, orderDetails } }) => {
          this.bookings = reservations;
          this.orderDetail = {
            ...orderDetails,
            subtotal: orderDetails?.price + orderDetails?.total_discounts,
          };
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  setStatus(status: string): string {
    switch (status) {
      case BOOKING_STATUS_HOLD:
        return 'bg-warning';
      case BOOKING_STATUS_CONFIRMED:
        return 'bg-success';
      case BOOKING_STATUS_CANCELLED:
      case BOOKING_STATUS_EXPIRED:
        return 'bg-danger';
      default:
        return '';
    }
  }

  statusChanged(ev: any) {
    this.params = {
      status: ev.target.value.toLowerCase() === 'all' ? null : ev.target.value,
    };
    this.getListing();
  }

  routeToBooking(booking: BookingModel) {
    if (
      booking?.status === BOOKING_STATUS_CANCELLED ||
      booking?.availability?.availabilityable?.category?.type == packages
    )
      return;
    this.router
      .navigate(['/my-account', 'booking-detail', booking?.code])
      .catch((reason) => {
        console.warn(reason);
      });
  }
}
