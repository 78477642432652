import {
  ASSET_AVAILABILITY,
  bicycle,
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_EXPIRED,
  ORDER_ID,
} from './../../../utils/general-constants';
import * as moment from 'moment';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from '../../../services/auth/auth.service';
import SwiperCore, { Navigation, SwiperOptions, Thumbs } from 'swiper';
import { boat, game, packages } from 'src/app/utils/general-constants';
import { BookingService } from '../../../services/booking/booking.service';
import { cruise, DEFAULT_DATE_FORMAT } from '../../../utils/general-constants';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import {
  BikeModel,
  BoatModel,
  BookingModel,
  BookingReservationModel,
  GameModel,
  ServiceModel,
} from '../../../models/booking.model';
import {
  AvailabilityModel,
  ItemParamsModel,
  PackageModel,
  TimeslotResevationModel,
} from 'src/app/models/booking.model';
import { CartAlertComponent } from '../../../components/cart-alert/cart-alert.component';
import { CartService } from '../../../services/cart/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { Moment } from 'moment/moment';
import { parseTime } from '../../../utils/parse-time';

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

@Component({
  selector: 'br-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent extends BaseComponent implements OnInit {
  thumbsSwiper: any;
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: true,
    zoom: {
      maxRatio: 5,
    },
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  type!: string;
  from!: string;
  till!: string;
  date!: string;
  duration!: number;
  childPrice!: number;
  adultPrice!: number;
  seats: Array<number> = [];
  selectedSeats: number = 1;
  selectedNoOfChild: number = 0;
  selectedNoOfAdult: number = 0;
  customBooking: boolean = false;
  showPricesForm: boolean = false;
  disableToAndFrom: boolean = false;
  adultOrChildren: string = 'adult';
  params: ItemParamsModel | undefined;
  timeslot!: TimeslotResevationModel | null;
  minDate = moment(new Date()).format(DEFAULT_DATE_FORMAT);
  item!: BoatModel | BikeModel | ServiceModel | PackageModel;
  durationList: Array<number> = [];

  locale: string = 'en';
  orderId: number | null = null;
  seatOptions: Array<number> = [];
  adultOptions: Array<number> = [];
  childOptions: Array<number> = [];
  slotType: 'custom' | 'regular' | null = null;
  availability: AvailabilityModel | null = null;

  minTill: Moment | null = null;
  maxTill: Moment | null = null;
  sunset = moment().endOf('day');
  sunrise = moment().startOf('day');
  availabilities: Array<AvailabilityModel> = [];
  private parkTimings: any;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService,
    private storage: LocalStorage,
    private route: ActivatedRoute,
    private cartService: CartService,
    private modalService: NgbModal
  ) {
    super(router, authService, userService, translate);
    storage.getItem(ORDER_ID).subscribe((res: any) => {
      if (!res) return;
      this.orderId = res;
    });
    this.route.queryParams.subscribe((res) => {
      this.date = moment(new Date()).format(DEFAULT_DATE_FORMAT);
      this.params = {
        item_id: res.item_id,
        type: res.type,
        date: this.date,
      };
      this.type = res.type;
      this.getItemDetail();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item || changes.type) {
      this.updateCapacities();
    }
  }

  ngOnInit(): void {
    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        startWith(this.translate.currentLang || this.translate.defaultLang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  get isBoat(): boolean {
    return this.type === boat;
  }

  get isBike(): boolean {
    return this.type === bicycle;
  }

  get isGame(): boolean {
    return this.type === game;
  }

  get isPackage(): boolean {
    return this.type === packages;
  }

  get isFixedPrice(): boolean {
    return !!this.availability?.fix_price;
  }

  get isCustomSlot(): boolean {
    return this.hasCustomSlot && this.slotType === 'custom';
  }

  get hasCustomSlot(): boolean {
    return !!(
      (this.isBoat || this.isPackage || this.isBike) &&
      this.item?.custom_slot_available
    );
  }

  get isCruise(): boolean {
    return this.isBoat && this.item?.category?.sub_type?.includes(cruise);
  }

  get hasSelection(): boolean {
    return !!this.availability || this.isCustomSlot;
  }

  get hasCapacity(): boolean {
    return (
      this.hasSelection &&
      (!(this.isGame || this.isCruise) || this.selectedSeats > 0)
    );
  }

  get hasAmenities(): boolean {
    return (
      this.item?.seating_capacity! > 0 ||
      this.item?.battery_capacity! > 0 ||
      this.item?.persons_capacity! > 0
    );
  }

  getItemDetail() {
    this.showLoader();
    this.bookingService
      .itemDetail(this.params)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.item = res?.data;
          this.updateCapacities();
          this.setAvailabilities();

          if (this.item.available_timings === ASSET_AVAILABILITY.PARK_TIMINGS) {
            this.bookingService
              .parkTiming({ date: this.date || new Date().toISOString() })
              .subscribe((res) => {
                this.parkTimings = res;
              });
          }
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  onDateChange(ev: any): void {
    if (this.params) {
      this.date = ev.target.value;
      this.params.date = ev.target.value;
      this.getItemDetail();
    }
  }

  checkAvailability(availability: AvailabilityModel): void {
    if (this.item?.category?.type !== packages) {
      if (availability?.is_fullday) {
        if (this.item.available_timings === ASSET_AVAILABILITY.PARK_TIMINGS) {
          if (!this.parkTimings.park_open) {
            this.errorService.showErrorMessage(
              this.translate.instant(marker('CategoryItem.UnAvailable'))
            );
            return;
          }
        }
      } else if (!this.available(availability?.from, availability?.till)) {
        this.errorService.showErrorMessage(
          this.translate.instant(marker('CategoryItem.UnAvailable'))
        );
        return;
      }
    }

    this.slotType = 'regular';
    this.availabilities.map((a) => (a.selected = false));
    availability.selected = true;

    this.availability = availability;

    this.selectedNoOfChild = 0;
    this.selectedNoOfAdult = 0;

    // set adult & child prices
    ({ ap: this.adultPrice, cp: this.childPrice } =
      this.getAdultAndChildPrice(availability));

    // calculations for Cruise Category
    if (this.isCruise || this.isGame) {
      this.updateCapacities();
      if (!this.seatOptions.length) {
        this.availability = null;
        this.errorService.showErrorMessage(marker('CategoryItem.NoSeats'));
        return;
      }
    }
  }

  private available(from: string, till: string): boolean {
    switch (this.item.available_timings) {
      case ASSET_AVAILABILITY.CUSTOM:
        const fromTime = moment(from, 'HH:mm:ss');
        const tillTime = moment(till, 'HH:mm:ss');
        if (
          fromTime.isSameOrAfter(moment(this.item?.from, 'HH:mm:ss')) &&
          fromTime.isSameOrBefore(moment(this.item?.till, 'HH:mm:ss')) &&
          tillTime.isSameOrAfter(moment(this.item?.from, 'HH:mm:ss')) &&
          tillTime.isSameOrBefore(moment(this.item?.till, 'HH:mm:ss'))
        ) {
          return true;
        } else {
          return false;
        }
      case ASSET_AVAILABILITY.SUNRISE_SUNSET:
        const fromTime1 = moment(from, 'HH:mm:ss');
        const tillTime1 = moment(till, 'HH:mm:ss');
        if (
          fromTime1.isSameOrAfter(moment(this.sunrise, 'HH:mm:ss')) &&
          fromTime1.isSameOrBefore(moment(this.sunset, 'HH:mm:ss')) &&
          tillTime1.isSameOrAfter(moment(this.sunrise, 'HH:mm:ss')) &&
          tillTime1.isSameOrBefore(moment(this.sunset, 'HH:mm:ss'))
        ) {
          return true;
        } else {
          return false;
        }
      case ASSET_AVAILABILITY.PARK_TIMINGS:
        const fromTime2 = moment(from, 'HH:mm:ss');
        const tillTime2 = moment(till, 'HH:mm:ss');
        if (
          fromTime2.isSameOrAfter(
            moment(this.parkTimings?.opening_time, 'HH:mm:ss')
          ) &&
          fromTime2.isSameOrBefore(
            moment(this.parkTimings?.closing_time, 'HH:mm:ss')
          ) &&
          tillTime2.isSameOrAfter(
            moment(this.parkTimings?.opening_time, 'HH:mm:ss')
          ) &&
          tillTime2.isSameOrBefore(
            moment(this.parkTimings?.closing_time, 'HH:mm:ss')
          )
        ) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  addToCart(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.item) return;

    if (!this.availability && !this.isCustomSlot) {
      this.errorService.showErrorMessage(marker('CategoryItem.SelectTimeslot'));
      return;
    }

    if (this.item?.seating_capacity == 1 || this.item?.persons_capacity === 1) {
      this.selectedNoOfAdult = this.adultOrChildren === 'adult' ? 1 : 0;
      this.selectedNoOfChild = this.adultOrChildren === 'adult' ? 0 : 1;
    }

    if (this.isGame) {
      if (this.selectedSeats == 0) {
        this.errorService.showErrorMessage(marker('CategoryItem.SelectTicket'));
        return;
      }

      if (this.item?.persons_capacity! < this.selectedSeats) {
        this.errorService.showErrorMessage(
          marker('CategoryItem.SeatsUnavailable')
        );
        return;
      }
    } else {
      if (this.selectedNoOfAdult == 0 && this.selectedNoOfChild == 0) {
        this.errorService.showErrorMessage(marker('CategoryItem.SelectTicket'));
        return;
      }

      const totalPersons = this.selectedNoOfAdult + this.selectedNoOfChild;
      if (this.isCruise) {
        if (this.selectedSeats !== totalPersons) {
          this.errorService.showErrorMessage(
            marker('CategoryItem.IncorrectSeatCount')
          );
          return;
        }
      } else if (
        (this.item as BoatModel | BikeModel).seating_capacity < totalPersons
      ) {
        this.errorService.showErrorMessage(
          marker('CategoryItem.SeatsUnavailable')
        );
        return;
      }
    }

    const payload: TimeslotResevationModel = {
      date: moment(this.date).format(DEFAULT_DATE_FORMAT),
      item_id: this.item.id,
      item_type: this.type,
    };

    if (this.isCustomSlot) {
      if (!this.from && !this.duration) {
        this.errorService.showErrorMessage(marker('CategoryItem.SelectTime'));
        return;
      }
      payload.from_time = moment(this.from, 'HH:mm:ss').format('HH:mm:ss');
      payload.till_time = moment(this.from, 'HH:mm:ss')
        .add(this.duration, 'hours')
        // .add(this.isInt(this.duration) == true ? 0 : 30, 'minutes')
        .format('HH:mm:ss');

      if (
        !this.available(payload.from_time, payload.till_time) &&
        this.item?.category?.type !== packages
      ) {
        this.errorService.showErrorMessage(
          this.translate.instant(marker('CategoryItem.UnAvailable'))
        );
        return;
      }
    } else {
      payload.availability_id = this.availability?.id;
    }

    if (this.isGame || this.isCruise) {
      payload.seats = this.selectedSeats;
    }

    switch (this.type) {
      case packages:
        this.managePackage();
        return;
      case game:
        this.manageGame();
        return;
      default:
        break;
    }

    this.showLoader();
    this.bookingService.timeslotReservation(payload).subscribe(
      (res) => {
        const tickets =
          this.isCruise || this.type == game
            ? this.selectedSeats
            : this.selectedNoOfAdult + this.selectedNoOfChild;

        const payload: BookingReservationModel = {
          availability_id: res?.data?.id,
          date: moment(this.date).format(DEFAULT_DATE_FORMAT),
          item_id: res?.data?.availabilityable?.id,
          number_of_adults: this.selectedNoOfAdult,
          number_of_children: this.selectedNoOfChild,
          complementary_items: {},
          extra_items: {},
          number_of_allergic_person: 0,
          source: 'online',
          seats: tickets,
          order_id: this.orderId,
          item_type: this.type,
        };

        this.bookingService
          .bookingReservation(this.clean(payload))
          .pipe(
            finalize(() => {
              this.hideLoader();
            })
          )
          .subscribe(
            (res) => {
              this.setOrderId(res.data);
            },
            (error) => {
              this.errorService.handle(error);
            }
          );
      },
      (error) => {
        this.hideLoader();
        this.errorService.handle(error);
      }
    );
  }

  toggleSlotType(event: Event, type: 'custom' | 'regular' | null): void {
    this.slotType = type;
    this.item.availabilties.map((a) => (a.selected = false));
  }

  manageGame() {
    if (this.item?.persons_capacity == 1) {
      this.selectedNoOfAdult = this.adultOrChildren === 'adult' ? 1 : 0;
      this.selectedNoOfChild = this.adultOrChildren === 'adult' ? 0 : 1;
    }

    if (this.selectedNoOfAdult == 0 && this.selectedNoOfChild == 0) {
      this.errorService.showErrorMessage(marker('CategoryItem.SelectTicket'));
      return;
    }

    if (this.selectedNoOfAdult + this.selectedNoOfChild < this.selectedSeats) {
      this.errorService.showErrorMessage(marker('CategoryItem.PAXError'));
      return;
    }

    if (this.selectedSeats < this.selectedNoOfAdult + this.selectedNoOfChild) {
      this.errorService.showErrorMessage(
        marker('CategoryItem.SeatsUnavailable')
      );
      return;
    }

    this.showLoader();
    const payload = {
      type: game,
      availability_id: this.availability!.id,
      date: moment(this.date).format(DEFAULT_DATE_FORMAT),
      number_of_allergic_person: 0,
      number_of_adults: this.selectedNoOfAdult,
      number_of_children: this.selectedNoOfChild,
      complementary_items: {},
      complimentary_allergic_items_association: [],
      source: 'online',
      seats: this.selectedSeats,
      order_id: this.orderId,
    };

    this.bookingService
      .gamesBooking(
        this.clean(payload),
        this.availability!.availabilityable_id,
        this.availability!.id
      )
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.setOrderId(res.data);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  managePackage() {
    const startTime = moment(this.from, 'HH:mm:ss').format('HH:mm:ss');
    const endTime = moment(this.from, 'HH:mm:ss')
      .add(this.duration, 'hours')
      // .add(this.isInt(this.duration) == true ? 0 : 30, 'minutes')
      .format('HH:mm:ss');

    // // start time and end time
    const duration = moment.duration(moment(endTime).diff(startTime));
    const hours = duration.asHours();

    if (this.item.hours_duration! <= hours) {
      this.errorService.showErrorMessage(marker('CategoryItem.HoursDuration'));
      return;
    }

    const price = this.item.price! + this.item?.category?.service_fee || 0;

    const payload = {
      type: this.type,
      availability_id: this.availability?.id,
      date: moment(this.date).format(DEFAULT_DATE_FORMAT),
      from: this.isCustomSlot ? startTime : null,
      till: this.isCustomSlot ? endTime : null,
      source: 'online',
      price: price,
      order_id: this.orderId,
    };

    this.bookingService
      .packageBooking(this.clean(payload), this.item.id)
      .subscribe(
        (res) => {
          this.setOrderId(res.data);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  setOrderId(res: BookingModel) {
    if (!this.orderId) {
      this.showTimerModal();
      const minutes = 60 * 15;
      this.bookingService.startTimer(minutes);
    }
    this.orderId = res.order_id;
    this.storage.setItem(ORDER_ID, this.orderId).subscribe(() => {
      this.selectedNoOfAdult = 0;
      this.selectedNoOfChild = 0;
      this.updateCapacities();
      this.cartService.addToCart();
    });
  }

  isInt(n: number) {
    return Number(n) === n && n % 1 === 0;
  }

  showTimerModal() {
    this.modalService.open(CartAlertComponent, {
      size: 'md',
      centered: true,
    });
  }

  updateCapacities() {
    let maxSeats =
      this.isGame || this.isPackage
        ? (this.item as GameModel | PackageModel).persons_capacity
        : (this.item as BoatModel | BikeModel).seating_capacity;

    if (
      (this.isCruise || this.isGame) &&
      this.availability?.reservations?.length
    ) {
      maxSeats -= this.availability.reservations.reduce((acc, curr) => {
        if (
          moment(curr.date).isSame(this.date) &&
          curr.status !== BOOKING_STATUS_EXPIRED &&
          curr.status !== BOOKING_STATUS_CANCELLED
        ) {
          return acc + curr.seats;
        } else {
          return acc;
        }
      }, 0);
    }

    this.seatOptions = new Array(maxSeats).fill(0).map((_, idx) => idx + 1);

    if (this.selectedSeats > maxSeats) {
      this.selectedSeats = maxSeats;
    }

    const overflow =
      this.selectedNoOfAdult +
      this.selectedNoOfChild -
      (this.isCruise || this.isGame ? this.selectedSeats : maxSeats);
    if (overflow > 0) {
      if (this.selectedNoOfAdult > 0) {
        if (this.selectedNoOfChild > 0) {
          this.selectedNoOfAdult = Math.max(
            0,
            this.selectedNoOfAdult - Math.floor(overflow / 2)
          );
          this.selectedNoOfChild = Math.max(
            0,
            this.selectedNoOfChild - Math.ceil(overflow / 2)
          );
        } else {
          this.selectedNoOfAdult = Math.max(
            0,
            this.selectedNoOfAdult - overflow
          );
        }
      } else if (this.selectedNoOfChild > 0) {
        this.selectedNoOfChild = Math.max(0, this.selectedNoOfChild - overflow);
      }
    }

    const maxAdult =
      (this.isCruise || this.isGame ? this.selectedSeats : maxSeats) -
      this.selectedNoOfChild;
    const maxChild =
      (this.isCruise || this.isGame ? this.selectedSeats : maxSeats) -
      this.selectedNoOfAdult;

    this.adultOptions = new Array(maxAdult).fill(0).map((_, idx) => idx + 1);
    this.childOptions = new Array(maxChild).fill(0).map((_, idx) => idx + 1);
  }

  private setAvailabilities() {
    this.bookingService
      .getSunriseDetail()
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        for (let i = 1; i <= res.hours; i++) {
          this.durationList.push(i);
          this.durationList.push(i + 0.5);
        }

        this.sunrise = moment(res.sunrise, 'HH:mm:ss');
        this.sunset = moment(res.sunset, 'HH:mm:ss');

        timer(0, 60000)
          .pipe(takeUntil(this.destroy))
          .subscribe(() => {
            const now = moment();
            const endOfDay = moment(now).endOf('day');

            this.minTill = moment(now).startOf('minute').add(1, 'minute');
            if (
              this.sunrise?.isValid() &&
              this.minTill.isBefore(this.sunrise)
            ) {
              this.minTill = this.sunrise;
            }

            if (this.isPackage && this.item?.hours_duration) {
            }

            this.maxTill = /*this.sunset?.isValid() ? this.sunset : */ endOfDay;

            this.availabilities = moment(this.date).isAfter(moment(), 'day')
              ? this.item.availabilties?.filter((av) => av.active)
              : this.item
              ? this.item.availabilties
                  .map(
                    (
                      availability
                    ): AvailabilityModel & {
                      parsedFrom?: Moment | null;
                      parsedTill?: Moment | null;
                    } => {
                      if (availability.is_fullday) {
                        return availability;
                      }

                      let parsedFrom = parseTime(availability.from);
                      let parsedTill = parseTime(availability.till);
                      if (!parsedFrom?.isValid() || !parsedTill?.isValid()) {
                        return availability;
                      }

                      if (parsedTill?.isBefore(parsedFrom)) {
                        parsedTill?.add(1, 'day');
                      }
                      return { ...availability, parsedFrom, parsedTill };
                    }
                  )
                  .filter(
                    ({ parsedFrom, parsedTill, is_fullday, active }) =>
                      active &&
                      (is_fullday ||
                        (parsedFrom?.isValid() &&
                          parsedFrom?.isAfter(now) &&
                          parsedFrom?.isBefore(this.sunset) &&
                          parsedTill?.isValid() &&
                          parsedTill?.isAfter(parsedFrom)))
                  )
                  .sort(
                    (
                      {
                        id: lhsId,
                        parsedFrom: lhsFrom,
                        parsedTill: lhsTill,
                        is_fullday: lhsFullDay,
                      },
                      {
                        id: rhsId,
                        parsedFrom: rhsFrom,
                        parsedTill: rhsTill,
                        is_fullday: rhsFullDay,
                      }
                    ) => {
                      if (lhsFullDay) {
                        if (rhsFullDay) {
                          return lhsId - rhsId;
                        }

                        return -1;
                      }

                      if (rhsFullDay) {
                        return 1;
                      }

                      if (lhsFrom?.isValid() && rhsFrom?.isValid()) {
                        if (!lhsFrom?.isSame(rhsFrom)) {
                          return lhsFrom?.isBefore(rhsFrom) ? -1 : 1;
                        }

                        if (
                          lhsTill?.isValid() &&
                          rhsTill?.isValid() &&
                          !lhsTill?.isSame(rhsTill)
                        ) {
                          return lhsTill?.isBefore(rhsTill) ? -1 : 1;
                        }
                      }

                      return lhsId - rhsId;
                    }
                  )
              : [];
          });
      });
  }
}
