<!-- BreadCrumbs -->
<div class="container">
    <div class="row">
        <div class="col breadCrumbs-wrapper">
            <i class="bi bi-house-door pe-auto" routerLink="/home"></i>
            <i class="bi bi-chevron-right"></i>
            <app-ng-dynamic-breadcrumb class="breadCrumb-link" [lastLinkColor]="'#000'" [symbol]="'>'">
            </app-ng-dynamic-breadcrumb>
        </div>
    </div>
</div>
<!-- /BreadCrumbs -->
<div class="container static-wrapper contact">
    <div class="row m-0">
        <div class="col-12 p-0">
            <div class="feature-img">
                <h1>{{ 'ContactUs.Title' | translate }}</h1>
                <img class="img-fluid rounded" [src]="'../../../assets/images/staticPages/faq.png'" alt="">
            </div>
        </div>
        <div class="col-md-5 static-content" [innerHtml]="contact?.content"></div>
        <div class="col-md-7 map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2416.9923999375314!2d6.07721791581472!3d52.714282379851404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c871d6bf9cede5%3A0x1eee177109f78200!2sBeulakerweg%20169%20B%2C%208355%20AG%20Giethoorn%2C%20Netherlands!5e0!3m2!1sen!2s!4v1636012455526!5m2!1sen!2s"
                style="border:0; width:100%; height:650px" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</div>