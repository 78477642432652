<header class="header-wrapper" [ngClass]="isHome ? 'home' : ''">
  <div class="navbar sub-header">
    <div class="container">
      <div class="row w-100">
        <div class="col-md-auto col-12 address">
          <i class="bi bi-geo-alt-fill"></i>
          <span> Beulakerweg 169B, 8355 AG Giethoorn, Netherlands</span>
        </div>
        <div class="col-md-auto headerEmail col-12">
          <i class="bi bi-envelope-fill"></i>
          <span> info@arendshorst-giethoorn.nl </span>
        </div>
        <div class="col-md-auto tel col-12">
          <i class="bi bi-telephone-fill"></i>
          <span> +31521362436 </span>
        </div>
        <div class="col-md-auto tel col-12">
          <i class="bi bi-whatsapp"></i>
          <span> +31619056165 </span>
        </div>
        <div class="col-lg-12 col-xl-auto mt-3 mt-xl-0 right-side p-0 col-12">
          <div class="social-icons">
            <i class="bi bi-facebook"></i>
            <i class="bi bi-twitter"></i>
            <i class="bi bi-instagram"></i>
          </div>
          <div class="Lang-flags">
            <span
              *ngFor="let lang of languages"
              (click)="onLanguageChange($event, lang)"
              class="pointer"
            >
              <img class="img-fluid" [src]="lang.icon" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <nav class="navbar navbar-expand-lg navbar-dark main-navbar">
    <div class="container pe-4">
      <a class="navbar-brand" routerLink="/home">
        <img src="../../../assets/images/home/logo.png" alt="" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              [routerLinkActive]="['active']"
              [routerLink]="['home']"
            >
              {{ "Header.Navbar.Home" | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLinkActive]="['active']"
              [routerLink]="['categories']"
            >
              {{ "Header.Navbar.Categories" | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLinkActive]="['active']"
              [routerLink]="['page', 'faq']"
            >
              {{ "Header.Navbar.FAQ" | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLinkActive]="['active']"
              [routerLink]="['page', 'brochures']"
            >
              {{ "Header.Navbar.Brochures" | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLinkActive]="['active']"
              [routerLink]="['page', 'contact-us']"
            >
              {{ "Header.Navbar.ContactUs" | translate }}
            </a>
          </li>
        </ul>
        <div class="d-flex authProfileBtns align-items-center" *ngIf="!loading">
          <a class="cart-icon" routerLink="/cart">
            <img src="../../../assets/images/icons/cart.svg" alt="" />
            <span *ngIf="cartCount > 0">{{
              cartCount > 9 ? "9+" : cartCount
            }}</span>
          </a>

          <ng-template class="d-flex" #elseBlock>
            <button
              class="btn btn-outline-light me-3"
              [routerLink]="['register']"
            >
              {{ "Buttons.Register" | translate }}
            </button>
            <button class="btn btn-primary text-white" [routerLink]="['login']">
              {{ "Buttons.Login" | translate }}
            </button>
          </ng-template>

          <div class="d-flex loggedInUser" *ngIf="userDetail; else elseBlock">
            <h4 class="text-light m-0 me-3 text-16">
              {{ "Header.Welcome" | translate }}
              <span class="fw-bold">{{ userDetail?.data?.name }},</span>
            </h4>
            <button
              class="avatar-img btn p-0 dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                class="img-fluid"
                [src]="
                  userDetail?.data?.avatar?.url ||
                  '../../../assets/images/profile/avatar.png'
                "
                alt=""
              />
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" routerLink="/my-account/my-profile">
                  {{ "Header.Navbar.Profile" | translate }}
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/my-account/my-orders">
                  {{ "Header.Navbar.MyOrders" | translate }}
                </a>
              </li>
              <li *ngIf="userDetail?.data?.role !== 'dealer'">
                <a class="dropdown-item" routerLink="/my-account/my-payments">
                  {{ "Header.Navbar.MyPayments" | translate }}
                </a>
              </li>
              <hr class="dropdown-divider" />
              <li>
                <a class="dropdown-item logout" (click)="logout()">
                  {{ "Header.Navbar.Logout" | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
