<div class="container auth-wrapper">
    <div class="auth-content d-flex align-items-center justify-content-center">

        <div class="row col-4">
            <div class="col col-12 text-center mb-3">
                <img src="../../../../assets/images/auth/check-email.png" alt="" class="d-inline-block">
            </div>
            <div class="col col-12 text-center mb-2">
                <button class="btn btn-link text-gray-700 text-decoration-none text-20 fw-bolder d-inline-block"
                    routerLink="/reset-password">
                    {{ 'ResetPassword.CheckEmail' | translate }}
                </button>
            </div>
            <div class="col col-12 text-center mb-3">
                <p class="text-gray-600 px-5 text-center mb-0">
                    {{ 'ResetPassword.EmailedInstructions' | translate }}
                </p>
            </div>

            <div class="col text-center mt-3">
                <button class="btn btn-primary d-inline-block text-light px-5 fw-bold w-100 text-18 text-uppercase"
                    (click)="routeToReset()">{{ 'ResetPassword.Title' | translate }}</button>
            </div>

            <div class="col col-12 pt-5 mt-3 text-center">
                <p class="text-gray-600 text-center mb-1 text-14">
                    {{ 'ResetPassword.QuestionsAndQueries' | translate }}
                </p>
                <a class="btn btn-link text-gray-600 text-decoration-none text-14 fw-bolder d-inline-block"
                    href="emailto:info@arendshorst-giethoorn.nl"> emailto:info@arendshorst-giethoorn.nl</a>
            </div>
        </div>
    </div>
</div>