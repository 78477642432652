import * as moment from "moment";
import { Moment } from "moment";

export function parseTime(value: unknown): Moment | null {
  if (value === null) {
    return null;
  }

  if (moment.isMoment(value)) {
    return value;
  }

  if (typeof value === 'string') {
    return moment(value, 'H:mm:ss');
  }

  if (value instanceof Date) {
    return moment(value);
  }

  return null;
}
