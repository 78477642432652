import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './pages/cart/cart.component';
import { HomeComponent } from './components/home/home.component';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { LoginComponent } from './pages/auth/login/login.component';
import { BookingComponent } from './pages/booking/booking.component';
import { VerifyComponent } from './pages/auth/verify/verify.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { PaymentComponent } from './pages/booking/payment/payment.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { StaticPageComponent } from './pages/static-page/static-page.component';
import { MyProfileComponent } from './pages/my-account/my-profile/my-profile.component';
import { PaymentStatusComponent } from './pages/payment-status/payment-status.component';
import { MyPaymentsComponent } from './pages/my-account/my-payments/my-payments.component';
import { ReviewTermsComponent } from './pages/booking/review-terms/review-terms.component';
import { MyBookingsComponent } from './pages/my-account/my-bookings/my-bookings.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { CategoryListingComponent } from './pages/category-listing/category-listing.component';
import { RegisterDealerComponent } from './pages/auth/register-dealer/register-dealer.component';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { ItemDetailsComponent } from './pages/category-listing/item-details/item-details.component';
import { BookingDetailComponent } from './pages/my-account/booking-detail/booking-detail.component';
import { PersonalDetailsComponent } from './pages/booking/personal-details/personal-details.component';
import { PaymentCompleteComponent } from './pages/booking/payment-complete/payment-complete.component';
import { EditBookingDetailsComponent } from './pages/my-account/edit-booking-details/edit-booking-details.component';
import { ForgetPassConfirmationComponent } from './pages/auth/forget-pass-confirmation/forget-pass-confirmation.component';
import { MyOrdersComponent } from './pages/my-account/my-orders/my-orders.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'home',
      breadcrumb: [
        {
          label: 'home',
          url: '',
        },
      ],
    },
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    data: {
      title: 'categories',
      breadcrumb: [
        {
          label: 'Categories',
          url: '/categories',
        },
      ],
    },
  },
  {
    path: 'categories/:id',
    component: CategoryListingComponent,
    data: {
      title: 'Listing',
      breadcrumb: [
        {
          label: 'Listing',
          url: '/categories/:id',
        },
      ],
    },
  },
  {
    path: 'categories/:id/detail',
    component: ItemDetailsComponent,
    data: {
      title: 'Item',
      breadcrumb: [
        {
          label: 'Item',
          url: '/categories/:id/detail',
        },
      ],
    },
  },
  {
    path: 'cart',
    component: CartComponent,
    data: {
      title: 'Cart',
      breadcrumb: [
        {
          label: 'Cart',
          url: '/cart',
        },
      ],
    },
  },
  {
    path: 'booking',
    component: BookingComponent,
    data: {
      title: 'booking',
      breadcrumb: [
        {
          label: 'Booking Cart',
          url: '/booking',
        },
      ],
    },
    children: [
      {
        path: 'personal-details',
        component: PersonalDetailsComponent,
        data: {
          title: 'personal-details',
          breadcrumb: [
            {
              label: 'Personal Details',
              url: '/personal-details',
            },
          ],
        },
      },
      {
        path: 'payment',
        component: PaymentComponent,
        data: {
          title: 'Payment',
          breadcrumb: [
            {
              label: 'Payment',
              url: '/payment',
            },
          ],
        },
      },
      {
        path: 'review-terms',
        component: ReviewTermsComponent,
        data: {
          title: 'Review Terms',
          breadcrumb: [
            {
              label: 'Review Terms',
              url: '/review-terms',
            },
          ],
        },
      },
    ],
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register',
      breadcrumb: [
        {
          label: 'Register',
          url: '/register',
        },
      ],
    },
  },

  {
    path: 'register-dealer',
    component: RegisterDealerComponent,
    data: {
      title: 'Register',
      breadcrumb: [
        {
          label: 'Register',
          url: '/register-dealer',
        },
      ],
    },
  },
  {
    path: 'confirm-email',
    component: ForgetPassConfirmationComponent,
    data: {
      title: 'Forgot Password',
      breadcrumb: [
        {
          label: 'Forgot Password',
          url: '/confirm-email',
        },
      ],
    },
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    data: {
      title: 'Forgot Password',
      breadcrumb: [
        {
          label: 'Forgot Password',
          url: '/forget-password',
        },
      ],
    },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password',
      breadcrumb: [
        {
          label: 'Reset Password',
          url: '/reset-password',
        },
      ],
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
      breadcrumb: [
        {
          label: 'Login',
          url: '/login',
        },
      ],
    },
  },
  {
    path: 'verify-email',
    component: VerifyComponent,
    data: {
      title: 'Verify Email',
      breadcrumb: [
        {
          label: 'Verify Email',
          url: '/verify-email',
        },
      ],
    },
  },
  // my account
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [AuthenticatedGuard],
    data: {
      title: 'My Account',
      breadcrumb: [
        {
          label: 'My Account',
          url: '/my-account',
        },
      ],
    },
    children: [
      {
        path: 'my-orders',
        component: MyOrdersComponent,
        data: {
          title: 'My Orders',
          breadcrumb: [
            {
              label: 'My Orders',
              url: '/my-orders',
            },
          ],
        },
      },
      {
        path: 'my-booking/:id',
        component: MyBookingsComponent,
        data: {
          title: 'My Booking',
          breadcrumb: [
            {
              label: 'My Bookings',
              url: '/my-bookings',
            },
          ],
        },
      },
      {
        path: 'my-payments',
        component: MyPaymentsComponent,
        data: {
          title: 'My Payments',
          breadcrumb: [
            {
              label: 'My Payments',
              url: '/my-account/my-payments',
            },
          ],
        },
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        data: {
          title: 'My Profile',
          breadcrumb: [
            {
              label: 'My Profile',
              url: '/my-account/my-profile',
            },
          ],
        },
      },

      {
        path: 'booking-detail/:id',
        component: BookingDetailComponent,
        data: {
          title: 'Booking Detail',
          breadcrumb: [
            {
              label: 'Booking Detail',
              url: '/my-account/booking-detail/:id',
            },
          ],
        },
      },
      {
        path: 'edit-booking/:code',
        component: EditBookingDetailsComponent,
        data: {
          title: 'Edit Booking',
          breadcrumb: [
            {
              label: 'Edit Booking',
              url: '/my-account/edit-booking/:code',
            },
          ],
        },
      },
    ],
  },
  {
    path: 'page/:slug',
    component: StaticPageComponent,
    data: {
      title: 'Page',
      breadcrumb: [
        {
          label: 'Page',
          url: '/page/:slug',
        },
      ],
    },
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: {
      title: 'Contact Us',
      breadcrumb: [
        {
          label: 'Contact Us',
          url: '/contact-us',
        },
      ],
    },
  },
  {
    path: 'thank-you/:code',
    component: PaymentCompleteComponent,
    data: {
      title: 'Thank You',
      breadcrumb: [
        {
          label: 'Thank You',
          url: '/thank-you/:code',
        },
      ],
    },
  },
  {
    path: 'payment-status/:id',
    component: PaymentStatusComponent,
    data: {
      title: 'Payment Status',
      breadcrumb: [
        {
          label: 'Payment Status',
          url: '/payment-status',
        },
      ],
    },
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: HomeComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),
  ],
})
export class AppRoutingModule {}
