<div class="modal-body p-5">
  <div class="close-btn" (click)="close()"><i class="bi bi-x"></i></div>
  <p class="text-center px-5 mb-3 mt-3" [innerHTML]="message"></p>
  <div class="d-flex align-items-center justify-content-center gap-2" *ngIf="showButtons">
    <button
      type="button"
      class="px-5 btn btn-success text-light text-14"
      (click)="confirm()"
    >
      {{ "Buttons.Ok" | translate }}
    </button>
    <button type="button" class="px-5 btn btn-danger text-14" (click)="close()">
      {{ "Buttons.No" | translate }}
    </button>
  </div>
</div>
