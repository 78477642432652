<div class="modal-body p-5">
    <p class="text-center px-5 mb-5">
        {{ message }}
    </p>
    <div class="d-flex align-items-center justify-content-center gap-2">
        <button type="button" class="px-5 btn btn-success text-light text-14" (click)="routeToEditBooking()">
            {{'Buttons.Yes' | translate}}
        </button>
        <button type="button" class="px-5 btn btn-danger text-14" (click)="close()">
            {{'Buttons.No' | translate }}</button>
    </div>
</div>
