<div class="container auth-wrapper">
    <div class="row m-0">
        <div class="col-md-6 ps-0 pe-md-3 pe-0">
            <div class="feature-img">
                <img class="img-fluid" src="../../../../assets/images/auth/forget.png" alt="">
            </div>
        </div>

        <div class="col-md-6">
            <div class="auth-content">

                <form autocomplete="off" [formGroup]="form" (ngSubmit)="forget()">
                    <div class="row">
                        <div class="col">
                            <h4 class="fs-4 fw-bold mb-4 text-center">{{ 'Forget.Title' | translate }}</h4>
                        </div>
                        <div class="col col-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="username"
                                    placeholder="{{ 'Forget.Placeholder.Username' | translate }}"
                                    formControlName="username">
                                <label for="username">{{ 'Forget.Form.Username' | translate }}</label>
                            </div>
                            <br-validation-messages [control]="getFormControl(form, 'username')"
                                [label]="'Forget.Form.Username' | translate"></br-validation-messages>
                        </div>

                        <div class="col text-center mt-3">
                            <button type="submit" [disabled]="!form.valid"
                                class="btn btn-primary d-inline-block text-light px-5 text-18 w-100 text-uppercase">
                                {{ 'Buttons.SendEmail' | translate }}
                            </button>
                        </div>

                        <div
                            class="col col-12 d-flex align-items-center justify-content-between signup-ForgetHolder pt-5 mt-3">
                            <p class="text-gray-600 px-5 text-center">
                                {{ 'Forget.ResetLinkMessage' | translate }}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>