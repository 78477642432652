<footer class="footer-wrapper">
    <div class="main-footer">
        <div class="container overflow-hidden">
            <div class="row mb-5">
                <!-- About -->
                <div class="mb-4 mb-md-0 col-12 col-lg-3 col-md-6">
                    <div class="footer-logo">
                        <img src="../../../assets/images/home/logo.png" class="img-fluid" alt="">
                    </div>
                    <p class="about-desc">
                        {{'Footer.Description' | translate}}
                    </p>
                </div>
                <!-- Recent Booking -->
                <div class=" mb-4 mb-lg-0 ps-lg-5 col-12 col-lg-3 col-md-6">
                    <h4 class="block-heading">{{ 'Footer.RecentBookings.Title' | translate }}</h4>
                    <ul class="list-style-arrow">
                        <li class="bookings-list">
                            <a href="#">
                                <img class="img-fluid" src="../../../assets/images/home/games.png" alt="">
                                <div class="content">
                                    <h5>Bike</h5>
                                    <span class="date">
                                        <i class="bi bi-clock"></i>
                                        28 Aug, 2021
                                    </span>
                                </div>
                            </a>
                        </li>
                        <li class="bookings-list">
                            <a href="#">
                                <img class="img-fluid" src="../../../assets/images/home/CanalCruses.png" alt="">
                                <div class="content">
                                    <h5>Bike</h5>
                                    <span class="date">
                                        <i class="bi bi-clock"></i>
                                        28 Aug, 2021
                                    </span>
                                </div>
                            </a>
                        </li>
                        <li class="bookings-list">
                            <a href="#">
                                <img class="img-fluid" src="../../../assets/images/home/canoes.png" alt="">
                                <div class="content">
                                    <h5>Bike</h5>
                                    <span class="date">
                                        <i class="bi bi-clock"></i>
                                        28 Aug, 2021
                                    </span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- UseFul Links -->
                <div class="mb-4 mb-md-0 ps-lg-5 col-12 col-lg-3 col-md-6">
                    <h4 class="block-heading">{{ 'Footer.UsefullLinks.Title' | translate }}</h4>
                    <ul class="list-style-arrow">
                        <li>
                            <a [routerLink]="['/home']">Home</a>
                        </li>
                        <li>
                            <a [routerLink]="['/categories']">Categories</a>
                        </li>
                        <li *ngFor="let item of usefullLinks">
                            <a [routerLink]="['/page', item?.slug]">{{ item?.name }}</a>
                        </li>
                    </ul>
                </div>
                <!-- Pages -->
                <div class=" ps-lg-5 col-12 col-lg-2 col-md-6">
                    <h4 class="block-heading">{{ 'Footer.Pages.Title' | translate }}</h4>
                    <ul class="list-style-arrow">
                        <li *ngFor="let item of pages">
                            <a [routerLink]="['/page', item?.slug]">{{ item?.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- bottom main footer -->
            <div class="row">
                <!-- Support -->
                <div class=" mb-4 mb-lg-0 col-12 col-lg-3 col-md-6">
                    <h4 class="block-heading">{{ 'Footer.Support.Title' | translate }}</h4>
                    <ul class="list-style-arrow">
                        <li><a href="#">Mon - Fri 9:30 am - 6:00 pm</a></li>
                        <li><a href="#">Mon - Fri 9:30 am - 6:00 pm</a></li>
                    </ul>
                </div>

                <!--Become a dealer -->
                <div class="ps-md-5 mb-4 mb-lg-0 col-12 col-lg-3 col-md-6">
                    <h4 class="block-heading">{{ 'Footer.BecomeDealer.Title' | translate }}</h4>
                    <ul class="list-style-arrow">
                        <li>
                            <a routerLink="/register-dealer">{{ 'Footer.BecomeDealer.Register' | translate }}</a>
                        </li>
                    </ul>
                </div>

                <!-- Payment Methods -->
                <div class="ps-lg-5 col-12 col-lg-6 ">
                    <h4 class="block-heading">{{ 'Footer.PaymentMethods.Title' | translate }}</h4>
                    <ul>
                        <li><img src="../../../assets/images/footer/Screenshot_20.png" alt=""></li>
                        <li><img src="../../../assets/images/footer/Screenshot_21.png" alt=""></li>
                        <li><img src="../../../assets/images/footer/Screenshot_22.png" alt=""></li>
                        <li><img src="../../../assets/images/footer/Screenshot_23.png" alt=""></li>
                        <li><img src="../../../assets/images/footer/Screenshot_24.png" alt=""></li>
                        <li><img src="../../../assets/images/footer/Screenshot_25.png" alt=""></li>
                        <li><img src="../../../assets/images/footer/Screenshot_26.png" alt=""></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <div class="container">
            <div class="row justify-content-center text-center">
                <p>{{ 'Footer.Copyrights' | translate }}</p>
            </div>
        </div>
    </div>
</footer>
