import { Component, OnInit } from '@angular/core';
import {
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_CONFIRMED,
  BOOKING_STATUS_EXPIRED,
  BOOKING_STATUS_HOLD,
  BookingStatuses,
} from '../../../utils/general-constants';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { OrderModel } from '../../../models/cart.model';
import { UserService } from '../../../services/user/user.service';
import { AuthService } from '../../../services/auth/auth.service';
import { BookingService } from '../../../services/booking/booking.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { ErrorHandlerService } from '../../../services/error/error-handler.service';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'br-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent extends BaseComponent implements OnInit {
  params: { status: string | null } = {
    status: null,
  };
  locale: string = 'en';
  loading: boolean = true;
  statuses = BookingStatuses;
  orders: Array<OrderModel> = [];

  constructor(
    protected router: Router,
    public userService: UserService,
    protected authService: AuthService,
    protected translate: TranslateService,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService,
    private paymentService: PaymentService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.list();
    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  list() {
    this.loading = true;
    this.showLoader();
    this.params = this.clean(this.params);
    this.bookingService
      .ordersList(this.params)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.hideLoader();
        })
      )
      .subscribe(
        (res) => {
          this.orders = res?.data;
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  setStatus(status: string): string {
    switch (status) {
      case BOOKING_STATUS_HOLD.toLowerCase():
        return 'bg-warning';
      case BOOKING_STATUS_CONFIRMED.toLowerCase():
        return 'bg-success';
      case BOOKING_STATUS_CANCELLED.toLowerCase():
      case BOOKING_STATUS_EXPIRED.toLowerCase():
        return 'bg-danger';
      default:
        return '';
    }
  }

  statusChanged(ev: any) {
    this.params = {
      status:
        ev.target.value.toLowerCase() === 'all'
          ? null
          : ev.target.value.toLowerCase(),
    };
    this.list();
  }

  redirectToMollie(code: string, event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.paymentService.molliePayment({ code: code }).subscribe(
      (res) => {
        window.open(res.data, '_self');
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }
}
