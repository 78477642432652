import { COUNTRIES } from './countries.constants';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ValidatorFn, AbstractControl } from '@angular/forms';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let validNumber = false;
        let prefix = COUNTRIES.find(c => c.countryCode === regionCode)?.phoneCode;
        try {
            const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
                prefix + control.value, regionCode
            );
            validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
        } catch (e) { }

        return validNumber ? null : { 'WrongNumber': { value: prefix + control.value } };
    }
}
