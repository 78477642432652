import * as moment from 'moment';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';
import { BaseComponent } from '../../pages/base.component';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  ASSET_AVAILABILITY,
  boat,
  BOOKING_STATUS_CANCELLED,
  BOOKING_STATUS_EXPIRED,
  cruise,
  DEFAULT_DATE_FORMAT,
  game,
  packages,
} from '../../utils/general-constants';
import { BookingService } from '../../services/booking/booking.service';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import {
  AvailabilityModel,
  BookingReservationModel,
  ComplementaryAllergicItem,
  ComplementaryItemModel,
  ExtraItemModel,
  GameModel,
  PackageModel,
  ServiceModel,
} from '../../models/booking.model';
import {
  BikeModel,
  BoatModel,
  TimeslotResevationModel,
} from 'src/app/models/booking.model';
import { TranslateService } from '@ngx-translate/core';
import { ExtraItemsModalComponent } from '../extra-items-modal/extra-items-modal.component';
import { ComplementaryItemsModalComponent } from '../complementary-items-modal/complementary-items-modal.component';
import { timer } from 'rxjs';
import { Moment } from 'moment/moment';
import { parseTime } from '../../utils/parse-time';
import { MessageAlertModalComponent } from '../message-alert-modal/message-alert-modal.component';
import { AllergicItemModalComponent } from '../allergic-item-modal/allergic-item-modal.component';

@Component({
  selector: 'br-booking-category-item',
  templateUrl: './booking-category-item.component.html',
  styleUrls: ['./booking-category-item.component.scss'],
})
export class BookingCategoryItemComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() type!: string;
  @Input() code!: string;
  @Input() orderId!: string;
  @Input() confirmationDate!: string;
  @Input() item!: BoatModel | BikeModel | ServiceModel | GameModel;

  from!: string;
  till!: string;
  duration!: number;
  childPrice!: number;
  adultPrice!: number;
  seats: Array<number> = [];
  selectedSeats: number = 1;
  selectedNoOfChild: number = 0;
  selectedNoOfAdult: number = 0;
  adultOrChildren: string = 'adult';
  availability: AvailabilityModel | null = null;

  minTill: Moment | null = null;
  maxTill: Moment | null = null;
  sunset = moment().endOf('day');
  sunrise = moment().startOf('day');
  availabilities: Array<AvailabilityModel> = [];

  locale: string = 'en';
  seatOptions: Array<number> = [];
  adultOptions: Array<number> = [];
  childOptions: Array<number> = [];
  extraItems: Array<ExtraItemModel> = [];
  slotType: 'custom' | 'regular' | null = null;
  allergicItems: Array<ComplementaryAllergicItem> = [];
  complementaryItems: Array<ComplementaryItemModel> = [];
  private parkTimings: any;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService,
    private modalService: NgbModal
  ) {
    super(router, authService, userService, translate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item || changes.type) {
      this.updateCapacities();
    }
  }

  ngOnInit(): void {
    if (this.item.available_timings === ASSET_AVAILABILITY.PARK_TIMINGS) {
      this.bookingService
        .parkTiming({ date: this.confirmationDate || new Date().toISOString() })
        .subscribe((res) => {
          this.parkTimings = res;
        });
    }
    this.bookingService
      .getSunriseDetail()
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        this.sunrise = moment(res.sunrise, 'HH:mm:ss');
        this.sunset = moment(res.sunset, 'HH:mm:ss');

        timer(0, 60000)
          .pipe(takeUntil(this.destroy))
          .subscribe(() => {
            const now = moment();
            const endOfDay = moment(now).endOf('day');

            this.minTill = moment(now).startOf('minute').add(1, 'minute');
            if (
              this.sunrise?.isValid() &&
              this.minTill.isBefore(this.sunrise)
            ) {
              this.minTill = this.sunrise;
            }

            if (this.isPackage && this.item?.hours_duration) {
            }

            this.maxTill = /*this.sunset?.isValid() ? this.sunset : */ endOfDay;

            this.availabilities = moment(this.confirmationDate).isAfter(
              moment(),
              'day'
            )
              ? this.item.availabilties?.filter((a) => a.active)
              : this.item
              ? this.item.availabilties
                  .map(
                    (
                      availability
                    ): AvailabilityModel & {
                      parsedFrom?: Moment | null;
                      parsedTill?: Moment | null;
                    } => {
                      if (availability.is_fullday) {
                        return availability;
                      }

                      let parsedFrom = parseTime(availability.from);
                      let parsedTill = parseTime(availability.till);
                      if (!parsedFrom?.isValid() || !parsedTill?.isValid()) {
                        return availability;
                      }

                      if (parsedTill?.isBefore(parsedFrom)) {
                        parsedTill?.add(1, 'day');
                      }

                      return { ...availability, parsedFrom, parsedTill };
                    }
                  )
                  .filter(
                    ({ parsedFrom, parsedTill, is_fullday, active }) =>
                      active &&
                      (is_fullday ||
                        (parsedFrom?.isValid() &&
                          parsedFrom?.isAfter(now) &&
                          parsedFrom?.isBefore(this.sunset) &&
                          parsedTill?.isValid() &&
                          parsedTill?.isAfter(parsedFrom)))
                  )
                  .sort(
                    (
                      {
                        id: lhsId,
                        parsedFrom: lhsFrom,
                        parsedTill: lhsTill,
                        is_fullday: lhsFullDay,
                      },
                      {
                        id: rhsId,
                        parsedFrom: rhsFrom,
                        parsedTill: rhsTill,
                        is_fullday: rhsFullDay,
                      }
                    ) => {
                      if (lhsFullDay) {
                        if (rhsFullDay) {
                          return lhsId - rhsId;
                        }

                        return -1;
                      }

                      if (rhsFullDay) {
                        return 1;
                      }

                      if (lhsFrom?.isValid() && rhsFrom?.isValid()) {
                        if (!lhsFrom?.isSame(rhsFrom)) {
                          return lhsFrom?.isBefore(rhsFrom) ? -1 : 1;
                        }

                        if (
                          lhsTill?.isValid() &&
                          rhsTill?.isValid() &&
                          !lhsTill?.isSame(rhsTill)
                        ) {
                          return lhsTill?.isBefore(rhsTill) ? -1 : 1;
                        }
                      }

                      return lhsId - rhsId;
                    }
                  )
              : [];
          });
      });

    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  get isBoat(): boolean {
    return this.type === boat;
  }

  get isGame(): boolean {
    return this.type === game;
  }

  get isPackage(): boolean {
    return this.type === packages;
  }

  get isFixedPrice(): boolean {
    return !!this.availability?.fix_price;
  }

  get isCustomSlot(): boolean {
    return this.hasCustomSlot && this.slotType === 'custom';
  }

  get hasCustomSlot(): boolean {
    return !!(
      (this.isBoat || this.isPackage) &&
      this.item?.custom_slot_available
    );
  }

  get isCruise(): boolean {
    return this.isBoat && this.item?.category?.sub_type?.includes(cruise);
  }

  get hasSelection(): boolean {
    return !(this.isCustomSlot ? !this.duration : !this.availability);
  }

  get hasCapacity(): boolean {
    return (
      this.hasSelection &&
      (!(this.isGame || this.isCruise) || this.selectedSeats > 0)
    );
  }

  get itemImage(): string {
    return (
      this.item?.media?.[0]?.original_url ||
      'assets/images/categories/item2.png'
    );
  }

  get hasExtraItems(): boolean {
    return !!(this.complementaryItems.length || this.extraItems.length);
  }

  checkAvailability(itemId: number, availability: AvailabilityModel): void {
    if (this.item?.is_booking_monster) {
      this.errorService.showErrorMessage(
        'Booking Monster items can not be changed'
      );
      return;
    }
    if (this.item?.category?.type !== packages) {
      if (availability?.is_fullday) {
        if (this.item.available_timings === ASSET_AVAILABILITY.PARK_TIMINGS) {
          if (!this.parkTimings.park_open) {
            this.errorService.showErrorMessage(
              this.translate.instant(marker('CategoryItem.UnAvailable'))
            );
            return;
          }
        }
      } else if (!this.available(availability?.from, availability?.till)) {
        this.errorService.showErrorMessage(
          this.translate.instant(marker('CategoryItem.UnAvailable'))
        );
        return;
      }
    }

    this.slotType = 'regular';
    this.availability = availability;

    this.availabilities.map((a) => (a.selected = false));
    availability.selected = true;

    this.selectedNoOfChild = 0;
    this.selectedNoOfAdult = 0;

    // set adult & child prices
    ({ ap: this.adultPrice, cp: this.childPrice } =
      this.getAdultAndChildPrice(availability));

    // calculations for Cruise Category
    if (this.isCruise || this.isGame) {
      this.updateCapacities();
      if (!this.seatOptions.length) {
        this.availability = null;
        this.errorService.showErrorMessage(marker('CategoryItem.NoSeats'));
        return;
      }
    }
  }

  private available(from: string, till: string): boolean {
    switch (this.item.available_timings) {
      case ASSET_AVAILABILITY.CUSTOM:
        const fromTime = moment(from, 'HH:mm:ss');
        const tillTime = moment(till, 'HH:mm:ss');
        if (
          fromTime.isSameOrAfter(moment(this.item?.from, 'HH:mm:ss')) &&
          fromTime.isSameOrBefore(moment(this.item?.till, 'HH:mm:ss')) &&
          tillTime.isSameOrAfter(moment(this.item?.from, 'HH:mm:ss')) &&
          tillTime.isSameOrBefore(moment(this.item?.till, 'HH:mm:ss'))
        ) {
          return true;
        } else {
          return false;
        }
      case ASSET_AVAILABILITY.SUNRISE_SUNSET:
        const fromTime1 = moment(from, 'HH:mm:ss');
        const tillTime1 = moment(till, 'HH:mm:ss');
        if (
          fromTime1.isSameOrAfter(moment(this.sunrise, 'HH:mm:ss')) &&
          fromTime1.isSameOrBefore(moment(this.sunset, 'HH:mm:ss')) &&
          tillTime1.isSameOrAfter(moment(this.sunrise, 'HH:mm:ss')) &&
          tillTime1.isSameOrBefore(moment(this.sunset, 'HH:mm:ss'))
        ) {
          return true;
        } else {
          return false;
        }
      case ASSET_AVAILABILITY.PARK_TIMINGS:
        const fromTime2 = moment(from, 'HH:mm:ss');
        const tillTime2 = moment(till, 'HH:mm:ss');
        if (
          fromTime2.isSameOrAfter(
            moment(this.parkTimings?.opening_time, 'HH:mm:ss')
          ) &&
          fromTime2.isSameOrBefore(
            moment(this.parkTimings?.closing_time, 'HH:mm:ss')
          ) &&
          tillTime2.isSameOrAfter(
            moment(this.parkTimings?.opening_time, 'HH:mm:ss')
          ) &&
          tillTime2.isSameOrBefore(
            moment(this.parkTimings?.closing_time, 'HH:mm:ss')
          )
        ) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  changeReservation(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.item) return;

    if (this.item?.seating_capacity == 1 || this.item?.persons_capacity === 1) {
      this.selectedNoOfAdult = this.adultOrChildren === 'adult' ? 1 : 0;
      this.selectedNoOfChild = this.adultOrChildren === 'adult' ? 0 : 1;
    }

    if (this.isGame || this.isPackage) {
      if (this.item?.persons_capacity! < this.selectedSeats) {
        this.errorService.showErrorMessage(
          marker('CategoryItem.SeatsUnavailable')
        );
        return;
      }
    } else {
      if (this.selectedNoOfAdult == 0 && this.selectedNoOfChild == 0) {
        this.errorService.showErrorMessage(marker('CategoryItem.SelectTicket'));
        return;
      }

      const totalPersons = this.selectedNoOfAdult + this.selectedNoOfChild;
      if (this.isCruise) {
        if (this.selectedSeats !== totalPersons) {
          this.errorService.showErrorMessage(
            marker('CategoryItem.IncorrectSeatCount')
          );
          return;
        }
      } else if (
        (this.item as BoatModel | BikeModel).seating_capacity < totalPersons
      ) {
        this.errorService.showErrorMessage(
          marker('CategoryItem.SeatsUnavailable')
        );
        return;
      }
    }

    const payload: TimeslotResevationModel = {
      date: this.confirmationDate || moment(new Date()).format(DEFAULT_DATE_FORMAT),
      item_id: this.item.id,
      item_type: this.type,
    };

    if (this.isCustomSlot) {
      if (!this.from && !this.duration) {
        this.errorService.showErrorMessage(marker('CategoryItem.SelectTime'));
        return;
      }
      payload.from_time = moment(this.from, 'HH:mm:ss').format('HH:mm:ss');
      payload.till_time = moment(this.from, 'HH:mm:ss')
        .add(this.duration, 'hours')
        // .add(this.isInt(this.duration) == true ? 0 : 30, 'minutes')
        .format('HH:mm:ss');

      if (
        !this.available(payload.from_time, payload.till_time) &&
        this.item?.category?.type !== packages
      ) {
        this.errorService.showErrorMessage(
          this.translate.instant(marker('CategoryItem.UnAvailable'))
        );
        return;
      }
    } else {
      if (!this.availability) {
        this.errorService.showErrorMessage(
          marker('CategoryItem.SelectTimeslot')
        );
        return;
      }
      payload.availability_id = this.availability.id;
    }

    if (this.isGame || this.isCruise) {
      payload.seats = this.selectedSeats;
    }

    switch (this.type) {
      case packages:
        this.managePackage();
        return;
      case game:
        this.manageGame();
        return;
      default:
        break;
    }
    this.manageBoatBooking(payload);
  }

  showAllergicItemDialog(ci: ComplementaryItemModel) {
    const modalRef = this.modalService.open(AllergicItemModalComponent, {
      centered: true,
    });

    // const seatingCapacity = this.item?.ava || item?.availability?.availabilityable?.seating_capacity;
    //
    // modalRef.componentInstance.item = ci;
    // modalRef.componentInstance.totalPerson = seatingCapacity;
    // modalRef.componentInstance.totalAllergicPersons =
    //   item.number_of_allergic_person;
    //
    // modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
    //   const index = item.complementaryItems.findIndex(
    //     (ii) => ii.id === receivedEntry.id
    //   );
    //   if (index !== -1) {
    //     item.complementaryItems[index].allergic_items = item.complementaryItems[
    //       index
    //     ].allergic_items.filter((item) =>
    //       receivedEntry.allergicItems?.includes(item.id)
    //     );
    //   }
    //   this.allergicItems.push(receivedEntry);
    // });
  }

  manageBoatBooking(payload: TimeslotResevationModel) {
    this.showLoader();
    this.bookingService.timeslotReservation(payload).subscribe(
      (res) => {
        const tickets =
          this.isCruise || this.isGame
            ? this.selectedSeats
            : this.selectedNoOfAdult + this.selectedNoOfChild;

        let finalResult: any = {};
        for (let i = 0; i < this.complementaryItems?.length!; i++) {
          const complementaryItems = {
            [this.complementaryItems![i]?.id]: {
              quantity: this.complementaryItems![i]?.quantity,
              allergic_items: this.complementaryItems[i].allergic_items?.length
                ? this.complementaryItems[i].allergic_items.map((ai) => ai.id)
                : this.allergicItems?.length
                ? this.allergicItems.find(
                    (ai) => ai.id == this.complementaryItems[i].id
                  )!?.allergic_items
                : [],
            },
          };
          finalResult = Object.assign(complementaryItems, finalResult);
        }

        let number_of_allergic_person = 0;
        for (const finalResultKey in finalResult) {
          number_of_allergic_person =
            number_of_allergic_person + finalResult[finalResultKey].quantity;
        }

        // set payload for extra items
        let extraItems = {};
        for (let i = 0; i < this.extraItems?.length!; i++) {
          const extraItem = {
            [this.extraItems![i]?.id]: {
              quantity: this.extraItems![i]?.pivot_quantity,
            },
          };
          extraItems = Object.assign(extraItem, extraItems);
        }

        const payload: BookingReservationModel = {
          availability_id: res?.data?.id,
          date: this.confirmationDate,
          item_id: res?.data?.availabilityable?.id,
          number_of_adults: this.selectedNoOfAdult,
          number_of_children: this.selectedNoOfChild,
          complementary_items: finalResult,
          extra_items: extraItems,
          number_of_allergic_person: number_of_allergic_person,
          source: 'online',
          seats: tickets,
          code: this.code,
          item_type: this.type,
        };

        payload.change_approved = false;

        this.bookingService
          .bookingReservation(payload)
          .pipe(finalize(() => this.hideLoader()))
          .subscribe(
            (res: any) => {
              const modalRef = this.modalService.open(
                MessageAlertModalComponent,
                {
                  centered: true,
                  size: 'sm',
                }
              );
              modalRef.componentInstance.message = res.message;
              modalRef.componentInstance.showButtons = true;

              modalRef.componentInstance.passEntry.subscribe(
                (receivedEntry: { confirm: boolean }) => {
                  if (receivedEntry.confirm) {
                    payload.change_approved = true;
                    this.bookingReservation(payload);
                  }
                }
              );
            },
            (error) => {
              this.errorService.handle(error);
            }
          );
      },
      (error) => {
        this.hideLoader();
        this.errorService.handle(error);
      }
    );
  }

  bookingReservation(payload: BookingReservationModel) {
    this.bookingService
      .bookingReservation(payload)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe({
        next: (res) => {
          this.modalService.dismissAll();
          this.router.navigate(['my-account', 'my-orders']);
        },
        error: (error: unknown) => {
          this.errorService.handle(error);
        },
      });
  }

  showComplementaryItemsModal() {
    const modalRef = this.modalService.open(ComplementaryItemsModalComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.id = this.availability?.availabilityable_id;
    modalRef.componentInstance.type = this.type;

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: ComplementaryItemModel) => {
        if (receivedEntry) {
          const index = this.complementaryItems.findIndex(
            (ei) => ei.id === receivedEntry.id
          );
          if (index !== -1) {
            this.complementaryItems[index].quantity = receivedEntry.quantity;
          } else {
            this.complementaryItems.push(receivedEntry);
          }
        }
      }
    );
  }

  showExtraItemModal() {
    const modalRef = this.modalService.open(ExtraItemsModalComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.id = this.availability?.availabilityable_id;
    modalRef.componentInstance.type = this.type;

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: ExtraItemModel) => {
        const index = this.extraItems.findIndex(
          (ei) => ei.id === receivedEntry.id
        );
        if (index !== -1) {
          this.extraItems[index].pivot_quantity = receivedEntry.pivot_quantity;
        } else {
          this.extraItems.push(receivedEntry);
        }
      }
    );
  }

  manageGame() {
    if (this.item?.persons_capacity == 1) {
      this.selectedNoOfAdult = this.adultOrChildren === 'adult' ? 1 : 0;
      this.selectedNoOfChild = this.adultOrChildren === 'adult' ? 0 : 1;
    }

    if (this.selectedNoOfAdult == 0 && this.selectedNoOfChild == 0) {
      this.errorService.showErrorMessage(marker('CategoryItem.SelectTicket'));
      return;
    }

    if (this.selectedNoOfAdult + this.selectedNoOfChild < this.selectedSeats) {
      this.errorService.showErrorMessage(marker('CategoryItem.PAXError'));
      return;
    }

    if (this.selectedSeats < this.selectedNoOfAdult + this.selectedNoOfChild) {
      this.errorService.showErrorMessage(
        marker('CategoryItem.SeatsUnavailable')
      );
      return;
    }

    this.showLoader();
    let finalResult: any = {};
    for (let i = 0; i < this.complementaryItems?.length!; i++) {
      const complementaryItems = {
        [this.complementaryItems![i]?.id]: {
          quantity: this.complementaryItems![i]?.quantity,
          allergic_items: this.complementaryItems[i].allergic_items?.length
            ? this.complementaryItems[i].allergic_items.map((ai) => ai.id)
            : this.allergicItems?.length
            ? this.allergicItems.find(
                (ai) => ai.id == this.complementaryItems[i].id
              )!?.allergic_items
            : [],
        },
      };
      finalResult = Object.assign(complementaryItems, finalResult);
    }

    let number_of_allergic_person = 0;
    for (const finalResultKey in finalResult) {
      number_of_allergic_person =
        number_of_allergic_person + finalResult[finalResultKey].quantity;
    }

    const payload: any = {
      type: game,
      availability_id: this.availability!.id,
      date: this.confirmationDate,
      number_of_allergic_person: 0,
      complementary_items: finalResult,
      complimentary_allergic_items_association: [],
      source: 'online',
      seats: this.selectedSeats,
      order_id: this.orderId,
      change_booking: true,
      number_of_adults: this.selectedNoOfAdult,
      number_of_children: this.selectedNoOfChild,
    };

    payload.change_approved = false;

    this.bookingService
      .updateGameBooking(this.code, payload)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res: any) => {
          const modalRef = this.modalService.open(MessageAlertModalComponent, {
            centered: true,
            size: 'sm',
          });
          modalRef.componentInstance.message = res.message;
          modalRef.componentInstance.showButtons = true;

          modalRef.componentInstance.passEntry.subscribe(
            (receivedEntry: { confirm: boolean }) => {
              if (receivedEntry.confirm) {
                payload.change_approved = true;
                this.gameBookingReservation(payload);
              }
            }
          );
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  gameBookingReservation(payload: any) {
    this.bookingService
      .updateGameBooking(this.code, payload)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe({
        next: (res) => {
          this.modalService.dismissAll();
          this.router.navigate(['my-account', 'my-orders']);
        },
        error: (error: unknown) => {
          this.errorService.handle(error);
        },
      });
  }

  managePackage() {
    const startTime = moment(this.from, 'HH:mm:ss').format('HH:mm:ss');
    const endTime = moment(this.from, 'HH:mm:ss')
      .add(this.duration, 'hours')
      // .add(this.isInt(this.duration) == true ? 0 : 30, 'minutes')
      .format('HH:mm:ss');

    // // start time and end time
    const duration = moment.duration(moment(endTime).diff(startTime));
    const hours = duration.asHours();

    if (this.item.hours_duration! <= hours) {
      this.errorService.showErrorMessage(marker('CategoryItem.HoursDuration'));
      return;
    }

    const price = this.item.price! + this.item?.category?.service_fee || 0;

    const payload = {
      type: this.type,
      availability_id: this.availability?.id,
      date: this.confirmationDate,
      from: this.isCustomSlot ? startTime : null,
      till: this.isCustomSlot ? endTime : null,
      source: 'online',
      price: price,
      code: this.code,
    };

    this.bookingService
      .packageBooking(this.clean(payload), this.item.id)
      .subscribe(
        (res) => {
          this.modalService.dismissAll();
          this.router.navigate(['my-account', 'my-orders']);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  updateCapacities() {
    let maxSeats =
      this.isGame || this.isPackage
        ? (this.item as GameModel | PackageModel).persons_capacity
        : (this.item as BoatModel | BikeModel).seating_capacity;

    if (
      (this.isCruise || this.isGame) &&
      this.availability?.reservations?.length
    ) {
      maxSeats -= this.availability.reservations.reduce((acc, curr) => {
        if (
          moment(curr.date).isSame(this.confirmationDate) &&
          curr.status !== BOOKING_STATUS_EXPIRED &&
          curr.status !== BOOKING_STATUS_CANCELLED
        ) {
          return acc + curr.seats;
        } else {
          return acc;
        }
      }, 0);
    }

    this.seatOptions = new Array(maxSeats).fill(0).map((_, idx) => idx + 1);

    if (this.selectedSeats > maxSeats) {
      this.selectedSeats = maxSeats;
    }

    const overflow =
      this.selectedNoOfAdult +
      this.selectedNoOfChild -
      (this.isCruise ? this.selectedSeats : maxSeats);
    if (overflow > 0) {
      if (this.selectedNoOfAdult > 0) {
        if (this.selectedNoOfChild > 0) {
          this.selectedNoOfAdult = Math.max(
            0,
            this.selectedNoOfAdult - Math.floor(overflow / 2)
          );
          this.selectedNoOfChild = Math.max(
            0,
            this.selectedNoOfChild - Math.ceil(overflow / 2)
          );
        } else {
          this.selectedNoOfAdult = Math.max(
            0,
            this.selectedNoOfAdult - overflow
          );
        }
      } else if (this.selectedNoOfChild > 0) {
        this.selectedNoOfChild = Math.max(0, this.selectedNoOfChild - overflow);
      }
    }

    const maxAdult =
      (this.isCruise || this.isGame ? this.selectedSeats : maxSeats) -
      this.selectedNoOfChild;
    const maxChild =
      (this.isCruise || this.isGame ? this.selectedSeats : maxSeats) -
      this.selectedNoOfAdult;

    this.adultOptions = new Array(maxAdult).fill(0).map((_, idx) => idx + 1);
    this.childOptions = new Array(maxChild).fill(0).map((_, idx) => idx + 1);
  }
}
