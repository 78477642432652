import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'br-cart-alert',
  templateUrl: './cart-alert.component.html',
  styleUrls: ['./cart-alert.component.scss'],
})
export class CartAlertComponent implements OnInit {
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 2000);
  }

  close() {
    this.modalService.dismissAll();
  }
}
