import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { BookingModel } from '../../models/booking.model';
import { BaseComponent } from '../../pages/base.component';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import {
  dealer,
  DEFAULT_DATE_FORMAT,
  user,
} from '../../utils/general-constants';
import { BookingService } from 'src/app/services/booking/booking.service';
import { TaxDetailModalComponent } from '../tax-detail-modal/tax-detail-modal.component';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import * as moment from 'moment';

@Component({
  selector: 'br-order-right-sidebar',
  templateUrl: './order-right-sidebar.component.html',
  styleUrls: ['./order-right-sidebar.component.scss'],
})
export class OrderRightSidebarComponent
  extends BaseComponent
  implements OnInit
{
  timeCounter!: string;
  locale: string = 'en';
  commissionAmount: number = 0;
  recurringDiscount: number = 0;

  @Input() total!: number;
  @Input() reservations!: Array<Array<BookingModel>>;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private storage: LocalStorage,
    private bookingService: BookingService,
    private modalService: NgbModal,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate);
    this.bookingService.timeCounter.subscribe((res) => {
      if (res) {
        this.timeCounter = res;
      }
    });
  }

  ngOnInit(): void {
    this.authService.loggedIn?.subscribe((res) => {
      if (!res) return;

      this.user$.subscribe((u) => {
        if (u) {
          if (u?.data?.role === user || u?.data?.role === 'staff_user') {
            this.getDiscountForUser();
          } else if (u?.data?.role === dealer) {
            this.commissionAmount = u?.data?.profile?.commission;
          }
        }
      });
    });

    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  getDiscountForUser() {
    this.bookingService
      .getRecurringDiscount({
        date: moment().format(DEFAULT_DATE_FORMAT),
      })
      .subscribe(
        (res) => {
          this.recurringDiscount = res?.discount_percentage;
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  viewTaxDetail(id: number) {
    const modalRef = this.modalService.open(TaxDetailModalComponent, {
      centered: true,
      size: 'xl',
    });

    modalRef.componentInstance.reservationId = id;
  }
}
