import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { BookingModel } from '../../../models/booking.model';
import { CountryInfoModal } from 'src/app/models/user.model';
import { COUNTRIES } from 'src/app/utils/countries.constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { CartService } from '../../../services/cart/cart.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { PhoneNumberValidator } from 'src/app/utils/phoneNumberValidator';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import { OrderModel } from '../../../models/cart.model';
import { ORDER_ID } from '../../../utils/general-constants';

@Component({
  selector: 'br-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent extends BaseComponent implements OnInit {
  form!: FormGroup;
  data: Array<Array<BookingModel>> = [];
  countries: Array<CountryInfoModal> = COUNTRIES;
  selectedCountry: CountryInfoModal = this.countries[0];
  order!: OrderModel;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private storage: LocalStorage,
    private errorService: ErrorHandlerService,
    private cartService: CartService
  ) {
    super(router, authService, userService, translate);
    storage.getItem(ORDER_ID).subscribe((res: any) => {
      this.list(res);
    });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      user_name: new FormControl(null, [Validators.required]),
      user_email: new FormControl(null, [
        Validators.email,
        Validators.required,
      ]),
      user_phone: new FormControl(null, [
        Validators.pattern('^[0-9]*$'),
        Validators.required,
        PhoneNumberValidator(this.selectedCountry.countryCode),
      ]),
      user_country: new FormControl(null, [Validators.required]),
      zip: new FormControl(null, [Validators.required]),
      note: new FormControl(null),
    });
    this.patchValues();
  }

  list(orderId: number) {
    this.showLoader();
    this.cartService
      .list(orderId)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.order = res.data;
          this.data = this.groupBy(res.data.reservations);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  patchValues() {
    this.authService.loggedIn?.subscribe((res) => {
      if (res === false) return;
      this.userService.getUpdatedUser().subscribe((res) => {
        if (!res?.data) return;
        this.form.patchValue({
          user_name: res?.data?.name,
          user_email: res?.data?.email,
          user_country: res?.data?.profile?.address,
          zip: res?.data?.profile?.zip_code,
        });
        if (res?.data?.profile?.phone?.includes('-')) {
          this.form.patchValue({
            user_phone: res?.data?.profile?.phone?.split('-')[1],
          });
          this.selectedCountry =
            this.countries.find(
              (c) => c?.phoneCode === res?.data?.profile?.phone?.split('-')[0]
            )! || this.countries[0];
        } else {
          this.form.patchValue({
            user_phone: res?.data?.profile?.phone,
          });
          this.selectedCountry =
            this.countries.find(
              (c) =>
                c?.phoneCode === '+' + res?.data?.profile?.country?.phone_code
            )! || this.countries[0];
        }
        this.form.controls.user_phone.setValidators([
          PhoneNumberValidator(this.selectedCountry?.countryCode),
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ]);
        this.form.controls.user_phone.updateValueAndValidity();
      });
    });
  }

  phoneChanged(value: string) {
    this.selectedCountry = this.countries.find((c) => c.phoneCode === value)!;
    this.form.get('user_phone')?.setValue(null);
    this.form.controls.user_phone.setValidators([
      PhoneNumberValidator(this.selectedCountry.countryCode),
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]);
    this.form.get('user_phone')?.updateValueAndValidity();
  }

  submit() {
    if (!this.form.valid) {
      this.errorService.formValidationError();
      return;
    }

    this.form.patchValue({
      user_phone:
        this.selectedCountry.phoneCode + '-' + this.form.value.user_phone,
    });

    this.storage.setItem('user', this.form.value).subscribe((res) => {
      this.router.navigate(['booking', 'payment']).catch((reason) => {
        console.warn(reason);
      });
    });
  }

  handleAddressChange(address: Address) {
    this.form.patchValue({
      user_country: address?.formatted_address,
    });
  }
}
