<!-- title + Grid View -->
<div
  class="d-flex flex-row align-items-center justify-content-between mb-4 flex-wrap"
>
  <button
    class="btn btn-link ps-lg-3 ps-0 text-dark text-decoration-none gap-3 d-flex align-items-center justify-content-start"
    (click)="back()"
  >
    <span class="br-icon-back"></span>
    {{ "Buttons.BackToDetailScreen" | translate }}
  </button>
  <!-- Pagination -->
  <div
    class="col-auto page-selection d-inline-flex flex-row align-items-center justify-content-start"
  >
    <h3 class="card-title fs-5 fw-bolder text-secondary m-0">
      {{ "ChangeBooking.Title" | translate }}
    </h3>
  </div>
</div>

<!--edit Booking-->
<div class="booking-with-wrapper rounded">
  <div
    class="d-flex row booking-card br-booking-header align-items-center justify-content-between border-bottom"
  >
    <div class="col col-1 p-0">
      <img
        [src]="
          booking.availability.availabilityable.media[0].original_url ||
          '../../../../assets/images/profile/default.svg'
        "
        class="rounded img-fluid"
      />
    </div>
    <div
      class="col-auto d-flex flex-column align-items-start justify-content-between me-5 ps-0"
    >
      <h3 class="fw-bolder">
        {{ getTranslatedData(booking?.availability?.availabilityable?.name!) }}
      </h3>
      <div class="d-flex align-items-end justify-content-start">
        <span class="text-black-50 pe-2 title-gray">
          {{ booking?.valid_until | date }}
        </span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-black-50 title-gray">{{
          "ChangeBooking.BookingNumber" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">{{ booking?.code }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-black-50 title-gray">
          {{ "ChangeBooking.From" | translate }}
        </span>
        <span class="text-black fw-bold detail-gray">
          {{ booking?.from || "NA" }}
        </span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-black-50 title-gray">
          {{ "ChangeBooking.Until" | translate }}
        </span>
        <span class="text-black fw-bold detail-gray">
          {{ booking?.till || "NA" }}
        </span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-black-50 title-gray">{{
          "ChangeBooking.Duration" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">
          {{ calculateHours(booking) }}
        </span>
      </div>
    </div>

    <div class="col col-auto pe-0" *ngIf="isBookingConfirmed">
      <button
        class="btn btn-link p-0 text-decoration-none text-info"
        (click)="showChangeBookingModal()"
      >
        {{ "Buttons.Change" | translate }}
      </button>
    </div>
  </div>

  <div class="row" *ngIf="booking?.status === 'HOLD' || canDealerEdit">
    <div class="col col-12">
      <!-- Section Body -->
      <div class="section-body">
        <div class="row justify-content-between">
          <div class="col-md-7 pe-4 ps-5">
            <!-- Complementary Items -->
            <ul class="mb-4">
              <li>
                <span class="fw-bolder">{{
                  "ChangeBooking.Complementaries" | translate
                }}</span>
                <span class="fw-bolder">{{
                  "ChangeBooking.UnitPrice" | translate
                }}</span>
              </li>

              <!-- complementary items -->
              <li *ngFor="let ci of booking?.complementaryItems">
                <div class="col d-flex align-items-center">
                  <span
                    class="red-rounder-close rounded-circle bg-danger text-white fs-6 lh-1 p-1 me-2"
                    (click)="removeComplementaryItem(ci)"
                  >
                    <i class="bi bi-x lh-1"></i>
                  </span>

                  <div
                    class="col edit-booking-select d-inline-flex flex-row align-items-center justify-content-start"
                  >
                    <select
                      class="form-select form-select-sm me-2"
                      [(ngModel)]="ci.quantity"
                    >
                      <option
                        *ngFor="let number of counter(100); let i = index"
                        value="{{ i + 1 }}"
                      >
                        {{ i + 1 }} x
                      </option>
                    </select>

                    <label class="form-label me-2 mb-0 text-dark text-13">
                      {{ ci?.title }}
                      <span class="text-black-50 fst-italic text-12">
                        {{ "ChangeBooking.PricePerPerson" | translate }}
                      </span>
                      <a
                        class="d-inline-block underline ps-2 text-info"
                        (click)="showAllergicItemDialog(ci, booking)"
                      >
                        {{ "Buttons.ViewEdit" | translate }}
                      </a>
                    </label>
                  </div>
                </div>
                <span>
                  {{ ci?.price | currency: "EUR":"symbol":"1.2-2":locale }}
                </span>
              </li>

              <button
                class="btn btn-primary btn-sm text-white px-3 py-2"
                (click)="showComplementaryItemModal()"
              >
                {{ "Buttons.AddMore" | translate }}
              </button>
            </ul>

            <!-- Extra Items -->
            <ul class="mb-4" *ngIf="isPackage">
              <li>
                <span class="fw-bolder">
                  {{ "ChangeBooking.ExtraItems" | translate }}
                </span>
                <span class="fw-bolder">
                  {{ "ChangeBooking.UnitPrice" | translate }}
                </span>
              </li>

              <li *ngFor="let ei of booking?.extraItems">
                <div class="col d-flex align-items-center">
                  <span
                    class="red-rounder-close rounded-circle bg-danger text-white fs-6 lh-1 p-1 me-2"
                    (click)="removeExtraItem(ei)"
                  >
                    <i class="bi bi-x lh-1"></i>
                  </span>

                  <div
                    class="col edit-booking-select d-inline-flex flex-row align-items-center justify-content-start"
                  >
                    <select
                      id="quantity"
                      class="form-select form-select-sm me-2"
                      [(ngModel)]="ei.pivot_quantity"
                    >
                      <option
                        *ngFor="
                          let number of counter(ei?.total_quantity!);
                          let i = index
                        "
                        value="{{ i + 1 }}"
                      >
                        {{ i + 1 }} x
                      </option>
                    </select>

                    <label class="form-label me-2 mb-0 text-dark text-13">
                      {{ ei.name }}
                      <span class="text-black-50 fst-italic text-12">
                        {{ "ChangeBooking.PricePerPerson" | translate }}
                      </span>
                    </label>
                  </div>
                </div>
                <span>{{
                  ei.price | currency: "EUR":"symbol":"1.2-2":locale
                }}</span>
              </li>

              <button
                class="btn btn-primary btn-sm text-white px-3 py-2"
                (click)="addExtraItems()"
              >
                {{ "Buttons.AddMore" | translate }}
              </button>
            </ul>

            <ul>
              <li>
                <span class="fw-bolder">
                  {{ "ChangeBooking.Details" | translate }}
                </span>
                <span class="fw-bolder">{{
                  "ChangeBooking.UnitPrice" | translate
                }}</span>
              </li>

              <li *ngIf="seatingCapacity > 1 && (isCruise || isGame)">
                <div class="col d-flex">
                  <div
                    class="col edit-booking-select d-inline-flex flex-row align-items-start justify-content-start"
                  >
                    <select
                      class="form-select form-select-sm me-2"
                      [(ngModel)]="selectedSeats"
                      (ngModelChange)="updateCapacities()"
                    >
                      <option
                        *ngFor="let value of seatOptions"
                        [ngValue]="value"
                      >
                        {{ value }}
                      </option>
                    </select>
                    <label class="form-label me-2 mb-0 text-dark text-13">
                      {{ "ChangeBooking.Seats" | translate }}
                    </label>
                  </div>
                </div>
              </li>

              <ng-container *ngIf="seatingCapacity > 1">
                <li>
                  <div class="col d-flex align-items-center">
                    <div
                      class="col edit-booking-select d-inline-flex flex-row align-items-center justify-content-start"
                    >
                      <select
                        class="form-select form-select-sm me-2"
                        [(ngModel)]="selectedNoOfAdult"
                        (ngModelChange)="updateCapacities()"
                      >
                        <option [ngValue]="0" [selected]="true">
                          {{ "ChangeBooking.Choose" | translate }}
                        </option>
                        <option
                          *ngFor="let value of adultOptions"
                          [ngValue]="value"
                        >
                          {{ value }}
                        </option>
                      </select>
                      <label class="form-label me-2 mb-0 text-dark text-13">
                        {{ "ChangeBooking.NumberOfAdults" | translate }}
                      </label>
                    </div>
                    <span *ngIf="!isFixedPrice">
                      {{ adultPrice | currency: "EUR":"symbol":"1.2-2":locale }}
                    </span>
                  </div>
                </li>

                <li>
                  <div class="col d-flex align-items-center">
                    <div
                      class="col edit-booking-select d-inline-flex flex-row align-items-center justify-content-start"
                    >
                      <select
                        class="form-select form-select-sm me-2"
                        [(ngModel)]="selectedNoOfChild"
                        (ngModelChange)="updateCapacities()"
                      >
                        <option [ngValue]="0" [selected]="true">
                          {{ "ChangeBooking.Choose" | translate }}
                        </option>
                        <option
                          *ngFor="let value of childOptions"
                          [ngValue]="value"
                        >
                          {{ value }}
                        </option>
                      </select>
                      <label class="form-label me-2 mb-0 text-dark text-13">
                        {{ "ChangeBooking.NumberOfChildren" | translate }}
                      </label>
                    </div>
                    <span *ngIf="!isFixedPrice">
                      {{ childPrice | currency: "EUR":"symbol":"1.2-2":locale }}
                    </span>
                  </div>
                </li>
              </ng-container>

              <ng-container *ngIf="seatingCapacity == 1">
                <li>
                  <div class="col d-flex">
                    <div
                      class="col edit-booking-select d-inline-flex flex-row align-items-center justify-content-start"
                    >
                      <input
                        class="me-2"
                        type="radio"
                        name="adults_or_children"
                        value="adult"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="adultOrChildren"
                      />
                      <label class="form-label me-2 mb-0 text-dark text-13">
                        {{ "ChangeBooking.Adults" | translate }}
                      </label>
                    </div>
                  </div>
                </li>

                <li *ngIf="seatingCapacity == 1">
                  <div class="col d-flex">
                    <div
                      class="col edit-booking-select d-inline-flex flex-row align-items-center justify-content-start"
                    >
                      <input
                        class="me-2"
                        type="radio"
                        value="children"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="adultOrChildren"
                      />
                      <label class="form-label me-2 mb-0 text-dark text-13">
                        {{ "ChangeBooking.Children" | translate }}
                      </label>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>

            <div class="mb-3">
              <label class="form-label text-black-50 fst-italic">
                {{ "ChangeBooking.Note" | translate }}
              </label>
              <textarea
                class="form-control"
                rows="3"
                [(ngModel)]="note"
              ></textarea>
            </div>
          </div>

          <div class="col-md-5 ps-md-4 ps-5 pe-4">
            <!-- Change Buttons -->
            <div class="d-flex gap-2">
              <button
                type="button"
                class="btn btn-info text-white btn-lg"
                (click)="updateBooking()"
              >
                {{ "Buttons.Update" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-danger btn-lg"
                [routerLink]="['/my-account', 'booking-detail', booking?.code]"
              >
                {{ "Buttons.Discard" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
