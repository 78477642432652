import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';

@Component({
  selector: 'br-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent implements OnInit {
  page!: PageModel;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private errorService: ErrorHandlerService
  ) {
    this.route.params.subscribe((res) => {
      this.getPageDetail(res?.slug);
    });
  }

  ngOnInit(): void {}

  getPageDetail(slug: string) {
    this.userService.showLoader.next(true);
    this.userService
      .getPageDetail({ slug: slug })
      .pipe(finalize(() => this.userService.showLoader.next(false)))
      .subscribe(
        (res) => {
          this.page = res?.data[0];
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }
}
