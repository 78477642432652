<!-- BreadCrumbs -->
<div class="container">
  <div class="row">
    <div class="col breadCrumbs-wrapper">
      <i class="bi bi-house-door" routerLink="/home"></i>
      <i class="bi bi-chevron-right"></i>
      <app-ng-dynamic-breadcrumb
        class="breadCrumb-link"
        [lastLinkColor]="'#000'"
        [symbol]="'>'"
      >
      </app-ng-dynamic-breadcrumb>
    </div>
  </div>
</div>
<!-- /BreadCrumbs -->

<div class="container Categories-wrapper">
  <div class="row border-bottom">
    <div class="col-12">
      <div
        class="d-flex align-items-start justify-content-between mb-2 titleBar"
      >
        <div
          class="d-flex flex-column align-items-start justifiy-content-between"
        >
          <h2 class="fw-bold text-secondary">
            {{ getTranslatedData(item?.name!) }}
          </h2>
          <span
            class="location text-primary d-flex align-items-start justify-content-start"
          >
            <i class="bi bi-geo-alt-fill me-1"></i>
            <span>{{ item?.location }}</span>
          </span>
        </div>
        <span *ngIf="isPackage" class="text-lg text-dark item-price fw-bold">
          {{ item?.price | currency: "EUR":"symbol":"1.2-2":locale }}
        </span>
        <span
          *ngIf="item?.price_per_hour"
          class="text-lg text-dark item-price fw-bold"
        >
          {{ item?.price_per_hour | currency: "EUR":"symbol":"1.2-2":locale }}
          <span>/hr</span>
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row py-5">
        <!-- Image Slider -->
        <div class="col-lg-6 col-12">
          <!-- When no Image is available -->
          <swiper
            [config]="config"
            [spaceBetween]="10"
            [navigation]="true"
            [zoom]="true"
            [thumbs]="{ swiper: thumbsSwiper }"
            class="details-page-swiper"
            *ngIf="!item?.media?.length"
          >
            <ng-template swiperSlide>
              <img
                class="img-fluid"
                src="https://swiperjs.com/demos/images/nature-3.jpg"
              />
            </ng-template>
          </swiper>

          <!-- When Image is available -->
          <swiper
            [config]="config"
            [spaceBetween]="10"
            [navigation]="true"
            [zoom]="true"
            [thumbs]="{ swiper: thumbsSwiper }"
            class="details-page-swiper"
            *ngIf="item?.media?.length! >= 1"
          >
            <ng-template swiperSlide *ngFor="let media of item?.media">
              <img class="img-fluid" [src]="media?.original_url" />
            </ng-template>
          </swiper>

          <swiper
            (swiper)="thumbsSwiper = $event"
            [spaceBetween]="20"
            [slidesPerView]="4"
            [freeMode]="true"
            [watchSlidesProgress]="true"
            class="swiper-thumbs"
            *ngIf="item?.media?.length! >= 1"
          >
            <ng-template swiperSlide *ngFor="let media of item?.media">
              <img class="img-fluid" [src]="media?.original_url" />
            </ng-template>
          </swiper>
        </div>

        <!-- item Description -->
        <div class="col-lg-6 col-12">
          <div class="descWrapper h-100">
            <h4>{{ "ItemDetail.Description" | translate }}</h4>
            <p
              class="fw-bold"
              [innerHtml]="getTranslatedData(item?.description!)"
            ></p>
            <h4>{{ getTranslatedData(item?.category?.name) }} Features</h4>
            <!-- Amenities -->
            <div class="amenities mb-3" *ngIf="hasAmenities">
              <ul class="list">
                <li class="list-item" *ngIf="item?.seating_capacity">
                  <i class="br-icon-person fs-4 me-1"></i>
                  {{ item?.seating_capacity }}
                </li>
                <li class="list-item" *ngIf="item?.persons_capacity">
                  <i class="br-icon-person fs-4 me-1"></i>
                  {{ item?.persons_capacity }}
                </li>
                <li class="list-item" *ngIf="item?.is_electric">
                  <i class="br-icon-battery fs-4 me-1"></i>
                  {{ item?.battery_capacity }} %
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row boat-pricings m-0">
    <!-- Selection row -->
    <div
      class="d-flex flex-column flex-md-row justify-content-center align-items-center border-bottom"
    >
      <div class="col-12 col-xl-3 col-lg-4 col-md-5 pe-md-3 mb-2 mb-md-0">
        <div
          class="mb-3 d-flex flex-column justify-content-center align-items-start align-items-md-center"
        >
          <label class="form-label fw-bold mb-2 fs-5">
            {{ "ItemDetail.SelectDate" | translate }}
          </label>
          <input
            type="date"
            class="form-control"
            [(ngModel)]="date"
            (change)="onDateChange($event)"
            [min]="minDate"
          />
        </div>
      </div>

      <!-- seats for cruise category -->
      <div
        class="col-12 col-xl-3 col-lg-4 col-md-5 pe-md-3 mb-2 mb-md-0"
        *ngIf="(isCruise || isGame) && hasSelection"
      >
        <div
          class="mb-3 d-flex flex-column justify-content-center align-items-start align-items-md-center"
        >
          <label class="form-label fw-bold mb-2 fs-5">
            {{
              isCruise
                ? ("ItemDetails.Seats" | translate)
                : ("ItemDetails.NoOfPersons" | translate)
            }}
          </label>
          <select
            class="form-select"
            [(ngModel)]="selectedSeats"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateCapacities()"
          >
            <option [ngValue]="0" [selected]="true">
              {{ "ItemDetail.ChooseHere" | translate }}
            </option>
            <option *ngFor="let item of seatOptions" [ngValue]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>

      <!-- for custom availability -->
      <div
        class="col-12 col-xl-3 col-lg-4 col-md-5 pe-md-3 mb-2 mb-md-0"
        *ngIf="isCustomSlot"
      >
        <div
          class="mb-3 d-flex flex-column justify-content-center align-items-start align-items-md-center"
        >
          <label class="form-label fw-bold mb-2 fs-5">
            {{ "ItemDetails.From" | translate }}
          </label>
          <input
            type="time"
            class="form-control"
            [(ngModel)]="from"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="disableToAndFrom"
          />
        </div>
      </div>

      <div
        class="col-12 col-xl-3 col-lg-4 col-md-5 pe-md-3 mb-2 mb-md-0"
        *ngIf="isCustomSlot"
      >
        <div
          class="mb-3 d-flex flex-column justify-content-center align-items-start align-items-md-center"
        >
          <label class="form-label fw-bold mb-2 fs-5">
            {{ "CategoryItem.Duration" | translate }}
          </label>
          <select
            class="form-select"
            [(ngModel)]="duration"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="disableToAndFrom"
          >
            <option *ngFor="let item of durationList" [ngValue]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Adult and Child -->
    <div
      class="d-flex flex-column flex-md-row justify-content-center align-items-center border-bottom"
      *ngIf="hasCapacity"
    >
      <div class="col-12 col-xl-3 col-lg-4 col-md-5 pe-md-3 mb-2 mb-md-0">
        <div
          class="mt-3 mb-3 d-flex flex-column justify-content-center align-items-start align-items-md-center"
          *ngIf="item?.seating_capacity! > 1 || item?.persons_capacity! > 1"
        >
          <label class="form-label fw-bold mb-2 fs-5">
            {{ "CategoryItem.Adults" | translate }}
          </label>
          <select
            class="form-select"
            [(ngModel)]="selectedNoOfAdult"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateCapacities()"
          >
            <option [ngValue]="0" [selected]="true">
              {{ "ItemDetail.ChooseHere" | translate }}
            </option>
            <option *ngFor="let item of adultOptions" [ngValue]="item">
              <ng-container
                *ngIf="isFixedPrice || isCustomSlot || isPackage; else variablePrice"
              >
                {{ item }}
              </ng-container>
              <ng-template #variablePrice>
                {{ item }} x
                {{ adultPrice | currency: "EUR":"symbol":"1.2-2":locale }} =
                {{
                  item * adultPrice | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </ng-template>
            </option>
          </select>
        </div>
      </div>

      <div
        class="col-12 col-xl-3 col-lg-4 col-md-5 pe-md-3 mb-2 mb-md-0"
        *ngIf="item?.seating_capacity! > 1 || item?.persons_capacity! > 1"
      >
        <div
          class="mt-3 mb-3 d-flex flex-column justify-content-center align-items-start align-items-md-center"
        >
          <label class="form-label fw-bold mb-2 fs-5">
            {{ "CategoryItem.Children" | translate }} 3-11
          </label>
          <select
            class="form-select"
            [(ngModel)]="selectedNoOfChild"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateCapacities()"
          >
            <option [ngValue]="0" [selected]="true">
              {{ "ItemDetail.ChooseHere" | translate }}
            </option>
            <option *ngFor="let item of childOptions" [ngValue]="item">
              <ng-container
                *ngIf="isFixedPrice || isCustomSlot || isPackage; else variablePrice"
              >
                {{ item }}
              </ng-container>
              <ng-template #variablePrice>
                {{ item }} x
                {{ childPrice | currency: "EUR":"symbol":"1.2-2":locale }} =
                {{
                  item * childPrice | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </ng-template>
            </option>
          </select>
        </div>
      </div>

      <div
        class="col-12 col-xl-3 col-lg-4 col-md-5 pe-md-3 mb-2 mb-md-0"
        *ngIf="item?.seating_capacity == 1 || item?.persons_capacity! == 1"
      >
        <div
          class="mt-3 mb-3 d-flex justify-content-center align-items-start align-items-md-center"
        >
          <input
            class="me-2"
            type="radio"
            name="adults_or_children"
            [(ngModel)]="adultOrChildren"
            value="adult"
          />
          <label class="form-label fw-bold fs-5 mb-0">
            {{ "CategoryItem.Adults" | translate }}
          </label>
        </div>
      </div>

      <div
        class="col-12 col-xl-3 col-lg-4 col-md-5 pe-md-3 mb-2 mb-md-0"
        *ngIf="item?.seating_capacity == 1 || item?.persons_capacity! == 1"
      >
        <div
          class="mt-3 mb-3 d-flex justify-content-center align-items-start align-items-md-center"
        >
          <input
            class="me-2"
            type="radio"
            name="adults_or_children"
            [(ngModel)]="adultOrChildren"
            value="children"
          />
          <label class="form-label fw-bold fs-5 mb-0">
            {{ "CategoryItem.Children" | translate }} 3-11
          </label>
        </div>
      </div>
    </div>

    <!-- Price and Reservations -->
    <div class="col-12 py-4 px-0">
      <h4>{{ "ItemDetail.PriceAndReservations" | translate }}</h4>
      <!-- item -->
      <div class="row gx-xl-5 gx-lg-2 gx-1 mb-4">
        <div class="col-3" *ngFor="let availability of availabilities">
          <div class="item mb-3">
            <div
              class="item-inner"
              (click)="checkAvailability(availability)"
              [ngClass]="availability?.selected ? 'active' : ''"
              [ngStyle]="{
                'justify-content':
                  availability?.fix_price == 1 ? 'space-between' : 'center'
              }"
            >
              <span class="time">{{ getAvailabilityTitle(availability) }}</span>
              <span class="price" *ngIf="availability?.fix_price == 1">
                {{
                  availability?.price | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </span>
            </div>
          </div>
        </div>
        <!-- Custom Availability for Boat -->
        <div class="col-3" *ngIf="hasCustomSlot">
          <div class="item">
            <div
              class="item-inner"
              [ngStyle]="{ 'justify-content': 'center' }"
              [ngClass]="isCustomSlot ? 'active' : ''"
              (click)="toggleSlotType($event, 'custom')"
            >
              <span class="time">{{ "ItemDetail.Future" | translate }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-12 text-end">
          <button
            class="btn btn-primary text-white fw-bold"
            (click)="addToCart($event)"
            *ngIf="hasCustomSlot || availabilities?.length"
          >
            {{ "Buttons.MakeReservation" | translate }}
          </button>
        </div>
        <br-no-record-found
          *ngIf="!item?.availabilties?.length"
        ></br-no-record-found>
      </div>
    </div>
  </div>
</div>
