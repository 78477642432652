<!-- Right -->
<h3 class="card-title mb-3">
  {{ "MyBooking.OrderDetails" | translate }}
</h3>
<!-- Booking tier -->
<div class="booking-with-wrapper rounded mb-5">
  <div
    class="d-flex row booking-card br-booking-header align-items-center justify-content-between border-bottom"
  >
    <div class="col-4 p-0 d-flex align-items-center justify-content-start">
      <!--      <img-->
      <!--        src="../../../../assets/images/1-intro-photo-final.jpg"-->
      <!--        class="rounded img-fluid tier-img"-->
      <!--        alt=""-->
      <!--      />-->

      <div
        class="d-flex flex-column align-items-start justify-content-center me-3"
      >
        <h3 class="fw-bolder">{{ orderDetail?.code }}</h3>
        <div class="d-flex align-items-end justify-content-start">
          <span
            class="badge px-3"
            [ngClass]="setStatus(orderDetail.status.toUpperCase()!)"
            >{{ orderDetail?.status | uppercase }}</span
          >
        </div>
      </div>
    </div>

    <!-- <div class="col-8 p-0">
      <div class="row gy-1 justify-content-end"> -->

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-black-50 title-gray">
          {{ "MyBooking.CreatedDate" | translate }}
        </span>
        <span class="text-black fw-bold detail-gray">{{
          orderDetail?.created_at | date
        }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-black-50 title-gray">{{
          "MyBooking.BookingNumber" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">{{
          orderDetail?.code
        }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-black-50 title-gray">
          {{ "MyBooking.Source" | translate }}
        </span>
        <span class="text-black fw-bold detail-gray">{{
          orderDetail?.source
        }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-end justify-content-center">
        <span class="text-black-50 title-gray">{{
          "MyBooking.TotalAmount" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">{{
          orderDetail?.price | currency: "EUR":"symbol":"1.2-2":locale
        }}</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col col-12">
      <!-- Section Body -->
      <div class="section-body">
        <div class="row justify-content-between">
          <div class="col-md-12">
            <ul class="ps-4 pe-7">
              <li class="pe-3">
                <span class="fw-bolder">
                  {{ "BookingDetail.SubTotal" | translate }}
                </span>
                <span class="fw-bolder">
                  {{
                    orderDetail?.subtotal
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </li>
            </ul>

            <ul class="bg-light ps-4 pt-3 pe-7 pb-2 mb-3">
              <li class="pe-3">
                <span>{{ "MyBooking.Discount" | translate }}</span>
                <span>
                  {{
                    orderDetail?.total_discounts
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </li>
            </ul>

            <ul class="ps-4 pe-7">
              <li class="pe-3">
                <span class="fw-bolder">
                  {{ "MyBooking.TotalAmount" | translate }}:
                </span>
                <span class="fw-bolder">
                  {{
                    orderDetail?.price | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="Booking-wrapper ps-2">
  <!-- title + Grid View -->
  <div class="d-flex flex-row align-items-center justify-content-between mb-4">
    <h3 class="card-title">{{ "MyBooking.Title" | translate }}</h3>
    <!-- Pagination -->
    <div
      class="col-md-3 col-6 page-selection d-inline-flex flex-row align-items-center justify-content-start"
    >
      <label class="form-label me-2 mb-0 text-gray-600">
        {{ "MyBooking.Filter" | translate }}:
      </label>
      <select
        class="form-select form-select-sm"
        (change)="statusChanged($event)"
      >
        <option *ngFor="let item of statuses" [value]="item.value">
          {{ item.name | translate }}
        </option>
      </select>
    </div>
  </div>

  <!-- Booking tier-->
  <div
    class="row booking-card br-booking-header align-items-start justify-content-between px-3 rounded"
    *ngFor="let booking of bookings"
    (click)="routeToBooking(booking)"
  >
    <div class="col-3 p-0 d-flex align-items-center justify-content-star">
      <img
        [src]="
          booking.availability.availabilityable.media[0].original_url ||
          '../../../../assets/images/profile/default.svg'
        "
        class="rounded img-fluid tier-img"
        alt=""
      />
      <div
        class="d-flex flex-column align-items-start justify-content-center me-5"
      >
        <h3 class="fw-bolder">
          {{
            getTranslatedData(booking?.availability?.availabilityable?.name!)
          }}
        </h3>
        <div class="d-flex align-items-end justify-content-start">
          <span class="badge px-3" [ngClass]="setStatus(booking?.status!)">
            {{ booking?.status }}
          </span>
        </div>
      </div>
    </div>

    <div class="col-7 p-0">
      <div class="row gy-1">
        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-gray-600 title-gray">{{
              "MyBooking.BookingNumber" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.code
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-gray-600 title-gray">{{
              "MyBooking.CreatedDate" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.created_at | date
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-gray-600 title-gray">{{
              "PaymentComplete.BookingDate" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.date | date
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-gray-600 title-gray">{{
              "MyBooking.From" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.from || "NA"
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-gray-600 title-gray">{{
              "MyBooking.Until" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              booking?.till || "NA"
            }}</span>
          </div>
        </div>

        <div class="col col-auto">
          <div
            class="d-flex flex-column align-items-start justify-content-center"
          >
            <span class="text-gray-600 title-gray">{{
              "MyBooking.Duration" | translate
            }}</span>
            <span class="text-black fw-bold detail-gray">{{
              calculateHours(booking)
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-2 col-auto d-flex align-items-start justify-content-end pe-0"
    >
      <div class="d-flex flex-column align-items-end justify-content-center">
        <span class="text-gray-600 title-gray">{{
          "MyBooking.TotalAmount" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">{{
          booking?.price | currency: "EUR":"symbol":"1.2-2":locale
        }}</span>
      </div>
      <!--      <div class="pt-2">-->
      <!--        <button class="btn btn-link p-0 text-decoration-none">-->
      <!--          <span class="br-icon-info text-gray-600 fs-4"></span>-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</div>

<br-no-record-found *ngIf="!bookings?.length && !loading"></br-no-record-found>
<!-- /Right -->
