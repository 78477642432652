import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'br-register-dealer',
  templateUrl: './register-dealer.component.html',
  styleUrls: ['./register-dealer.component.scss']
})
export class RegisterDealerComponent extends BaseComponent implements OnInit {

  form!: FormGroup;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private errorService: ErrorHandlerService
  ) {
    super(router, authService, userService, translate)
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      full_name: new FormControl(null, Validators.compose([
        Validators.required
      ])),
      email: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.email
      ])),
      phone: new FormControl(null, [
        Validators.required
      ]),
      description: new FormControl(null)
    });
  }

  register(): void {
    Object.keys(this.form.controls).forEach(key =>
      this.form.get(key)?.markAsTouched()
    );

    if (this.form.invalid) {
      this.errorService.formValidationError();
      return;
    }

    this.showLoader();

    this.authService.registerDealer(this.form.value).pipe(
      finalize(() => this.hideLoader())
    ).subscribe((res: { message: string }) => {
      this.form.reset();
      this.errorService.showToast(res.message, 'success');
      this.router.navigate(['verify-email']);
    }, error => {
      this.errorService.handle(error);
    });
  }

}
