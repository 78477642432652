<!-- BreadCrumbs -->
<div class="container">
  <div class="row">
    <div class="col breadCrumbs-wrapper">
      <i class="bi bi-house-door" routerLink="/home"></i>
      <i class="bi bi-chevron-right"></i>
      <app-ng-dynamic-breadcrumb
        class="breadCrumb-link"
        [lastLinkColor]="'#000'"
        [symbol]="'>'"
      >
      </app-ng-dynamic-breadcrumb>
    </div>
  </div>
</div>
<!-- /BreadCrumbs -->

<div class="container my-account-wrapper">
  <div class="row">
    <!-- Left Tabs -->
    <div class="col-12 col-lg-3 mb-5 mb-lg-0">
      <div class="left-tabs">
        <button
          [routerLink]="['/my-account', 'my-profile']"
          routerLinkActive="active"
        >
          <span class="br-icon-my-profile-active"></span>
          {{ "Buttons.MyProfile" | translate }}
        </button>
        <button
          [routerLink]="['/my-account', 'my-orders']"
          routerLinkActive="active"
        >
          <span class="br-icon-my-booking-active"></span>
          {{ "Buttons.MyOrders" | translate }}
        </button>
        <button
          [routerLink]="['/my-account', 'my-payments']"
          routerLinkActive="active"
          *ngIf="userService.user?.data?.role !== 'dealer'"
        >
          <span class="br-icon-my-payment-active"></span>
          {{ "Buttons.MyPayments" | translate }}
        </button>
      </div>
    </div>
    <!-- /Left Tabs-->

    <div class="col-lg-9 col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
