<div class="container booking-wrapper">
  <div class="row">
    <!-- title + Steps-->
    <div
      class="title-and-steps flex-row align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center justify-content-start">
        <ul class="active-step">
          <li
            class="bg-success border-0"
            routerLink="/booking/personal-details"
          >
            <span class="lh-1"><i class="br-icon-check"></i></span>
          </li>
          <li>
            <span>2</span>
          </li>
        </ul>
        <h4>{{ "Payment.Title" | translate }}</h4>
      </div>
      <ul class="inactive-steps">
        <li>
          <span>3</span>
        </li>
      </ul>
    </div>
    <!-- /Title + Steps-->
  </div>

  <div class="row">
    <!-- left -->
    <div class="col-xl-9 col-lg-8 col-12 p-0 pe-md-2">
      <div class="blue-header-section">
        <!-- Section Header -->
        <div
          class="section-header py-4 px-4 d-flex flex-row align-items-center justify-content-between"
        >
          <h4 class="mb-0">{{ "Payment.Title" | translate }}</h4>
        </div>

        <!-- Section Body -->
        <div class="section-body p-0 pt-4">
          <!-- header -->
          <ul class="row table-row color-primary">
            <li class="col-4">{{ "Payment.OrderOverview" | translate }}</li>
            <li class="col justify-content-center">
              {{ "Payment.Total" | translate }}
            </li>
            <li class="col-2 justify-content-center">
              {{ "Payment.Tickets" | translate }}
            </li>
            <li class="col justify-content-center">
              {{ "Payment.From" | translate }}
            </li>
            <li class="col justify-content-center">
              {{ "Payment.Until" | translate }}
            </li>
            <li class="col justify-content-end">
              {{ "Payment.Duration" | translate }}
            </li>
          </ul>

          <!-- row -->
          <ul class="row table-row" *ngFor="let item of reservations">
            <li class="col-4 justify-content-start fw-bolder">
              {{
                getTranslatedData(item?.availability?.availabilityable?.name!)
              }}
            </li>

            <li class="col justify-content-center">
              <ng-container
                *ngIf="
                  item?.availability?.availabilityable?.category?.type ===
                    'package';
                  else otherItems
                "
              >
                <span>
                  {{
                    item?.availability?.availabilityable?.price
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </ng-container>

              <ng-template #otherItems>
                <span *ngIf="item?.availability?.fix_price == true">
                  {{
                    calculateAvailabilityPrice(item)
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </span>
              </ng-template>
            </li>

            <li class="col-2 justify-content-center">
              {{
                item?.availability?.availabilityable?.category?.type ===
                "package"
                  ? "-"
                  : item?.seats
                  ? item.seats
                  : item.number_of_children + item.number_of_adults
              }}
            </li>
            <li class="col justify-content-center">
              {{
                item.availability.is_fullday
                  ? "-"
                  : getFromUntil(item.availability.from)
              }}
            </li>
            <li class="col justify-content-center">
              {{
                item.availability.is_fullday
                  ? "-"
                  : getFromUntil(item.availability.till)
              }}
            </li>
            <li class="col justify-content-end">{{ calculateHours(item) }}</li>

            <ng-container
              *ngIf="
                item?.availability?.availabilityable?.category?.type !==
                'package'
              "
            >
              <!-- adults -->
              <ul
                class="row complementary-row"
                *ngIf="item?.availability?.fix_price === false"
              >
                <li class="col-4 justify-content-start">
                  {{ item.number_of_adults }} x<span class="ms-4">{{
                    "Cart.Adults" | translate
                  }}</span>
                </li>
                <li class="col justify-content-center">
                  {{
                    item.number_of_adults! *
                      (item?.availability?.variable_price!
                        ? item?.availability?.adult_price!
                        : item?.availability?.price_per_person!)
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </li>
                <li class="col-2 justify-content-center"></li>
                <li class="col justify-content-center"></li>
                <li class="col justify-content-center"></li>
                <li class="col justify-content-end"></li>
              </ul>

              <!-- children -->
              <ul
                class="row complementary-row"
                *ngIf="item?.availability?.fix_price === false"
              >
                <li class="col-4 justify-content-start">
                  {{ item.number_of_children }} x<span class="ms-4">
                    {{ "Cart.Children" | translate }}
                  </span>
                </li>
                <li class="col justify-content-center">
                  {{
                    item.number_of_children! *
                      (item?.availability?.variable_price!
                        ? item?.availability?.child_price!
                        : item?.availability?.price_per_person!)
                      | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </li>
                <li class="col-2 justify-content-center"></li>
                <li class="col justify-content-center"></li>
                <li class="col justify-content-center"></li>
                <li class="col justify-content-end"></li>
              </ul>
            </ng-container>

            <!-- complementary items -->
            <ul
              class="row complementary-row"
              *ngFor="let ci of item.complementaryItems"
            >
              <li class="col-4 justify-content-start">
                {{ ci.quantity }} x<span class="ms-4">{{ ci.title }}</span>
              </li>
              <li class="col justify-content-center">
                {{
                  ci.quantity * ci.price
                    | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </li>
              <li class="col-2 justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-end"></li>
            </ul>

            <!-- extra items -->
            <ul
              class="row complementary-row"
              *ngFor="let ei of item.extraItems"
            >
              <li class="col-4 justify-content-start">
                {{ ei.pivot_quantity }} x<span class="ms-4">{{ ei.name }}</span>
              </li>
              <li class="col justify-content-center">
                {{
                  ei.pivot_quantity * ei.price
                    | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </li>
              <li class="col-2 justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-end"></li>
            </ul>

            <!-- sub total -->
            <ul class="row complementary-row">
              <li class="col-4 justify-content-start fw-bold">
                {{ "Payment.SubTotal" | translate }}
              </li>
              <li class="col justify-content-center fw-bold">
                {{
                  reservationSubTotal(item!, false)
                    | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </li>
              <li class="col-2 justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-end"></li>
            </ul>

            <!-- tax detail -->
            <ul class="row complementary-row">
              <li class="col-4 justify-content-start">
                {{ "Payment.TaxAmount" | translate }}
              </li>
              <li
                class="col justify-content-center text-info"
                (click)="viewTaxDetail(item?.id!)"
              >
                {{ "Payment.View" | translate }}
              </li>
              <li class="col-2 justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-end"></li>
            </ul>

            <!-- voucher -->
            <ul class="row complementary-row" *ngIf="item.promotion?.active">
              <li class="col-4 justify-content-start">
                {{ "Payment.VoucherDiscount" | translate }}
              </li>
              <li class="col justify-content-center">
                {{
                  item?.promotion?.type! === "FIXED AMOUNT"
                    ? (-1 * item?.promotion?.amount!
                      | currency: "EUR":"symbol":"1.2-2":locale)
                    : item?.promotion?.amount + "%"
                }}
              </li>
              <li class="col-2 justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-end"></li>
            </ul>

            <!-- service fee -->
            <ul
              class="row complementary-row"
              *ngIf="
                item?.availability?.availabilityable?.category?.service_fee! > 0
              "
            >
              <li class="col-4 justify-content-start">
                {{ "Payment.ServiceFee" | translate }}
              </li>
              <li class="col justify-content-center">
                {{
                  item.availability?.availabilityable?.category?.service_fee
                    | currency: "EUR":"symbol":"1.2-2":locale
                }}
              </li>
              <li class="col-2 justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-end"></li>
            </ul>

            <!-- total -->
            <ul class="row complementary-row">
              <li class="col-4 justify-content-start fw-bold">
                {{ "Payment.Total" | translate }}
              </li>
              <li class="col justify-content-center fw-bold">
                {{ item?.price! | currency: "EUR":"symbol":"1.2-2":locale }}
              </li>
              <li class="col-2 justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-center"></li>
              <li class="col justify-content-end"></li>
            </ul>

            <li class="col-12 w-100">
              <hr class="col-12 w-100 m-0" />
            </li>
          </ul>

          <!-- Total -->
          <ul class="row table-row">
            <li class="col-4 justify-content-start fw-bold item-heading-color">
              {{ "Complementaries.Table.TotalAmount" | translate }}
            </li>
            <li class="col justify-content-center fw-bold item-heading-color">
              {{ total | currency: "EUR":"symbol":"1.2-2":locale }}
            </li>
            <li class="col-2"></li>
            <li class="col"></li>
            <li class="col"></li>
            <li class="col"></li>
          </ul>
          <!-- Continue -->
        </div>

        <div class="d-flex justify-content-end py-3 pe-3">
          <div class="col-3 d-flex justify-content-end">
            <button
              class="btn btn-info text-white btn-lg"
              routerLink="/booking/review-terms"
            >
              {{ "Buttons.ContinueToPay" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Right -->
    <div class="col-xl-3 col-lg-4 col-12 p-0 ps-md-2">
      <div class="blue-header-section border order-details">
        <br-order-right-sidebar
          *ngIf="data?.length"
          [reservations]="data"
          [total]="total"
        ></br-order-right-sidebar>
      </div>
    </div>
  </div>
</div>
