import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from './../../pages/base.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'br-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent
  extends BaseComponent
  implements OnInit
{
  form!: FormGroup;
  password = new FormControl(
    null,
    Validators.compose([Validators.required, Validators.minLength(8)])
  );

  passwordConfirmation = new FormControl(null, [
    Validators.required,
    (control: AbstractControl) => {
      if (this.password.valid && control.value !== this.password.value) {
        return { confirmed: true };
      }
      return null;
    },
  ]);

  constructor(
    router: Router,
    authService: AuthService,
    userService: UserService,
    translate: TranslateService,
    private errorService: ErrorHandlerService,
    private modalService: NgbModal
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      old_password: new FormControl(null, [Validators.required]),
      password: this.password,
      password_confirmation: this.passwordConfirmation,
    });
  }

  changePassword(): void {
    this.userService.changePassword(this.form.value).subscribe(
      (res) => {
        this.errorService.showErrorMessage(
          marker('ChangePassword.UpdateSuccess'),
          'success'
        );
        this.modalService.dismissAll();
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }
}
