import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class ErrorHandlerService {
  private notifier: NotifierService;

  constructor(
    notifierService: NotifierService,
    private translate: TranslateService,
  ) {
    this.notifier = notifierService;
  }

  formValidationError() {
    return this.translate
      .get('Errors.Validation.Message')
      .subscribe(message => this.notifier.notify('error', message));
  }

  handle(error: any): void {
    if (error instanceof HttpErrorResponse) {
      if (error.status < 100) {
        this.networkError();
        return;
      }

      if (error.status === 401) {
        this.translate.get('Errors.Unauthenticated').subscribe(message => {
          if (typeof error.error === 'object' && error.error.error) {
            this.showToast(error.error.error);
          } else {
            this.showToast(message);
          }
        });
        return;
      }

      if (error.status === 403) {
        if (typeof error.error === 'object' && error.error.message) {
          this.notifier.notify('error', error.error.message);
        } else {
          this.forbiddenError();
        }
        return;
      }

      if (error.status === 404) {
        if (typeof error.error === 'object' && error.error.message) {
          this.notifier.notify('error', error.error.message);
        } else {
          this.notFoundError();
        }
        return;
      }

      if (error.status === 422) {
        this.validationError(error);
        return;
      }

      if (error.status === 429) {
        this.throttleError();
        return;
      }

      if (typeof error.error === 'object' && error.error.error) {
        this.notifier.notify('error', error.error.error);
        return;
      }

      if (typeof error.error === 'object' && error.error.message) {
        this.notifier.notify('error', error.error.message);
        return;
      }

      this.translate
        .get('Errors.Server')
        .subscribe(message => this.notifier.notify('error', message));
      return;
    }

    this.translate
      .get('Errors.Generic')
      .subscribe(message => this.notifier.notify('error', message));
    return;
  }

  handleUnknownError() {
    this.translate
      .get('Errors.Unknown')
      .subscribe(message => this.notifier.notify('error', message));
  }

  handleReaderError(error: any): void {
    this.translate.get('Errors.Reader.Unknown')
      .subscribe(message => this.notifier.notify('error', message));
  }

  networkError(): void {
    this.translate.get('Errors.Network')
      .subscribe(message => this.notifier.notify('error', message));
  }

  authenticationError(): void {
    this.translate.get('Errors.Unauthenticated')
      .subscribe(message => this.notifier.notify('error', message));
  }

  forbiddenError(): void {
    this.translate.get('Errors.Forbidden')
      .subscribe(message => this.notifier.notify('error', message));
  }

  notFoundError(): void {
    this.translate.get('Errors.NotFound')
      .subscribe(message => this.notifier.notify('error', message));
  }

  throttleError(): void {
    this.translate.get('Errors.Throttle')
      .subscribe(message => this.notifier.notify('error', message));
  }

  validationError(error?: any): void {
    if (error) {
      if (typeof error === 'string') {
        this.translate.get('Errors.Validation.Title', { cause: error })
          .subscribe(message => this.notifier.notify('error', message));

        return;
      }

      if (error instanceof HttpErrorResponse) {
        if (typeof error.error === 'string') {
          this.translate.get('Errors.Validation.Title', { cause: error.error })
            .subscribe(message => this.notifier.notify('error', message));

          return;
        }

        if (typeof error.error === 'object') {
          if ('errors' in error.error) {
            const keys = Object.keys(error.error.errors);
            if (keys.length) {
              const value = keys
                .map(key => error.error.errors[key])
                .map(valueToMap => Array.isArray(valueToMap) ? valueToMap[0] : valueToMap)
                .filter(valueToFilter => valueToFilter && typeof valueToFilter === 'string')
                .shift();

              if (value) {
                this.translate.get('Errors.Validation.Title', { cause: value })
                  .subscribe(message => this.notifier.notify('error', message));
                return;
              }
            }
          }

          if ('message' in error.error) {
            this.translate.get('Errors.Validation.Title', { cause: error.error.message })
              .subscribe(message => this.notifier.notify('error', message));

            return;
          }
        }

        this.translate.get('Errors.ServerValidation')
          .subscribe(message => this.notifier.notify('error', message));

        return;
      }

      if (error instanceof Error && error.message) {
        this.translate.get(error.message)
          .pipe(
            switchMap(message => this.translate.get('Errors.Validation.Title', { cause: message }))
          )
          .subscribe(message => this.notifier.notify('error', message));

        return;
      }
    }

    this.translate.get('Errors.GenericValidation')
      .subscribe(message => this.notifier.notify('error', message));
  }

  showToast(message: string, type: string = 'error') {
    this.notifier.notify(type, message);
  }

  showErrorMessage(key: string, type: string = 'error') {
    this.translate.get(key).subscribe(message => {
      this.notifier.notify(type, message);
    });
  }
}
