import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import {
  DEFAULT_DATE_FORMAT,
  TIME_COUNTER,
} from 'src/app/utils/general-constants';
import { BookingService } from '../../services/booking/booking.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import { PaginationService } from '../../services/pagination/pagination.service';
import {
  CategoryDetailModel,
  CategoryParamsModel,
  BoatModel,
  ParkTimingModel,
  SunInfoModel,
} from 'src/app/models/booking.model';
import {
  TranslationModel,
  BikeModel,
  ServiceModel,
  GameModel,
  PackageModel,
} from '../../models/booking.model';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'br-category-listing',
  templateUrl: './category-listing.component.html',
  styleUrls: ['./category-listing.component.scss'],
})
export class CategoryListingComponent extends BaseComponent implements OnInit {
  innerWidth: number;
  listActive: boolean = true;
  gridActive: boolean = false;
  showMoreBoats: boolean = true;
  showMoreOthers: boolean = true;
  showMoreCategories: boolean = true;

  type!: string;
  pager: any = {};
  categoryId: number;
  pageSize: number = 5;
  selectedDate!: string;
  params!: CategoryParamsModel;
  categoryName!: TranslationModel;
  categoryDetail!: CategoryDetailModel;
  minDate = moment(new Date()).format(DEFAULT_DATE_FORMAT);

  allData: Array<
    BoatModel | BikeModel | ServiceModel | GameModel | PackageModel
  > = [];
  pagedData: Array<
    BoatModel | BikeModel | ServiceModel | GameModel | PackageModel
  > = [];
  sunDuration: Array<number> = [];
  timeCounter!: string;
  parkTimings!: ParkTimingModel;
  sunInfo!: SunInfoModel;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService,
    private paginationService: PaginationService,
    private storage: LocalStorage
  ) {
    super(router, authService, userService, translate);
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 992) {
      this.gridActive = true;
      this.listActive = false;
      this.showMoreBoats = false;
      this.showMoreOthers = false;
      this.showMoreCategories = false;
    }
    this.categoryId = parseInt(this.route.snapshot.params.id);
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.selectedDate = navigation.extras.state.params.date;
    } else {
      this.selectedDate = moment(new Date()).format(DEFAULT_DATE_FORMAT);
    }
    this.bookingService.timeCounter.subscribe((res) => {
      if (res) {
        this.timeCounter = res;
      }
    });
    this.setCategoryListingParams();
  }

  ngOnInit(): void {
    this.storage.getItem(TIME_COUNTER).subscribe((res: any) => {
      if (res?.mint && res?.sec) {
        const seconds = parseInt(res.mint) * 60 + parseInt(res.sec);
        this.bookingService.startTimer(seconds);
      }
    });
  }

  toggleView(): void {
    this.gridActive = !this.gridActive;
    this.listActive = !this.listActive;
  }

  setCategoryListingParams(): void {
    this.params = {
      id: [this.categoryId],
      date: this.selectedDate,
    };
    this.getSunRiseDetail();
    this.getParkTimingDetails();
  }

  getListing(): void {
    this.showLoader();
    this.bookingService
      .categoryDetail(this.params)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.categoryDetail = res;
          this.type = res?.data[0]?.type;
          this.categoryName =
            res?.data?.length === 1
              ? res?.data[0]?.name
              : res?.data?.find((c) => c.parent_id === null)?.name!;

          // map data to check the selected subcategory_filters items
          const categoryIds = res?.data.map((d) => d.id);
          this.categoryDetail.subcategory_filters =
            res?.subcategory_filters?.map((sc) => {
              return {
                ...sc,
                checked: categoryIds.includes(sc?.id) ? true : false,
              };
            });

          // map data to false for othercategory_filters items
          this.categoryDetail.othercategory_filters =
            res?.othercategory_filters?.map((oc) => {
              return { ...oc, checked: false };
            });

          // set the data for parent and child categories and store boats, bicyle, services and extra items
          res?.data?.forEach((cd) => {
            cd?.boats?.forEach((b) => {
              this.allData.push(b);
            });

            cd?.bicycles?.forEach((item) => {
              this.allData.push(item);
            });

            cd?.services?.forEach((s) => {
              this.allData.push(s);
            });

            cd?.games?.forEach((g) => {
              this.allData.push(g);
            });

            cd?.packages?.forEach((p) => {
              this.allData.push(p);
            });
          });
          this.setPage(1);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  // to manage the change when clicked on other category item
  loadData(ev: any): void {
    this.resetData();

    // check only one item at a time for other categories
    this.categoryDetail.othercategory_filters =
      this.categoryDetail?.othercategory_filters?.map((oc) => {
        return { ...oc, checked: oc.id == ev.target.value ? true : false };
      });

    // map data to false for subcategory_filters items
    this.categoryDetail.subcategory_filters =
      this.categoryDetail.subcategory_filters?.map((oc) => {
        return { ...oc, checked: false };
      });

    this.params.id = [parseInt(ev.target.value)];

    this.getListing();
  }

  // to manage the data when selected category sub item is changed
  loadCategoryData(ev: any): void {
    this.resetData();

    // check only one item at a time for other categories and toggle the checked
    const index = this.categoryDetail.subcategory_filters.findIndex(
      (sc) => sc.id == ev.target.value
    );
    this.categoryDetail.subcategory_filters[index].checked =
      !this.categoryDetail.subcategory_filters[index].checked;

    // map data to false for othercategory_filters items
    this.categoryDetail.othercategory_filters =
      this.categoryDetail.othercategory_filters?.map((oc) => {
        return { ...oc, checked: false };
      });

    // get the checked item ids
    const ids = this.categoryDetail.subcategory_filters
      .filter((sc) => sc.checked === true)
      .map((sc) => sc.id);

    if (ids?.length >= 1) {
      this.params.id = ids;
      this.getFiltersData();
    } else {
      this.errorService.showErrorMessage(marker('CategoryListing.FilterLimit'));
    }
  }

  getFiltersData() {
    this.showLoader();
    this.bookingService
      .categoryDetail(this.params)
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.type = res?.data[0]?.type;
          res?.data?.forEach((cd) => {
            cd?.boats?.forEach((b) => {
              this.allData.push(b);
            });

            cd?.bicycles?.forEach((item) => {
              this.allData.push(item);
            });

            cd?.services?.forEach((s) => {
              this.allData.push(s);
            });

            cd?.games?.forEach((g) => {
              this.allData.push(g);
            });

            cd?.packages?.forEach((p) => {
              this.allData.push(p);
            });
          });
          this.setPage(1);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  onDateChange(ev: any): void {
    this.resetData();
    this.params.date = ev.target.value;
    this.getParkTimingDetails();
  }

  resetData(): void {
    this.allData = [];
    this.pagedData = [];
  }

  clearFilters() {
    this.resetData();
    this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
    this.params = {
      date: this.selectedDate,
      id: [parseInt(this.route.snapshot.params.id)],
    };
    this.getListing();
  }

  setPage(page: number) {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    // get pager object from service
    this.pager = this.paginationService.getPager(
      this.allData.length,
      page,
      this.pageSize
    );
    // get current page of items
    this.pagedData = this.allData.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }

  showItems(ev: any) {
    this.pageSize = parseInt(ev.target.value);
    this.setPage(1);
  }

  getSunRiseDetail() {
    this.bookingService.getSunriseDetail().subscribe((res) => {
      this.sunInfo = res;
      for (let i = 1; i <= res.hours; i++) {
        this.sunDuration.push(i);
        this.sunDuration.push(i + 0.5);
      }
    });
  }

  getParkTimingDetails(): void {
    this.bookingService
      .parkTiming({ date: this.selectedDate || new Date().toISOString() })
      .subscribe((res) => {
        this.parkTimings = res;
        this.getListing();
      });
  }
}
