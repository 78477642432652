import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { UserService } from './../../services/user/user.service';
import { CategoryListModel } from 'src/app/models/booking.model';
import { BookingService } from './../../services/booking/booking.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import { BaseComponent } from '../base.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'br-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent extends BaseComponent implements OnInit {
  categories: Array<CategoryListModel> = [];
  colors: Array<string> = [
    'blue',
    'yellow',
    'green',
    'red',
    'purple',
    'blue',
    'black',
  ];

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    public bookingService: BookingService,
    private errorService: ErrorHandlerService,
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    this.getCategoriesListing();
  }

  getCategoriesListing() {
    this.userService.showLoader.next(true);
    this.bookingService
      .categoryListing()
      .pipe(finalize(() => this.userService.showLoader.next(false)))
      .subscribe(
        (res: CategoryListModel[]) => {
          this.bookingService.categories = res;
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }
}
