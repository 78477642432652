<div class="modal-body p-0">
  <div class="close-btn" (click)="close()"><i class="bi bi-x"></i></div>
  <!-- item -->
  <div
    class="item-wrapper2 px-sm-5 px-3 pt-4 rounded mb-4"
    *ngFor="let item of complementaryItems"
  >
    <div class="row align-items-stretch item-wrapper-inner m-0">
      <!-- item image -->
      <div class="col-md-4 item-img mb-md-0 mb-3">
        <img class="rounded-2 img-fluid w-100" [src]="setImage(item)" alt="" />
      </div>
      <!-- item content -->
      <div class="col-md-8 item-content">
        <div
          class="d-flex flex-column align-items-start justify-content-between h-100"
        >
          <div>
            <!-- title, location, Rate-->
            <h2 class="text-dark">{{ item.title }}</h2>
            <!-- item desc -->
            <p class="m-0 mb-3 item-desc" [innerHtml]="item.description"></p>
          </div>

          <!-- booking buttons -->
          <div class="d-flex row align-items-end justify-content-between w-100">
            <div class="col col-10 quantity-select">
              <label class="form-label">
                {{ "ComplementaryItem.Quantity" | translate }}
              </label>
              <select
                class="form-select"
                aria-label="Default select example"
                [(ngModel)]="item.quantity"
              >
                <option [ngValue]="0" [selected]="true">
                  {{ "Common.ChooseHere" | translate }}
                </option>
                <option
                  *ngFor="
                    let i of [
                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                      18, 19, 20
                    ]
                  "
                  [ngValue]="i"
                >
                  {{ i }} x
                  {{ item?.price | currency: "EUR":"symbol":"1.2-2":locale }} =
                  {{
                    i * item?.price! | currency: "EUR":"symbol":"1.2-2":locale
                  }}
                </option>
              </select>
            </div>
            <div class="col col-2 p-sm-0 p-0">
              <button
                class="btn btn-info text-white w-100 px-0"
                (click)="addComplementaryItem(item)"
                [disabled]="item.quantity == 0"
              >
                {{ "Buttons.Add" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /item -->
  <br-no-record-found
    *ngIf="!complementaryItems?.length && !loading"
    [message]="'ComplementaryItem.NotFound' | translate"
  ></br-no-record-found>
</div>
