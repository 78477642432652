import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { UserModel } from 'src/app/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { OrderModel } from '../../../models/cart.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from '../../../services/user/user.service';
import { CartService } from '../../../services/cart/cart.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { ErrorHandlerService } from '../../../services/error/error-handler.service';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'br-payment-complete',
  templateUrl: './payment-complete.component.html',
  styleUrls: ['./payment-complete.component.scss'],
})
export class PaymentCompleteComponent extends BaseComponent implements OnInit {
  order!: OrderModel;
  locale: string = 'en';
  userDetail!: UserModel;
  reservationSum: number = 0;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected translate: TranslateService,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private bookingService: BookingService,
    private errorService: ErrorHandlerService,
    protected userService: UserService,
    private cartService: CartService
  ) {
    super(router, authService, userService, translate);
    const code = this.route.snapshot.params.code;
    this.list(code);
  }

  ngOnInit(): void {
    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  list(code: string) {
    this.showLoader();
    this.user$.subscribe(
      (res) => {
        if (res) {
          this.userDetail = res;
        }
      },
      (error) => {
        this.errorService.handle(error);
      }
    );

    this.cartService
      .list(code, 'by_code')
      .pipe(finalize(() => this.hideLoader()))
      .subscribe(
        (res) => {
          this.order = res.data;
          this.reservationSum =  res?.data?.reservations?.reduce((sum, reservation) => {
            return sum + reservation.price;
        }, 0);
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  redirectToMollie() {
    this.showLoader();
    this.paymentService
      .molliePayment({ code: this.order.code })
      .pipe(
        finalize(() => {
          this.hideLoader();
        })
      )
      .subscribe(
        (res) => {
          window.open(res.data, '_self');
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }

  downloadQRCode() {
    this.download(
      window.URL.createObjectURL(
        new Blob([this.order.qr_code], { type: 'image/svg' })
      ),
      'svg'
    );
  }

  download(href: string, name: string) {
    const a = document.createElement('a');
    a.download = name;
    a.href = href;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  generateReceipt(): void {
    this.showLoader();

    this.paymentService
      .generateReceipt(this.order.code)
      .pipe(
        finalize(() => {
          this.hideLoader();
        })
      )
      .subscribe({
        next: (res: { Url: string }) => {
          window.open(res.Url, '_blank');
        },
        error: (error: HttpErrorResponse) => {
          this.errorService.handle(error);
        },
      });
  }
}
