export const COUNTRIES = [
    {
        "id": 0,
        "country": "Netherlands",
        "phoneCode": "+31",
        "countryCode": "NL",
        "flag": "🇳🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/nl.png",
            "svg": "https://flagcdn.com/nl.svg"
        }
    },
    {
        "id": 1,
        "country": "Germany",
        "phoneCode": "+49",
        "countryCode": "DE",
        "flag": "🇩🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/de.png",
            "svg": "https://flagcdn.com/de.svg"
        }
    },
    {
        "id": 2,
        "country": "Yemen",
        "phoneCode": "+967",
        "countryCode": "YE",
        "flag": "🇾🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/ye.png",
            "svg": "https://flagcdn.com/ye.svg"
        }
    },
    {
        "id": 3,
        "country": "Sint Maarten",
        "phoneCode": "+1721",
        "countryCode": "SX",
        "flag": "🇸🇽",
        "flags": {
            "png": "https://flagcdn.com/w320/sx.png",
            "svg": "https://flagcdn.com/sx.svg"
        }
    },
    {
        "id": 4,
        "country": "Macau",
        "phoneCode": "+853",
        "countryCode": "MO",
        "flag": "🇲🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/mo.png",
            "svg": "https://flagcdn.com/mo.svg"
        }
    },
    {
        "id": 5,
        "country": "Nigeria",
        "phoneCode": "+234",
        "countryCode": "NG",
        "flag": "🇳🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/ng.png",
            "svg": "https://flagcdn.com/ng.svg"
        }
    },
    {
        "id": 6,
        "country": "Somalia",
        "phoneCode": "+252",
        "countryCode": "SO",
        "flag": "🇸🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/so.png",
            "svg": "https://flagcdn.com/so.svg"
        }
    },
    {
        "id": 7,
        "country": "Myanmar",
        "phoneCode": "+95",
        "countryCode": "MM",
        "flag": "🇲🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/mm.png",
            "svg": "https://flagcdn.com/mm.svg"
        }
    },
    {
        "id": 8,
        "country": "Liberia",
        "phoneCode": "+231",
        "countryCode": "LR",
        "flag": "🇱🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/lr.png",
            "svg": "https://flagcdn.com/lr.svg"
        }
    },
    {
        "id": 9,
        "country": "Dominica",
        "phoneCode": "+1767",
        "countryCode": "DM",
        "flag": "🇩🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/dm.png",
            "svg": "https://flagcdn.com/dm.svg"
        }
    },
    {
        "id": 10,
        "country": "Saint Kitts and Nevis",
        "phoneCode": "+1869",
        "countryCode": "KN",
        "flag": "🇰🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/kn.png",
            "svg": "https://flagcdn.com/kn.svg"
        }
    },
    {
        "id": 11,
        "country": "North Korea",
        "phoneCode": "+850",
        "countryCode": "KP",
        "flag": "🇰🇵",
        "flags": {
            "png": "https://flagcdn.com/w320/kp.png",
            "svg": "https://flagcdn.com/kp.svg"
        }
    },
    {
        "id": 12,
        "country": "Saudi Arabia",
        "phoneCode": "+966",
        "countryCode": "SA",
        "flag": "🇸🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/sa.png",
            "svg": "https://flagcdn.com/sa.svg"
        }
    },
    {
        "id": 13,
        "country": "Nepal",
        "phoneCode": "+977",
        "countryCode": "NP",
        "flag": "🇳🇵",
        "flags": {
            "png": "https://flagcdn.com/w320/np.png",
            "svg": "https://flagcdn.com/np.svg"
        }
    },
    {
        "id": 14,
        "country": "Venezuela",
        "phoneCode": "+58",
        "countryCode": "VE",
        "flag": "🇻🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/ve.png",
            "svg": "https://flagcdn.com/ve.svg"
        }
    },
    {
        "id": 15,
        "country": "Guam",
        "phoneCode": "+1671",
        "countryCode": "GU",
        "flag": "🇬🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/gu.png",
            "svg": "https://flagcdn.com/gu.svg"
        }
    },
    {
        "id": 16,
        "country": "Bermuda",
        "phoneCode": "+1441",
        "countryCode": "BM",
        "flag": "🇧🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/bm.png",
            "svg": "https://flagcdn.com/bm.svg"
        }
    },
    {
        "id": 17,
        "country": "Kenya",
        "phoneCode": "+254",
        "countryCode": "KE",
        "flag": "🇰🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/ke.png",
            "svg": "https://flagcdn.com/ke.svg"
        }
    },
    {
        "id": 18,
        "country": "Cameroon",
        "phoneCode": "+237",
        "countryCode": "CM",
        "flag": "🇨🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/cm.png",
            "svg": "https://flagcdn.com/cm.svg"
        }
    },
    {
        "id": 19,
        "country": "Rwanda",
        "phoneCode": "+250",
        "countryCode": "RW",
        "flag": "🇷🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/rw.png",
            "svg": "https://flagcdn.com/rw.svg"
        }
    },
    {
        "id": 20,
        "country": "Mauritania",
        "phoneCode": "+222",
        "countryCode": "MR",
        "flag": "🇲🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/mr.png",
            "svg": "https://flagcdn.com/mr.svg"
        }
    },
    {
        "id": 21,
        "country": "New Caledonia",
        "phoneCode": "+687",
        "countryCode": "NC",
        "flag": "🇳🇨",
        "flags": {
            "png": "https://flagcdn.com/w320/nc.png",
            "svg": "https://flagcdn.com/nc.svg"
        }
    },
    {
        "id": 22,
        "country": "Fiji",
        "phoneCode": "+679",
        "countryCode": "FJ",
        "flag": "🇫🇯",
        "flags": {
            "png": "https://flagcdn.com/w320/fj.png",
            "svg": "https://flagcdn.com/fj.svg"
        }
    },
    {
        "id": 23,
        "country": "Argentina",
        "phoneCode": "+54",
        "countryCode": "AR",
        "flag": "🇦🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/ar.png",
            "svg": "https://flagcdn.com/ar.svg"
        }
    },
    {
        "id": 24,
        "country": "Brunei",
        "phoneCode": "+673",
        "countryCode": "BN",
        "flag": "🇧🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/bn.png",
            "svg": "https://flagcdn.com/bn.svg"
        }
    },
    {
        "id": 25,
        "country": "Nicaragua",
        "phoneCode": "+505",
        "countryCode": "NI",
        "flag": "🇳🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/ni.png",
            "svg": "https://flagcdn.com/ni.svg"
        }
    },
    {
        "id": 26,
        "country": "United Kingdom",
        "phoneCode": "+44",
        "countryCode": "GB",
        "flag": "🇬🇧",
        "flags": {
            "png": "https://flagcdn.com/w320/gb.png",
            "svg": "https://flagcdn.com/gb.svg"
        }
    },
    {
        "id": 27,
        "country": "Laos",
        "phoneCode": "+856",
        "countryCode": "LA",
        "flag": "🇱🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/la.png",
            "svg": "https://flagcdn.com/la.svg"
        }
    },
    {
        "id": 28,
        "country": "Marshall Islands",
        "phoneCode": "+692",
        "countryCode": "MH",
        "flag": "🇲🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/mh.png",
            "svg": "https://flagcdn.com/mh.svg"
        }
    },
    {
        "id": 29,
        "country": "Ukraine",
        "phoneCode": "+380",
        "countryCode": "UA",
        "flag": "🇺🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/ua.png",
            "svg": "https://flagcdn.com/ua.svg"
        }
    },
    {
        "id": 30,
        "country": "Isle of Man",
        "phoneCode": "+44",
        "countryCode": "IM",
        "flag": "🇮🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/im.png",
            "svg": "https://flagcdn.com/im.svg"
        }
    },
    {
        "id": 31,
        "country": "Kuwait",
        "phoneCode": "+965",
        "countryCode": "KW",
        "flag": "🇰🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/kw.png",
            "svg": "https://flagcdn.com/kw.svg"
        }
    },
    {
        "id": 32,
        "country": "Antigua and Barbuda",
        "phoneCode": "+1268",
        "countryCode": "AG",
        "flag": "🇦🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/ag.png",
            "svg": "https://flagcdn.com/ag.svg"
        }
    },
    {
        "id": 33,
        "country": "Cyprus",
        "phoneCode": "+357",
        "countryCode": "CY",
        "flag": "🇨🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/cy.png",
            "svg": "https://flagcdn.com/cy.svg"
        }
    },
    {
        "id": 34,
        "country": "Palau",
        "phoneCode": "+680",
        "countryCode": "PW",
        "flag": "🇵🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/pw.png",
            "svg": "https://flagcdn.com/pw.svg"
        }
    },
    {
        "id": 35,
        "country": "New Zealand",
        "phoneCode": "+64",
        "countryCode": "NZ",
        "flag": "🇳🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/nz.png",
            "svg": "https://flagcdn.com/nz.svg"
        }
    },
    {
        "id": 36,
        "country": "Republic of the Congo",
        "phoneCode": "+242",
        "countryCode": "CG",
        "flag": "🇨🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/cg.png",
            "svg": "https://flagcdn.com/cg.svg"
        }
    },
    {
        "id": 37,
        "country": "British Virgin Islands",
        "phoneCode": "+1284",
        "countryCode": "VG",
        "flag": "🇻🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/vg.png",
            "svg": "https://flagcdn.com/vg.svg"
        }
    },
    {
        "id": 38,
        "country": "Bahrain",
        "phoneCode": "+973",
        "countryCode": "BH",
        "flag": "🇧🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/bh.png",
            "svg": "https://flagcdn.com/bh.svg"
        }
    },
    {
        "id": 39,
        "country": "Bolivia",
        "phoneCode": "+591",
        "countryCode": "BO",
        "flag": "🇧🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/bo.png",
            "svg": "https://flagcdn.com/bo.svg"
        }
    },
    {
        "id": 40,
        "country": "Togo",
        "phoneCode": "+228",
        "countryCode": "TG",
        "flag": "🇹🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/tg.png",
            "svg": "https://flagcdn.com/tg.svg"
        }
    },
    {
        "id": 41,
        "country": "Uzbekistan",
        "phoneCode": "+998",
        "countryCode": "UZ",
        "flag": "🇺🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/uz.png",
            "svg": "https://flagcdn.com/uz.svg"
        }
    },
    {
        "id": 42,
        "country": "Bahamas",
        "phoneCode": "+1242",
        "countryCode": "BS",
        "flag": "🇧🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/bs.png",
            "svg": "https://flagcdn.com/bs.svg"
        }
    },
    {
        "id": 43,
        "country": "Maldives",
        "phoneCode": "+960",
        "countryCode": "MV",
        "flag": "🇲🇻",
        "flags": {
            "png": "https://flagcdn.com/w320/mv.png",
            "svg": "https://flagcdn.com/mv.svg"
        }
    },
    {
        "id": 44,
        "country": "Turkmenistan",
        "phoneCode": "+993",
        "countryCode": "TM",
        "flag": "🇹🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/tm.png",
            "svg": "https://flagcdn.com/tm.svg"
        }
    },
    {
        "id": 45,
        "country": "Uruguay",
        "phoneCode": "+598",
        "countryCode": "UY",
        "flag": "🇺🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/uy.png",
            "svg": "https://flagcdn.com/uy.svg"
        }
    },
    {
        "id": 46,
        "country": "Bulgaria",
        "phoneCode": "+359",
        "countryCode": "BG",
        "flag": "🇧🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/bg.png",
            "svg": "https://flagcdn.com/bg.svg"
        }
    },
    {
        "id": 47,
        "country": "Lesotho",
        "phoneCode": "+266",
        "countryCode": "LS",
        "flag": "🇱🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/ls.png",
            "svg": "https://flagcdn.com/ls.svg"
        }
    },
    {
        "id": 48,
        "country": "Saint Pierre and Miquelon",
        "phoneCode": "+508",
        "countryCode": "PM",
        "flag": "🇵🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/pm.png",
            "svg": "https://flagcdn.com/pm.svg"
        }
    },
    {
        "id": 49,
        "country": "Cuba",
        "phoneCode": "+53",
        "countryCode": "CU",
        "flag": "🇨🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/cu.png",
            "svg": "https://flagcdn.com/cu.svg"
        }
    },
    {
        "id": 50,
        "country": "Haiti",
        "phoneCode": "+509",
        "countryCode": "HT",
        "flag": "🇭🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/ht.png",
            "svg": "https://flagcdn.com/ht.svg"
        }
    },
    {
        "id": 51,
        "country": "Malta",
        "phoneCode": "+356",
        "countryCode": "MT",
        "flag": "🇲🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/mt.png",
            "svg": "https://flagcdn.com/mt.svg"
        }
    },
    {
        "id": 52,
        "country": "Tonga",
        "phoneCode": "+676",
        "countryCode": "TO",
        "flag": "🇹🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/to.png",
            "svg": "https://flagcdn.com/to.svg"
        }
    },
    {
        "id": 53,
        "country": "Falkland Islands",
        "phoneCode": "+500",
        "countryCode": "FK",
        "flag": "🇫🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/fk.png",
            "svg": "https://flagcdn.com/fk.svg"
        }
    },
    {
        "id": 54,
        "country": "Ethiopia",
        "phoneCode": "+251",
        "countryCode": "ET",
        "flag": "🇪🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/et.png",
            "svg": "https://flagcdn.com/et.svg"
        }
    },
    {
        "id": 55,
        "country": "South Sudan",
        "phoneCode": "+211",
        "countryCode": "SS",
        "flag": "🇸🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/ss.png",
            "svg": "https://flagcdn.com/ss.svg"
        }
    },
    {
        "id": 56,
        "country": "Tunisia",
        "phoneCode": "+216",
        "countryCode": "TN",
        "flag": "🇹🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/tn.png",
            "svg": "https://flagcdn.com/tn.svg"
        }
    },
    {
        "id": 57,
        "country": "Puerto Rico",
        "phoneCode": "+1787",
        "countryCode": "PR",
        "flag": "🇵🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/pr.png",
            "svg": "https://flagcdn.com/pr.svg"
        }
    },
    {
        "id": 58,
        "country": "Ecuador",
        "phoneCode": "+593",
        "countryCode": "EC",
        "flag": "🇪🇨",
        "flags": {
            "png": "https://flagcdn.com/w320/ec.png",
            "svg": "https://flagcdn.com/ec.svg"
        }
    },
    {
        "id": 59,
        "country": "Cape Verde",
        "phoneCode": "+238",
        "countryCode": "CV",
        "flag": "🇨🇻",
        "flags": {
            "png": "https://flagcdn.com/w320/cv.png",
            "svg": "https://flagcdn.com/cv.svg"
        }
    },
    {
        "id": 60,
        "country": "Monaco",
        "phoneCode": "+377",
        "countryCode": "MC",
        "flag": "🇲🇨",
        "flags": {
            "png": "https://flagcdn.com/w320/mc.png",
            "svg": "https://flagcdn.com/mc.svg"
        }
    },
    {
        "id": 61,
        "country": "Tajikistan",
        "phoneCode": "+992",
        "countryCode": "TJ",
        "flag": "🇹🇯",
        "flags": {
            "png": "https://flagcdn.com/w320/tj.png",
            "svg": "https://flagcdn.com/tj.svg"
        }
    },
    {
        "id": 62,
        "country": "Botswana",
        "phoneCode": "+267",
        "countryCode": "BW",
        "flag": "🇧🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/bw.png",
            "svg": "https://flagcdn.com/bw.svg"
        }
    },
    {
        "id": 63,
        "country": "Bouvet Island",
        "phoneCode": "+47",
        "countryCode": "BV",
        "flag": "🇧🇻",
        "flags": {
            "png": "https://flagcdn.com/w320/bv.png",
            "svg": "https://flagcdn.com/bv.svg"
        }
    },
    {
        "id": 64,
        "country": "Indonesia",
        "phoneCode": "+62",
        "countryCode": "ID",
        "flag": "🇮🇩",
        "flags": {
            "png": "https://flagcdn.com/w320/id.png",
            "svg": "https://flagcdn.com/id.svg"
        }
    },
    {
        "id": 65,
        "country": "Estonia",
        "phoneCode": "+372",
        "countryCode": "EE",
        "flag": "🇪🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/ee.png",
            "svg": "https://flagcdn.com/ee.svg"
        }
    },
    {
        "id": 66,
        "country": "Qatar",
        "phoneCode": "+974",
        "countryCode": "QA",
        "flag": "🇶🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/qa.png",
            "svg": "https://flagcdn.com/qa.svg"
        }
    },
    {
        "id": 67,
        "country": "Cayman Islands",
        "phoneCode": "+1345",
        "countryCode": "KY",
        "flag": "🇰🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/ky.png",
            "svg": "https://flagcdn.com/ky.svg"
        }
    },
    {
        "id": 68,
        "country": "Croatia",
        "phoneCode": "+385",
        "countryCode": "HR",
        "flag": "🇭🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/hr.png",
            "svg": "https://flagcdn.com/hr.svg"
        }
    },
    {
        "id": 69,
        "country": "Zambia",
        "phoneCode": "+260",
        "countryCode": "ZM",
        "flag": "🇿🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/zm.png",
            "svg": "https://flagcdn.com/zm.svg"
        }
    },
    {
        "id": 70,
        "country": "Guadeloupe",
        "phoneCode": "+590",
        "countryCode": "GP",
        "flag": "🇬🇵",
        "flags": {
            "png": "https://flagcdn.com/w320/gp.png",
            "svg": "https://flagcdn.com/gp.svg"
        }
    },
    {
        "id": 71,
        "country": "Romania",
        "phoneCode": "+40",
        "countryCode": "RO",
        "flag": "🇷🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/ro.png",
            "svg": "https://flagcdn.com/ro.svg"
        }
    },
    {
        "id": 72,
        "country": "Faroe Islands",
        "phoneCode": "+298",
        "countryCode": "FO",
        "flag": "🇫🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/fo.png",
            "svg": "https://flagcdn.com/fo.svg"
        }
    },
    {
        "id": 73,
        "country": "Moldova",
        "phoneCode": "+373",
        "countryCode": "MD",
        "flag": "🇲🇩",
        "flags": {
            "png": "https://flagcdn.com/w320/md.png",
            "svg": "https://flagcdn.com/md.svg"
        }
    },
    {
        "id": 74,
        "country": "Niue",
        "phoneCode": "+683",
        "countryCode": "NU",
        "flag": "🇳🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/nu.png",
            "svg": "https://flagcdn.com/nu.svg"
        }
    },
    {
        "id": 75,
        "country": "Wallis and Futuna",
        "phoneCode": "+681",
        "countryCode": "WF",
        "flag": "🇼🇫",
        "flags": {
            "png": "https://flagcdn.com/w320/wf.png",
            "svg": "https://flagcdn.com/wf.svg"
        }
    },
    {
        "id": 76,
        "country": "Pitcairn Islands",
        "phoneCode": "+64",
        "countryCode": "PN",
        "flag": "🇵🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/pn.png",
            "svg": "https://flagcdn.com/pn.svg"
        }
    },
    {
        "id": 77,
        "country": "Barbados",
        "phoneCode": "+1246",
        "countryCode": "BB",
        "flag": "🇧🇧",
        "flags": {
            "png": "https://flagcdn.com/w320/bb.png",
            "svg": "https://flagcdn.com/bb.svg"
        }
    },
    {
        "id": 78,
        "country": "Bosnia and Herzegovina",
        "phoneCode": "+387",
        "countryCode": "BA",
        "flag": "🇧🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/ba.png",
            "svg": "https://flagcdn.com/ba.svg"
        }
    },
    {
        "id": 79,
        "country": "South Korea",
        "phoneCode": "+82",
        "countryCode": "KR",
        "flag": "🇰🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/kr.png",
            "svg": "https://flagcdn.com/kr.svg"
        }
    },
    {
        "id": 80,
        "country": "Burkina Faso",
        "phoneCode": "+226",
        "countryCode": "BF",
        "flag": "🇧🇫",
        "flags": {
            "png": "https://flagcdn.com/w320/bf.png",
            "svg": "https://flagcdn.com/bf.svg"
        }
    },
    {
        "id": 81,
        "country": "El Salvador",
        "phoneCode": "+503",
        "countryCode": "SV",
        "flag": "🇸🇻",
        "flags": {
            "png": "https://flagcdn.com/w320/sv.png",
            "svg": "https://flagcdn.com/sv.svg"
        }
    },
    {
        "id": 82,
        "country": "Syria",
        "phoneCode": "+963",
        "countryCode": "SY",
        "flag": "🇸🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/sy.png",
            "svg": "https://flagcdn.com/sy.svg"
        }
    },
    {
        "id": 83,
        "country": "Colombia",
        "phoneCode": "+57",
        "countryCode": "CO",
        "flag": "🇨🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/co.png",
            "svg": "https://flagcdn.com/co.svg"
        }
    },
    {
        "id": 84,
        "country": "Bangladesh",
        "phoneCode": "+880",
        "countryCode": "BD",
        "flag": "🇧🇩",
        "flags": {
            "png": "https://flagcdn.com/w320/bd.png",
            "svg": "https://flagcdn.com/bd.svg"
        }
    },
    {
        "id": 85,
        "country": "Martinique",
        "phoneCode": "+596",
        "countryCode": "MQ",
        "flag": "🇲🇶",
        "flags": {
            "png": "https://flagcdn.com/w320/mq.png",
            "svg": "https://flagcdn.com/mq.svg"
        }
    },
    {
        "id": 86,
        "country": "Micronesia",
        "phoneCode": "+691",
        "countryCode": "FM",
        "flag": "🇫🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/fm.png",
            "svg": "https://flagcdn.com/fm.svg"
        }
    },
    {
        "id": 87,
        "country": "Sweden",
        "phoneCode": "+46",
        "countryCode": "SE",
        "flag": "🇸🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/se.png",
            "svg": "https://flagcdn.com/se.svg"
        }
    },
    {
        "id": 88,
        "country": "Hungary",
        "phoneCode": "+36",
        "countryCode": "HU",
        "flag": "🇭🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/hu.png",
            "svg": "https://flagcdn.com/hu.svg"
        }
    },
    {
        "id": 89,
        "country": "French Southern and Antarctic Lands",
        "phoneCode": "+262",
        "countryCode": "TF",
        "flag": "🇹🇫",
        "flags": {
            "png": "https://flagcdn.com/w320/tf.png",
            "svg": "https://flagcdn.com/tf.svg"
        }
    },
    {
        "id": 90,
        "country": "Nauru",
        "phoneCode": "+674",
        "countryCode": "NR",
        "flag": "🇳🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/nr.png",
            "svg": "https://flagcdn.com/nr.svg"
        }
    },
    {
        "id": 91,
        "country": "Iraq",
        "phoneCode": "+964",
        "countryCode": "IQ",
        "flag": "🇮🇶",
        "flags": {
            "png": "https://flagcdn.com/w320/iq.png",
            "svg": "https://flagcdn.com/iq.svg"
        }
    },
    {
        "id": 92,
        "country": "Poland",
        "phoneCode": "+48",
        "countryCode": "PL",
        "flag": "🇵🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/pl.png",
            "svg": "https://flagcdn.com/pl.svg"
        }
    },
    {
        "id": 93,
        "country": "Niger",
        "phoneCode": "+227",
        "countryCode": "NE",
        "flag": "🇳🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/ne.png",
            "svg": "https://flagcdn.com/ne.svg"
        }
    },
    {
        "id": 94,
        "country": "Greece",
        "phoneCode": "+30",
        "countryCode": "GR",
        "flag": "🇬🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/gr.png",
            "svg": "https://flagcdn.com/gr.svg"
        }
    },
    {
        "id": 95,
        "country": "Tanzania",
        "phoneCode": "+255",
        "countryCode": "TZ",
        "flag": "🇹🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/tz.png",
            "svg": "https://flagcdn.com/tz.svg"
        }
    },
    {
        "id": 96,
        "country": "Morocco",
        "phoneCode": "+212",
        "countryCode": "MA",
        "flag": "🇲🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/ma.png",
            "svg": "https://flagcdn.com/ma.svg"
        }
    },
    {
        "id": 97,
        "country": "Liechtenstein",
        "phoneCode": "+423",
        "countryCode": "LI",
        "flag": "🇱🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/li.png",
            "svg": "https://flagcdn.com/li.svg"
        }
    },
    {
        "id": 98,
        "country": "Timor-Leste",
        "phoneCode": "+670",
        "countryCode": "TL",
        "flag": "🇹🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/tl.png",
            "svg": "https://flagcdn.com/tl.svg"
        }
    },
    {
        "id": 99,
        "country": "Eritrea",
        "phoneCode": "+291",
        "countryCode": "ER",
        "flag": "🇪🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/er.png",
            "svg": "https://flagcdn.com/er.svg"
        }
    },
    {
        "id": 100,
        "country": "Mayotte",
        "phoneCode": "+262",
        "countryCode": "YT",
        "flag": "🇾🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/yt.png",
            "svg": "https://flagcdn.com/yt.svg"
        }
    },
    {
        "id": 101,
        "country": "France",
        "phoneCode": "+33",
        "countryCode": "FR",
        "flag": "🇫🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/fr.png",
            "svg": "https://flagcdn.com/fr.svg"
        }
    },
    {
        "id": 102,
        "country": "Honduras",
        "phoneCode": "+504",
        "countryCode": "HN",
        "flag": "🇭🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/hn.png",
            "svg": "https://flagcdn.com/hn.svg"
        }
    },
    {
        "id": 103,
        "country": "Samoa",
        "phoneCode": "+685",
        "countryCode": "WS",
        "flag": "🇼🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/ws.png",
            "svg": "https://flagcdn.com/ws.svg"
        }
    },
    {
        "id": 104,
        "country": "Greenland",
        "phoneCode": "+299",
        "countryCode": "GL",
        "flag": "🇬🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/gl.png",
            "svg": "https://flagcdn.com/gl.svg"
        }
    },
    {
        "id": 105,
        "country": "Kosovo",
        "phoneCode": "+383",
        "countryCode": "XK",
        "flag": "🇽🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/xk.png",
            "svg": "https://flagcdn.com/xk.svg"
        }
    },
    {
        "id": 106,
        "country": "Anguilla",
        "phoneCode": "+1264",
        "countryCode": "AI",
        "flag": "🇦🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/ai.png",
            "svg": "https://flagcdn.com/ai.svg"
        }
    },
    {
        "id": 107,
        "country": "Serbia",
        "phoneCode": "+381",
        "countryCode": "RS",
        "flag": "🇷🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/rs.png",
            "svg": "https://flagcdn.com/rs.svg"
        }
    },
    {
        "id": 108,
        "country": "Slovakia",
        "phoneCode": "+421",
        "countryCode": "SK",
        "flag": "🇸🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/sk.png",
            "svg": "https://flagcdn.com/sk.svg"
        }
    },
    {
        "id": 109,
        "country": "Heard Island and McDonald Islands",
        "phoneCode": "+672",
        "countryCode": "HM",
        "flag": "🇭🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/hm.png",
            "svg": "https://flagcdn.com/hm.svg"
        }
    },
    {
        "id": 110,
        "country": "Kiribati",
        "phoneCode": "+686",
        "countryCode": "KI",
        "flag": "🇰🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/ki.png",
            "svg": "https://flagcdn.com/ki.svg"
        }
    },
    {
        "id": 111,
        "country": "Montserrat",
        "phoneCode": "+1664",
        "countryCode": "MS",
        "flag": "🇲🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/ms.png",
            "svg": "https://flagcdn.com/ms.svg"
        }
    },
    {
        "id": 112,
        "country": "Spain",
        "phoneCode": "+34",
        "countryCode": "ES",
        "flag": "🇪🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/es.png",
            "svg": "https://flagcdn.com/es.svg"
        }
    },
    {
        "id": 113,
        "country": "Belize",
        "phoneCode": "+501",
        "countryCode": "BZ",
        "flag": "🇧🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/bz.png",
            "svg": "https://flagcdn.com/bz.svg"
        }
    },
    {
        "id": 114,
        "country": "Seychelles",
        "phoneCode": "+248",
        "countryCode": "SC",
        "flag": "🇸🇨",
        "flags": {
            "png": "https://flagcdn.com/w320/sc.png",
            "svg": "https://flagcdn.com/sc.svg"
        }
    },
    {
        "id": 115,
        "country": "Western Sahara",
        "phoneCode": "+2125288",
        "countryCode": "EH",
        "flag": "🇪🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/eh.png",
            "svg": "https://flagcdn.com/eh.svg"
        }
    },
    {
        "id": 116,
        "country": "Gibraltar",
        "phoneCode": "+350",
        "countryCode": "GI",
        "flag": "🇬🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/gi.png",
            "svg": "https://flagcdn.com/gi.svg"
        }
    },
    {
        "id": 117,
        "country": "Thailand",
        "phoneCode": "+66",
        "countryCode": "TH",
        "flag": "🇹🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/th.png",
            "svg": "https://flagcdn.com/th.svg"
        }
    },
    {
        "id": 118,
        "country": "Vietnam",
        "phoneCode": "+84",
        "countryCode": "VN",
        "flag": "🇻🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/vn.png",
            "svg": "https://flagcdn.com/vn.svg"
        }
    },
    {
        "id": 119,
        "country": "Saint Helena, Ascension and Tristan da Cunha",
        "phoneCode": "+290",
        "countryCode": "SH",
        "flag": "🇸🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/sh.png",
            "svg": "https://flagcdn.com/sh.svg"
        }
    },
    {
        "id": 120,
        "country": "Austria",
        "phoneCode": "+43",
        "countryCode": "AT",
        "flag": "🇦🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/at.png",
            "svg": "https://flagcdn.com/at.svg"
        }
    },
    {
        "id": 121,
        "country": "Saint Vincent and the Grenadines",
        "phoneCode": "+1784",
        "countryCode": "VC",
        "flag": "🇻🇨",
        "flags": {
            "png": "https://flagcdn.com/w320/vc.png",
            "svg": "https://flagcdn.com/vc.svg"
        }
    },
    {
        "id": 122,
        "country": "Andorra",
        "phoneCode": "+376",
        "countryCode": "AD",
        "flag": "🇦🇩",
        "flags": {
            "png": "https://flagcdn.com/w320/ad.png",
            "svg": "https://flagcdn.com/ad.svg"
        }
    },
    {
        "id": 123,
        "country": "Svalbard and Jan Mayen",
        "phoneCode": "+4779",
        "countryCode": "SJ",
        "flag": "🇸🇯",
        "flags": {
            "png": "https://flagcdn.com/w320/sj.png",
            "svg": "https://flagcdn.com/sj.svg"
        }
    },
    {
        "id": 124,
        "country": "Norfolk Island",
        "phoneCode": "+672",
        "countryCode": "NF",
        "flag": "🇳🇫",
        "flags": {
            "png": "https://flagcdn.com/w320/nf.png",
            "svg": "https://flagcdn.com/nf.svg"
        }
    },
    {
        "id": 125,
        "country": "Suriname",
        "phoneCode": "+597",
        "countryCode": "SR",
        "flag": "🇸🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/sr.png",
            "svg": "https://flagcdn.com/sr.svg"
        }
    },
    {
        "id": 126,
        "country": "Mongolia",
        "phoneCode": "+976",
        "countryCode": "MN",
        "flag": "🇲🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/mn.png",
            "svg": "https://flagcdn.com/mn.svg"
        }
    },
    {
        "id": 127,
        "country": "Bhutan",
        "phoneCode": "+975",
        "countryCode": "BT",
        "flag": "🇧🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/bt.png",
            "svg": "https://flagcdn.com/bt.svg"
        }
    },
    {
        "id": 128,
        "country": "Sierra Leone",
        "phoneCode": "+232",
        "countryCode": "SL",
        "flag": "🇸🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/sl.png",
            "svg": "https://flagcdn.com/sl.svg"
        }
    },
    {
        "id": 129,
        "country": "Cambodia",
        "phoneCode": "+855",
        "countryCode": "KH",
        "flag": "🇰🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/kh.png",
            "svg": "https://flagcdn.com/kh.svg"
        }
    },
    {
        "id": 130,
        "country": "Algeria",
        "phoneCode": "+213",
        "countryCode": "DZ",
        "flag": "🇩🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/dz.png",
            "svg": "https://flagcdn.com/dz.svg"
        }
    },
    {
        "id": 131,
        "country": "Gabon",
        "phoneCode": "+241",
        "countryCode": "GA",
        "flag": "🇬🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/ga.png",
            "svg": "https://flagcdn.com/ga.svg"
        }
    },
    {
        "id": 132,
        "country": "Afghanistan",
        "phoneCode": "+93",
        "countryCode": "AF",
        "flag": "🇦🇫",
        "flags": {
            "png": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png",
            "svg": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg"
        }
    },
    {
        "id": 133,
        "country": "Armenia",
        "phoneCode": "+374",
        "countryCode": "AM",
        "flag": "🇦🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/am.png",
            "svg": "https://flagcdn.com/am.svg"
        }
    },
    {
        "id": 134,
        "country": "Saint Lucia",
        "phoneCode": "+1758",
        "countryCode": "LC",
        "flag": "🇱🇨",
        "flags": {
            "png": "https://flagcdn.com/w320/lc.png",
            "svg": "https://flagcdn.com/lc.svg"
        }
    },
    {
        "id": 135,
        "country": "Tuvalu",
        "phoneCode": "+688",
        "countryCode": "TV",
        "flag": "🇹🇻",
        "flags": {
            "png": "https://flagcdn.com/w320/tv.png",
            "svg": "https://flagcdn.com/tv.svg"
        }
    },
    {
        "id": 136,
        "country": "Turks and Caicos Islands",
        "phoneCode": "+1649",
        "countryCode": "TC",
        "flag": "🇹🇨",
        "flags": {
            "png": "https://flagcdn.com/w320/tc.png",
            "svg": "https://flagcdn.com/tc.svg"
        }
    },
    {
        "id": 137,
        "country": "Equatorial Guinea",
        "phoneCode": "+240",
        "countryCode": "GQ",
        "flag": "🇬🇶",
        "flags": {
            "png": "https://flagcdn.com/w320/gq.png",
            "svg": "https://flagcdn.com/gq.svg"
        }
    },
    {
        "id": 138,
        "country": "Sudan",
        "phoneCode": "+249",
        "countryCode": "SD",
        "flag": "🇸🇩",
        "flags": {
            "png": "https://flagcdn.com/w320/sd.png",
            "svg": "https://flagcdn.com/sd.svg"
        }
    },
    {
        "id": 139,
        "country": "Antarctica",
        "phoneCode": "+672",
        "countryCode": "AQ",
        "flag": "🇦🇶",
        "flags": {
            "png": "https://flagcdn.com/w320/aq.png",
            "svg": "https://flagcdn.com/aq.svg"
        }
    },
    {
        "id": 140,
        "country": "São Tomé and Príncipe",
        "phoneCode": "+239",
        "countryCode": "ST",
        "flag": "🇸🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/st.png",
            "svg": "https://flagcdn.com/st.svg"
        }
    },
    {
        "id": 141,
        "country": "Slovenia",
        "phoneCode": "+386",
        "countryCode": "SI",
        "flag": "🇸🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/si.png",
            "svg": "https://flagcdn.com/si.svg"
        }
    },
    {
        "id": 142,
        "country": "Hong Kong",
        "phoneCode": "+852",
        "countryCode": "HK",
        "flag": "🇭🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/hk.png",
            "svg": "https://flagcdn.com/hk.svg"
        }
    },
    {
        "id": 143,
        "country": "Réunion",
        "phoneCode": "+262",
        "countryCode": "RE",
        "flag": "🇷🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/re.png",
            "svg": "https://flagcdn.com/re.svg"
        }
    },
    {
        "id": 144,
        "country": "Saint Martin",
        "phoneCode": "+590",
        "countryCode": "MF",
        "flag": "🇲🇫",
        "flags": {
            "png": "https://flagcdn.com/w320/mf.png",
            "svg": "https://flagcdn.com/mf.svg"
        }
    },
    {
        "id": 145,
        "country": "Latvia",
        "phoneCode": "+371",
        "countryCode": "LV",
        "flag": "🇱🇻",
        "flags": {
            "png": "https://flagcdn.com/w320/lv.png",
            "svg": "https://flagcdn.com/lv.svg"
        }
    },
    {
        "id": 146,
        "country": "French Guiana",
        "phoneCode": "+594",
        "countryCode": "GF",
        "flag": "🇬🇫",
        "flags": {
            "png": "https://flagcdn.com/w320/gf.png",
            "svg": "https://flagcdn.com/gf.svg"
        }
    },
    {
        "id": 147,
        "country": "Pakistan",
        "phoneCode": "+92",
        "countryCode": "PK",
        "flag": "🇵🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/pk.png",
            "svg": "https://flagcdn.com/pk.svg"
        }
    },
    {
        "id": 148,
        "country": "Azerbaijan",
        "phoneCode": "+994",
        "countryCode": "AZ",
        "flag": "🇦🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/az.png",
            "svg": "https://flagcdn.com/az.svg"
        }
    },
    {
        "id": 149,
        "country": "Comoros",
        "phoneCode": "+269",
        "countryCode": "KM",
        "flag": "🇰🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/km.png",
            "svg": "https://flagcdn.com/km.svg"
        }
    },
    {
        "id": 150,
        "country": "Oman",
        "phoneCode": "+968",
        "countryCode": "OM",
        "flag": "🇴🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/om.png",
            "svg": "https://flagcdn.com/om.svg"
        }
    },
    {
        "id": 151,
        "country": "Senegal",
        "phoneCode": "+221",
        "countryCode": "SN",
        "flag": "🇸🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/sn.png",
            "svg": "https://flagcdn.com/sn.svg"
        }
    },
    {
        "id": 152,
        "country": "United States Virgin Islands",
        "phoneCode": "+1340",
        "countryCode": "VI",
        "flag": "🇻🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/vi.png",
            "svg": "https://flagcdn.com/vi.svg"
        }
    },
    {
        "id": 153,
        "country": "Ghana",
        "phoneCode": "+233",
        "countryCode": "GH",
        "flag": "🇬🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/gh.png",
            "svg": "https://flagcdn.com/gh.svg"
        }
    },
    {
        "id": 154,
        "country": "Central African Republic",
        "phoneCode": "+236",
        "countryCode": "CF",
        "flag": "🇨🇫",
        "flags": {
            "png": "https://flagcdn.com/w320/cf.png",
            "svg": "https://flagcdn.com/cf.svg"
        }
    },
    {
        "id": 155,
        "country": "South Georgia",
        "phoneCode": "+500",
        "countryCode": "GS",
        "flag": "🇬🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/gs.png",
            "svg": "https://flagcdn.com/gs.svg"
        }
    },
    {
        "id": 156,
        "country": "American Samoa",
        "phoneCode": "+1684",
        "countryCode": "AS",
        "flag": "🇦🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/as.png",
            "svg": "https://flagcdn.com/as.svg"
        }
    },
    {
        "id": 157,
        "country": "Israel",
        "phoneCode": "+972",
        "countryCode": "IL",
        "flag": "🇮🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/il.png",
            "svg": "https://flagcdn.com/il.svg"
        }
    },
    {
        "id": 158,
        "country": "Zimbabwe",
        "phoneCode": "+263",
        "countryCode": "ZW",
        "flag": "🇿🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/zw.png",
            "svg": "https://flagcdn.com/zw.svg"
        }
    },
    {
        "id": 159,
        "country": "Burundi",
        "phoneCode": "+257",
        "countryCode": "BI",
        "flag": "🇧🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/bi.png",
            "svg": "https://flagcdn.com/bi.svg"
        }
    },
    {
        "id": 160,
        "country": "Guinea-Bissau",
        "phoneCode": "+245",
        "countryCode": "GW",
        "flag": "🇬🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/gw.png",
            "svg": "https://flagcdn.com/gw.svg"
        }
    },
    {
        "id": 161,
        "country": "Malawi",
        "phoneCode": "+265",
        "countryCode": "MW",
        "flag": "🇲🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/mw.png",
            "svg": "https://flagcdn.com/mw.svg"
        }
    },
    {
        "id": 162,
        "country": "United States",
        "phoneCode": "+1201",
        "countryCode": "US",
        "flag": "🇺🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/us.png",
            "svg": "https://flagcdn.com/us.svg"
        }
    },
    {
        "id": 163,
        "country": "Panama",
        "phoneCode": "+507",
        "countryCode": "PA",
        "flag": "🇵🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/pa.png",
            "svg": "https://flagcdn.com/pa.svg"
        }
    },
    {
        "id": 164,
        "country": "Madagascar",
        "phoneCode": "+261",
        "countryCode": "MG",
        "flag": "🇲🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/mg.png",
            "svg": "https://flagcdn.com/mg.svg"
        }
    },
    {
        "id": 165,
        "country": "Georgia",
        "phoneCode": "+995",
        "countryCode": "GE",
        "flag": "🇬🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/ge.png",
            "svg": "https://flagcdn.com/ge.svg"
        }
    },
    {
        "id": 166,
        "country": "Portugal",
        "phoneCode": "+351",
        "countryCode": "PT",
        "flag": "🇵🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/pt.png",
            "svg": "https://flagcdn.com/pt.svg"
        }
    },
    {
        "id": 167,
        "country": "Mali",
        "phoneCode": "+223",
        "countryCode": "ML",
        "flag": "🇲🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/ml.png",
            "svg": "https://flagcdn.com/ml.svg"
        }
    },
    {
        "id": 168,
        "country": "Cook Islands",
        "phoneCode": "+682",
        "countryCode": "CK",
        "flag": "🇨🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/ck.png",
            "svg": "https://flagcdn.com/ck.svg"
        }
    },
    {
        "id": 169,
        "country": "Iceland",
        "phoneCode": "+354",
        "countryCode": "IS",
        "flag": "🇮🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/is.png",
            "svg": "https://flagcdn.com/is.svg"
        }
    },
    {
        "id": 170,
        "country": "Chad",
        "phoneCode": "+235",
        "countryCode": "TD",
        "flag": "🇹🇩",
        "flags": {
            "png": "https://flagcdn.com/w320/td.png",
            "svg": "https://flagcdn.com/td.svg"
        }
    },
    {
        "id": 171,
        "country": "Turkey",
        "phoneCode": "+90",
        "countryCode": "TR",
        "flag": "🇹🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/tr.png",
            "svg": "https://flagcdn.com/tr.svg"
        }
    },
    {
        "id": 172,
        "country": "Christmas Island",
        "phoneCode": "+61",
        "countryCode": "CX",
        "flag": "🇨🇽",
        "flags": {
            "png": "https://flagcdn.com/w320/cx.png",
            "svg": "https://flagcdn.com/cx.svg"
        }
    },
    {
        "id": 173,
        "country": "Costa Rica",
        "phoneCode": "+506",
        "countryCode": "CR",
        "flag": "🇨🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/cr.png",
            "svg": "https://flagcdn.com/cr.svg"
        }
    },
    {
        "id": 174,
        "country": "Lithuania",
        "phoneCode": "+370",
        "countryCode": "LT",
        "flag": "🇱🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/lt.png",
            "svg": "https://flagcdn.com/lt.svg"
        }
    },
    {
        "id": 175,
        "country": "Cocos (Keeling) Islands",
        "phoneCode": "+61",
        "countryCode": "CC",
        "flag": "🇨🇨",
        "flags": {
            "png": "https://flagcdn.com/w320/cc.png",
            "svg": "https://flagcdn.com/cc.svg"
        }
    },
    {
        "id": 176,
        "country": "India",
        "phoneCode": "+91",
        "countryCode": "IN",
        "flag": "🇮🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/in.png",
            "svg": "https://flagcdn.com/in.svg"
        }
    },
    {
        "id": 177,
        "country": "Egypt",
        "phoneCode": "+20",
        "countryCode": "EG",
        "flag": "🇪🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/eg.png",
            "svg": "https://flagcdn.com/eg.svg"
        }
    },
    {
        "id": 178,
        "country": "Jersey",
        "phoneCode": "+44",
        "countryCode": "JE",
        "flag": "🇯🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/je.png",
            "svg": "https://flagcdn.com/je.svg"
        }
    },
    {
        "id": 179,
        "country": "Vatican City",
        "phoneCode": "+3906698",
        "countryCode": "VA",
        "flag": "🇻🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/va.png",
            "svg": "https://flagcdn.com/va.svg"
        }
    },
    {
        "id": 180,
        "country": "South Africa",
        "phoneCode": "+27",
        "countryCode": "ZA",
        "flag": "🇿🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/za.png",
            "svg": "https://flagcdn.com/za.svg"
        }
    },
    {
        "id": 181,
        "country": "Jamaica",
        "phoneCode": "+1876",
        "countryCode": "JM",
        "flag": "🇯🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/jm.png",
            "svg": "https://flagcdn.com/jm.svg"
        }
    },
    {
        "id": 182,
        "country": "Brazil",
        "phoneCode": "+55",
        "countryCode": "BR",
        "flag": "🇧🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/br.png",
            "svg": "https://flagcdn.com/br.svg"
        }
    },
    {
        "id": 183,
        "country": "Denmark",
        "phoneCode": "+45",
        "countryCode": "DK",
        "flag": "🇩🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/dk.png",
            "svg": "https://flagcdn.com/dk.svg"
        }
    },
    {
        "id": 184,
        "country": "Belgium",
        "phoneCode": "+32",
        "countryCode": "BE",
        "flag": "🇧🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/be.png",
            "svg": "https://flagcdn.com/be.svg"
        }
    },
    {
        "id": 185,
        "country": "Paraguay",
        "phoneCode": "+595",
        "countryCode": "PY",
        "flag": "🇵🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/py.png",
            "svg": "https://flagcdn.com/py.svg"
        }
    },
    {
        "id": 186,
        "country": "Kazakhstan",
        "phoneCode": "+76",
        "countryCode": "KZ",
        "flag": "🇰🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/kz.png",
            "svg": "https://flagcdn.com/kz.svg"
        }
    },
    {
        "id": 187,
        "country": "Namibia",
        "phoneCode": "+264",
        "countryCode": "NA",
        "flag": "🇳🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/na.png",
            "svg": "https://flagcdn.com/na.svg"
        }
    },
    {
        "id": 188,
        "country": "Ivory Coast",
        "phoneCode": "+225",
        "countryCode": "CI",
        "flag": "🇨🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/ci.png",
            "svg": "https://flagcdn.com/ci.svg"
        }
    },
    {
        "id": 189,
        "country": "Mauritius",
        "phoneCode": "+230",
        "countryCode": "MU",
        "flag": "🇲🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/mu.png",
            "svg": "https://flagcdn.com/mu.svg"
        }
    },
    {
        "id": 190,
        "country": "Palestine",
        "phoneCode": "+970",
        "countryCode": "PS",
        "flag": "🇵🇸",
        "flags": {
            "png": "https://flagcdn.com/w320/ps.png",
            "svg": "https://flagcdn.com/ps.svg"
        }
    },
    {
        "id": 191,
        "country": "Montenegro",
        "phoneCode": "+382",
        "countryCode": "ME",
        "flag": "🇲🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/me.png",
            "svg": "https://flagcdn.com/me.svg"
        }
    },
    {
        "id": 192,
        "country": "British Indian Ocean Territory",
        "phoneCode": "+246",
        "countryCode": "IO",
        "flag": "🇮🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/io.png",
            "svg": "https://flagcdn.com/io.svg"
        }
    },
    {
        "id": 193,
        "country": "Malaysia",
        "phoneCode": "+60",
        "countryCode": "MY",
        "flag": "🇲🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/my.png",
            "svg": "https://flagcdn.com/my.svg"
        }
    },
    {
        "id": 194,
        "country": "Djibouti",
        "phoneCode": "+253",
        "countryCode": "DJ",
        "flag": "🇩🇯",
        "flags": {
            "png": "https://flagcdn.com/w320/dj.png",
            "svg": "https://flagcdn.com/dj.svg"
        }
    },
    {
        "id": 195,
        "country": "Solomon Islands",
        "phoneCode": "+677",
        "countryCode": "SB",
        "flag": "🇸🇧",
        "flags": {
            "png": "https://flagcdn.com/w320/sb.png",
            "svg": "https://flagcdn.com/sb.svg"
        }
    },
    {
        "id": 196,
        "country": "Guyana",
        "phoneCode": "+592",
        "countryCode": "GY",
        "flag": "🇬🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/gy.png",
            "svg": "https://flagcdn.com/gy.svg"
        }
    },
    {
        "id": 197,
        "country": "Curaçao",
        "phoneCode": "+599",
        "countryCode": "CW",
        "flag": "🇨🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/cw.png",
            "svg": "https://flagcdn.com/cw.svg"
        }
    },
    {
        "id": 198,
        "country": "Kyrgyzstan",
        "phoneCode": "+996",
        "countryCode": "KG",
        "flag": "🇰🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/kg.png",
            "svg": "https://flagcdn.com/kg.svg"
        }
    },
    {
        "id": 199,
        "country": "Philippines",
        "phoneCode": "+63",
        "countryCode": "PH",
        "flag": "🇵🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/ph.png",
            "svg": "https://flagcdn.com/ph.svg"
        }
    },
    {
        "id": 200,
        "country": "Papua New Guinea",
        "phoneCode": "+675",
        "countryCode": "PG",
        "flag": "🇵🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/pg.png",
            "svg": "https://flagcdn.com/pg.svg"
        }
    },
    {
        "id": 201,
        "country": "Trinidad and Tobago",
        "phoneCode": "+1868",
        "countryCode": "TT",
        "flag": "🇹🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/tt.png",
            "svg": "https://flagcdn.com/tt.svg"
        }
    },
    {
        "id": 202,
        "country": "Libya",
        "phoneCode": "+218",
        "countryCode": "LY",
        "flag": "🇱🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/ly.png",
            "svg": "https://flagcdn.com/ly.svg"
        }
    },
    {
        "id": 203,
        "country": "Aruba",
        "phoneCode": "+297",
        "countryCode": "AW",
        "flag": "🇦🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/aw.png",
            "svg": "https://flagcdn.com/aw.svg"
        }
    },
    {
        "id": 204,
        "country": "Russia",
        "phoneCode": "+73",
        "countryCode": "RU",
        "flag": "🇷🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/ru.png",
            "svg": "https://flagcdn.com/ru.svg"
        }
    },
    {
        "id": 205,
        "country": "North Macedonia",
        "phoneCode": "+389",
        "countryCode": "MK",
        "flag": "🇲🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/mk.png",
            "svg": "https://flagcdn.com/mk.svg"
        }
    },
    {
        "id": 206,
        "country": "Grenada",
        "phoneCode": "+1473",
        "countryCode": "GD",
        "flag": "🇬🇩",
        "flags": {
            "png": "https://flagcdn.com/w320/gd.png",
            "svg": "https://flagcdn.com/gd.svg"
        }
    },
    {
        "id": 207,
        "country": "Canada",
        "phoneCode": "+1",
        "countryCode": "CA",
        "flag": "🇨🇦",
        "flags": {
            "png": "https://flagcdn.com/w320/ca.png",
            "svg": "https://flagcdn.com/ca.svg"
        }
    },
    {
        "id": 208,
        "country": "French Polynesia",
        "phoneCode": "+689",
        "countryCode": "PF",
        "flag": "🇵🇫",
        "flags": {
            "png": "https://flagcdn.com/w320/pf.png",
            "svg": "https://flagcdn.com/pf.svg"
        }
    },
    {
        "id": 209,
        "country": "Eswatini",
        "phoneCode": "+268",
        "countryCode": "SZ",
        "flag": "🇸🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/sz.png",
            "svg": "https://flagcdn.com/sz.svg"
        }
    },
    {
        "id": 210,
        "country": "Iran",
        "phoneCode": "+98",
        "countryCode": "IR",
        "flag": "🇮🇷",
        "flags": {
            "png": "https://flagcdn.com/w320/ir.png",
            "svg": "https://flagcdn.com/ir.svg"
        }
    },
    {
        "id": 211,
        "country": "Japan",
        "phoneCode": "+81",
        "countryCode": "JP",
        "flag": "🇯🇵",
        "flags": {
            "png": "https://flagcdn.com/w320/jp.png",
            "svg": "https://flagcdn.com/jp.svg"
        }
    },
    {
        "id": 212,
        "country": "China",
        "phoneCode": "+86",
        "countryCode": "CN",
        "flag": "🇨🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/cn.png",
            "svg": "https://flagcdn.com/cn.svg"
        }
    },
    {
        "id": 213,
        "country": "Saint Barthélemy",
        "phoneCode": "+590",
        "countryCode": "BL",
        "flag": "🇧🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/bl.png",
            "svg": "https://flagcdn.com/bl.svg"
        }
    },
    {
        "id": 214,
        "country": "Angola",
        "phoneCode": "+244",
        "countryCode": "AO",
        "flag": "🇦🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/ao.png",
            "svg": "https://flagcdn.com/ao.svg"
        }
    },
    {
        "id": 215,
        "country": "Ireland",
        "phoneCode": "+353",
        "countryCode": "IE",
        "flag": "🇮🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/ie.png",
            "svg": "https://flagcdn.com/ie.svg"
        }
    },
    {
        "id": 216,
        "country": "Norway",
        "phoneCode": "+47",
        "countryCode": "NO",
        "flag": "🇳🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/no.png",
            "svg": "https://flagcdn.com/no.svg"
        }
    },
    {
        "id": 217,
        "country": "Mozambique",
        "phoneCode": "+258",
        "countryCode": "MZ",
        "flag": "🇲🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/mz.png",
            "svg": "https://flagcdn.com/mz.svg"
        }
    },
    {
        "id": 218,
        "country": "Jordan",
        "phoneCode": "+962",
        "countryCode": "JO",
        "flag": "🇯🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/jo.png",
            "svg": "https://flagcdn.com/jo.svg"
        }
    },
    {
        "id": 219,
        "country": "Gambia",
        "phoneCode": "+220",
        "countryCode": "GM",
        "flag": "🇬🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/gm.png",
            "svg": "https://flagcdn.com/gm.svg"
        }
    },
    {
        "id": 220,
        "country": "Singapore",
        "phoneCode": "+65",
        "countryCode": "SG",
        "flag": "🇸🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/sg.png",
            "svg": "https://flagcdn.com/sg.svg"
        }
    },
    {
        "id": 221,
        "country": "San Marino",
        "phoneCode": "+378",
        "countryCode": "SM",
        "flag": "🇸🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/sm.png",
            "svg": "https://flagcdn.com/sm.svg"
        }
    },
    {
        "id": 222,
        "country": "Chile",
        "phoneCode": "+56",
        "countryCode": "CL",
        "flag": "🇨🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/cl.png",
            "svg": "https://flagcdn.com/cl.svg"
        }
    },
    {
        "id": 223,
        "country": "United States Minor Outlying Islands",
        "phoneCode": "+268",
        "countryCode": "UM",
        "flag": "🇺🇲",
        "flags": {
            "png": "https://flagcdn.com/w320/um.png",
            "svg": "https://flagcdn.com/um.svg"
        }
    },
    {
        "id": 224,
        "country": "Benin",
        "phoneCode": "+229",
        "countryCode": "BJ",
        "flag": "🇧🇯",
        "flags": {
            "png": "https://flagcdn.com/w320/bj.png",
            "svg": "https://flagcdn.com/bj.svg"
        }
    },
    {
        "id": 225,
        "country": "Sri Lanka",
        "phoneCode": "+94",
        "countryCode": "LK",
        "flag": "🇱🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/lk.png",
            "svg": "https://flagcdn.com/lk.svg"
        }
    },
    {
        "id": 226,
        "country": "Belarus",
        "phoneCode": "+375",
        "countryCode": "BY",
        "flag": "🇧🇾",
        "flags": {
            "png": "https://flagcdn.com/w320/by.png",
            "svg": "https://flagcdn.com/by.svg"
        }
    },
    {
        "id": 227,
        "country": "Caribbean Netherlands",
        "phoneCode": "+599",
        "countryCode": "BQ",
        "flag": "🇧🇶",
        "flags": {
            "png": "https://flagcdn.com/w320/bq.png",
            "svg": "https://flagcdn.com/bq.svg"
        }
    },
    {
        "id": 228,
        "country": "Peru",
        "phoneCode": "+51",
        "countryCode": "PE",
        "flag": "🇵🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/pe.png",
            "svg": "https://flagcdn.com/pe.svg"
        }
    },
    {
        "id": 229,
        "country": "Guernsey",
        "phoneCode": "+44",
        "countryCode": "GG",
        "flag": "🇬🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/gg.png",
            "svg": "https://flagcdn.com/gg.svg"
        }
    },
    {
        "id": 230,
        "country": "Åland Islands",
        "phoneCode": "+35818",
        "countryCode": "AX",
        "flag": "🇦🇽",
        "flags": {
            "png": "https://flagcdn.com/w320/ax.png",
            "svg": "https://flagcdn.com/ax.svg"
        }
    },
    {
        "id": 231,
        "country": "Dominican Republic",
        "phoneCode": "+1809",
        "countryCode": "DO",
        "flag": "🇩🇴",
        "flags": {
            "png": "https://flagcdn.com/w320/do.png",
            "svg": "https://flagcdn.com/do.svg"
        }
    },
    {
        "id": 232,
        "country": "Tokelau",
        "phoneCode": "+690",
        "countryCode": "TK",
        "flag": "🇹🇰",
        "flags": {
            "png": "https://flagcdn.com/w320/tk.png",
            "svg": "https://flagcdn.com/tk.svg"
        }
    },
    {
        "id": 233,
        "country": "United Arab Emirates",
        "phoneCode": "+971",
        "countryCode": "AE",
        "flag": "🇦🇪",
        "flags": {
            "png": "https://flagcdn.com/w320/ae.png",
            "svg": "https://flagcdn.com/ae.svg"
        }
    },
    {
        "id": 234,
        "country": "Australia",
        "phoneCode": "+61",
        "countryCode": "AU",
        "flag": "🇦🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/au.png",
            "svg": "https://flagcdn.com/au.svg"
        }
    },
    {
        "id": 235,
        "country": "Guatemala",
        "phoneCode": "+502",
        "countryCode": "GT",
        "flag": "🇬🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/gt.png",
            "svg": "https://flagcdn.com/gt.svg"
        }
    },
    {
        "id": 236,
        "country": "Lebanon",
        "phoneCode": "+961",
        "countryCode": "LB",
        "flag": "🇱🇧",
        "flags": {
            "png": "https://flagcdn.com/w320/lb.png",
            "svg": "https://flagcdn.com/lb.svg"
        }
    },
    {
        "id": 237,
        "country": "Luxembourg",
        "phoneCode": "+352",
        "countryCode": "LU",
        "flag": "🇱🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/lu.png",
            "svg": "https://flagcdn.com/lu.svg"
        }
    },
    {
        "id": 238,
        "country": "Albania",
        "phoneCode": "+355",
        "countryCode": "AL",
        "flag": "🇦🇱",
        "flags": {
            "png": "https://flagcdn.com/w320/al.png",
            "svg": "https://flagcdn.com/al.svg"
        }
    },
    {
        "id": 239,
        "country": "Vanuatu",
        "phoneCode": "+678",
        "countryCode": "VU",
        "flag": "🇻🇺",
        "flags": {
            "png": "https://flagcdn.com/w320/vu.png",
            "svg": "https://flagcdn.com/vu.svg"
        }
    },
    {
        "id": 240,
        "country": "DR Congo",
        "phoneCode": "+243",
        "countryCode": "CD",
        "flag": "🇨🇩",
        "flags": {
            "png": "https://flagcdn.com/w320/cd.png",
            "svg": "https://flagcdn.com/cd.svg"
        }
    },
    {
        "id": 241,
        "country": "Northern Mariana Islands",
        "phoneCode": "+1670",
        "countryCode": "MP",
        "flag": "🇲🇵",
        "flags": {
            "png": "https://flagcdn.com/w320/mp.png",
            "svg": "https://flagcdn.com/mp.svg"
        }
    },
    {
        "id": 242,
        "country": "Czechia",
        "phoneCode": "+420",
        "countryCode": "CZ",
        "flag": "🇨🇿",
        "flags": {
            "png": "https://flagcdn.com/w320/cz.png",
            "svg": "https://flagcdn.com/cz.svg"
        }
    },
    {
        "id": 243,
        "country": "Italy",
        "phoneCode": "+39",
        "countryCode": "IT",
        "flag": "🇮🇹",
        "flags": {
            "png": "https://flagcdn.com/w320/it.png",
            "svg": "https://flagcdn.com/it.svg"
        }
    },
    {
        "id": 244,
        "country": "Uganda",
        "phoneCode": "+256",
        "countryCode": "UG",
        "flag": "🇺🇬",
        "flags": {
            "png": "https://flagcdn.com/w320/ug.png",
            "svg": "https://flagcdn.com/ug.svg"
        }
    },
    {
        "id": 245,
        "country": "Switzerland",
        "phoneCode": "+41",
        "countryCode": "CH",
        "flag": "🇨🇭",
        "flags": {
            "png": "https://flagcdn.com/w320/ch.png",
            "svg": "https://flagcdn.com/ch.svg"
        }
    },
    {
        "id": 246,
        "country": "Mexico",
        "phoneCode": "+52",
        "countryCode": "MX",
        "flag": "🇲🇽",
        "flags": {
            "png": "https://flagcdn.com/w320/mx.png",
            "svg": "https://flagcdn.com/mx.svg"
        }
    },
    {
        "id": 247,
        "country": "Taiwan",
        "phoneCode": "+886",
        "countryCode": "TW",
        "flag": "🇹🇼",
        "flags": {
            "png": "https://flagcdn.com/w320/tw.png",
            "svg": "https://flagcdn.com/tw.svg"
        }
    },
    {
        "id": 248,
        "country": "Guinea",
        "phoneCode": "+224",
        "countryCode": "GN",
        "flag": "🇬🇳",
        "flags": {
            "png": "https://flagcdn.com/w320/gn.png",
            "svg": "https://flagcdn.com/gn.svg"
        }
    },
    {
        "id": 249,
        "country": "Finland",
        "phoneCode": "+358",
        "countryCode": "FI",
        "flag": "🇫🇮",
        "flags": {
            "png": "https://flagcdn.com/w320/fi.png",
            "svg": "https://flagcdn.com/fi.svg"
        }
    }
]