<!-- BreadCrumbs -->
<div class="container">
  <div class="row">
    <div class="col breadCrumbs-wrapper">
      <i class="bi bi-house-door pe-auto" routerLink="/home"></i>
      <i class="bi bi-chevron-right"></i>
      <app-ng-dynamic-breadcrumb
        class="breadCrumb-link"
        [lastLinkColor]="'#000'"
        [symbol]="'>'"
      >
      </app-ng-dynamic-breadcrumb>
    </div>
  </div>
</div>
<!-- /BreadCrumbs -->

<div class="container Categories-wrapper">
  <div class="row">
    <div
      class="block col-12 col-md-6"
      *ngFor="let category of bookingService.categories; let i = index"
      [ngClass]="i === 0 ? 'col-lg-6' : 'col-lg-3'"
      [routerLink]="['/categories', category.id]"
    >
      <div class="content">
        <img
          class="img-fluid"
          [src]="
            category?.images?.url ||
            '../../../assets/images/categories/img1.jpg'
          "
          alt=""
        />
        <div class="category-title" [ngClass]="colors[i]">
          <h3>{{ getTranslatedData(category.name) }}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
