import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TokenResponseModel } from 'src/app/models/auth.model';
import { brAccessToken } from 'src/app/utils/general-constants';
import { distinctUntilChanged, first, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenStoreService {
  private readonly tokenObservable: Observable<TokenResponseModel | null> | undefined;
  private tokenSubject: ReplaySubject<TokenResponseModel | null> = new ReplaySubject(1);

  constructor(
    private storage: LocalStorage
  ) {
    storage.getItem(brAccessToken).subscribe((token: any) => {
      this.tokenSubject.next(token);
    });

    this.tokenObservable = this.tokenSubject?.pipe(
      distinctUntilChanged(),
      publishReplay(1),
      refCount(),
    );
  }

  get loggedIn() {
    return this.onLoginChange?.pipe(first());
  }

  get onLoginChange() {
    return this.onTokenChange?.pipe(map(token => {
      return !!token;
    }));
  }

  get onTokenChange() {
    return this.tokenObservable;
  }

  get token() {
    return this.onTokenChange?.pipe(first());
  }

  async updateToken(value?: TokenResponseModel | null) {
    let promise: Observable<boolean>;

    if (value) {
      promise = this.storage.setItem(brAccessToken, value);
    } else {
      promise = this.storage.removeItem(brAccessToken);
    }

    return promise.subscribe(() => {
      this.tokenSubject.next(value);
      return value;
    });
  }
}
