<!-- BreadCrumbs -->
<div class="container">
  <div class="row">
    <div class="col-sm-6 breadCrumbs-wrapper">
      <i class="bi bi-house-door pe-auto" routerLink="/home"></i>
      <i class="bi bi-chevron-right"></i>
      <app-ng-dynamic-breadcrumb
        class="breadCrumb-link"
        [lastLinkColor]="'#000'"
        [symbol]="'>'"
      >
      </app-ng-dynamic-breadcrumb>
    </div>
    <div class="col-sm-6" *ngIf="timeCounter">
      <div class="text-end mt-4 timer-cart">
        {{ "Cart.TimeRemaining" | translate }}
        <span class="timer">{{ timeCounter }}</span>
      </div>
    </div>
  </div>
</div>
<!-- /BreadCrumbs -->

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="cart-item-box" *ngFor="let group of data">
        <div class="pt-5">
          <div class="item-counter">
            <button (click)="create(group)">
              <img src="../../../assets/images/icons/plus.svg" alt="" />
            </button>
            <span class="item-quantity">{{ group.length }}</span>
            <button (click)="remove(group)">
              <img src="../../../assets/images/icons/minus.svg" alt="" />
            </button>
          </div>
        </div>

        <div class="item-content">
          <!-- First item -->
          <div class="cart-item" *ngFor="let item of group">
            <div class="item-details">
              <div class="row align-items-center">
                <div class="item-info col-lg-3 col-md-6">
                  <div class="item-img">
                    <img [src]="setItemMedia(item)" alt="" />
                  </div>
                  <div class="br-item-info">
                    <div class="br-item-name">
                      {{
                        getTranslatedData(
                          item?.availability?.availabilityable?.name!
                        )
                      }}
                    </div>
                    <div class="br-item-price">
                      {{
                        calculateAvailabilityPrice(item)
                          | currency: "EUR":"symbol":"1.2-2":locale
                      }}
                    </div>
                  </div>
                </div>
                <div class="person-details col-lg-4 col-md-6">
                  <div
                    class="row"
                    *ngIf="
                      item?.availability?.availabilityable?.category?.type !==
                      'package'
                    "
                  >
                    <!--                    <ng-container-->
                    <!--                      *ngIf="-->
                    <!--                        item?.availability?.availabilityable?.category?.type ==-->
                    <!--                          'game';-->
                    <!--                        else adultsChild-->
                    <!--                      "-->
                    <!--                    >-->
                    <!--                      &lt;!&ndash; Seats &ndash;&gt;-->
                    <!--                      <div class="no-of-persons col-6 text-center">-->
                    <!--                        <div class="mb-2">-->
                    <!--                          Seats-->
                    <!--                          <span>-->
                    <!--                            ({{-->
                    <!--                              calculateAvailabilityPrice(item)-->
                    <!--                                | currency: "EUR":"symbol":"1.2-2":locale-->
                    <!--                            }})-->
                    <!--                          </span>-->
                    <!--                        </div>-->
                    <!--                        <div class="item-counter">-->
                    <!--                          <button (click)="manageSeats(item, 'increment')">-->
                    <!--                            <img-->
                    <!--                              src="../../../assets/images/icons/plus.svg"-->
                    <!--                              alt=""-->
                    <!--                            />-->
                    <!--                          </button>-->
                    <!--                          <span class="item-quantity">{{ item?.seats }}</span>-->
                    <!--                          <button (click)="manageSeats(item, 'decrement')">-->
                    <!--                            <img-->
                    <!--                              src="../../../assets/images/icons/minus.svg"-->
                    <!--                              alt=""-->
                    <!--                            />-->
                    <!--                          </button>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </ng-container>-->
                    <!-- Adults -->
                    <div class="no-of-persons col-6 text-center">
                      <div class="mb-2">
                        {{ "Cart.Adults" | translate }}
                        <span *ngIf="item.availability.fix_price == false">
                          ({{
                            item?.availability?.variable_price
                              ? item?.availability?.adult_price
                              : (item?.availability?.price_per_person
                                | currency: "EUR":"symbol":"1.2-2":locale)
                          }})
                        </span>
                      </div>
                      <div class="item-counter">
                        <button (click)="increment(item, 'number_of_adults')">
                          <img
                            src="../../../assets/images/icons/plus.svg"
                            alt=""
                          />
                        </button>
                        <span class="item-quantity">{{
                          item?.number_of_adults
                        }}</span>
                        <button (click)="decrement(item, 'number_of_adults')">
                          <img
                            src="../../../assets/images/icons/minus.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                    <!-- Children -->
                    <div class="no-of-persons col-6 text-center">
                      <div class="mb-2">
                        {{ "Cart.Children" | translate }}
                        <span *ngIf="item.availability.fix_price == false">
                          ({{
                            item?.availability?.variable_price
                              ? item?.availability?.child_price
                              : (item?.availability?.price_per_person
                                | currency: "EUR":"symbol":"1.2-2":locale)
                          }})
                        </span>
                      </div>
                      <div class="item-counter">
                        <button (click)="increment(item, 'number_of_children')">
                          <img
                            src="../../../assets/images/icons/plus.svg"
                            alt=""
                          />
                        </button>
                        <span class="item-quantity">{{
                          item?.number_of_children
                        }}</span>
                        <button (click)="decrement(item, 'number_of_children')">
                          <img
                            src="../../../assets/images/icons/minus.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="add-complementaries text-center-lg col-lg-3 col-md-6"
                >
                  <ng-container
                    *ngIf="
                      item?.availability?.availabilityable?.category?.type !==
                      'package'
                    "
                  >
                    <!--                    <div-->
                    <!--                      *ngIf="-->
                    <!--                        item?.availability?.availabilityable?.category?.type !==-->
                    <!--                        'game'-->
                    <!--                      "-->
                    <!--                    >-->
                    <!--                      <a (click)="showExtraItemDialog(item)">-->
                    <!--                        {{ "Cart.ExtraItems" | translate }}-->
                    <!--                      </a>-->
                    <!--                    </div>-->
                    <div>
                      <a (click)="showComplementaryDialog(item)">
                        {{ "Cart.Complementaries" | translate }}
                      </a>
                    </div>
                  </ng-container>
                </div>
                <div class="item-price col-lg-2 col-md-6 ps-lg-0">
                  {{ item?.price | currency: "EUR":"symbol":"1.2-2":locale }}
                  <br />
                  <small style="font-size: 12px; font-weight: normal">
                    (incl.
                    <span class="text-info" (click)="showServiceFee(item)">{{
                      "Cart.ServiceFee" | translate
                    }}</span>
                    {{ "Common.And" | translate }}
                    <span class="text-info" (click)="showTaxDetail(item)">{{
                      "Cart.Tax" | translate
                    }}</span
                    >)
                  </small>
                </div>
              </div>

              <div class="row align-items-center mt-3" *ngIf="item.is_expired">
                <div class="col-12">
                  {{ "Cart.Unavailable" | translate }}
                </div>
              </div>

              <ng-container
                *ngIf="
                  item.complementaryItems.length > 0 ||
                  item.extraItems.length > 0
                "
              >
                <div class="row complentery-row">
                  <div class="col-12">
                    <hr />
                  </div>
                  <div class="col-lg-4 col-6">
                    <div>
                      <strong
                        >{{ "Cart.ComplimentaryExtraItems" | translate }}
                      </strong>
                    </div>
                  </div>
                  <div class="col-lg-1 col-3">
                    <div>
                      <strong>{{ "Cart.Price" | translate }}</strong>
                    </div>
                  </div>
                  <div class="col-lg-1 col-3">
                    <div>
                      <strong>{{ "Cart.Total" | translate }}</strong>
                    </div>
                  </div>
                </div>

                <!-- complementary items -->
                <div
                  class="row complentery-row"
                  *ngFor="let ci of item.complementaryItems"
                >
                  <div class="d-flex col-lg-4 col-6 align-items-center">
                    <span
                      class="red-rounder-close rounded-circle bg-danger text-white fs-6 lh-1 p-1 me-2"
                      (click)="removeComplementaryItem(item, ci)"
                    >
                      <i class="bi bi-x lh-1"></i>
                    </span>
                    <div>
                      {{ ci.quantity }} X {{ ci.title }}
                      <a
                        class="d-inline-block underline ps-2 text-info"
                        (click)="showAllergicItemDialog(ci, item)"
                      >
                        {{ "Complementaries.ViewEdit" | translate }}
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-1 col-3">
                    <div>
                      {{ ci.price | currency: "EUR":"symbol":"1.2-2":locale }}
                    </div>
                  </div>
                  <div class="col-lg-1 col-3">
                    <div>
                      <strong>{{
                        ci.price * ci.quantity
                          | currency: "EUR":"symbol":"1.2-2":locale
                      }}</strong>
                    </div>
                  </div>
                </div>

                <!-- extra Items -->
                <div
                  class="row complentery-row"
                  *ngFor="let ei of item.extraItems"
                >
                  <div class="col-lg-4 col-6">
                    <div>{{ ei.pivot_quantity }} X {{ ei.name }}</div>
                  </div>
                  <div class="col-lg-1 col-3">
                    <div>
                      {{ ei.price | currency: "EUR":"symbol":"1.2-2":locale }}
                    </div>
                  </div>
                  <div class="col-lg-1 col-3">
                    <div>
                      <strong>{{
                        ei.price * ei.pivot_quantity
                          | currency: "EUR":"symbol":"1.2-2":locale
                      }}</strong>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="col-12">
                <hr />
              </div>
              <!-- Voucher -->
              <div
                class="voucher-cart"
                *ngIf="
                  item?.availability?.availabilityable?.category?.type !==
                    'package' &&
                  item?.availability?.availabilityable?.category?.type !==
                    'game'
                "
              >
                <div class="mb-2">
                  <strong>{{ "Cart.Voucher" | translate }}:</strong>
                </div>
                <div>
                  <form [formGroup]="form" (ngSubmit)="applyVoucher(item)">
                    <input
                      type="text"
                      name="voucher"
                      formControlName="promo_code"
                    />
                    <button type="submit">
                      {{ "Buttons.Add" | translate }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div class="remove-item">
              <a (click)="deleteReservation(item)"><i class="bi bi-x"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Buttons row -->
  <div class="bottom-btn-row row mb-5" *ngIf="data?.length">
    <div class="col-md-6">
      <button class="outline-reset" (click)="reset()">
        {{ "Buttons.ResetCart" | translate }}
      </button>
    </div>
    <div class="col-md-6 text-end">
      <button class="outline-continue" routerLink="/category-listing">
        {{ "Buttons.ContinueShopping" | translate }}
      </button>
      <button class="proceed-btn" (click)="proceedToCheckout()">
        {{ "Buttons.ProceedToCheckout" | translate }}
      </button>
    </div>
  </div>

  <br-no-record-found *ngIf="!data.length"></br-no-record-found>
</div>
