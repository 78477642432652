import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'br-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent extends BaseComponent implements OnInit {
  constructor(
    protected router: Router,
    protected authService: AuthService,
    public userService: UserService,
    protected translate: TranslateService
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {}
}
