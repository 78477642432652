import * as moment from 'moment';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { CategoryListModel } from 'src/app/models/booking.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BookingService } from '../../services/booking/booking.service';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import { DEFAULT_DATE_FORMAT } from '../../utils/general-constants';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BaseComponent } from 'src/app/pages/base.component';

@Component({
  selector: 'br-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit {
  form!: FormGroup;
  newsletterForm!: FormGroup;
  categories: Array<CategoryListModel> = [];
  minDate = moment(new Date()).format('YYYY-MM-DD');

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    public bookingService: BookingService,
    private errorService: ErrorHandlerService,
  ) {
    super(router, authService, userService, translate);
  }

  ngOnInit(): void {
    const date = moment(new Date()).format(DEFAULT_DATE_FORMAT);
    this.form = new FormGroup({
      type: new FormControl(null, Validators.compose([Validators.required])),
      date: new FormControl(date, [Validators.required]),
    });

    this.newsletterForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });

    if (this.translate.currentLang) {
      this.getCategoriesListing();
    }
  }

  getCategoriesListing() {
    this.bookingService.categoryListing().subscribe(
      (res: CategoryListModel[]) => {
        this.bookingService.categories = res;
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }

  submit(): void {
    this.router.navigate(['categories', this.form.value.type], {
      state: { params: this.form.value },
    });
  }

  getFormControl(form: FormGroup, controlName: string): FormGroup {
    return form.get(controlName) as FormGroup;
  }

  subscribe() {
    this.userService.subscribeNewsletter(this.newsletterForm.value).subscribe(
      (res) => {
        this.newsletterForm.reset();
        this.errorService.showToast(res.message, 'success');
      },
      (error) => {
        this.newsletterForm.reset();
        this.errorService.handle(error);
      }
    );
  }
}
