<!-- Right -->
<div class="Booking-wrapper ps-2">
  <!-- title + Grid View -->
  <div class="d-flex flex-row align-items-center justify-content-between mb-4">
    <h3 class="card-title">{{ "MyPayments.Title" | translate }}</h3>
    <!-- Pagination -->
    <div
      class="col-2 page-selection d-inline-flex flex-row align-items-center justify-content-start"
    >
      <label class="form-label me-2 mb-0 text-gray-600">
        {{ "MyPayments.Filter" | translate }}:
      </label>
      <select
        class="form-select form-select-sm"
        (change)="statusChanged($event)"
      >
        <option *ngFor="let item of statuses" [value]="item.value | lowercase">
          {{ item.name | translate }}
        </option>
      </select>
    </div>
  </div>

  <!-- Booking tier-->
  <div
    class="row booking-card align-items-center justify-content-between px-3 rounded"
    *ngFor="let item of payments"
  >
    <div class="col-4 p-0 d-flex align-items-center justify-content-star">
      <img
        [src]="'../../../../assets/images/profile/dp.png'"
        class="rounded img-fluid tier-img"
        alt=""
      />
      <div
        class="d-flex flex-column align-items-start justify-content-center me-5"
      >
        <h3 class="fw-bolder">{{ item?.order?.code }}</h3>
        <span class="text-gray-600 pe-2 title-gray">{{
          item?.order?.created_at | date
        }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-gray-600 title-gray">{{
          "MyPayments.CustomerName" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray">{{
          item?.order?.customer_details?.user_name || "NA"
        }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-gray-600 title-gray"
          >{{ "MyPayments.Booking" | translate }} #</span
        >
        <span class="text-black fw-bold detail-gray">{{
          item?.order?.code
        }}</span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <span class="text-gray-600 title-gray">{{
          "MyPayments.Status" | translate
        }}</span>
        <span
          class="text-success fw-bold detail-gray fw-bold"
          [ngClass]="setStatus(item?.status!)"
        >
          {{ item?.status | titlecase }}
        </span>
      </div>
    </div>

    <div class="col col-auto">
      <div class="d-flex flex-column align-items-end justify-content-center">
        <span class="text-gray-600 title-gray">{{
          "MyPayments.TotalAmount" | translate
        }}</span>
        <span class="text-black fw-bold detail-gray"
          >{{ item?.amount | currency: "EUR":"symbol":"1.2-2":locale }}
        </span>
      </div>
    </div>

    <div class="col col-auto pe-0">
      <button class="btn btn-link p-0 text-decoration-none">
        <span class="br-icon-info text-gray-600 fs-4"></span>
      </button>
    </div>
  </div>

  <br-no-record-found
    *ngIf="!payments?.length && !loading"
  ></br-no-record-found>
</div>
<!-- /Right -->
