import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../pages/base.component';
import { CartService } from '../../services/cart/cart.service';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SelectedLanguageModel, UserModel } from 'src/app/models/user.model';
import { ErrorHandlerService } from 'src/app/services/error/error-handler.service';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'br-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  loading: boolean = false;
  @Input() isHome!: boolean;
  userDetail!: UserModel | null;

  public locale: string = 'en';
  cartCount: number = 0;
  @Input() languages!: Array<SelectedLanguageModel>;
  @Input() language!: SelectedLanguageModel;
  @Output() languageChange = new EventEmitter<SelectedLanguageModel>();

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected userService: UserService,
    protected translate: TranslateService,
    private errorService: ErrorHandlerService,
    private cartService: CartService
  ) {
    super(router, authService, userService, translate);
    this.loading = true;
    this.userService.userSubject.subscribe(
      (res: UserModel | null) => {
        this.loading = false;
        this.userDetail = res;
      },
      (error) => {
        this.errorService.handle(error);
      }
    );

    this.cartService.cartUpdated$.subscribe((res) => {
      this.cartCount = res;
    });
  }

  ngOnInit(): void {
    this.translate.onLangChange
      .pipe(
        map((event) => event.lang),
        startWith(this.translate.currentLang || this.translate.defaultLang),
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((locale) => {
        this.locale = locale;
      });
  }

  onLanguageChange(event: Event, lang: SelectedLanguageModel) {
    event.preventDefault();
    event.stopPropagation();

    this.languageChange.emit(lang);
  }

  logout(): void {
    this.authService.logout()?.subscribe(
      (res) => {
        this.loggedIn = false;
        this.router.navigate(['login']).catch((reason) => console.warn(reason));
      },
      (error) => {
        this.errorService.handle(error);
      }
    );
  }
}
