<!-- BreadCrumbs -->
<div class="container">
  <div class="row">
    <div class="col-sm-6 breadCrumbs-wrapper">
      <i class="bi bi-house-door" routerLink="/home"></i>
      <i class="bi bi-chevron-right"></i>
      <app-ng-dynamic-breadcrumb
        class="breadCrumb-link"
        [lastLinkColor]="'#000'"
        [symbol]="'>'"
      >
      </app-ng-dynamic-breadcrumb>
    </div>
    <div class="col-sm-6" *ngIf="timeCounter">
      <div class="text-end mt-4 timer-cart">
        {{ "Cart.TimeRemaining" | translate }}
        <span class="timer">{{ timeCounter }}</span>
      </div>
    </div>
  </div>
</div>
<!-- /BreadCrumbs -->

<div class="container category-listing-wrapper">
  <div class="row">
    <!-- Left -->
    <div class="col-12 col-md-4 col-lg-3 category-listing-filters">
      <div class="filters-wrapper">
        <!-- Filters Header -->
        <div
          class="filters-header rounded-top py-2 px-3 bg-secondary d-flex flex-row align-items-center justify-content-between"
        >
          <h4 class="text-light fs-5 fw-bold mb-0">
            {{ "CategoryListing.Filters" | translate }}
          </h4>
          <button class="btn btn-link text-light pe-0" (click)="clearFilters()">
            {{ "CategoryListing.ClearAll" | translate }}
          </button>
        </div>
        <!-- /Filters Header -->

        <!-- Filter  Date-->
        <div class="mb-3 px-3 mt-3 filters-select-date">
          <label for="exampleFormControlInput1" class="form-label">
            {{ "CategoryListing.SelectDate" | translate }}
          </label>
          <input
            type="date"
            class="form-control rounded"
            id="exampleFormControlInput1"
            [(ngModel)]="selectedDate"
            (change)="onDateChange($event)"
            [min]="minDate"
            max="2030-12-31"
          />
        </div>
        <!-- /Filter  Date-->

        <!-- Selected Category Filters -->
        <div class="collapes-list-wrapper">
          <button
            class="btn btn-link p-3 py-0 text-decoration-none text-dark d-flex align-items-start justify-content-between w-100"
            type="button"
            (click)="showMoreBoats = !showMoreBoats"
          >
            <span class="fw-bold">{{ getTranslatedData(categoryName) }}</span>
            <i
              class="bi bi-chevron-down"
              [ngClass]="{
                'bi-chevron-down': showMoreBoats,
                'bi-chevron-up': !showMoreBoats
              }"
            ></i>
          </button>
          <div *ngIf="showMoreBoats">
            <ul class="p-3 pb-0 m-0">
              <li
                class="form-check mb-2"
                *ngFor="let item of categoryDetail?.subcategory_filters"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  [value]="item?.id"
                  [checked]="item?.checked"
                  id="flexCheckDefault"
                  (change)="loadCategoryData($event)"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  {{ getTranslatedData(item?.name) }}
                </label>
                <span class="float-end badge bg-light">{{ item?.count }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Selected Category Filters -->

        <!-- Other Category Filters -->
        <div class="collapes-list-wrapper border-0">
          <button
            class="btn btn-link p-3 py-0 text-decoration-none text-dark d-flex align-items-start justify-content-between w-100"
            type="button"
            (click)="showMoreOthers = !showMoreOthers"
          >
            <span class="fw-bold">{{
              "CategoryListing.Others" | translate
            }}</span>
            <i
              class="bi bi-chevron-down"
              [ngClass]="{
                'bi-chevron-down': showMoreOthers,
                'bi-chevron-up': !showMoreOthers
              }"
            ></i>
          </button>
          <div *ngIf="showMoreOthers">
            <ul class="p-3 pb-0 m-0">
              <li
                class="form-check mb-2"
                *ngFor="
                  let item of categoryDetail?.othercategory_filters;
                  let i = index
                "
              >
                <input
                  class="form-check-input pointer"
                  type="checkbox"
                  [value]="item?.id"
                  id="OtherCategoryFilters-{{ i }}"
                  (change)="loadData($event)"
                  [checked]="item?.checked"
                />
                <label
                  class="form-check-label pointer"
                  for="OtherCategoryFilters-{{ i }}"
                >
                  {{ getTranslatedData(item?.name) }}
                </label>
                <span class="float-end badge bg-light">{{ item.count }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- /Other Category Filters -->
      </div>
    </div>
    <!-- /Left -->

    <!-- Right -->
    <div class="col-12 col-lg-9 col-md-8 category-listing-content">
      <!-- title + Grid View -->
      <div
        class="title-and-grid-toggle flex-row align-items-center justify-content-between"
      >
        <h5 class="card-title">
          {{ allData?.length }}
          {{ getTranslatedData(categoryName) }} found
        </h5>

        <div class="options d-flex align-items-center justify-content-start">
          <!-- Pagination -->
          <div
            class="page-selection d-inline-flex flex-row align-items-center justify-content-start"
          >
            <label for="exampleInputEmail1" class="form-label me-2">
              {{ "CategoryListing.Show" | translate }}:
            </label>
            <select
              id="exampleInputEmail1"
              class="form-select form-select-sm"
              [(ngModel)]="pageSize"
              aria-label="Default select example"
              (change)="showItems($event)"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <!-- grid list toggle -->
          <div
            class="ps-4 d-inline-flex flex-row align-items-center justify-content-start"
          >
            <button
              class="btn btn-link p-0 pe-3"
              (click)="toggleView()"
              [ngClass]="gridActive ? 'active' : ''"
              [disabled]="gridActive"
            >
              <i class="br-icon-thumbnail-view-active"></i>
            </button>
            <button
              class="btn btn-link p-0 px-0"
              (click)="toggleView()"
              [ngClass]="listActive ? 'active' : ''"
              [disabled]="listActive"
            >
              <i class="br-icon-list-view-active"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Boats | Bike | Services | Game - Title + Grid View -->
      <div
        class="categoroes-container row listView"
        [ngClass]="{ 'gridView flex-wrap': gridActive, listView: listActive }"
        *ngIf="pagedData?.length"
      >
        <!-- Boats | Bike | Services Item -->
        <div
          class="item-wrapper col-12 p-0 rounded mb-4"
          [ngClass]="{ 'col-lg-6': gridActive, 'col-12': listActive }"
          *ngFor="let item of pagedData"
        >
          <!-- Category Item -->
          <br-category-item
            [item]="item"
            [listActive]="listActive"
            [gridActive]="gridActive"
            [type]="type"
            [categoryId]="categoryId"
            [confirmationDate]="selectedDate"
            [durationList]="sunDuration"
            [parkTimings]="parkTimings"
            [sunInfo]="sunInfo"
          >
          </br-category-item>
        </div>
        <!-- Boats | Bike | Services | Game Item -->
      </div>

      <br-no-record-found *ngIf="!pagedData?.length"></br-no-record-found>

      <!-- Pagination of listing -->
      <div
        class="br-pagination-wrapper mb-5 d-flex justify-content-between align-items-center"
        *ngIf="pager?.pages && pager?.pages?.length"
      >
        <div class="br-pagination-no">
          {{ "CategoryListing.Pages" | translate }}:
          <span>{{ pager?.currentPage }} / {{ pager?.totalPages }}</span>
        </div>

        <nav aria-label="Page navigation example">
          <ul class="pagination mb-0">
            <li class="page-item">
              <a
                class="page-link"
                aria-label="Previous"
                (click)="setPage(pager.currentPage - 1)"
              >
                <span aria-hidden="true"
                  ><i class="bi bi-chevron-left"></i
                ></span>
              </a>
            </li>
            <li class="page-item" *ngFor="let page of pager.pages">
              <a
                class="page-link"
                (click)="setPage(page)"
                [ngClass]="{ active: pager.currentPage === page }"
              >
                {{ page }}
              </a>
            </li>
            <li class="page-item">
              <a
                class="page-link"
                aria-label="Next"
                (click)="setPage(pager.currentPage + 1)"
              >
                <span aria-hidden="true"
                  ><i class="bi bi-chevron-right"></i
                ></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- /Right -->
  </div>
</div>
