import { Config } from 'src/app/config';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable, of, throwError } from 'rxjs';
import { BaseUserModel } from 'src/app/models/user.model';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TokenStoreService } from './../token/token-store.service';
import {
  switchMap,
  catchError,
  map,
  tap,
  publishReplay,
  refCount,
} from 'rxjs/operators';
import {
  EmailVerificationModel,
  ForgotPasswordModel,
  LoginModel,
  MessageResponseModel,
  RegisterUserModel,
  ResendVerificationCodeModel,
  ResetPasswordModel,
  TokenResponseModel,
} from 'src/app/models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends HttpService {
  private refreshing: Observable<TokenResponseModel> | null = null;

  constructor(
    http: HttpClient,
    private tokenStoreService: TokenStoreService,
    private handler: HttpBackend
  ) {
    super(http);
  }

  get loggedIn() {
    return this.tokenStoreService.loggedIn;
  }

  get onLoginChange(): any {
    return this.tokenStoreService.onLoginChange;
  }

  get token() {
    return this.tokenStoreService.token;
  }

  register(request: RegisterUserModel) {
    return this.http.post<{ is_verified: boolean; message: string }>(
      `${Config.apiUrl}/auth/register`,
      request
    );
  }

  registerDealer(request: BaseUserModel) {
    return this.http.post<{ message: string }>(
      `${Config.apiUrl}/dealer/registration/request`,
      request
    );
  }

  login(credentials: LoginModel) {
    return this.http
      .post<TokenResponseModel>(`${Config.apiUrl}/auth/login`, credentials)
      .pipe(switchMap((token) => this.tokenStoreService.updateToken(token)));
  }

  emailVerification(request: { email: string; token: number }) {
    return this.http.post<MessageResponseModel>(
      `${Config.apiUrl}/auth/verify`,
      request
    );
  }

  resendVerificationCode(request: ResendVerificationCodeModel) {
    return this.http.post(`${Config.apiUrl}/auth/verify/resend`, request);
  }

  forgot(request: ForgotPasswordModel) {
    return this.http.post(`${Config.apiUrl}/auth/forgot`, request);
  }

  reset(request: ResetPasswordModel) {
    return this.http
      .post<TokenResponseModel>(`${Config.apiUrl}/auth/reset-password`, request)
      .pipe(switchMap((token) => this.tokenStoreService.updateToken(token)));
  }

  refresh(currentToken: string): Observable<TokenResponseModel> {
    if (!currentToken) {
      return throwError('unauthorized');
    }

    if (!this.refreshing) {
      this.refreshing = this.http
        .post<TokenResponseModel>(`${Config.apiUrl}/auth/refresh`, null, {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        })
        .pipe(
          switchMap((newToken) =>
            this.tokenStoreService.updateToken(newToken).then(() => newToken)
          ),
          tap(() => {
            this.refreshing = null;
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.refreshing;
  }

  refreshToken(request: string) {
    const userName = { user_name: request };
    if (!this.refreshing) {
      this.refreshing = this.http
        .post<TokenResponseModel>(`${Config.apiUrl}/auth/login`, userName)
        .pipe(
          switchMap((newToken) =>
            this.tokenStoreService.updateToken(newToken).then(() => newToken)
          ),
          tap(() => {
            this.refreshing = null;
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.refreshing;
  }

  logout() {
    return this.token?.pipe(
      switchMap((token) => {
        if (token) {
          return this.http.post(`${Config.apiUrl}/auth/logout`, null).pipe(
            catchError(() => of()),
            switchMap(() => this.tokenStoreService.updateToken(null)),
            map(() => true)
          );
        }
        return of(true);
      })
    );
  }
}
