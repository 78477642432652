<form [formGroup]="form" (ngSubmit)="changePassword()">
  <div class="modal-body p-5">
    <div class="row">
      <div class="col">
        <h4 class="fs-4 fw-bold mb-4 text-center">
          {{ "ChangePassword.Title" | translate }}
        </h4>
      </div>

      <div class="col-12">
        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="oldPassword"
            placeholder="{{
              'ChangePassword.Placeholder.CurrentPassword' | translate
            }}"
            formControlName="old_password"
          />
          <label for="oldPassword">{{
            "ChangePassword.CurrentPassword" | translate
          }}</label>
          <br-validation-messages
            [control]="getFormControl(form, 'old_password')"
            [label]="'ChangePassword.CurrentPassword' | translate"
          ></br-validation-messages>
        </div>
      </div>

      <div class="col-12">
        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="newPassword"
            placeholder="{{
              'ChangePassword.Placeholder.NewPassword' | translate
            }}"
            formControlName="password"
          />
          <label for="newPassword">{{
            "ChangePassword.NewPassword" | translate
          }}</label>
          <br-validation-messages
            [control]="getFormControl(form, 'password')"
            [label]="'ChangePassword.NewPassword' | translate"
          ></br-validation-messages>
        </div>
      </div>

      <div class="col-12">
        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="confirmNewPassword"
            placeholder="{{
              'ChangePassword.Placeholder.ConfirmNewPassword' | translate
            }}"
            formControlName="password_confirmation"
          />
          <label for="confirmNewPassword">{{
            "ChangePassword.ConfirmNewPassword" | translate
          }}</label>
          <br-validation-messages
            [control]="getFormControl(form, 'password_confirmation')"
            [label]="'ChangePassword.ConfirmNewPassword' | translate"
            [relatedControls]="'ChangePassword.NewPassword' | translate"
          ></br-validation-messages>
        </div>
      </div>

      <div class="col text-center mt-3">
        <button
          class="btn btn-success d-inline-block text-light px-5 text-14"
          [disabled]="!form.valid"
        >
          {{ "Buttons.Update" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
