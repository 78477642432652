import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../services/payment/payment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../services/error/error-handler.service';
import { UserService } from '../../services/user/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'br-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit {
  status!: string;
  code!: string;
  message!: string;

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private errorService: ErrorHandlerService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res: any) => {
      const paymentId = parseInt(res['id']);
      this.getStatus(paymentId);
    });
  }

  getStatus(id: number) {
    this.userService.showLoader.next(true);
    this.paymentService
      .molliePaymentStatus(id)
      .pipe(finalize(() => this.userService.showLoader.next(false)))
      .subscribe({
        next: (res) => {
          this.status = res?.status;
          this.code = res?.order_code;
          this.message = res?.message;
        },
        error: (error: HttpErrorResponse) => {
          this.errorService.handle(error);
        },
      });
  }

  redirectToMollie() {
    this.userService.showLoader.next(true);
    this.paymentService
      .molliePayment({ code: this.code })
      .pipe(
        finalize(() => {
          this.userService.showLoader.next(false);
        })
      )
      .subscribe(
        (res) => {
          window.open(res.data, '_self');
        },
        (error) => {
          this.errorService.handle(error);
        }
      );
  }
}
